import config from 'src/config';

import { Script } from '@/account/components/Script';
import { useIsCustomerPortalPath } from '@/account/utils';

export function PrismicPreviewToolbarScript() {
  const isCustomerPortalPath = useIsCustomerPortalPath();

  if (!isCustomerPortalPath) {
    return null;
  }

  if (!config('prismic.toolbarEnabled')) {
    return null;
  }

  return (
    <Script
      async
      defer
      src="https://static.cdn.prismic.io/prismic.js?new=true&repo=thefarmersdog"
    />
  );
}
