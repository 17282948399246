import { v4 as uuidv4 } from 'uuid';

import { track } from '@farmersdog/analytics';
import { SubscriptionType, cookie } from '@farmersdog/lead-browser-storage';
import {
  mountTikTokEvent,
  mountISpotRecipesConversion,
  mountAmazonTagAddToShoppingCart,
  ga4Mounter,
  getGa4ItemsFromRecipes,
  TIKTOK_ADDTOCART_EVENT,
} from '@farmersdog/pixels';

import { segmentTrackEvents } from '../analytics';
import { getTosaFacebookPixelAttributes } from '../utils/vendors/facebook';
import { mountXAddToCart } from '../vendors';

import { useRequestAttributesContext } from './useRequestAttributes';

import type { OnRecipesCompletedArgs } from './useHandleFormCompleted';
import type { FetchLeadQuery, FetchQuoteQuery } from '../graphql/types';

export function getDiscountedPreTaxTotal(
  quote: FetchQuoteQuery['fetchQuote']
): string {
  return (quote.trial.discount.subtotal / 100).toFixed(2);
}

function getSelectedRecipes(pets: FetchLeadQuery['lead']['pets'] | undefined) {
  if (!pets) {
    return [];
  }

  const allRecipeNames: Set<string> = new Set();

  pets.forEach(pet =>
    pet.selection?.fresh?.options?.recipes?.forEach(recipe =>
      allRecipeNames.add(recipe)
    )
  );

  return Array.from(allRecipeNames);
}

export function useHandleRecipesCompleted() {
  const requestAttributes = useRequestAttributesContext();

  const handleRecipesCompleted = ({
    isLastPet,
    lead,
    quote,
  }: OnRecipesCompletedArgs) => {
    const totalAmount = quote ? getDiscountedPreTaxTotal(quote) : undefined;

    const facebookPixelAttributes = lead
      ? getTosaFacebookPixelAttributes({ lead })
      : undefined;

    const ga4Items = getGa4ItemsFromRecipes(getSelectedRecipes(lead?.pets));
    const eventUuid = uuidv4();

    const leadEmail = lead?.email;

    // Run a segment track event for each pet.
    track(segmentTrackEvents.recipes_success, {
      totalAmount,
      firstName: lead?.firstName,
      email: leadEmail,
      product_line: SubscriptionType.Fresh,
      label: SubscriptionType.Fresh,
      fbp: cookie.getFacebookPixelCookie(),
      fcp: cookie.getFacebookClickIdCookie(),
      ...requestAttributes,
      ...facebookPixelAttributes,
      eventId: eventUuid,
      city: lead?.city?.toLowerCase(),
      state: lead?.state?.toLowerCase(),
      zip: lead?.zip,
    });

    ga4Mounter.recipesSuccess({
      data: { items: ga4Items },
      isLastPet,
    });

    // special case - capture each "add to cart" for TikTok
    void mountTikTokEvent(TIKTOK_ADDTOCART_EVENT, {
      event_id: eventUuid,
      email: leadEmail,
    });

    // Only mount pixel events for final pet's recipe page submission.
    if (isLastPet) {
      mountISpotRecipesConversion({
        utmSource: requestAttributes?.utm_source,
      });
      mountAmazonTagAddToShoppingCart();
      mountXAddToCart();
    }
  };

  return handleRecipesCompleted;
}
