import { track } from '@farmersdog/analytics';
import { cookie } from '@farmersdog/lead-browser-storage';

const events = {
  fluent_lead_conversion: 'Fluent - Lead Conversion',
};

type FluentEventAttributes = {
  email?: string;
};

export const trackFluentLeadConversion = ({ email }: FluentEventAttributes) => {
  const fluentId = cookie.getFluentId();
  if (fluentId) {
    track(events.fluent_lead_conversion, {
      email,
      fluentId,
    });
  }
};
