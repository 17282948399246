import type React from 'react';
import classNames from 'classnames';

import { Text, useViewport } from '@farmersdog/corgi-x';
import { getPreferredScrollBehavior } from '@farmersdog/corgi';

import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

import NavSelect from './NavSelect';
import styles from './BenefitsNav.module.scss';
import { BenefitsKeys } from './types';

interface BenefitsNavProps {
  [BenefitsKeys.health]: React.MutableRefObject<HTMLElement | undefined>;
  [BenefitsKeys.picky]: React.MutableRefObject<HTMLElement | undefined>;
  [BenefitsKeys.weight]: React.MutableRefObject<HTMLElement | undefined>;
}

function BenefitsNav(props: BenefitsNavProps) {
  const handleNavigation = (benefit: BenefitsKeys) => {
    props[benefit]?.current?.scrollIntoView({
      behavior: getPreferredScrollBehavior(),
    });
  };

  const { xs, lg } = useViewport();
  const isMobile = xs && !lg;

  const { isAccessibilityThemeOn } = useAccessibilityThemeContext();

  return (
    <div
      className={classNames(styles.container, {
        [styles.accessibilityThemeExtraPadding]:
          isAccessibilityThemeOn && !isMobile,
      })}
    >
      <Text
        variant="article-20"
        fontStyle="italic"
        color="charcoal-3"
        className={styles.title}
      >
        See the benefits of feeding fresh
      </Text>
      <NavSelect onChange={handleNavigation}>
        <option value={BenefitsKeys.health}>Boosting Health & Wellness</option>
        <option value={BenefitsKeys.picky}>Pleasing Picky Eaters</option>
        <option value={BenefitsKeys.weight}>Attaining Optimal Weight</option>
      </NavSelect>
    </div>
  );
}

export default BenefitsNav;
