import type { DiyRecipe } from '../types';
import { PrimaryProtein } from '../types';
import beefAndCarrotsWebp from '../assets/beef-and-carrots.webp';

export const beefAndCarrots: DiyRecipe = {
  name: 'Beef & Carrots',
  primaryProtein: PrimaryProtein.Beef,
  ingredients: [
    {
      name: '90% lean ground beef',
      quantity: 1115,
      unit: 'g',
      alternativeQuantity: 2.5,
      alternativeUnit: 'lb',
    },
    {
      name: 'sweet potato, shredded or finely diced',
      quantity: 290,
      unit: 'g',
      alternativeQuantity: 2.25,
      alternativeUnit: 'cup',
    },
    {
      name: 'carrots, shredded or cut into 1/4 inch dice',
      quantity: 290,
      unit: 'g',
      alternativeQuantity: 2.25,
      alternativeUnit: 'cup',
    },
    {
      name: 'spinach, chopped',
      quantity: 175,
      unit: 'g',
      alternativeIngredients: [
        { name: 'fresh', quantity: 5.75, unit: 'cup' },
        { name: 'frozen', quantity: 1, unit: 'cup' },
      ],
    },
    {
      name: 'eggs (yolk and white)',
      quantity: 135,
      unit: 'g',
      alternativeQuantity: 3,
      alternativeUnit: 'large',
    },
    {
      name: 'The Farmer’s Dog DIY Nutrient Mix',
      quantity: 1,
      unit: 'packet',
    },
    {
      name: 'sunflower oil',
      quantity: 14,
      unit: 'g',
      alternativeQuantity: 3,
      alternativeUnit: 'teaspoon',
    },
    {
      name: 'salmon oil',
      quantity: 1,
      unit: 'g',
      alternativeQuantity: 0.25,
      alternativeUnit: 'teaspoon',
    },
  ],
  ingredientSummary:
    'Ground Beef, Sweet Potato, Carrots, Spinach, Eggs, DIY Nutrient Mix, Sunflower Oil, Salmon Oil',
  ingredientsNote:
    'Fresh or frozen vegetables may be used in these recipes. For best results, weigh by grams and use food safe thermometer.',
  id: 'beef-and-carrots',
  cookTime: '35 mins',
  calories: 2430,
  popularity: 1,
  utensils: [
    '5+ quart pot/skillet/dutch oven',
    'knife and cutting board or food processor (or buy pre-cut frozen)',
    'spatula',
    'kitchen scale',
    'food safe thermometer',
  ],
  image: beefAndCarrotsWebp,
  instructions: [
    {
      name: 'Step 1',
      description:
        'Place pot/skillet/dutch oven over medium to medium-high heat on the stovetop. Pour in the sunflower oil and spread evenly to coat the bottom.',
    },
    {
      name: 'Step 2',
      description:
        'Add the ground beef. Break apart and stir until the meat is no longer pink and is broken into small pieces.',
    },
    {
      name: 'Step 3',
      description:
        'Add the sweet potato and carrots. Cook until the vegetables are soft and easily break apart or are tender when pierced with a fork.',
      note: 'Be sure to check that all ingredients are soft and easily broken in order to avoid choking hazards. Large chunks may require additional cooking time. Do not drain any liquid or fat that’s a result of the cooking process.',
    },
    {
      name: 'Step 4',
      description:
        'Add the spinach, cook until wilted, and stir to evenly distribute throughout the mixture.',
    },
    {
      name: 'Step 5',
      description:
        'Add the eggs and salmon oil to the pot. Break the yolks and stir to evenly distribute. Cook until the eggs are firm and no longer runny. Using a food-safe thermometer, check the temperature in several places to verify that a food-safe temperature of 165°F is reached throughout. Otherwise, ensure all meat and eggs are cooked thoroughly.',
      note: 'In order to maintain safe, ready-to-eat meals it is important that all food reaches the USDA guidelines for safety.',
    },
    {
      name: 'Step 6',
      description:
        'Remove the pot from the heat and sprinkle the {numberOfPackets} of The Farmer’s Dog DIY Nutrient Mix evenly across the surface. Stir to evenly distribute throughout the food until you can no longer see the nutrient mix.',
    },
  ],
  videoId: '4ig4aoamwy',
};
