import { useMemo } from 'react';

import { tosaToken } from '@farmersdog/lead-browser-storage';

import { useQuery } from '../../../services/apollo';
import leadQuery from '../lead.graphql';

import type { FetchLeadQuery, FetchLeadQueryVariables } from '../../types';

// TODO: Remove options argument once we remove cvr_new_lead_query_hook Split flag
export function useGetLead() {
  const skip = !canGetLead();

  const { data, error } = useQuery<FetchLeadQuery, FetchLeadQueryVariables>(
    leadQuery,
    { skip }
  );

  // If we're skipping the call, or if the call errored out, we can return
  // `null` as `lead` so that the caller knows we're not waiting for the lead
  const dataToReturn = useMemo(() => {
    return skip || error ? { lead: null } : data;
  }, [data, error, skip]);

  return { data: dataToReturn };
}

function canGetLead() {
  return Boolean(tosaToken.get());
}
