import classNames from 'classnames';
import { Text, Nowrap, useViewport } from '@farmersdog/corgi-x';
import Picture from 'src/components/Picture';

import {
  cbsSources,
  cbsSourcesKale,
  vogueSources,
  vogueSourcesKale,
  fastCompanySources,
  fastCompanySourcesKale,
  todayShowSources,
  todayShowSourcesKale,
} from './assets';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

import styles from './CompanyReviews.module.scss';

function CompanyReviews() {
  const { lg } = useViewport();
  const quoteVariant = lg ? 'article-20' : 'article-16';

  const { isAccessibilityThemeOn } = useAccessibilityThemeContext();

  // TODO - delete this var and us `data.js` again when the CVR_CTA_ACCESSIBILITY_HOMEPAGE experiment is over
  const DATA = [
    {
      logoSources: isAccessibilityThemeOn
        ? todayShowSourcesKale
        : todayShowSources,
      logoClassName: 'todayShow',
      companyName: 'Today Show',
      quote: (
        <span>
          An online pet food company that makes feeding real food to dogs{' '}
          <Nowrap>really easy.</Nowrap>
        </span>
      ),
    },
    {
      logoSources: isAccessibilityThemeOn
        ? fastCompanySourcesKale
        : fastCompanySources,
      logoClassName: 'fastCompany',
      companyName: 'Fast Company',
      quote: (
        <span>
          Researchers concluded that fresh diets do demonstrate a number of pet{' '}
          <Nowrap>health benefits.</Nowrap>
        </span>
      ),
    },
    {
      logoSources: isAccessibilityThemeOn ? vogueSourcesKale : vogueSources,
      logoClassName: 'vogue',
      companyName: 'Vogue',
      quote: (
        <span>
          It’s never deep frozen, and it never sits on a shelf. All you do is
          open the pack <Nowrap>and pour.</Nowrap>
        </span>
      ),
    },
    {
      logoSources: isAccessibilityThemeOn ? cbsSourcesKale : cbsSources,
      logoClassName: 'cbsNews',
      companyName: 'CBS News',
      quote: (
        <span>
          Owners can get healthy dog food shipped right to{' '}
          <Nowrap>their door.</Nowrap>
        </span>
      ),
    },
  ];

  return (
    <div
      className={classNames(styles.container, {
        [styles.kaleBackground]: isAccessibilityThemeOn,
      })}
    >
      <div className={styles.content}>
        {DATA.map(
          ({ logoSources, logoClassName, quote, companyName }, index) => (
            <div key={index} className={styles.review}>
              <div className={styles[logoClassName]}>
                <Picture
                  sources={logoSources}
                  className={styles.logo}
                  alt={`${companyName} Logo`}
                />
              </div>
              <Text
                as="blockquote"
                variant={quoteVariant}
                color={isAccessibilityThemeOn ? 'kale-3' : 'white'}
                className={styles.quote}
              >
                “{quote}”
              </Text>
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default CompanyReviews;
