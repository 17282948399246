import { PATH_HOME, PATH_SIGNUP_RECIPES } from '@farmersdog/constants/paths';
import { Link } from '@farmersdog/corgi';
import { Logo } from '@farmersdog/corgi-x';

import { PreviousLinkNavigationLabel } from '../../../../hooks/usePreviousLinkNavigation';
import { BackButton } from '../../../shared/SignupHeader/BackButton';

import styles from './LargeNavigation.module.css';
import { StepsLarge } from './StepsLarge';

interface LargeNavigationArgs {
  handleNavigatePrevious: () => void;
}

export function LargeNavigation({
  handleNavigatePrevious,
}: LargeNavigationArgs) {
  return (
    <div className={styles.large}>
      <nav className={styles.container} data-testid="header-nav-desktop">
        <div className={styles.logoContainer}>
          <Link
            to={PATH_HOME}
            aria-label={PreviousLinkNavigationLabel.homePage}
            data-testid="header-logo-desktop"
          >
            <Logo variant="product" size={50} className={styles.logo} />
          </Link>

          <BackButton
            previousSignupRoute={PATH_SIGNUP_RECIPES}
            handleNavigatePrevious={handleNavigatePrevious}
            navigatePreviousLinkLabel={
              PreviousLinkNavigationLabel.previousSignupPage
            }
          />
        </div>
        <StepsLarge />
      </nav>
    </div>
  );
}
