import { formatNames } from '@/account/utils/formatNames';

export interface FormatPetOptions {
  possessive?: boolean;
}

export function formatPetNames(
  names: string[],
  plural: string,
  options?: FormatPetOptions
): string {
  const isPlural: boolean = names.length > 1;
  let formattedText: string = isPlural
    ? `${plural}`
    : (formatNames(names) ?? '');
  if (options?.possessive) {
    formattedText = formattedText.concat(isPlural ? '’' : '’s');
  }
  return formattedText;
}
