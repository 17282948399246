import classNames from 'classnames';
import { useCombobox } from 'downshift';
import { useState, useMemo, useEffect, useRef } from 'react';

import type { WithRefProps } from '@farmersdog/corgi';
import { withRef, heading } from '@farmersdog/corgi';

import RemoveIcon from './assets/remove.svg';
import { fuzzySearch } from './search';
import { SearchableList } from './SearchableList';
import selectComboBoxStyles from './SelectComboBox.module.css';

import type { BreedType } from '../../../types';
import type { UseComboboxStateChange } from 'downshift';
import type { Dispatch, SetStateAction } from 'react';

type RefProps = WithRefProps<HTMLInputElement>;
export type SelectComboBoxProps = RefProps & SelectBaseProps;

interface SelectBaseProps {
  selectedBreed?: BreedType;
  availableBreedsList: BreedType[];
  accessibleLabel: string;
  shouldFocusInput?: boolean;
  setShouldFocusInput: Dispatch<SetStateAction<boolean>>;
  selectedBreedPosition?: number;
  unknownBreed: BreedType | undefined;
  shouldShowClearButton: boolean;
  handleBreedSelected: (breed: BreedType) => void;
  handleBreedRemoved: () => void;
  className?: string;
}

const typography = heading.create({ size: 28 });

export const SelectComboBox = withRef<HTMLInputElement, SelectComboBoxProps>(
  (selectProps: SelectComboBoxProps) => {
    const {
      selectedBreed,
      shouldShowClearButton,
      availableBreedsList,
      shouldFocusInput,
      setShouldFocusInput,
      selectedBreedPosition,
      handleBreedSelected,
      handleBreedRemoved,
      unknownBreed,
      className: inheritedClassnames,
    } = selectProps;

    const [isFocused, setIsFocused] = useState<boolean>(false);

    const [userInput, setUserInput] = useState<string>(
      selectedBreed?.name ?? ''
    );

    const fuzzySearchResults = useMemo(() => {
      return fuzzySearch({
        items: availableBreedsList,
        inputValue: userInput,
      });
    }, [availableBreedsList, userInput]);

    const dropdownItems = useMemo(() => {
      if (userInput.trim().length === 0 || userInput === selectedBreed?.name) {
        return availableBreedsList;
      }

      return [
        ...fuzzySearchResults.map(({ item }) => item),
        ...(unknownBreed ? [unknownBreed] : []),
      ];
    }, [
      availableBreedsList,
      fuzzySearchResults,
      selectedBreed,
      unknownBreed,
      userInput,
    ]);

    const firstListItemRef = useRef<HTMLDivElement>(null);

    const scrollToTopOfBreeds = () => {
      firstListItemRef.current?.scrollIntoView({
        behavior: 'instant',
        block: 'start',
      });
    };
    const onInputValueChange = ({
      inputValue,
    }: UseComboboxStateChange<BreedType>) => {
      scrollToTopOfBreeds();
      setUserInput(inputValue ?? '');
    };

    const toggleFocus = () => {
      setIsFocused(!isFocused);
      if (shouldFocusInput) {
        setShouldFocusInput(false);
      }
    };

    const handleBlur = () => {
      toggleFocus();

      if (!selectedBreed) {
        setUserInput('');
        return;
      }

      const lowerCasedSelectedBreed = selectedBreed.name.toLowerCase();
      if (lowerCasedSelectedBreed !== userInput.toLowerCase()) {
        onInputValueChange({
          inputValue: selectedBreed.name,
        } as UseComboboxStateChange<BreedType>);
      }
    };

    const onBreedSelect = ({
      selectedItem,
    }: UseComboboxStateChange<BreedType>) => {
      if (selectedItem) {
        handleBreedSelected(selectedItem);
      }
    };

    useEffect(() => {
      setUserInput(selectedBreed ? selectedBreed.name : '');
    }, [selectedBreed]);

    const {
      isOpen,
      getLabelProps,
      getMenuProps,
      getInputProps,
      getItemProps,
      setHighlightedIndex,
    } = useCombobox<BreedType>({
      items: dropdownItems,
      onInputValueChange,
      onSelectedItemChange: onBreedSelect,
    });

    useEffect(() => {
      const indexOfExactMatch = fuzzySearchResults.findIndex(
        ({ isExactMatch }) => isExactMatch
      );

      if (indexOfExactMatch !== -1) {
        setHighlightedIndex(indexOfExactMatch);
      }
    }, [fuzzySearchResults, setHighlightedIndex]);

    const shouldShowBreedsList = userInput !== '' && (isOpen || !selectedBreed);

    const inputRef = useRef<HTMLInputElement>(null);
    const inputProps = getInputProps({
      ref: inputRef,
      onFocus: toggleFocus,
      onBlur: handleBlur,
      value: userInput,
    });

    // This is necessary to deal with iOS Chrome's lack of firing a React
    // synthetic 'blur' event on the focused input when the user taps the
    // 'Done' button on the keyboard.
    useEffect(() => {
      const inputElement = inputRef.current;
      inputElement?.addEventListener('blur', inputProps.onBlur);
      return () => inputElement?.removeEventListener('blur', inputProps.onBlur);
    }, [inputProps.onBlur]);

    return (
      <div
        className={classNames(
          selectComboBoxStyles.ComboBoxContainer,
          inheritedClassnames,
          {
            [selectComboBoxStyles.focused]: isFocused,
          }
        )}
        id={`selectedBreed-${(selectedBreedPosition ?? 0) + 1}`}
      >
        <label className={selectComboBoxStyles.label} {...getLabelProps()}>
          {selectProps.accessibleLabel}
        </label>
        {shouldShowClearButton && (
          <button
            aria-label="clear selection"
            type="button"
            role="button"
            onClick={handleBreedRemoved}
            className={classNames(selectComboBoxStyles['ComboBox-delete-btn'], {
              [selectComboBoxStyles['ComboBox-delete-btn-placeholder']]:
                !selectedBreed,
            })}
            title={`Remove ${selectedBreed?.name ?? 'input'}`}
          >
            <RemoveIcon viewBox="0 0 44 44" />
          </button>
        )}
        <input
          placeholder="type and select breed"
          className={classNames(
            selectComboBoxStyles.input,
            typography.className
          )}
          {...inputProps}
          autoFocus={shouldFocusInput}
        />

        {/* Dropdown Menu */}
        <ul
          {...getMenuProps()}
          className={classNames(
            selectComboBoxStyles['Select-dropDown'],
            !shouldShowBreedsList && selectComboBoxStyles.hidden
          )}
        >
          {shouldShowBreedsList && (
            <SearchableList
              items={dropdownItems}
              getItemProps={getItemProps}
              firstItemRef={firstListItemRef}
            />
          )}
        </ul>
      </div>
    );
  }
);
