import { useSelector } from 'react-redux';
import { Grid, Picture } from '@farmersdog/corgi-x';
import { CtaTracker, TrackingEvent } from '@farmersdog/utils';
import { selectRequestAttributes } from 'src/reducers/referrer';
import { trackHomepageSuccess } from 'src/analytics';

import { ExtendedButton } from '../../../ExtendedButton';
import { homepageRedesignHeroSources } from './assets';

import styles from './HeroImage.module.css';

type HeroImageProps = {
  linkToFreshSignup: string;
};

const ctaLabel = 'Shop Fresh';

export const HeroImage = ({ linkToFreshSignup }: HeroImageProps) => {
  const requestAttributes = useSelector(selectRequestAttributes);

  return (
    <Grid bottomSpacing="md" className={styles.heroImageContainer}>
      <Picture
        className={styles.heroImage}
        sources={[
          homepageRedesignHeroSources.webP,
          homepageRedesignHeroSources.jpg,
          homepageRedesignHeroSources.png,
        ]}
        alt="A dog with a bowl of food" // TODO: define proper alt
      />
      <CtaTracker
        trackingFunction={trackHomepageSuccess}
        event={TrackingEvent.homepageSuccess}
        trackingData={{ location: 'Hero', ctaCopy: ctaLabel }}
        requestAttributes={requestAttributes}
      >
        <ExtendedButton
          variant="bordered-white"
          type="link"
          to={linkToFreshSignup}
          className={styles.heroImageCta}
        >
          {ctaLabel}
        </ExtendedButton>
      </CtaTracker>
    </Grid>
  );
};
