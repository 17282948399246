import noop from 'lodash/noop';

import type { GladlyAvailability } from './constants';

export const GLADLY_CHAT_BUTTON_ID = 'custom-gladly-chat-button';
export const GLADLY_UNREAD_MESSAGE_ID = 'with-unread-message';
export interface GladlyType {
  /** Get the current user */
  getUser?: () => GladlyUser | undefined;
  /** Set the user */
  setUser?: (user: GladlyUser) => void;
  /** Display Gladly Sidekick */
  show: () => void;
  /** Notify sidekick when website navigates without reloading the page */
  navigate: () => void;
  /** Check Live Chat availability. */
  getAvailability: () => GladlyAvailability;
}

declare global {
  interface Window {
    Gladly?: GladlyType;
  }
}

export interface GladlyUser {
  name: string;
  email: string;
}
export interface GladlyServiceInterface {
  identify(user: GladlyUser): void;
  show: () => void;
  navigate: () => void;
  hide: () => void;
  getAvailability: () => GladlyAvailability;
}

/**
 * Wrap gladly client so it is usable in all contexts. This allows the client
 * to be freely used without errors related to undefined `window`
 */
const gladlyProxy = new Proxy({} as GladlyType, {
  get<K extends keyof Window['Gladly']>(_: unknown, key: K) {
    if (
      typeof window !== 'undefined' &&
      window.Gladly &&
      typeof window.Gladly[key] === 'function'
    ) {
      return window.Gladly[key];
    }

    return noop;
  },
});

/**
 * Remove Sidekick button from the DOM.
 */
const hideChatButton = () => {
  if (!gladlyProxy) {
    return;
  }

  const chatButton = document.getElementById(GLADLY_CHAT_BUTTON_ID);

  if (chatButton) {
    chatButton.style.display = 'none';
  }
};

/**
 * Sets user’s name and email.
 * If the user is already set, it will not be set again.
 */
function setUser(user: GladlyUser) {
  const gladlyUser = gladlyProxy.getUser?.();

  if (!gladlyUser) {
    gladlyProxy.setUser?.({
      name: user.name,
      email: user.email,
    });
  }
}

export const GladlyService: GladlyServiceInterface = {
  /**
   * Identify a user in Gladly Sidekick conversations
   *
   * @param user - The user to identify
   */
  identify(user: GladlyUser) {
    void setUser(user);
  },
  show() {
    void gladlyProxy.show();
  },
  navigate() {
    void gladlyProxy.navigate();
  },
  hide() {
    void hideChatButton();
  },
  getAvailability() {
    return gladlyProxy.getAvailability();
  },
};
