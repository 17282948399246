import type { OnTosaCompleted } from '@farmersdog/tosa';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { fetchPreviousUser } from 'src/actions/signup';
import * as paths from '@farmersdog/constants/paths';

import { useThrowToErrorBoundary } from 'src/hooks/useThrowToErrorBoundary';
import { loginWithToken } from 'src/actions/auth';

export function useHandleTosaCompleted(): OnTosaCompleted {
  const history = useHistory();
  const dispatch = useDispatch();
  const throwToErrorBoundary = useThrowToErrorBoundary();

  const handleTosaCompleted: OnTosaCompleted = async ({
    registeredUserResponse,
  }) => {
    if (!registeredUserResponse) {
      return throwToErrorBoundary(new Error('Cannot find user'));
    }

    // @ts-expect-error This is the same type. It is disagreeing because we
    // have not fully typed the register response from TOSA
    const user: Reducer.User & { token: string } = registeredUserResponse;

    // Persist token in cookie for use outside of TOSA
    dispatch(loginWithToken(user.token));
    // Ensure clean signup state by recovering with existing user before navigating to website
    await dispatch(fetchPreviousUser(user.email));

    history.push(paths.PATH_SIGNUP_TREATS);
  };

  return handleTosaCompleted;
}
