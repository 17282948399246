import { PATH_TERMS } from '@farmersdog/constants/paths';
import { Link } from '@farmersdog/corgi-x';
import { AdditionalTerms } from 'src/components/AdditionalTerms';

const EMAIL = 'care@thefarmersdog.com';
const MAIL_TO = `mailto:${EMAIL}`;

export function ReferralTerms() {
  // TODO: Make this dynamic based on domain
  const origin = 'https://thefarmersdog.com';
  const termsUrl = `${origin}${PATH_TERMS}`;

  return (
    <AdditionalTerms title="Referral Terms">
      <p>
        Offer valid while supplies last online at thefarmersdog.com in the
        contiguous 48 United States only. Limit one per household; offer
        applicable to new customers only with an active automatic recurring
        subscription service. Pause, reschedule, or cancel anytime. Offer
        applies to The Farmer’s Dog fresh food products only; not to applicable
        taxes, shipping & handling charges. Offer cannot be combined with any
        other offers, credits or discounts. Offer is non-transferable and not
        valid for cash or cash equivalent. No adjustments on previous purchases.
        The Farmer’s Dog reserves the right to modify, terminate or cancel the
        offer at any time. For complete terms and conditions, please visit{' '}
        <Link to={termsUrl}>{termsUrl}</Link>. For inquiries, contact{' '}
        <Link to={MAIL_TO}>{EMAIL}</Link>.
      </p>
    </AdditionalTerms>
  );
}
