import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useLocation, Redirect } from 'react-router-dom';

import { QueryParameter } from '@farmersdog/constants';
import { PATH_LOGIN } from '@farmersdog/constants/paths';
import { StripeElementsProvider, StripeProvider } from '@farmersdog/corgi-x';

import config from 'src/config';
import ErrorBoundary from 'src/errors/ErrorBoundary';
import { selectIsLoggedIn } from 'src/reducers/auth';

import { LazyLoadedPawAuthenticatedRoutes } from '@/account/routes';

export default function CustomerAccount() {
  const location = useLocation();

  const isLoggedIn = useSelector(selectIsLoggedIn);

  const stripeKey = config('stripe.publicKey');

  if (!isLoggedIn) {
    return (
      <Redirect
        to={`${PATH_LOGIN}?${QueryParameter.NextPath}=${location.pathname}${location.search}${location.hash}`}
      />
    );
  }

  return (
    <StripeProvider publishableKey={stripeKey}>
      <StripeElementsProvider>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <ErrorBoundary>
          <LazyLoadedPawAuthenticatedRoutes />
        </ErrorBoundary>
      </StripeElementsProvider>
    </StripeProvider>
  );
}
