import type { DiyRecipe } from '../types';
import { PrimaryProtein } from '../types';
import genericDiyRecipeImageWebp from '../assets/generic-diy-recipe-image.webp';

export const chickenAndVegetables: DiyRecipe = {
  name: 'Chicken & Vegetables',
  primaryProtein: PrimaryProtein.Chicken,
  ingredients: [
    {
      name: 'ground chicken (93% lean)',
      quantity: 1325,
      unit: 'g',
      alternativeQuantity: 2.9,
      alternativeUnit: 'lb',
    },
    {
      name: 'sweet potato, shredded or finely diced',
      quantity: 490,
      unit: 'g',
      alternativeQuantity: 3.75,
      alternativeUnit: 'cup',
    },
    {
      name: 'spinach, chopped',
      quantity: 210,
      unit: 'g',
      alternativeIngredients: [
        { name: 'fresh', quantity: 7, unit: 'cup' },
        { name: 'frozen', quantity: 1.25, unit: 'cup' },
      ],
    },
    {
      name: 'zucchini',
      quantity: 190,
      unit: 'g',
      alternativeQuantity: 1.5,
      alternativeUnit: 'cup',
    },
    {
      name: 'egg whites',
      quantity: 66,
      unit: 'g',
      alternativeQuantity: 2,
      alternativeUnit: 'large',
    },
    {
      name: 'The Farmer’s Dog DIY Nutrient Mix',
      quantity: 1,
      unit: 'packet',
      alternativeQuantity: 22.5,
      alternativeUnit: 'g',
    },
    {
      name: 'sunflower oil',
      quantity: 15,
      unit: 'g',
      alternativeQuantity: 3.25,
      alternativeUnit: 'teaspoon',
    },
    {
      name: 'salmon oil',
      quantity: 8,
      unit: 'g',
      alternativeQuantity: 1.75,
      alternativeUnit: 'teaspoon',
    },
  ],
  ingredientSummary:
    'Ground Chicken, Sweet Potato, Spinach, Zucchini, Egg Whites, DIY Nutrient Mix, Sunflower Oil, Salmon Oil',
  ingredientsNote:
    'Fresh or frozen vegetables may be used in these recipes. For best results, weigh by grams and use food safe thermometer.',
  id: 'chicken-and-vegetables',
  cookTime: '35 mins',
  calories: 2430,
  utensils: [
    '5+ quart pot/skillet/dutch oven',
    'knife and cutting board or food processor (or buy pre-cut frozen)',
    'spatula',
    'kitchen scale',
    'food safe thermometer',
  ],
  image: genericDiyRecipeImageWebp,
  instructions: [
    {
      name: 'Step 1',
      description:
        'Place pot/skillet/dutch oven over medium to medium-high heat on the stovetop. Pour the sunflower oil in and spread it to evenly coat the bottom.',
    },
    {
      name: 'Step 2',
      description:
        'Add the ground chicken. Break apart and stir until the meat is no longer pink and is broken into small pieces.',
    },
    {
      name: 'Step 3',
      description:
        'Add the sweet potato, zucchini, and spinach. Cook until the spinach has wilted and the vegetables are soft and easily break apart or are tender when pierced with a fork.',
      note: 'Be sure to check that all ingredients are soft and easily broken in order to avoid choking hazards. Large chunks may require additional cooking time. Do not drain any liquid or fat that’s a result of the cooking process.',
    },
    {
      name: 'Step 4',
      description:
        'Add the egg whites and salmon oil to the pot and stir to evenly distribute. Cook until the eggs are firm and no longer runny.',
    },
    {
      name: 'Step 5',
      description:
        'Using a food safe thermometer, check the temperature at several places throughout the pot to verify that a food safe temperature of 165°F is reached.',
      note: 'In order to maintain safe, ready-to-eat meals it is important that all food reaches the USDA guidelines for safety.',
    },
    {
      name: 'Step 6',
      description:
        'Remove the pot from the heat and sprinkle {numberOfPackets} of The Farmer’s Dog DIY Nutrient Mix evenly across the surface. Stir to evenly distribute throughout the food until you can no longer see the nutrient mix.',
    },
  ],
};
