import type { ReactElement } from 'react';
import React from 'react';

type CtaTrackerProps = {
  children: ReactElement<{ onClick: (event: MouseEvent) => void }>;
  event: TrackingEvent;
  trackingData: Record<string, string>;
  requestAttributes?: Record<string, string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trackingFunction: (args: any) => void;
};

export enum TrackingEvent {
  homepageSuccess = 'homepage_success',
  goToSignup = 'go_to_signup',
}

export const CtaTracker = ({
  children,
  trackingData,
  event = TrackingEvent.homepageSuccess,
  requestAttributes,
  trackingFunction,
}: CtaTrackerProps) => {
  const onClickHandler = () => {
    const { location, ctaCopy, page } = trackingData;

    const props =
      event === TrackingEvent.homepageSuccess && requestAttributes
        ? { location, ctaCopy, requestAttributes }
        : { location, ctaCopy, page };

    trackingFunction(props);
  };

  return React.cloneElement(children, {
    onClick: (e: MouseEvent) => {
      onClickHandler();
      if (children.props.onClick) {
        children.props.onClick(e);
      }
    },
  });
};
