import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { PATH_SIGNUP_RECIPES } from '@farmersdog/constants/paths';

import { selectNumberOfPets } from 'src/reducers/signup/pets';

import type { Step } from './steps';

import './HeaderProgress.scss';

interface HeaderProgressProps {
  steps: Step[];
  style?: React.CSSProperties;
}
function HeaderProgress({ steps, style = {} }: HeaderProgressProps) {
  const progressPercentage = steps.reduce((previousPercentage, step, i) => {
    const enabled = steps.slice(i - 1, i).every(s => s.completed);

    return (
      previousPercentage + (enabled ? step.percentage / (steps.length - 1) : 0)
    );
  }, 0);
  const isRecipesPage = useRouteMatch(PATH_SIGNUP_RECIPES);
  const numberOfPets = useSelector(selectNumberOfPets) as number;

  const headerProgressClass = classNames('HeaderProgress', {
    shiftUp: isRecipesPage && numberOfPets > 1,
  });

  return (
    <div className={headerProgressClass} style={style}>
      <div className="HeaderProgress-bar inactive" />
      <div
        className="HeaderProgress-bar active"
        style={{
          width: `calc(${Math.min(
            100,
            Math.floor(progressPercentage)
          )}% - 1px)`,
        }}
      />
      <div className="HeaderProgress-steps">
        <span className="active" />
        {steps.slice(0, -1).map(step => (
          <span
            key={`header-progress-${step.path}`}
            className={step.completed ? 'active' : 'inactive'}
          />
        ))}
      </div>
    </div>
  );
}

export default HeaderProgress;
