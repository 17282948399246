import { useId } from 'react';

import { REVIEWS_BANNER } from 'src/constants/wistiaVideoIds';

import styles from './VideoBanner.module.scss';
import { VideoSection, Text } from '@farmersdog/corgi-x';

const WISTIA_OPTIONS = {
  popover: true,
};

function VideoBanner() {
  const overlayId = useId();

  return (
    <div data-name="VideoBanner" className={styles.container}>
      <VideoSection
        wistiaId={REVIEWS_BANNER}
        className={styles.video}
        aria-labelledby={overlayId}
        overlayContent={
          <div className={styles.content}>
            <Text
              variant="heading-28"
              color="white"
              className={styles.quote}
              id={overlayId}
            >
              “When she started eating healthier, she started being more active,
              smiling more, playing more!”
            </Text>
            <Text variant="heading-16" color="white" bold>
              – Coco’s dad
            </Text>
          </div>
        }
        wistiaOptions={WISTIA_OPTIONS}
      />
    </div>
  );
}

export default VideoBanner;
