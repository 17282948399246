import { Button, Grid, GridItem, Picture, Text } from '@farmersdog/corgi-x';

import desktopDogWalkingFoodPack from './assets/dog-walking-food-pack-desktop.png';
import desktopDogNatureFoodPack from './assets/dog-nature-food-pack-desktop.png';

import mobileDogWalkingFoodPack from './assets/dog-walking-food-pack-mobile.png';
import mobileDogNatureFoodPack from './assets/dog-nature-food-pack-mobile.png';

import { HomepageHeroImageTreatments } from 'src/abTesting';

import { CtaTracker, TrackingEvent } from '@farmersdog/utils';
import { useSelector } from 'react-redux';
import { selectRequestAttributes } from 'src/reducers/referrer';
import { trackHomepageSuccess } from 'src/analytics';

import styles from './Q5Hero.module.css';

interface Q5HeroProps {
  variant: HomepageHeroImageTreatments;
  children: React.ReactNode;
  title: React.ReactElement;
  subtitle: React.ReactElement;
  linkToFreshSignup: string;
  cta: string;
}

const DESKTOP_FOOD_PACKS = {
  [HomepageHeroImageTreatments.Q5DogNatureHeroOnly]: desktopDogNatureFoodPack,
  [HomepageHeroImageTreatments.Q5DogWalkingHeroOnly]: desktopDogWalkingFoodPack,
};

const MOBILE_FOOD_PACKS = {
  [HomepageHeroImageTreatments.Q5DogNatureHeroOnly]: mobileDogNatureFoodPack,
  [HomepageHeroImageTreatments.Q5DogWalkingHeroOnly]: mobileDogWalkingFoodPack,
};

export const Q5Hero = ({
  children,
  title,
  subtitle,
  variant,
  linkToFreshSignup,
  cta,
}: Q5HeroProps) => {
  const requestAttributes = useSelector(selectRequestAttributes);

  const desktopFoodPackImage =
    DESKTOP_FOOD_PACKS[variant as keyof typeof DESKTOP_FOOD_PACKS];

  const mobileFoodPackImage =
    MOBILE_FOOD_PACKS[variant as keyof typeof MOBILE_FOOD_PACKS];

  return (
    <section data-testid="q5-hero" className={styles.section}>
      <Grid justifyContent="center" className={styles.grid}>
        <GridItem className={styles.gridItem} xs={12} lg={4}>
          <Text
            as="h2"
            className={styles.title}
            bottomSpacing="none"
            color="white"
            variant="article-72"
            align={{
              xs: 'center',
              lg: 'left',
            }}
          >
            {title}
          </Text>
        </GridItem>
        <GridItem className={styles.packContainer} lg={4}>
          <Picture
            className={styles.foodPack}
            alt="Pack of dog food"
            sources={[desktopFoodPackImage]}
          />
        </GridItem>
        <GridItem className={styles.copyContainer} xs={12} lg={4}>
          <Text
            as="p"
            bold
            className={styles.subtitle}
            topSpacing={{
              xs: 'md',
              lg: 'none',
            }}
            bottomSpacing={{
              xs: 'md',
              lg: 'lg',
            }}
            variant="heading-22"
            color="white"
            align={{
              xs: 'center',
              lg: 'left',
            }}
          >
            {subtitle}
          </Text>
          <CtaTracker
            trackingFunction={trackHomepageSuccess}
            event={TrackingEvent.homepageSuccess}
            trackingData={{ location: 'Hero', ctaCopy: cta }}
            requestAttributes={requestAttributes}
          >
            <Button className={styles.cta} type="link" to={linkToFreshSignup}>
              {cta}
            </Button>
          </CtaTracker>
        </GridItem>
        <div className={styles.mobileFoodPackContainer}>
          <Picture
            className={styles.mobileFoodPackImage}
            alt="Pack of dog food"
            sources={[mobileFoodPackImage]}
          />
        </div>
        {children}
      </Grid>
    </section>
  );
};
