import { LeadNodes, PetNodes, NodeNames } from '@farmersdog/constants';

import { filterObjectByFields } from '../../../../utils/filterObjectByFields';
import { isNotNullUndefinedOrEmptyString } from '../../../../utils/isNotNullUndefinedOrEmptyString';
import { removeTypenamesFromGraphQLResponse } from '../../../../utils/removeTypenamesFromGraphQLResponse';

import type { FetchLeadQuery } from '../../../../graphql/types';
import type { FormFieldsType } from '../../../../types';

export type ConvertLeadToFormStateType = typeof convertLeadToFormState;

export function convertLeadToFormState(
  rawLeadData: FetchLeadQuery['lead']
): FormFieldsType {
  const leadData = removeTypenamesFromGraphQLResponse(rawLeadData);

  const leadFields = Object.values(LeadNodes);
  const petFields = Object.values(PetNodes);

  const leadObj = filterObjectByFields({
    object: leadData,
    fields: leadFields,
  });

  const petsBirthdayValues = leadData.pets.map((pet, idx) => {
    const unit = pet.birthdayInput?.unit;
    const amount = pet.birthdayInput?.amount;
    const result = [];
    if (unit) {
      result.push([`${NodeNames.BirthdayUnit}-${idx + 1}`, unit]);
    }
    if (amount) {
      result.push([`${NodeNames.BirthdayAmount}-${idx + 1}`, amount]);
    }
    return result;
  });

  const petsFreshSelection = leadData.pets.map((pet, idx) => {
    const freshRecipesNames = pet.selection?.fresh?.options.recipes;

    if (!freshRecipesNames) {
      return [];
    }
    return [`${NodeNames.FreshSelection}-${idx + 1}`, freshRecipesNames];
  });

  const petsValues = leadData.pets.map((pet, idx) => {
    let healthy = false;
    let prescriptionDiet = false;
    const petPosition = idx + 1;
    const indexedPet = Object.entries(pet)
      .filter(([key, val]) => {
        return (
          petFields.includes(key as PetNodes) &&
          isNotNullUndefinedOrEmptyString(val)
        );
      })
      .map(([key, val]) => {
        if (Array.isArray(val) && val.length > 0) {
          if (key === NodeNames.Issues) {
            healthy = true;
          } else if (key === NodeNames.PrescriptionDiets) {
            prescriptionDiet = true;
          }
        }
        return [`${key}-${petPosition}`, val];
      });

    indexedPet.push([`${NodeNames.Healthy}-${petPosition}`, healthy]);
    indexedPet.push([
      `${NodeNames.PrescriptionDiet}-${petPosition}`,
      prescriptionDiet,
    ]);

    return indexedPet;
  });
  petsValues.push(...petsBirthdayValues);
  petsValues.push(petsFreshSelection);

  const petsObj = Object.fromEntries(
    petsValues.flat(1) as Array<[string, unknown]>
  );

  const numPets =
    leadData.pets.length > 0 ? { numPets: leadData.pets.length } : undefined;

  const formLead: FormFieldsType = {
    ...leadObj,
    ...petsObj,
    ...numPets,
  };

  return formLead;
}
