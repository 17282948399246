import type { RecipeNames } from '@farmersdog/constants';

export const cvr_weight_override = 'cvr_weight_override';
export const cvr_global_discount_override = 'cvr_globaldiscount_override';
export const cvr_default_discount = 'cvr_default_discount';

export enum FeatureName {
  CVR_DEFAULT_DISCOUNT = 'cvr_default_discount',
  CVR_GLOBAL_DISCOUNT_OVERRIDE = 'cvr_globaldiscount_override',
  CVR_SKIP_GET_STARTED = 'cvr_skip_get_started',
  CVR_SURPRISE_HIGHER_DISCOUNT = 'cvr_surprise_higher_discount',
  CVR_WEIGHT_OVERRIDE = 'cvr_weight_override',
  CVR_TREATS_IN_CHECKOUT_UI = 'cvr_treats_in_checkout_ui',
  CVR_SIGNUP_ICON_UPDATE = 'cvr_signup_icon_update',
  CVR_PRIOR_FOOD_TYPE_PROGRESSION = 'cvr_prior_food_type_progression',
  CVR_RECIPES_PAGE_NON_BLUEPRINT = 'cvr_recipes_page_non_blueprint',
  CVR_TOSA_DISCOUNT_BANNER_COPY = 'cvr_tosa_discount_banner_copy',
}

export enum ToggleTreatments {
  off = 'off',
  on = 'on',
  control = 'control',
}

export enum RecipePagePackOrderEnum {
  off = 'off',
  chicken_first = 'chicken_first',
}

export enum SkipGetStartedTreatments {
  off = 'off',
  same_copy = 'same_copy',
  new_copy = 'new_copy',
  no_copy = 'no_copy',
}

export enum CvrSurpriseHigherDiscountTreatments {
  off = 'off',
  recipes = 'recipes',
  checkout = 'checkout',
}

export enum CvrTreatsInCheckoutUiTreatments {
  control = 'control',
  off = 'off',
  page = 'page',
}

export enum CvrPriorFoodTypeProgressionTreatments {
  off = 'off',
  on = 'on',
}

// Configs
export interface RecipePagePackOrderConfig {
  recipeOrder: RecipeNames[];
}
