import {
  Grid,
  GridItem,
  PageWidth,
  Picture,
  Section,
  Text,
} from '@farmersdog/corgi-x';
import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';
import { getLinkToFreshSignup } from '@farmersdog/tosa/src/utils/getLinkToSignupFlow';
import { ExtendedButton } from '../ExtendedButton';

import {
  ArrowBottomSvg,
  ArrowTopSvg,
  turkeyBowlSources,
  turkeyPackSources,
} from './assets';
import styles from './BowlPackSection.module.css';

export const BowlPackSection = () => {
  const linkToFreshSignup = getLinkToFreshSignup(PATH_SIGNUP_ME);

  return (
    <PageWidth className={styles.bowlPackSectionWrapper}>
      <Section aria-labelledby="bowl-pack-section-heading">
        <Grid
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          gap="md"
        >
          <GridItem>
            <Text
              variant="heading-28"
              as="h2"
              bold
              id="bowl-pack-section-heading"
            >
              All the nutrients without the extreme processing
            </Text>
            <Text
              variant="heading-16"
              as="p"
              className={styles.bowlPackSectionParagraph}
            >
              Unlike kibble, our food is gently cooked using real ingredients
              you can see, and all the vitamins and minerals your dog needs.
            </Text>
          </GridItem>
          <Grid bottomSpacing="md" className={styles.bowlImageContainer}>
            <ArrowTopSvg className={styles.arrowTop} />
            <Picture
              sources={[turkeyBowlSources.webP, turkeyBowlSources.png]}
              alt="Turkey Bowl"
              className={styles.bowlImage}
            />
            <ArrowBottomSvg className={styles.arrowBottom} />
          </Grid>
          <Grid bottomSpacing="md" className={styles.packImageContainer}>
            <Picture
              sources={[turkeyPackSources.webP, turkeyPackSources.png]}
              alt="Turkey Pack"
              className={styles.packImage}
            />
          </Grid>
          <GridItem>
            <Text variant="heading-28" bold as="h2">
              The right amount of food every time
            </Text>
            <Text
              variant="heading-16"
              as="p"
              className={styles.bowlPackSectionParagraph}
            >
              Pre-portioned packs make it easy to keep your dog at a healthy
              weight.
              <br />
              No more guessing or vague scoops.
            </Text>
          </GridItem>
          <GridItem>
            <ExtendedButton
              variant="solid-kale"
              type="link"
              to={linkToFreshSignup}
            >
              Shop Plans Today
            </ExtendedButton>
          </GridItem>
        </Grid>
      </Section>
    </PageWidth>
  );
};
