import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { tosaToken } from '@farmersdog/lead-browser-storage';
import { Logger } from '@farmersdog/logger';

import config from '../config';
import { restoreSignupFlow } from '../utils';

import { useLeadState } from './useLeadState';
import { convertLeadToFormState } from './useLeadState/utils/convertLeadToFormState';
import { useThrowToErrorBoundary } from './useThrowToErrorBoundary';

import type {
  GetFirstBlockedBranch,
  GetLastCompletedBranch,
  GetNextIncompleteBranch,
} from './useProgress';
import type { BranchNode } from '../blueprint/types';
import type { LeadView } from '../exports';
import type {
  UseForm,
  UseSchemaReturn,
  UseFormNavigateReturn,
  Experiments,
} from '../types';

interface UseRestoreLeadProgressArgs {
  urlEmail: string | null;
  reset: UseForm['reset'];
  setFormSteps: UseSchemaReturn['setFormSteps'];
  blueprint: UseSchemaReturn['schema'];
  formNavigation: UseFormNavigateReturn;
  getNextIncompleteBranch: GetNextIncompleteBranch;
  getLastCompletedBranch: GetLastCompletedBranch;
  getFirstBlockedBranch: GetFirstBlockedBranch;
  formSteps: UseSchemaReturn['formSteps'];
  handleRestoreComplete: () => void;
  experiments: Experiments;
}

export function useRestoreLeadProgress({
  urlEmail,
  reset,
  setFormSteps,
  blueprint,
  formNavigation,
  getLastCompletedBranch,
  getFirstBlockedBranch,
  formSteps,
  handleRestoreComplete,
  experiments,
}: UseRestoreLeadProgressArgs) {
  const { pathname } = useLocation();

  const { getLead, getLeadState, createLead, createLeadState } = useLeadState();

  const [restoredFormSteps, setRestoredFormSteps] = useState(formSteps);
  const firstFormStepId = formSteps[0]?.__self__;

  const throwToErrorBoundary = useThrowToErrorBoundary();

  useEffect(() => {
    if (!blueprint || formNavigation.current || !getLeadState.called) {
      return;
    }

    // After the formSteps state used by useProgress has been updated, we can get the next branch to move on to
    if (
      compareFormStepsEquality({
        currentFormSteps: formSteps,
        nextFormSteps: restoredFormSteps,
      })
    ) {
      const firstBlockedBranch = getFirstBlockedBranch();
      const lastCompletedBranch = getLastCompletedBranch();

      const nextBranch = firstBlockedBranch || lastCompletedBranch;
      formNavigation.goToStep(nextBranch.id);
    }
  }, [
    blueprint,
    formNavigation,
    formSteps,
    getLeadState.called,
    getFirstBlockedBranch,
    getLastCompletedBranch,
    restoredFormSteps,
  ]);

  useEffect(() => {
    if (
      !blueprint ||
      formNavigation.current ||
      getLeadState.called ||
      createLeadState.called
    ) {
      return;
    }

    if (!urlEmail && !tosaToken.get()) {
      formNavigation.goToStep(firstFormStepId);
      return handleRestoreComplete();
    }

    async function restoreLead() {
      try {
        if (urlEmail && !createLeadState.called) {
          try {
            await createLead({
              email: urlEmail,
            });
          } catch (error) {
            throwToErrorBoundary(error);
          }
        }

        const leadData = await getLead();

        if (!leadData) {
          formNavigation.goToStep(firstFormStepId);
          return handleRestoreComplete();
        }

        const updatedFormSteps = restoreSignupFlow({
          data: convertLeadToFormState(leadData as LeadView),
          reset,
          resetOptions: { keepDefaultValues: true },
          setFormSteps,
          blueprint,
          experiments,
        });
        setRestoredFormSteps(updatedFormSteps);
        handleRestoreComplete();
      } catch {
        formNavigation.goToStep(firstFormStepId);
        handleRestoreComplete();
      }
    }

    void restoreLead();
  }, [
    handleRestoreComplete,
    urlEmail,
    blueprint,
    firstFormStepId,
    formNavigation,
    reset,
    setFormSteps,
    experiments,
    pathname,
    throwToErrorBoundary,
    getLead,
    getLeadState.called,
    createLead,
    createLeadState.called,
  ]);

  return getLeadState;
}

interface CompareFormStepsEqualityArgs {
  currentFormSteps: BranchNode[];
  nextFormSteps: BranchNode[];
}
function compareFormStepsEquality({
  currentFormSteps,
  nextFormSteps,
}: CompareFormStepsEqualityArgs) {
  const logger = new Logger('tosa:frontend:compareFormStepsEquality');

  if (currentFormSteps.length !== nextFormSteps.length) {
    if (config.get('features.tosaLogging')) {
      logger.info('form steps lengths not equal', {
        currentFormSteps,
        nextFormSteps,
      });
    }
    return false;
  }

  for (let i = 0; i < currentFormSteps.length; i++) {
    if (currentFormSteps[i].name !== nextFormSteps[i].name) {
      if (config.get('features.tosaLogging')) {
        logger.info('form steps names not equal', {
          currentFormSteps,
          nextFormSteps,
        });
      }
      return false;
    }
  }

  if (config.get('features.tosaLogging')) {
    logger.info('form steps evaluation completed');
  }

  return true;
}
