// Site page paths
export const PATH_HOME = '/';
export const PATH_ABOUT_US = '/about-us';
export const PATH_TERMS = '/content/terms';
export const PATH_OFFER_TERMS = '/content/offerterms';
export const PATH_BONUS_DISCOUNT_OFFER_TERMS = '/content/offerterms-jan25';
export const PATH_REFERRAL_TERMS = '/content/referralterms';
export const PATH_PRIVACY = '/content/privacy';
export const PATH_ACCESSIBILITY = '/content/accessibility-statement';
export const PATH_DIY = '/diy';
export const PATH_CAREERS = '/careers';
export const PATH_FAQ = '/faq';
export const PATH_REVIEWS = '/reviews';
export const PATH_WHY_FRESH = '/why-fresh';
export const PATH_UPDATE_PAYMENT_METHOD = `/update-payment-method/:token`;
export const PATH_HOME_REDESIGN = '/home-redesign';

// CMS page paths
export const PATH_BREED = `/breed`;

// Discount code paths
export const PATH_REFERRAL_CODE = '/p';
export const PATH_REFERRAL_CODE_MATCH = `${PATH_REFERRAL_CODE}/:referrerCode`;

// Login paths
export const PATH_LOGIN = '/login';
export const PATH_PASSWORD_CHANGE = '/password/change';
export const PATH_PASSWORD_CREATE = '/password/create';
export const PATH_PASSWORD_RESET = '/password/reset';

// Signup paths
export const PATH_SIGNUP = '/signup';
export const PATH_SIGNUP_ME = '/signup/me';
export const PATH_SIGNUP_PETS = '/signup/pets';
export const PATH_SIGNUP_RECIPES = '/signup/recipes';
export const PATH_SIGNUP_RECIPES_V2 = '/signup/recipesV2';
export const PATH_SIGNUP_TREATS = '/signup/treats';
export const PATH_SIGNUP_PLANS = '/signup/plans';
export const PATH_SIGNUP_CHECKOUT = '/signup/checkout';
export const PATH_SIGNUP_SUCCESS = '/signup/success';
export const PATH_SIGNUP_TOSA_ME_OVERRIDE = '/signup/return-to-me-section';
export const PATH_SIGNUP_TOSA_PETS_OVERRIDE = '/signup/return-to-pets-section';
export const PATH_SIGNUP_TOSA_LAST_PET_OVERRIDE = '/signup/return-to-last-pet';
export const PATH_SIGNUP_CANONICAL_ROUTE = '/signup?productLine=fresh';
export const PATH_SIGNUP_SITE_UNAVAILABLE = '/signup/site-unavailable';
export const PATH_SIGNUP_PRODUCT_ENABLE = '/signup/product';

// Accounts paths
export const PATH_CUSTOMER_ACCOUNT = '/app';

export const PATH_CUSTOMER_ACCOUNT_FAQ = '/app/faq';
export const PATH_GIFT = '/app/gift';
export const PATH_ADD_ONS = '/app/add-ons';

export const PATH_RESCHEDULE = '/app/reschedule';
export const PATH_INVENTORY = '/app/inventory';

export const PATH_EDIT_BUFFER = '/app/buffer';

export const PATH_MEALS = '/app/meals';
export const PATH_PLAN = '/app/plan';
export const PATH_ORDERS = '/app/orders';
export const PATH_PROFILE = '/app/profile';
export const PATH_PROFILE_CANCEL = `${PATH_PROFILE}/cancel`;
export const PATH_PROFILE_REACTIVATE = `${PATH_PROFILE}/reactivate`;
export const PATH_SUMMARY = '/app/summary';
export const PATH_WEBSITE_HOMEPAGE = '/app/home';

export const PATH_ACCOUNT_DAISY_UNAVAILABLE_PAGE = '/app/unavailable';

// Calibrate paths
export const PATH_CALIBRATE = '/app/calibrate';
export const PATH_CALIBRATE_PLANS = `${PATH_CALIBRATE}/plans`;
export const PATH_CALIBRATE_RESCHEDULE = `${PATH_CALIBRATE}/reschedule`;
export const PATH_CALIBRATE_PACKS = `${PATH_CALIBRATE}/packs`;
export const PATH_CALIBRATE_SUCCESS = `${PATH_CALIBRATE}/success`;
export const PATH_CALIBRATE_PET_EDIT = `${PATH_CALIBRATE}/pet/:petName`;

export const PATH_ADJUST_DAILY_CALORIES = `${PATH_CUSTOMER_ACCOUNT}/adjust-daily-calories`;

// DIY paths
export const PATH_DIY_RECIPES = `${PATH_CUSTOMER_ACCOUNT}/recipes`;
export const PATH_DIY_DOWNSELL = `/app/diysignup`;

// Treats paths
export const PATH_TREAT_PRODUCTS = `${PATH_CUSTOMER_ACCOUNT}/treats`;
export const PATH_SNAP_STICKS_DETAILS = `${PATH_TREAT_PRODUCTS}/snap-sticks`;

export const PATH_APPLE_PAY_VERIFICATION =
  '/.well-known/apple-developer-merchantid-domain-association';

// PAW paths
export const PAW_PATH_HOME = '/';
export const PAW_PATH_ACCOUNT = '/account';
export const PAW_PATH_LOGIN = '/login';
export const PAW_PATH_CUSTOMER_ACCOUNT = '/app';
export const PAW_PATH_PASSWORD_RESET = '/password/reset';
export const PAW_PATH_SIGNUP = '/signup';
export const PAW_PATH_PRE_CANCEL = `${PAW_PATH_ACCOUNT}/cancel/are-you-sure`;
export const PAW_PATH_CANCEL_REASONS = `${PAW_PATH_ACCOUNT}/cancel/reasons`;
export const PAW_PATH_REACTIVATE = `${PAW_PATH_ACCOUNT}/reactivate`;
export const PAW_PATH_HOMEPAGE = `${PAW_PATH_ACCOUNT}/home`;
export const PAW_PATH_TEMPORARY_PAUSE = `${PAW_PATH_ACCOUNT}/pause`;
export const PAW_PATH_RECIPES = `${PAW_PATH_ACCOUNT}/recipes`;
export const PAW_PATH_MY_DOGS = `${PATH_CUSTOMER_ACCOUNT}/my-dogs`;
export const PAW_PATH_ADD_DOG = `${PAW_PATH_ACCOUNT}/add-a-dog`;
export const PAW_SUGGEST_ORDER_SIZE = `${PAW_PATH_ACCOUNT}/suggest-order-size`;
export const PAW_SUGGEST_ORDER_SIZE_UP = `${PAW_PATH_ACCOUNT}/suggest-order-size-up`;
export const PAW_PATH_PAYMENT_RETRY = `${PAW_PATH_ACCOUNT}/payment-retry`;
export const PAW_PATH_PAYMENT_RETRY_SUCCESS = `${PAW_PATH_PAYMENT_RETRY}/success`;
export const PAW_PATH_PAYMENT_RETRY_FAILURE = `${PAW_PATH_PAYMENT_RETRY}/failure`;
