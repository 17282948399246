import dogNatureDesktopJpg from './q5-dog-nature-background-desktop.jpg';
import dogNatureDesktopWebp from './q5-dog-nature-background-desktop.webp';

import dogNatureMobileJpg from './q5-dog-nature-background-mobile.jpg';
import dogNatureMobileWebp from './q5-dog-nature-background-mobile.webp';

export const mobileDogNature = {
  webP: dogNatureMobileWebp,
  jpg: dogNatureMobileJpg,
};

export const desktopDogNature = {
  webP: dogNatureDesktopWebp,
  jpg: dogNatureDesktopJpg,
};
