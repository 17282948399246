import { katz, KatzEventName, PIXEL_ID, gid } from './katz';

/**
 * Mount a Katz lead conversion pixel
 */
export function mountKatzLead(): void {
  katz.mount({
    ppt: PIXEL_ID,
    g: KatzEventName.LEAD,
    gid,
  });
}
