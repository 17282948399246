import { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Text, Input, Link, Callout } from '@farmersdog/corgi-x';
import config from 'src/config';
import type { TurnstileInstance } from '@marsidev/react-turnstile';

import { PATH_LOGIN } from '@farmersdog/constants/paths';
import SuccessCheckmark from 'src/components/SuccessCheckmark';
import Slider from 'src/components/Slider';
import Form from 'src/components/Form';
import parseQueryString from 'src/utils/parseQueryString';
import { showError } from 'src/actions/ui';
import {
  FinishCheckoutModal,
  FINISH_CHECKOUT_MODAL_ID,
} from 'src/components/FinishCheckoutModal';
import useGlobalLightbox from 'src/utils/useGlobalLightbox';
import { useRequestPasswordReset } from 'src/graphql/mutations/useRequestPasswordReset';
import { trackRequestPasswordResetChallengeExpired } from 'src/analytics/events/trackRequestPasswordResetChallengeExpired';

import AuthPage, { USER_NOT_FOUND_ERROR } from 'src/components/AuthPage';
import { ValidationError } from 'src/errors';

import { BACK_TO_LOGIN_BUTTON_COPY, SUCCESS_TEXT } from './ResetPassword.copy';

import styles from './ResetPassword.module.scss';
import { QueryParameter } from '@farmersdog/constants';
import { TurnstileWidget } from './components/Turnstile';
import { SubmitButton } from './components/SubmitButton';
import { TurnstileState } from './components/Turnstile/TurnstileWidget';
import type { ApolloError } from '@apollo/client';
import { handleTurnstileSubmit } from './handleTurnstileSubmit';

const EMAIL_IS_REQUIRED_ERROR = 'Email is required';
export const INVALID_EMAIL_ERROR = 'Invalid email address';

const REQUEST_PASSWORD_RESET_ERRORS_INVALID = [
  EMAIL_IS_REQUIRED_ERROR,
  INVALID_EMAIL_ERROR,
  USER_NOT_FOUND_ERROR,
];

type RequestPasswordResetError = ApolloError & {
  cause?: {
    extensions?: {
      data?: {
        params?: string;
      };
    };
  };
};

function ResetPassword() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [email, setEmail] = useState(
    parseQueryString(location.search, 'email') || ''
  );
  const [error, setError] = useState<ValidationError | undefined>();
  const [success, setSuccess] = useState(false);
  const emailField = useRef<HTMLInputElement>(null);

  const turnstileInstance = useRef<TurnstileInstance>(null);
  const [captchaState, setCaptchaState] = useState<TurnstileState>(
    TurnstileState.UNKNOWN
  );
  const [captchaToken, setCaptchaToken] = useState('');
  const [captchaError, setCaptchaError] = useState<
    ValidationError | undefined
  >();
  const [showCaptchaError, setShowCaptchaError] = useState(false);

  const [isInteractiveChallenge, setIsInteractiveChallenge] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { open: openFinishCheckoutModal } = useGlobalLightbox({
    id: FINISH_CHECKOUT_MODAL_ID,
  });

  const isTurnstileEnabled = config(
    'cloudflare.turnstile.features.request_password_reset.enabled'
  );

  const handleError = (err: RequestPasswordResetError) => {
    const message = err.message;
    const params = err.cause?.extensions?.data?.params || '';

    if (REQUEST_PASSWORD_RESET_ERRORS_INVALID.includes(message)) {
      resetTurnstile();
      setError(new ValidationError(message, params));
    }

    if (message === USER_NOT_FOUND_ERROR) {
      openFinishCheckoutModal();
      return;
    }

    if (params === 'email') {
      emailField.current?.focus();
    } else {
      dispatch(showError(err));
    }
  };

  const resetTurnstile = () => {
    turnstileInstance?.current?.reset();
    setCaptchaState(TurnstileState.UNKNOWN);
  };

  const handleExpiredToken = () => {
    resetTurnstile();
    trackRequestPasswordResetChallengeExpired();
  };

  const [callResetPassword, { loading }] = useRequestPasswordReset({
    onError: (err: unknown) => handleError(err as RequestPasswordResetError),
    onCompleted: () => setSuccess(true),
  });

  const handleSubmit = async () => {
    if (!isTurnstileEnabled) {
      await callResetPassword({ email, challengeToken: captchaToken });
      return;
    }

    await handleTurnstileSubmit(
      turnstileInstance,
      callResetPassword,
      email,
      setIsSubmitting,
      setShowCaptchaError
    );
  };
  useEffect(() => {
    emailField.current?.focus();
  }, []);

  const backToLoginLink = success
    ? `${PATH_LOGIN}?${QueryParameter.Email}=${encodeURIComponent(email)}`
    : PATH_LOGIN;

  const validationError = error?.validation ?? {};

  return (
    <AuthPage
      title={success ? 'Recovery link sent' : 'Set new password'}
      header="Set new password"
      subHeader="Trouble logging in? Enter your email address to set a fresh password."
      loading={loading}
    >
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Slider className={styles.slider} currentSlide={success ? 1 : 0}>
        <Slider.Slide>
          <Form
            aria-label="Reset Password"
            className={styles.form}
            onSubmit={handleSubmit}
            enableSubmit={!loading}
          >
            <Input
              ref={emailField}
              data-name="email"
              name="Email"
              label="Email"
              type="email"
              autoComplete="email"
              value={email}
              onChange={e => {
                setError(undefined);
                setEmail(e.target.value);
              }}
              aria-label="Email Address"
            />
            {validationError.email && (
              <Text as="div" className={styles.error} variant="heading-16">
                {validationError.email}
              </Text>
            )}
            {showCaptchaError && captchaError?.validation?.captcha && (
              <Callout variant="negative" className={styles.captchaError}>
                {captchaError?.validation?.captcha}
              </Callout>
            )}
            {isTurnstileEnabled && (
              <TurnstileWidget
                ref={turnstileInstance}
                handleExpiredToken={handleExpiredToken}
                setCaptchaState={setCaptchaState}
                setCaptchaToken={setCaptchaToken}
                setCaptchaError={(msg?: string) => {
                  if (!msg) return;
                  setCaptchaError(new ValidationError(msg, 'captcha'));
                }}
                isInteractiveChallenge={isInteractiveChallenge}
                setIsInteractiveChallenge={setIsInteractiveChallenge}
              />
            )}
            <SubmitButton
              loading={loading}
              isSubmitting={isSubmitting}
              email={email}
              captchaState={captchaState}
              isTurnstileEnabled={isTurnstileEnabled}
              isInteractiveChallenge={isInteractiveChallenge}
            />
          </Form>
        </Slider.Slide>
        <Slider.Slide>
          {success && (
            <div className={styles.success}>
              <SuccessCheckmark />
              <Text as="p" variant="heading-16" color="kale-3">
                {SUCCESS_TEXT}
              </Text>
              <Text
                as="p"
                variant="heading-16"
                color="kale-3"
                className={styles.lastLine}
              >
                Check {email} for the link to change the password.
              </Text>
            </div>
          )}
        </Slider.Slide>
      </Slider>
      <Link className={styles.loginLink} to={backToLoginLink}>
        <Text as="span" variant="heading-16">
          {BACK_TO_LOGIN_BUTTON_COPY}
        </Text>
      </Link>
      <FinishCheckoutModal email={email} />
    </AuthPage>
  );
}

export default ResetPassword;
