import {
  useMountPinterestPageView,
  useMountNextDoorPageView,
  useMountAmazonTagPageView,
  useMountGa4PageView,
  useMountAudacyPageView,
  useMountPostiePageView,
} from '@farmersdog/pixels';

import { getIsFirstPetsPage } from '../../utils';

import { useMountTikTokPageView } from './useMountTikTokPageView';

import type { BranchNode } from '../../blueprint/types';

interface UseHandlePageViewArgs {
  current: string | undefined;
  formSteps: BranchNode[];
  email: string | undefined;
}

export function useHandlePageView({
  current,
  formSteps,
  email,
}: UseHandlePageViewArgs): void {
  const isFirstPetsPage = getIsFirstPetsPage({ current, formSteps });

  useMountTikTokPageView({ email, isFirstPetsPage });
  useMountGa4PageView();
  useMountPinterestPageView();
  useMountAudacyPageView();
  useMountNextDoorPageView({ isFirstPetsPage });
  useMountAmazonTagPageView({ isFirstPetsPage });
  useMountPostiePageView();
}
