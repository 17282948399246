import { useRef } from 'react';
import { Grid, Text } from '@farmersdog/corgi-x';

import { Helmet } from 'react-helmet-async';
import { Navigation } from 'src/components/Navigation';

import {
  BetterForThem,
  BowlPackSection,
  HomepageRedesignHero,
  ImageCard,
  Marquee,
  VetsKnowBest,
  FAQ,
} from './components';

import schema from '../Home/schema.json';

import styles from './HomepageRedesign.module.css';

export const HomepageRedesign = () => {
  const ctaReveal = useRef(null);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      <Navigation ctaTrigger={ctaReveal} />
      <HomepageRedesignHero />
      <Marquee>
        <Text vSpacing="none" as="p" bold variant="heading-16" color="kale-2">
          Order now for delivery as early as{' '}
          <Text bold as="span" color="kale-3">
            Feb 3
          </Text>
        </Text>
      </Marquee>
      <div ref={ctaReveal}>
        <Grid className={styles.grid}>
          <BowlPackSection />
          <BetterForThem />
          <VetsKnowBest />
          <ImageCard />
          <FAQ />
        </Grid>
      </div>
    </>
  );
};
