import type { DiyRecipe } from '../types';
import { PrimaryProtein, RecipesCategory } from '../types';
import genericDiyRecipeImageWebp from '../assets/generic-diy-recipe-image.webp';

export const porkRiceAndVegetablesForPuppies: DiyRecipe = {
  name: 'Pork, Rice & Vegetables for Puppies',
  id: 'pork-rice-and-vegetables-for-puppies',
  primaryProtein: PrimaryProtein.Pork,
  category: RecipesCategory.ForPuppies,
  isPuppy: true,
  ingredients: [
    {
      name: 'pork tenderloin cut into 1⁄2 inch or smaller cubes',
      quantity: 760,
      unit: 'g',
      alternativeQuantity: 1.6,
      alternativeUnit: 'lb',
    },
    {
      name: 'cooked white rice',
      quantity: 420,
      unit: 'g',
      alternativeQuantity: 2.75,
      alternativeUnit: 'cup',
    },
    {
      name: 'broccoli, riced or finely chopped',
      quantity: 120,
      unit: 'g',
      alternativeQuantity: 1.25,
      alternativeUnit: 'cup',
    },
    {
      name: 'spinach, chopped',
      quantity: 120,
      unit: 'g',
      alternativeIngredients: [
        {
          name: 'fresh',
          quantity: 4,
          unit: 'cup',
        },
        {
          name: 'frozen',
          quantity: 0.75,
          unit: 'cup',
        },
      ],
      alternativeUnit: 'cup',
    },
    {
      name: 'pumpkin puree',
      quantity: 100,
      unit: 'g',
      alternativeQuantity: 0.5,
      alternativeUnit: 'cup',
    },
    {
      name: 'The Farmer’s Dog DIY Nutrient Mix',
      quantity: 1,
      unit: 'packet',
    },
    {
      name: 'sunflower oil',
      quantity: 20,
      unit: 'g',
      alternativeQuantity: 4.5,
      alternativeUnit: 'teaspoon',
    },
    {
      name: 'salmon oil',
      quantity: 10,
      unit: 'g',
      alternativeQuantity: 2.25,
      alternativeUnit: 'teaspoon',
    },
    {
      name: 'salt, non-iodized',
      quantity: 1,
      unit: 'g',
      alternativeQuantity: 0.25,
      alternativeUnit: 'teaspoon',
    },
  ],
  ingredientsNote:
    'Fresh or frozen vegetables may be used in these recipes. For best results, weigh by grams and use food safe thermometer.',
  cookTime: '40 mins',
  ingredientSummary:
    'Pork Tenderloin, White Rice, Broccoli, Spinach, Pumpkin Puree, DIY Nutrient Mix, Sunflower Oil, Salmon Oil, Salt',
  utensils: [
    '5+ quart pot/skillet/dutch oven',
    'knife and cutting board or food processor (or buy pre-cut frozen)',
    'spatula',
    'kitchen scale',
    'food safe thermometer',
  ],
  image: genericDiyRecipeImageWebp,
  instructions: [
    {
      name: 'Step 1',
      description:
        'Cook rice according to the instructions on the packaging and set aside.',
    },
    {
      name: 'Step 2',
      description:
        'Place pot/skillet/dutch oven over medium to medium-high heat on the stovetop.',
    },
    {
      name: 'Step 3',
      description:
        'Add the pork to the pot. Stir until the meat is no longer translucent/pink.',
    },
    {
      name: 'Step 4',
      description:
        'Add the broccoli. Cook until the broccoli is soft and easily breaks apart or is tender when pierced with a fork.',
      note: 'Be sure to check that all ingredients are soft and easily broken in order to avoid choking hazards. Large chunks may require additional cooking time. Do not drain any fat or liquid that’s a result of the cooking process.',
    },
    {
      name: 'Step 5',
      description:
        'Add the salt, pumpkin puree, cooked rice, and spinach. Cook until the spinach has wilted.',
    },
    {
      name: 'Step 6',
      description:
        'Add the sunflower oil and salmon oil and stir to evenly distribute. Using a food safe thermometer, check the temperature at several places throughout the pot to verify that a food safe temperature of 165°F is reached.',
      note: 'In order to maintain safe, ready-to-eat meals it is important that all food reaches the USDA guidelines for safety.',
    },
    {
      name: 'Step 7',
      description:
        'Remove the pot from the heat and sprinkle the {numberOfPackets} of The Farmer’s Dog DIY Nutrient Mix evenly across the surface. Stir to evenly distribute throughout the food until you can no longer see the nutrient mix.',
    },
  ],
  calories: 1537,
};
