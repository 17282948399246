/* CH#49502: Used by CVR */
import { useLocation } from 'react-router-dom';

import config from 'src/config';
import { Link } from '@farmersdog/corgi';
import { Nowrap, PageWidth, Text } from '@farmersdog/corgi-x';
import classNames from 'classnames';

import { useCorgiViewport } from 'src/screen';

import {
  PATH_ABOUT_US,
  PATH_BREED,
  PATH_CAREERS,
  PATH_DIY,
  PATH_FAQ,
  PATH_LOGIN,
  PATH_REVIEWS,
  PATH_SIGNUP,
} from '@farmersdog/constants/paths';

import { FooterSupportLinks } from './FooterLinks/FooterSupportLinks';
import { FooterContactLinks } from './FooterLinks/FooterContactLinks';
import LegalDetails from './LegalDetails';
import NewsletterForm from './NewsletterForm';

import { LINK_AFFILIATES, LINK_DIGEST } from '../../constants/links';
import { DIY_FAQ } from '../../pages/DIY/components/FAQ/constants/ids';
import styles from './FullFooter.module.scss';

/**
 * @deprecated Use a `Footer` component from `@farmersdog/corgi-x`. If in the Customer Account, use `AccountFooter.tsx`.
 */
function FullFooter() {
  const responsiveClassName = useCorgiViewport({
    xs: styles.xs,
    lg: styles.lg,
  });

  const location = useLocation();

  const isDIY = location.pathname === PATH_DIY;

  return (
    <footer className={classNames(styles.container, responsiveClassName)}>
      <PageWidth>
        <div className={styles.content}>
          <span className={styles.navigation}>
            <Text as="h4" variant="heading-16" bold>
              Site Map
            </Text>
            <ul>
              <li>
                <Link variant="secondary" to={PATH_REVIEWS}>
                  <Text variant="heading-16">Reviews</Text>
                </Link>
              </li>
              <li>
                <Link variant="secondary" to={PATH_ABOUT_US}>
                  <Text variant="heading-16">About Us</Text>
                </Link>
              </li>
              <li>
                <Link variant="secondary" to={isDIY ? `#${DIY_FAQ}` : PATH_FAQ}>
                  <Text variant="heading-16">FAQ</Text>
                </Link>
              </li>
              <li>
                <Link variant="secondary" to={PATH_LOGIN}>
                  <Text variant="heading-16">Log In</Text>
                </Link>
              </li>
              <li>
                <Link variant="secondary" to={PATH_SIGNUP}>
                  <Text variant="heading-16">Sign Up</Text>
                </Link>
              </li>
              <li>
                <Link
                  variant="secondary"
                  href={`${LINK_DIGEST}/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text variant="heading-16">Digest</Text>
                </Link>
              </li>
              <li>
                <Link variant="secondary" to={PATH_DIY}>
                  <Text variant="heading-16">DIY</Text>
                </Link>
              </li>
              <li>
                <Link variant="secondary" href={PATH_CAREERS} target="_blank">
                  <Text variant="heading-16">Careers</Text>
                </Link>
                <Text
                  variant="article-12"
                  className={styles.navigationAnnotation}
                  fontStyle="italic"
                >
                  We&apos;re Hiring!
                </Text>
              </li>
              <li>
                <Link
                  variant="secondary"
                  href={LINK_AFFILIATES}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text variant="heading-16">Affiliates</Text>
                </Link>
              </li>
              <li>
                <Link
                  variant="secondary"
                  href={`${config('app.vetPortalUrl')}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text variant="heading-16">For Vet Professionals</Text>
                </Link>
              </li>
              <li>
                <Link variant="secondary" href={PATH_BREED}>
                  <Text variant="heading-16">Breeds</Text>
                </Link>
              </li>
            </ul>
          </span>
          <span className={styles.contact}>
            <FooterSupportLinks />
            <FooterContactLinks />
          </span>
          <span className={styles.newsletter}>
            <Text as="h4" variant="heading-16" bold>
              Free Health and Nutrition Tips
            </Text>
            <NewsletterForm />
          </span>
        </div>
        <div className={styles.ancillary}>
          <Text variant="heading-16">
            Our food is made while playing doo-wop tunes, all{' '}
            <Nowrap>with ❤</Nowrap> in the US
          </Text>
        </div>
      </PageWidth>
      <LegalDetails mode="light" />
    </footer>
  );
}

export default FullFooter;
