import { PATH_ORDERS } from '@farmersdog/constants/paths';

import type * as Types from '@/account/graphql/core/types';

import { getHomepagePath } from './getHomepagePath';

interface GetDefaultAccountPathArgs {
  withRegularOrder: boolean;
  isSubscriptionActive: boolean;
  subscriptionType?: Types.SubscriptionType | null;
}

export function getDefaultAccountPath(args: GetDefaultAccountPathArgs) {
  const homepagePath = getHomepagePath(args);

  if (!homepagePath) {
    return PATH_ORDERS;
  }

  return homepagePath;
}
