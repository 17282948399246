import { Text, useViewport } from '@farmersdog/corgi-x';

import Picture from 'src/components/Picture';

import styles from './Hero.module.scss';
import { background, backgroundMobile } from './assets/backgrounds';

export default function Hero() {
  const { md } = useViewport();
  const sources = md ? background : backgroundMobile;
  return (
    <>
      <section className={styles.container}>
        <Picture sources={sources} alt="" className={styles.background} />
        <div className={styles.gradient} />
        <Text
          as="h1"
          variant="heading-52"
          color="white"
          bold
          className={styles.header}
        >
          Better for them, easier for you.
        </Text>
      </section>
    </>
  );
}
