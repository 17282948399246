import { track, events } from 'src/analytics';
import { cookie } from '@farmersdog/lead-browser-storage';

type FluentEventAttributes = {
  email?: string;
};

export const trackFluentTrialConversion = ({
  email,
}: FluentEventAttributes) => {
  const fluentId = cookie.getFluentId();
  if (fluentId) {
    track(events.fluent_trial_conversion, {
      email,
      fluentId,
    });
  }
};
