import { useHistory } from 'react-router';

import {
  PATH_SIGNUP_RECIPES,
  PATH_SIGNUP_TREATS,
} from '@farmersdog/constants/paths';

import { LargeNavigation } from './LargeNavigation';
import styles from './SignupHeader.module.css';
import { SmallNavigation } from './SmallNavigation';

interface SignUpHeaderNonTosaPageProps {
  currentPath: string;
}

export function SignUpHeaderNonTosaPage({
  currentPath,
}: SignUpHeaderNonTosaPageProps) {
  const history = useHistory();

  const handleNavigatePrevious = () => {
    if (currentPath === PATH_SIGNUP_TREATS) {
      history.push(PATH_SIGNUP_RECIPES);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.navigation}>
        <div className={styles.large}>
          <LargeNavigation handleNavigatePrevious={handleNavigatePrevious} />
        </div>
        <div className={styles.small}>
          <SmallNavigation handleNavigatePrevious={handleNavigatePrevious} />
        </div>
      </div>
    </div>
  );
}
