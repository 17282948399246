import path from 'path';
import { logImpression } from 'src/abTesting/logImpression';
import config from 'src/config';

const authorizationKey = config('server.splitKey');
const debug = config('splitio.debug');

export const serverSplitConfig: SplitIO.INodeSettings = {
  debug,
  features: path.join(__dirname, 'split.development.yaml'),
  core: {
    authorizationKey,
  },
  impressionListener: {
    logImpression,
  },
};
