import type React from 'react';
import { useState } from 'react';

import { Text } from '@farmersdog/corgi-x';
import { Arrow } from '@farmersdog/corgi-x/icons';

import styles from './NavSelect.module.scss';
import type { BenefitsKeys } from './types';

interface NavSelectProps {
  onChange: (value: BenefitsKeys) => void;
  children: React.ReactNode;
}
function NavSelect({ onChange, children }: NavSelectProps) {
  const [value, setValue] = useState<BenefitsKeys | string>('');
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const benefitKey = e.target.value as BenefitsKeys;
    setValue(benefitKey);
    onChange(benefitKey);
  };

  return (
    <Text
      as="span"
      variant="heading-22"
      color="kale-3"
      bold
      className={styles.container}
    >
      <select
        value={value}
        onBlur={handleChange}
        onChange={handleChange}
        className={styles.select}
        aria-label="Jump to a benefit"
      >
        {!value && (
          <option value="" disabled aria-hidden>
            Jump to a benefit
          </option>
        )}
        {children}
      </select>
      <span className={styles.arrow}>
        <Arrow arrowDirection="down" height={15} baseColor="Charcoal1" />
      </span>
    </Text>
  );
}

export default NavSelect;
