import { useQuery as apolloUseQuery } from '@apollo/client';

import { useClient } from './useClient';

import type { OperationVariables, QueryHookOptions } from '@apollo/client';
import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import type { DocumentNode } from 'graphql';

export function useQuery<
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
) {
  const clientOverride = useClient();

  return apolloUseQuery<TData, TVariables>(query, {
    ...options,
    client: clientOverride,
  });
}
