import { jwtDecode } from 'jwt-decode';

import { removeTokenCookie } from './removeTokenCookie';

type Jwt = { id: number };

export function getUserIdFromToken(token: string | undefined) {
  if (!token) {
    return;
  }

  const decoded = jwtDecode<Jwt>(token);
  if (decoded.id) {
    return decoded.id;
  }

  // Token without a user ID. Invalid so should be removed.
  removeTokenCookie();
  return;
}
