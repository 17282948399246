import { ACQ_HOMEPAGE_REDESIGN } from 'src/abTesting/features';
import { ToggleTreatments, useSsrFeature } from 'src/abTesting';
import { useCheckForHomepageRedesignTreatmentMismatch } from './useCheckForHomepageRedesignTreatmentMismatch';

export const useShowHomepageRedesign = () => {
  const { treatment: serverTreatment } = useSsrFeature<ToggleTreatments>(
    ACQ_HOMEPAGE_REDESIGN
  );

  useCheckForHomepageRedesignTreatmentMismatch({ serverTreatment });

  return {
    shouldShowHomepageRedesign: serverTreatment === ToggleTreatments.on,
  };
};
