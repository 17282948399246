import type { TurnstileInstance } from '@marsidev/react-turnstile';

export const FIVE_SECONDS_TIMEOUT_LIMIT = 5000;

export const handleTurnstileSubmit = async (
  turnstileInstance: React.RefObject<TurnstileInstance>,
  callResetPassword: (args: {
    email: string;
    challengeToken: string;
  }) => Promise<void>,
  email: string,
  setIsSubmitting: (isSubmitting: boolean) => void,
  setShowCaptchaError: (showCaptchaError: boolean) => void
) => {
  setIsSubmitting(true);
  setShowCaptchaError(false);

  await turnstileInstance.current
    // 👇 allows for up to 5s for invisible widget to solve
    ?.getResponsePromise(FIVE_SECONDS_TIMEOUT_LIMIT)
    .then(async token => {
      await callResetPassword({ email, challengeToken: token });
    })
    .catch(() => {
      setShowCaptchaError(true);
    })
    .finally(() => {
      setIsSubmitting(false);
    });
};
