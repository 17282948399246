import { useLazyQuery } from '../../services/apollo';

import query from './fetchQuote.graphql';

import type { FetchQuoteQuery, FetchQuoteQueryVariables } from '../types';
import type * as Apollo from '@apollo/client';

type Options = Apollo.QueryHookOptions<
  FetchQuoteQuery,
  FetchQuoteQueryVariables
>;

export function useLazyQuote(options?: Options) {
  return useLazyQuery<FetchQuoteQuery, FetchQuoteQueryVariables>(
    query,
    options
  );
}
