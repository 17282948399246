// Must use type import since RUM is loaded via CDN
import noop from 'lodash/noop';

import type { datadogRum as dataDogRumType } from '@datadog/browser-rum';

export type DatadogRum = typeof dataDogRumType;

declare global {
  interface Window {
    DD_RUM: DatadogRum | undefined;
  }
}

/**
 * Wrap datadog client so it is usable in server and client contexts. This
 * allows the client to be freely used without errors related to undefined
 * `window`
 */
export const datadogRum = new Proxy({} as DatadogRum, {
  get<K extends keyof DatadogRum>(_: unknown, key: K) {
    if (
      typeof window !== 'undefined' &&
      window.DD_RUM &&
      typeof window.DD_RUM[key] === 'function'
    ) {
      return window.DD_RUM[key];
    }

    return noop;
  },
});
