import { ACQ_HOMEPAGE_PRESS_BAR_LOWER } from 'src/abTesting/features';
import { ToggleTreatments, useSsrFeature } from 'src/abTesting';
import { useCheckForPressBarLowerTreatmentMismatch } from '../useCheckForPressBarLowerTreatmentMismatch';

export const useShowPressBarLower = () => {
  const { treatment: serverTreatment } = useSsrFeature<ToggleTreatments>(
    ACQ_HOMEPAGE_PRESS_BAR_LOWER
  );

  useCheckForPressBarLowerTreatmentMismatch({ serverTreatment });

  return {
    shouldShowPressBarLower: serverTreatment === ToggleTreatments.on,
  };
};
