import { RecipeNames } from '@farmersdog/constants';

import type { RecommendedRecipe } from '../../types';

const RECIPE_PACK_ORDER = [
  RecipeNames.PORK,
  RecipeNames.TURKEY,
  RecipeNames.CHICKEN_CHIA,
  RecipeNames.BEEF,
];

interface SortRecipesArgs {
  recipes: RecommendedRecipe[];
}

export function sortRecipes({ recipes }: SortRecipesArgs) {
  return [...recipes].sort((a, b) =>
    RECIPE_PACK_ORDER.indexOf(a.name) > RECIPE_PACK_ORDER.indexOf(b.name)
      ? 1
      : -1
  );
}
