import { Logger } from '@farmersdog/logger';
import {
  Turnstile,
  type TurnstileInstance,
  type TurnstileProps as TurnstileLibraryProps,
} from '@marsidev/react-turnstile';
import classNames from 'classnames';
import {
  type MutableRefObject,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import styles from './TurnstileWidget.module.css';
import { getCaptchaResponseFromErrorCode, getTurnstileSiteKey } from './utils/';
import { trackRequestPasswordResetEnterInteractiveChallengeMode } from 'src/analytics/events/trackRequestPasswordResetEnterInteractiveChallengeMode';
import { trackRequestPasswordResetLeaveInteractiveChallengeMode } from 'src/analytics/events/trackRequestPasswordResetLeaveInteractiveChallengeMode';
import { trackRequestPasswordResetChallengeSuccess } from 'src/analytics/events/trackRequestPasswordResetChallengeSuccess';

export type CaptchaResponse = {
  message?: string;
  pass: boolean;
};

enum ActionType {
  REQUEST_PASSWORD_RESET = 'request-password-reset',
}

enum AppearanceType {
  INTERACTION_ONLY = 'interaction-only',
}

enum WidgetTheme {
  LIGHT = 'light',
}

export enum TurnstileState {
  EXPIRED = 'expired',
  FAILED = 'failed',
  PASSED = 'passed',
  UNKNOWN = 'unknown',
}

export type TurnstileProps = {
  setCaptchaError: ((error?: string) => boolean) | (() => void);
  setCaptchaState: (state: TurnstileState) => void;
  setCaptchaToken: (token: string) => void;
  handleExpiredToken: () => void;
  isInteractiveChallenge: boolean;
  setIsInteractiveChallenge: (value: boolean) => void;
};

/**
 * Render the Turnstile library component
 */
export const TurnstileWidget = forwardRef<TurnstileInstance, TurnstileProps>(
  (
    {
      setCaptchaError,
      setCaptchaState,
      setCaptchaToken,
      handleExpiredToken,
      isInteractiveChallenge,
      setIsInteractiveChallenge,
    },
    ref
  ) => {
    const turnstileInstanceRef = ref as MutableRefObject<TurnstileInstance>;
    const logger = useMemo(
      () => new Logger('website:requestPasswordReset:TurnstileWidget'),
      []
    );
    const SITE_KEY = getTurnstileSiteKey();

    const MAX_ATTEMPTS = 3;
    const DEFAULT_RENDER_OPTIONS: TurnstileLibraryProps['options'] = {
      appearance: AppearanceType.INTERACTION_ONLY,
      action: ActionType.REQUEST_PASSWORD_RESET,
      theme: WidgetTheme.LIGHT,
    };
    const attemptCount = useRef(1);

    const handleEnterInteractiveChallengeMode = () => {
      trackRequestPasswordResetEnterInteractiveChallengeMode();
      setIsInteractiveChallenge(true);
    };
    const handleLeaveInteractiveChallengeMode = () => {
      trackRequestPasswordResetLeaveInteractiveChallengeMode();
    };

    const handleSuccess = (token: string) => {
      setCaptchaToken(token);
      setCaptchaState(TurnstileState.PASSED);
      setCaptchaError();
      trackRequestPasswordResetChallengeSuccess();
    };

    const handleError = (code: string = '') => {
      const { message, retry, reset } = getCaptchaResponseFromErrorCode(code);
      if (reset) {
        turnstileInstanceRef?.current?.reset();
        return;
      }

      if (retry && attemptCount.current < MAX_ATTEMPTS) {
        attemptCount.current = (attemptCount.current ?? 1) + 1;
        turnstileInstanceRef?.current?.reset();
        return;
      }

      setCaptchaState(TurnstileState.FAILED);
      setCaptchaError(message);
      logger.error('Failed captcha challenge', {
        code,
        message,
      });
    };
    useEffect(() => {
      if (!SITE_KEY || SITE_KEY.length === 0) {
        setCaptchaToken('');
        setCaptchaState(TurnstileState.UNKNOWN);
        setCaptchaError();
        logger.error('Invalid turnstile site key');
        return;
      }
    }, [setCaptchaToken, setCaptchaState, setCaptchaError, SITE_KEY, logger]);

    return (
      <Turnstile
        className={
          isInteractiveChallenge
            ? classNames(styles.turnstileWidget, styles.interactive)
            : styles.turnstileWidget
        }
        data-testid="turnstile-library-component"
        options={DEFAULT_RENDER_OPTIONS}
        ref={turnstileInstanceRef}
        siteKey={SITE_KEY}
        onAfterInteractive={handleLeaveInteractiveChallengeMode}
        onBeforeInteractive={handleEnterInteractiveChallengeMode}
        onSuccess={handleSuccess}
        onError={handleError}
        onExpire={handleExpiredToken}
      />
    );
  }
);
