import { Text, Divider, Mask } from '@farmersdog/corgi-x';
import styles from './VetsWide.module.scss';
import Picture from '../../../../components/Picture';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

type VetsData = {
  name: string;
  quote: JSX.Element;
  iconSources: string[];
};

function Vet({ name, quote, iconSources }: VetsData) {
  const { isAccessibilityThemeOn } = useAccessibilityThemeContext();
  const color = isAccessibilityThemeOn ? 'Kale3' : 'White';

  return (
    <div className={styles.vet}>
      <Mask shape="circle" size="md" className={styles.icon}>
        <Picture sources={iconSources} alt={name} />
      </Mask>
      <Text
        variant="heading-22"
        color={color}
        as="figcaption"
        bold
        className={styles.name}
      >
        {name}
      </Text>
      <Text
        as="blockquote"
        color={color}
        variant="article-20"
        className={styles.quote}
      >
        “{quote}”
      </Text>
    </div>
  );
}

interface VetsWideProps {
  vetsData: VetsData[];
}

function VetsWide({ vetsData }: VetsWideProps) {
  const { isAccessibilityThemeOn } = useAccessibilityThemeContext();

  return (
    <div className={styles.container}>
      <Vet {...vetsData[0]} />
      <Divider
        orientation="vertical"
        spacing={13}
        color={isAccessibilityThemeOn ? 'Kale3' : 'White'}
        width={1}
      />
      <Vet {...vetsData[1]} />
      <Divider
        orientation="vertical"
        spacing={13}
        color={isAccessibilityThemeOn ? 'Kale3' : 'White'}
        width={1}
      />
      <Vet {...vetsData[2]} />
    </div>
  );
}

export default VetsWide;
