import classNames from 'classnames';

import { Text, Mask } from '@farmersdog/corgi-x';

import Picture from 'src/components/Picture';

import styles from './LabeledPicture.module.scss';

interface LabeledPictureProps {
  sources: string[];
  label: string;
  alt: string;
  className?: string;
}

function LabeledPicture({
  sources,
  label,
  alt,
  className,
}: LabeledPictureProps) {
  return (
    <div className={classNames(styles.container, className)}>
      <Mask shape="circle" size="xl">
        <Picture sources={sources} alt={alt} />
      </Mask>
      <Text className={styles.label} variant="heading-22" color="white" bold>
        {label}
      </Text>
    </div>
  );
}

export default LabeledPicture;
