import track from '../track';
import events from '../eventNames';
import type { TreatSku } from 'src/data/treats';

export enum ViewScreens {
  SNAP_STICKS = 'Snap Sticks',
  MEALS = 'Meals',
}

interface TrackAddedTreatsArgs {
  sku: TreatSku;
  quantity: number;
  viewScreen: ViewScreens;
}

interface TrackAddedTreatsV2Args {
  productName: string;
  size: string;
  sku: string;
  quantity: number;
  viewScreen: ViewScreens;
}

/**
 * @deprecated Use trackAddedTreatsV2 instead
 */
export function trackAddedTreats(addedTreatsInfo: TrackAddedTreatsArgs): void {
  track(events.treats_add_to_plan, { addedTreatsInfo });
}

export function trackAddedTreatsV2(
  addedTreatsInfo: TrackAddedTreatsV2Args
): void {
  track(events.treats_add_to_plan, { addedTreatsInfo });
}
