import { track } from '@farmersdog/analytics';
import { LiveMessage } from '@farmersdog/corgi';
import { Button, Text } from '@farmersdog/corgi-x';
import { signupRecoveryModalState } from '@farmersdog/lead-browser-storage';

import { segmentTrackEvents } from '../../../analytics/segmentTrackEventNames';
import { useLabel } from '../../../hooks/useLabel';
import { getInputAttributes } from '../../../utils';
import { FormHint, InlineInput } from '../base';

import styles from './EmailInput.module.css';
import { useSuggestedEmail, useFormHint } from './hooks';

import type { TOSAComponentInput, TOSALeafNode } from '../../../types';
import type { ChangeEvent, FocusEvent, MouseEvent } from 'react';

export const emailHintCopy =
  'You can use your email to access your recommendations anytime.';

export function EmailInput(props: TOSAComponentInput<TOSALeafNode>) {
  const { node, formMethods } = props;
  const { register, getValues, setValue } = formMethods;
  const registerProps = register(node.name);
  const { frontLabel, backLabel } = useLabel({ node, getValues });
  const inputAttributes = getInputAttributes(node);

  const { suggestedEmail, resetSuggestedEmail, suggestEmail } =
    useSuggestedEmail();

  const { hintId, invalid } = useFormHint({ formMethods, nodeName: node.name });

  const handleBlur = async (e: FocusEvent<HTMLInputElement>) => {
    await registerProps.onBlur(e);

    const emailInput = e.target.value?.trim();
    suggestEmail(emailInput);
  };

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    await registerProps.onChange(e);

    const emailInput = e.target.value?.trim();
    setValue(node.name, emailInput, { shouldValidate: true });

    resetSuggestedEmail();
    signupRecoveryModalState.remove();
  };

  const handleSuggestedEmailClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();

    setValue(node.name, suggestedEmail, { shouldValidate: true });
    resetSuggestedEmail();
    track(segmentTrackEvents.email_correction_accepted);
  };

  return (
    <>
      <span>{frontLabel}</span>
      <InlineInput
        className={styles.email}
        {...inputAttributes}
        {...registerProps}
        aria-describedby={invalid ? hintId : undefined}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {invalid && (
        <FormHint id={hintId} className={styles.formHint}>
          {emailHintCopy}
        </FormHint>
      )}
      <span>{backLabel}</span>
      <LiveMessage aria-live="assertive" hidden={!suggestedEmail}>
        {suggestedEmail && (
          <Text
            variant="heading-16"
            as="div"
            onClick={handleSuggestedEmailClick}
            className={styles.text}
          >
            Did you mean{' '}
            <Button variant="plain-text" className={styles.cta}>
              {suggestedEmail}
            </Button>
            ?
          </Text>
        )}
      </LiveMessage>
    </>
  );
}
