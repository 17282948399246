import { paths } from '@farmersdog/constants';
import { isFreshSignup, tosaToken } from '@farmersdog/lead-browser-storage';
import cookie, {
  setCorePostGresUserId,
} from '@farmersdog/lead-browser-storage/src/cookie/cookie';

import { useCalculatingScreen } from '../../components/shared/CalculatingScreen';
import { getFinalFormStepByRoute } from '../../utils/getFinalFormStepByRoute';
import { LightboxId, useGlobalLightbox } from '../useGlobalLightbox';
import { useLeadState } from '../useLeadState';
import { useThrowToErrorBoundary } from '../useThrowToErrorBoundary';

import { useHandleFinalFormStep } from './utils';
import { handleFinalPetsStep } from './utils/handleFinalPetsStep';

import type { BranchNode } from '../../blueprint/types';
import type {
  FormFieldsType,
  FormValidationSchema,
  OnSubmitHandler,
  UseFormNavigateReturn,
} from '../../types';
import type { OnFormCompleted } from '../useHandleFormCompleted';
import type { Progress } from '../useProgress';
import type { BaseSyntheticEvent } from 'react';

export interface UseFormSubmitArgs {
  onFormCompleted: OnFormCompleted;
  progress: Progress;
  formNavigation: UseFormNavigateReturn;
  formSteps: BranchNode[];
  formValidationSchema: FormValidationSchema;
  setErrorModalMessage: (message: string) => void;
  clearAvailablePetRecipesCache: () => void;
}

export interface UseFormSubmitState {
  loading: boolean;
}

export type UseFormSubmitReturn = [
  handler: OnSubmitHandler,
  state: UseFormSubmitState,
];

// Have to use `object` as `react-hook-form` doesn't allow generics in their
// form submit event handler code.
type FormEvent<T = object> = BaseSyntheticEvent<
  T,
  HTMLFormElement,
  HTMLFormElement
>;

export function useFormSubmit({
  progress,
  formNavigation,
  formSteps,
  onFormCompleted,
  formValidationSchema,
  clearAvailablePetRecipesCache,
  setErrorModalMessage,
}: UseFormSubmitArgs): UseFormSubmitReturn {
  const calculatingScreen = useCalculatingScreen();
  const errorModalControl = useGlobalLightbox({
    id: LightboxId.ErrorModal,
  });
  const {
    convertFormStateToLeadInput,
    writeLead,
    writeLeadState,
    registerLeadState,
    registerLead,
  } = useLeadState();

  const onManagedError = (message: string) => {
    setErrorModalMessage(message);
    errorModalControl.open();
  };
  const throwToErrorBoundary = useThrowToErrorBoundary();
  const handleFinalFormStep = useHandleFinalFormStep();

  const shouldShowCalculatingScreen = !(
    cookie.isPhoneSalesAgent() || cookie.devToolsSkipCalculatingScreen()
  );

  const handleSubmit: OnSubmitHandler = async (data, e?: FormEvent) => {
    const stepName = e?.target.id ?? '';
    const onFormStepCompleted = () => onFormCompleted(stepName);

    if (!isLeadPreviouslyAuthorized()) {
      onFormStepCompleted();
      return formNavigation.setNext({
        formSteps,
      });
    }

    if (isFreshSignup()) {
      const finalPetsStep = getFinalFormStepByRoute(
        formSteps,
        paths.PATH_SIGNUP_PETS
      );
      const finalFormStep = formSteps[formSteps.length - 1];

      const isFinalPetsStep = finalPetsStep === stepName;
      const isFinalFormStep = stepName === finalFormStep.name;

      if (isFinalPetsStep) {
        await handleFinalPetsStep({
          shouldShowCalculatingScreen,
          leadInput: convertFormStateToLeadInput({
            formValidationSchema,
            formData: data,
          }),
          calculatingScreen,
          onManagedError,
          throwToErrorBoundary,
          clearAvailablePetRecipesCache,
          registerLead,
          onPetsFormCompleted: onFormStepCompleted,
        });
        return formNavigation.setNext({
          formSteps,
        });
      } else if (isFinalFormStep) {
        await handleFinalFormStep({
          formValidationSchema,
          data,
          updateLeadAndAdvanceStep,
          stepName,
          onManagedError,
          throwToErrorBoundary,
        });
      } else {
        return updateLeadAndAdvanceStep({ data, stepName });
      }
    } else {
      if (!progress.getComplete()) {
        return updateLeadAndAdvanceStep({ data, stepName });
      } else {
        await handleFinalPetsStep({
          shouldShowCalculatingScreen,
          leadInput: convertFormStateToLeadInput({
            formValidationSchema,
            formData: data,
          }),
          calculatingScreen,
          onManagedError,
          clearAvailablePetRecipesCache,
          registerLead,
          throwToErrorBoundary,
          onPetsFormCompleted: onFormStepCompleted,
        });
      }
    }
  };

  const loading = registerLeadState.loading || writeLeadState.loading;
  const formSubmitState = { loading };

  const updateLeadAndAdvanceStep = async ({
    data,
    stepName,
  }: {
    data: FormFieldsType;
    stepName: string;
  }) => {
    try {
      const lead = await writeLead({
        lead: convertFormStateToLeadInput({
          formValidationSchema,
          formData: data,
        }),
      });
      const corePostgresUserId = lead?.data?.updateLead?.corePostgresUserId;
      if (corePostgresUserId) {
        setCorePostGresUserId(String(corePostgresUserId));
      }
      clearAvailablePetRecipesCache();
      onFormCompleted(stepName);
      return formNavigation.setNext({ formSteps });
    } catch (error) {
      return throwToErrorBoundary(error);
    }
  };

  return [handleSubmit, formSubmitState];
}

function isLeadPreviouslyAuthorized(): boolean {
  return Boolean(tosaToken.get());
}
