import { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Logger } from '@farmersdog/logger';
import {
  PATH_PASSWORD_RESET,
  PATH_SIGNUP,
  PATH_CUSTOMER_ACCOUNT,
} from '@farmersdog/constants/paths';

import { scrollToTop } from 'src/utils/dom';

import AuthPage, {
  USER_NOT_ACTIVE_ERROR,
  USER_MISSING_PASSWORD_ERROR,
  NO_ACTIVE_FOODPLANS,
} from 'src/components/AuthPage';

import parseQueryString from 'src/utils/parseQueryString';
import useGlobalLightbox from 'src/utils/useGlobalLightbox';

import { showError } from 'src/actions/ui';
import { loginWithToken, logout } from 'src/actions/auth';

import { selectToken } from 'src/reducers/auth';
import { selectSubscriptionStatus } from 'src/reducers/customerAccount/user';
import * as SubscriptionStatus from 'src/constants/subscriptionStatus';

import {
  Button,
  Divider,
  Form,
  Input,
  Text,
  FormControl,
} from '@farmersdog/corgi-x';

import { useLazyPrefetchData } from '@/account/app/hooks/usePrefetchData';
import { useRequestPasswordReset } from 'src/graphql/mutations/useRequestPasswordReset';

import {
  FinishCheckoutModal,
  FINISH_CHECKOUT_MODAL_ID,
} from 'src/components/FinishCheckoutModal';
import {
  NoPasswordSetModal,
  NO_PASSWORD_SET_MODAL_ID,
} from './components/NoPasswordSetModal';

import { useAuthGraphqlLoginSubmit } from './hooks';

import styles from './Login.module.scss';
import { QueryParameter } from '@farmersdog/constants';
import { getDeprecatedErrors } from 'src/graphql/utils/getDeprecatedErrors';
import { ValidationError } from 'src/errors';
import {
  trackGoToSignup,
  trackLoginChallengeExpired,
  trackLoginFrontendSuccess,
  trackLogoutFrontendSuccess,
} from 'src/analytics';
import {
  TurnstileWidget,
  TurnstileState,
} from './components/Turnstile/TurnstileWidget';
import type { LoginCustomerMutation } from 'src/graphql/auth.types';
import type { ApolloError } from '@apollo/client';
import type { Validation } from 'src/errors/ValidationError';
import type { TurnstileInstance } from '@marsidev/react-turnstile';
import config from 'src/config';
import { useTermsChangesBanner } from 'src/hooks/useTermsChangesBanner';
import { CtaTracker, TrackingEvent } from '@farmersdog/utils';

const log = new Logger('app:pages:Login');

export const LOGIN_ERROR_INVALID_REQUEST = 'Invalid login request';
export const LOGIN_ERROR_INVALID_EMAIL = 'Invalid email address';

const TRACKING_DATA = {
  page: 'Log In',
  location: 'New to The Farmer’s Dog?',
  ctaCopy: 'Build Your Plan',
};

const isValidationError = (error: unknown): error is ValidationError => {
  return (error as ValidationError).validation !== undefined;
};

const LOGIN_ERRORS_INVALID = [
  LOGIN_ERROR_INVALID_REQUEST,
  LOGIN_ERROR_INVALID_EMAIL,
];

function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // TODO: Converting the Auth Redux is its own large scope - https://app.shortcut.com/farmersdog/story/125981/convert-auth-redux-store-to-typescript
  const authToken = useSelector(selectToken) as string;

  const subscriptionStatus = useSelector(selectSubscriptionStatus);
  const prefetchUserStatus = useLazyPrefetchData();

  const submitLoginRequest = useAuthGraphqlLoginSubmit({
    onCompleted,
    onError,
  });

  async function onCompleted(data: LoginCustomerMutation) {
    // TODO: See if these try catches can be removed - https://app.shortcut.com/farmersdog/story/75585/components-login
    try {
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await dispatch(loginWithToken(data.loginCustomer.token));
    } catch (err: unknown) {
      log.debug('loginWithToken failed, logging out user...');
      setIsSubmitting(false);
      dispatch(logout());
      setError(err);
      trackLogoutFrontendSuccess();
      return;
    }
    log.debug('Logged in');
    // TODO: See if these try catches can be removed - https://app.shortcut.com/farmersdog/story/75585/components-login
    try {
      await redirectAfterLogin();
      trackLoginFrontendSuccess({ method: 'password' });
    } catch (err) {
      log.debug('Redirect after login failed, logging out user...');
      setIsSubmitting(false);
      dispatch(logout());
      setError(err);
      trackLogoutFrontendSuccess();
    }
    setIsSubmitting(false);
  }

  const resetTurnstile = () => {
    turnstileInstance?.current?.reset();
    setCaptchaState(TurnstileState.UNKNOWN);
  };

  const handleExpiredToken = () => {
    resetTurnstile();
    trackLoginChallengeExpired();
  };

  function onError(err: ApolloError) {
    const deprecatedError = getDeprecatedErrors(err.networkError)?.[0];
    if (deprecatedError) {
      const validationError = new ValidationError(
        deprecatedError.message,
        deprecatedError.params
      );
      setError(validationError);
      setValidationError(validationError.validation);
    } else if (LOGIN_ERRORS_INVALID.includes(err?.message)) {
      resetTurnstile();
      setError(new ValidationError(LOGIN_ERROR_INVALID_REQUEST, 'login input'));
    }
    setIsSubmitting(false);
  }

  const emailParam = parseQueryString(location.search, 'email') || '';

  const [error, setError] = useState<unknown>();
  const [validationError, setValidationError] = useState<Validation>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoggingInWithToken, setIsLoggingInWithToken] = useState(false);
  const [email, setEmail] = useState(emailParam);
  const [password, setPassword] = useState('');

  const [captchaState, setCaptchaState] = useState(TurnstileState.UNKNOWN);
  const [captchaToken, setCaptchaToken] = useState('');

  const emailField = useRef<HTMLInputElement>(null);
  const passwordField = useRef<HTMLInputElement>(null);
  const turnstileInstance = useRef<TurnstileInstance>(null);

  const isTurnstileEnabled = config(
    'cloudflare.turnstile.features.login.enabled'
  );

  const invalidCaptcha =
    isTurnstileEnabled && captchaState !== TurnstileState.PASSED;

  const { open: openNoPasswordSetModal } = useGlobalLightbox({
    id: NO_PASSWORD_SET_MODAL_ID,
  });

  const { open: openFinishCheckoutModal } = useGlobalLightbox({
    id: FINISH_CHECKOUT_MODAL_ID,
  });

  const [requestPasswordReset] = useRequestPasswordReset({
    onCompleted: openNoPasswordSetModal,
    onError: err => {
      setIsSubmitting(false);
      setError(err);
    },
  });

  useEffect(() => {
    scrollToTop();
  }, []);

  const redirectAfterLogin = useCallback(async () => {
    // Redirect user to customer account
    const next = parseQueryString(location.search + location.hash, 'next');
    await prefetchUserStatus();

    log.debug('Redirecting', { to: next || PATH_CUSTOMER_ACCOUNT });
    history.push(next || PATH_CUSTOMER_ACCOUNT);
  }, [location, prefetchUserStatus, history]);

  function handleLogin() {
    log.debug('Logging in...');
    setIsSubmitting(true);
    setError(null);
    setValidationError({});
    void submitLoginRequest(email, password, captchaToken);
  }

  useEffect(() => {
    const handleTokenFound = async () => {
      log.debug('Logging in with auth token...');
      setIsLoggingInWithToken(true);
      // TODO: See if these try catches can be removed - https://app.shortcut.com/farmersdog/story/75585/components-login
      try {
        // eslint-disable-next-line @typescript-eslint/await-thenable
        await dispatch(loginWithToken(authToken));
      } catch (err) {
        log.debug('Login with auth token failed, logging out user...');
        setIsLoggingInWithToken(false);
        dispatch(logout());
        setError(err);
        trackLogoutFrontendSuccess();
        return;
      }
      log.debug('Logged in using auth token');

      if (subscriptionStatus === SubscriptionStatus.pending) {
        openFinishCheckoutModal();
        setIsLoggingInWithToken(false);
        return;
      }

      // TODO: See if these try catches can be removed - https://app.shortcut.com/farmersdog/story/75585/components-login
      try {
        await redirectAfterLogin();
        trackLoginFrontendSuccess({ method: 'token' });
      } catch (err) {
        log.debug('Redirect failed, logging out user...');
        setIsLoggingInWithToken(false);
        dispatch(logout());
        setError(err);
        trackLogoutFrontendSuccess();
      }
    };

    if (authToken && !isSubmitting) {
      log.debug('An auth token has been found');
      void handleTokenFound();
    }
  }, [
    authToken,
    dispatch,
    redirectAfterLogin,
    isSubmitting,
    subscriptionStatus,
    openFinishCheckoutModal,
  ]);

  useEffect(() => {
    if (!error) {
      return;
    }

    if (!isValidationError(error)) {
      // TODO: This class of error can now be handled properly - https://app.shortcut.com/farmersdog/story/125979/handle-non-validation-errors-in-login-frontend
      return;
    }

    const { validation } = error;

    if (!validation) {
      dispatch(showError(error));
      return;
    }

    // This could be an invalid email, password, or both.
    // Make both truthy so their input state will be invalid,
    // but set the message in password to display at the bottom of the form.
    if (validation['login input'] === LOGIN_ERROR_INVALID_REQUEST) {
      const passwordFeedback =
        'Sorry, but the email or password you entered was incorrect.';
      error.validation.email = ' ';
      error.validation.password = passwordFeedback;

      setValidationError({
        email: ' ',
        password: passwordFeedback,
      });
    }

    if (validation.email === USER_NOT_ACTIVE_ERROR) {
      openFinishCheckoutModal();
      return;
    }

    if (validation.unknown === USER_MISSING_PASSWORD_ERROR) {
      void requestPasswordReset({ email });
      return;
    }

    if (validation.plans === NO_ACTIVE_FOODPLANS) {
      const customerFacingNoPlansMsg =
        'We’d love to help you reactivate your account, please give us a call or send us a message using the link below.';

      error.message = customerFacingNoPlansMsg;
      dispatch(showError(error));
    }

    if (validation.password) {
      passwordField.current?.focus();
    } else if (validation.email) {
      emailField.current?.focus();
    }

    if (validation.captcha) {
      error.message = validation.captcha;
      dispatch(showError(error));
    }
  }, [error, dispatch, requestPasswordReset, openFinishCheckoutModal, email]);

  const recoverPasswordLink = email
    ? `${PATH_PASSWORD_RESET}?${QueryParameter.Email}=${encodeURIComponent(
        email
      )}`
    : PATH_PASSWORD_RESET;

  const isTermsChangeNoticeEnabled = useTermsChangesBanner();

  return (
    <AuthPage
      title={isLoggingInWithToken || isSubmitting ? 'Logging in…' : 'Log in'}
      loading={isLoggingInWithToken}
      header="Welcome!"
      subHeader="Please log in to continue"
      isTermsChangeNoticeEnabled={isTermsChangeNoticeEnabled}
    >
      <Form
        aria-label="Login"
        method="POST"
        name="login"
        onSubmit={handleLogin}
        enableSubmit={!isSubmitting}
        className={styles.form}
        aria-live="assertive"
        data-testid="loginFormWebsite"
      >
        <FormControl
          message={validationError.email}
          invalid={Boolean(validationError.email)}
        >
          <Input
            ref={emailField}
            data-name="email"
            name="Email"
            label="Email"
            type="email"
            autoComplete="email"
            value={email}
            onChange={e => {
              setEmail(e.target.value);
              setError(null);
              setValidationError({});
            }}
          />
        </FormControl>
        <FormControl
          message={validationError.password}
          invalid={Boolean(validationError.password)}
        >
          <Input
            className={styles.password}
            ref={passwordField}
            data-name="password"
            name="Password"
            label="Password"
            type="password"
            value={password}
            autoComplete="current-password"
            withRevealButton
            onChange={e => {
              setPassword(e.target.value);
              setError(null);
              setValidationError({});
            }}
          />
        </FormControl>
        <FormControl
          message={validationError.captcha}
          invalid={Boolean(validationError.captcha)}
        >
          {isTurnstileEnabled && (
            <div className={styles.captcha}>
              <TurnstileWidget
                ref={turnstileInstance}
                handleExpiredToken={handleExpiredToken}
                setCaptchaState={setCaptchaState}
                setCaptchaToken={setCaptchaToken}
                setCaptchaError={(msg?: string) => {
                  if (!msg) return;
                  setError(new ValidationError(msg, 'captcha'));
                }}
              />
            </div>
          )}
        </FormControl>
        <Button
          aria-label={isSubmitting ? 'Loading' : 'Log in'}
          className={styles.submitButton}
          type="submit"
          name="submit"
          loading={isSubmitting}
          aria-disabled={!email || !password || invalidCaptcha}
        >
          Log In
        </Button>
        <Button
          type="link"
          variant="plain-text"
          weight="normal"
          className={styles.resetPassword}
          to={recoverPasswordLink}
        >
          Forgot Password?
        </Button>
      </Form>
      <Divider
        width={1}
        orientation="horizontal"
        color="Charcoal0"
        className={styles.divider}
      />
      <Text
        as="h2"
        className={styles.ctaHeader}
        variant="heading-16"
        color="kale-3"
        bold
      >
        New to The Farmer’s Dog?
      </Text>
      <CtaTracker
        trackingFunction={trackGoToSignup}
        event={TrackingEvent.goToSignup}
        trackingData={TRACKING_DATA}
      >
        <Button
          type="link"
          variant="secondary"
          to={PATH_SIGNUP}
          className={styles.signup}
        >
          Build Your Plan
        </Button>
      </CtaTracker>
      <FinishCheckoutModal
        email={email}
        onCancelClick={() => {
          dispatch(logout());
          setError(null);
          setValidationError({});
        }}
      />
      <NoPasswordSetModal />
    </AuthPage>
  );
}

export default Login;
