import classNames from 'classnames';
import { Star } from '@farmersdog/corgi-x/icons';

import styles from './FiveStars.module.scss';
import type { IconProps } from '@farmersdog/corgi-x';

interface FiveStarsProps {
  color: IconProps['baseColor'];
  className?: string;
}

/**
 * Five star icons in a line
 */
function FiveStars({ color, className }: FiveStarsProps) {
  return (
    <div
      className={classNames(styles.container, className)}
      aria-label="Rating 5 out of 5"
      role="img"
    >
      <Star height={24} width={24} baseColor={color} aria-hidden />
      <Star height={24} width={24} baseColor={color} aria-hidden />
      <Star height={24} width={24} baseColor={color} aria-hidden />
      <Star height={24} width={24} baseColor={color} aria-hidden />
      <Star height={24} width={24} baseColor={color} aria-hidden />
    </div>
  );
}

export default FiveStars;
