import Page from 'src/components/Page';
import ErrorPage from 'src/errors/ErrorPage';
import { useAsyncModule } from 'src/utils/useAsyncModule';

import type { PawAuthenticatedRoutesModule } from './PawAuthenticatedRoutes';

/** We temporarily need a second entrypoint into PAW. In this case it is at a
 * level of the tree beyond authentication. As PAW becomes more complete we will
 * only need the single PAW App module (domains/website/paw/src/app/App.tsx) and this
 * can be removed.  */
export function LazyLoadedPawAuthenticatedRoutes() {
  const pawAuthenticatedRoutesModule =
    useAsyncModule<PawAuthenticatedRoutesModule>(
      () => import('./PawAuthenticatedRoutes')
    );

  if (pawAuthenticatedRoutesModule.error) {
    return <ErrorPage error={pawAuthenticatedRoutesModule.error} />;
  }

  if (
    pawAuthenticatedRoutesModule.loading ||
    !pawAuthenticatedRoutesModule.called
  ) {
    return <Page title="Loading…" loading />;
  }

  const PawAuthenticatedRoutes =
    pawAuthenticatedRoutesModule.data?.PawAuthenticatedRoutes;
  return <>{PawAuthenticatedRoutes && <PawAuthenticatedRoutes />}</>;
}
