import classNames from 'classnames';
import { Carousel } from '@farmersdog/corgi';
import { Text } from '@farmersdog/corgi-x';
import { useCorgiViewport } from 'src/screen';

import FiveStars from '../FiveStars';
import ReviewCard from '../ReviewCard';

import styles from './GoogleReviews.module.scss';
import { reviewData } from './data';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

/**
 * Google Reviews section containing a title and carousel of reviews
 */
function GoogleReviews() {
  const isMobile = useCorgiViewport({
    xs: true,
    lg: false,
  });

  const { isAccessibilityThemeOn } = useAccessibilityThemeContext();

  return (
    <div
      className={classNames(styles.container, {
        [styles.lg]: !isMobile,
        [styles.accessibilityTheme]: isAccessibilityThemeOn,
      })}
    >
      <FiveStars color="Kale3" className={styles.stars} />
      <Text
        variant="heading-40"
        as="h2"
        color="kale-3"
        bold
        className={styles.heading}
      >
        Google Reviews
      </Text>
      <Text
        variant="heading-16"
        color={isAccessibilityThemeOn ? 'kale-3' : 'carrot-2'}
        as="h2"
      >
        Over 100,000,000 Meals Served
      </Text>
      <Carousel
        withButtons={!isMobile}
        className={styles.carousel}
        centerFirstAndLast={isMobile}
      >
        {reviewData.map((review, index) => (
          <ReviewCard key={index} {...review} />
        ))}
      </Carousel>
    </div>
  );
}

export default GoogleReviews;
