import { Grid, Slideshow } from '@farmersdog/corgi-x';

import { trackCondensedPressBarClicked } from 'src/analytics/events/trackCondensedPressBarClicked';
import type { PressBarItems } from './PressBarItems';
import { PressBarItem } from './PressBarItems';

type CondensedMobilePressBarProps = {
  items: PressBarItems[];
};

export const CondensedMobilePressBar = ({
  items,
}: CondensedMobilePressBarProps) => (
  <Slideshow
    withDots
    withButtons
    withLoop
    label="Mobile press bar"
    controlsPosition="outside"
    scrollBehavior="smooth"
    onSlideChange={trackCondensedPressBarClicked}
  >
    {items.map(item => (
      <Grid key={item} justifyContent="center" alignItems="center">
        <PressBarItem name={item} loading="lazy" />
      </Grid>
    ))}
  </Slideshow>
);
