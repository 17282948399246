import * as yup from 'yup';

import { NodeNames, tosaErrorCodes as errorCodes } from '@farmersdog/constants';
import { emailRegex } from '@farmersdog/constants/regexPatterns';

import { getNodeNameAndPosition } from '../blueprint/utils';

import * as constants from './constants';
import { BirthdayAccuracy, BodyCondition } from './constants';
import { getAgeInWeeks } from './getAgeInWeeks';

yup.addMethod(yup.string, 'email', function (message: string) {
  return this.matches(emailRegex, {
    name: 'email',
    message,
    excludeEmptyString: true,
  });
});

declare module 'yup' {
  interface ArraySchema<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    TIn extends any[] | null | undefined,
    TContext,
    TDefault = undefined,
    TFlags extends yup.Flags = '',
  > {
    unique(message: string): ArraySchema<TIn, TContext, TDefault, TFlags>;
  }

  interface ObjectSchema<
    TIn extends yup.Maybe<yup.AnyObject>,
    TContext = yup.AnyObject,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    TDefault = any,
    TFlags extends yup.Flags = '',
  > {
    minWeeks(
      minAgeInWeeks: number,
      message: string
    ): ObjectSchema<TIn, TContext, TDefault, TFlags>;
    maxWeeks(
      maxAgeInWeeks: number,
      message: string
    ): ObjectSchema<TIn, TContext, TDefault, TFlags>;
  }
}

yup.addMethod(yup.array, 'unique', function (message: string) {
  return this.test('unique', message, (value = []) => {
    return new Set(value).size === value.length;
  });
});

function isLengthOfTime(input: unknown): input is constants.LengthOfTime {
  return (
    !!input &&
    typeof input === 'object' &&
    'unit' in input &&
    'amount' in input &&
    typeof input.unit === 'string' &&
    typeof input.amount === 'number'
  );
}

yup.addMethod(
  yup.object,
  'minWeeks',
  function (minAgeInWeeks: number, message: string) {
    return this.test('unique', message, input => {
      if (!isLengthOfTime(input)) {
        return false;
      }

      const ageInWeeks = getAgeInWeeks(input);

      return ageInWeeks >= minAgeInWeeks;
    });
  }
);

yup.addMethod(
  yup.object,
  'maxWeeks',
  function (maxAgeInWeeks: number, message: string) {
    return this.test('unique', message, input => {
      if (!isLengthOfTime(input)) {
        return false;
      }

      const ageInWeeks = getAgeInWeeks(input);

      return ageInWeeks < maxAgeInWeeks;
    });
  }
);

export const petValidationSchema = {
  [NodeNames.BirthdayAmount]: yup
    .number()
    .test('age limit', errorCodes.PET_TOO_YOUNG, (value, context) => {
      if (!value) {
        return false;
      }
      const { position } = getNodeNameAndPosition(context.path);
      const unit = context.parent[
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        `${NodeNames.BirthdayUnit}-${position}`
      ] as string;

      const ageInWeeks = getAgeInWeeks({
        unit,
        amount: value,
        position,
      });
      if (ageInWeeks < constants.MIN_AGE_WEEKS) {
        throw context.createError({ message: errorCodes.PET_TOO_YOUNG });
      }

      if (ageInWeeks >= constants.MAX_AGE_WEEKS) {
        throw context.createError({ message: errorCodes.PET_TOO_OLD });
      }

      return true;
    }),

  [NodeNames.BirthdayUnit]: yup
    .string()
    .oneOf(
      [BirthdayAccuracy.Months, BirthdayAccuracy.Weeks, BirthdayAccuracy.Years],
      errorCodes.UNEXPECTED_ERROR
    )
    .required(),
  [NodeNames.ActivityLevel]: yup.string().required(),
  [NodeNames.BodyCondition]: yup.string().required(),
  [NodeNames.Breeds]: yup.array().of(yup.string().required()).min(1).required(),
  [NodeNames.EatingStyle]: yup.string().required(),
  [NodeNames.FoodBrand]: yup.string().required(),
  [NodeNames.FoodType]: yup.string().required(),
  [NodeNames.Gender]: yup.string().required(),
  [NodeNames.Healthy]: yup.string().required(),
  [NodeNames.Issues]: yup.array().of(yup.string().required()).min(1).required(),
  [NodeNames.Name]: yup
    .string()
    .required()
    .trim()
    .test('unique', errorCodes.PET_NAMES_NOT_UNIQUE, (value, context) => {
      if (!value) {
        return false;
      }
      const { name: thisNodeName, position: thisNodePosition } =
        getNodeNameAndPosition(context.path);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const otherPetNames = Object.entries(context.parent)
        .filter(([key]) => {
          const { name, position } = getNodeNameAndPosition(key);
          return name === thisNodeName && position !== thisNodePosition;
        })
        .map(([, val]) => String(val).toLowerCase());

      return !otherPetNames.includes(value.toLowerCase());
    }),
  [NodeNames.Nature]: yup.string().required(),
  [NodeNames.Neutered]: yup.boolean().required(),
  [NodeNames.PrescriptionDiet]: yup.string().required(),
  [NodeNames.PrescriptionDiets]: yup
    .array()
    .of(yup.string().required())
    .min(1)
    .required(),
  [NodeNames.Weight]: yup.number().required(),
  [NodeNames.TargetWeight]: yup
    .number()
    .required()
    .test(
      'body condition & weight mismatch',
      errorCodes.OVERWEIGHT_PET_TARGET_WEIGHT,
      (value, context) => {
        if (!value) {
          return false;
        }
        const { position } = getNodeNameAndPosition(context.path);

        if (!position) {
          return false;
        }
        const bodyCondition = context.parent[
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          `${NodeNames.BodyCondition}-${position}`
        ] as string;

        const weight = context.parent[
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          `${NodeNames.Weight}-${position}`
        ] as string;

        if (
          (bodyCondition === BodyCondition.Chunky ||
            bodyCondition === BodyCondition.Rounded) &&
          Number(value) >= Number(weight)
        ) {
          throw context.createError({
            message: errorCodes.OVERWEIGHT_PET_TARGET_WEIGHT,
          });
        } else if (
          bodyCondition === BodyCondition.TooSkinny &&
          Number(value) <= Number(weight)
        ) {
          throw context.createError({
            message: errorCodes.UNDERWEIGHT_PET_TARGET_WEIGHT,
          });
        }

        return true;
      }
    ),
  [NodeNames.TreatsQuantity]: yup.string().required(),
};

export const leadValidationSchema = {
  [NodeNames.Email]: yup.string().required().email(errorCodes.EMAIL_INVALID),
  [NodeNames.FirstName]: yup.string().required(),
  [NodeNames.FreshFoodConfidence]: yup.string().required(),
  [NodeNames.NumPets]: yup
    .number()
    .required()
    .positive(errorCodes.NUM_PETS_INVALID)
    .integer(errorCodes.NUM_PETS_INVALID)
    .max(constants.MAX_NUMBER_OF_PETS, errorCodes.NUM_PETS_TOO_MANY),
  [NodeNames.Phone]: yup
    .string()
    .matches(constants.PHONE_REGEXP, {
      excludeEmptyString: true,
      message: errorCodes.PHONE_INVALID,
    })
    .nullable(),
  [NodeNames.PhoneConsent]: yup.boolean(),
  [NodeNames.Zip]: yup
    .string()
    .required()
    .matches(constants.ZIP_REGEXP, errorCodes.ZIP_INVALID),
  selection: yup.object().shape({
    treats: yup.array().of(
      yup.object().shape({
        name: yup.string().required(),
        size: yup.string().required(),
        quantity: yup.number().positive().integer().required(),
      })
    ),
  }),
};

export const validationSchema = {
  ...petValidationSchema,
  ...leadValidationSchema,
};

export type ValidationSchemaType = typeof validationSchema;
export type ValidationSchemaFields = keyof ValidationSchemaType;

export function isValidationSchemaField(
  schema: ValidationSchemaType,
  field: unknown
): field is ValidationSchemaFields {
  return typeof field === 'string' && Object.keys(schema).includes(field);
}
