import { Grid, PageWidth } from '@farmersdog/corgi-x';
import { getLinkToFreshSignup } from '@farmersdog/tosa/src/utils/getLinkToSignupFlow';
import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';

import { HeroCta, HeroIconItems, HeroImage, HeroTitle } from './components';

import styles from './HomepageRedesignHero.module.css';

export const HomepageRedesignHero = () => {
  const linkToFreshSignup = getLinkToFreshSignup(PATH_SIGNUP_ME);

  return (
    <PageWidth className={styles.pageHeroWrapper}>
      <Grid
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap="lg"
        topSpacing="xl"
        bottomSpacing="lg"
      >
        <HeroTitle />
        <HeroCta linkToFreshSignup={linkToFreshSignup} />
        <HeroIconItems />
      </Grid>
      <HeroImage linkToFreshSignup={linkToFreshSignup} />
    </PageWidth>
  );
};
