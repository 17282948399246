import { useSelector } from 'react-redux';

import { anonymousId } from '@farmersdog/lead-browser-storage';
import { Logger } from '@farmersdog/logger';
import { browserOnly } from '@farmersdog/utils';

import { ACQ_HOMEPAGE_PRESS_BAR_LOWER } from 'src/abTesting/features';
import { selectTokenId } from 'src/reducers/auth';
import { ToggleTreatments, useFeature } from 'src/abTesting';

interface UseCheckForPressBarLowerTreatmentMismatchProps {
  serverTreatment: ToggleTreatments | undefined;
}

export function useCheckForPressBarLowerTreatmentMismatch({
  serverTreatment,
}: UseCheckForPressBarLowerTreatmentMismatchProps) {
  const userId = useSelector(selectTokenId) as string | undefined;
  const { treatment: clientTreatment, isReady } = useFeature(
    ACQ_HOMEPAGE_PRESS_BAR_LOWER
  );

  browserOnly(() => {
    const isControl = clientTreatment === ToggleTreatments.control;
    if (!isReady || isControl) return;

    if (clientTreatment !== serverTreatment) {
      const tfdAnonymousId = anonymousId.get();
      const log = new Logger('website:checkForPressBarLowerTreatmentMismatch');
      log.warn('Homepage Press Bar Lower Experiment Treatment Mismatch: ', {
        clientTreatment,
        serverTreatment,
        userId,
        tfdAnonymousId,
      });
    }
  });
}
