import type { ApolloError } from '@apollo/client';
import { useMutation } from '@apollo/client';
import resetPasswordMutation from './resetPasswordMutation.auth.graphql';
import type {
  ResetPasswordInput,
  ResetPasswordResponse,
} from 'src/graphql/auth.types';
import type { MutationState } from 'src/graphql/mutations/useMutation';
import { GraphQLEndpoints } from 'src/graphql/Apollo/constants';

export interface UseResetPasswordArgs {
  onCompleted: (
    data:
      | ResetPasswordResponse
      | {
          resetPassword: ResetPasswordResponse;
        }
  ) => void;
  onError: (err: ApolloError) => void;
}

export interface UseResetPassword {
  callResetPassword: (input: ResetPasswordInput) => void;
  resetPasswordState: MutationState<ResetPasswordResponse>;
}

export function useResetPassword({
  onError,
  onCompleted,
}: UseResetPasswordArgs): UseResetPassword {
  const [resetPassword, resetPasswordState] =
    useMutation<ResetPasswordResponse>(resetPasswordMutation, {
      onCompleted,
      onError,
      context: { endpoint: GraphQLEndpoints.AUTH },
    });

  return {
    callResetPassword: (input: ResetPasswordInput) => {
      void resetPassword({
        variables: { input },
      });
    },
    resetPasswordState,
  };
}
