import { useState, useEffect, useCallback } from 'react';

import {
  isBranchNode,
  blueprintTraverseApply,
  loadBlueprint,
  compileBlueprintComponentMap,
  filterBlueprintForPhoneSalesAgent,
  filterBlueprintForProductLine,
  filterBlueprintForExperimentation,
} from '../blueprint/utils';
import { getComponent } from '../schema/componentMap';
import { transformBlueprintStaticData } from '../utils/transform-blueprint-static-data';

import { useStaticDataFetchContext } from './useStaticDataFetchContext';
import { useThrowToErrorBoundary } from './useThrowToErrorBoundary';

import type {
  BranchNode,
  CompiledBlueprint,
  NodeRegistry,
  Blueprint,
} from '../blueprint/types';
import type { Experiments, UseSchemaReturn } from '../types';

export interface FormMetadata {
  blueprint: CompiledBlueprint | undefined;
  registry: NodeRegistry | undefined;
  steps: BranchNode[];
}

export interface GetBlueprint {
  (variables?: { version: string }): Blueprint | Promise<Blueprint>;
}

export interface UseSchemaArgs {
  getBlueprint: GetBlueprint;
  blueprintVersion?: string;
  anonymousExperiments: Experiments;
}

function computeFormSteps(blueprint: CompiledBlueprint): FormMetadata {
  const steps: BranchNode[] = [];

  const formMetadata = loadBlueprint(blueprint);

  blueprintTraverseApply(formMetadata.blueprint.root, (node: BranchNode) => {
    if (isBranchNode(node) && node.route) {
      steps.push(node);
    }
  });

  return {
    ...formMetadata,
    steps,
  };
}

export function useSchema({
  getBlueprint,
  blueprintVersion,
  anonymousExperiments,
}: UseSchemaArgs): UseSchemaReturn {
  const { fetchAttempted, setFetchAttempted } = useStaticDataFetchContext();

  const [compiledBlueprint, setCompiledBlueprint] =
    useState<CompiledBlueprint>();

  const [form, setForm] = useState<FormMetadata>({
    registry: undefined,
    blueprint: undefined,
    steps: [],
  });

  const setFormSteps = useCallback(
    (blueprint: CompiledBlueprint): BranchNode[] => {
      // Ideally this function would simply do
      // `setCompiledBlueprint(blueprint)` and let hooks take care of the rest,
      // but we have to return the updated steps for the consumer to use.
      const blueprintWithExperiments = filterBlueprintForExperimentation({
        blueprint,
        anonymousExperiments,
      });

      setCompiledBlueprint(blueprintWithExperiments);

      const recomputedFormSteps = computeFormSteps(blueprintWithExperiments);

      return recomputedFormSteps.steps;
    },
    [anonymousExperiments]
  );

  const throwToErrorBoundary = useThrowToErrorBoundary();

  useEffect(() => {
    async function getAndLoadBlueprint() {
      let blueprint;
      try {
        blueprint = blueprintVersion
          ? await getBlueprint({ version: blueprintVersion })
          : await getBlueprint();
      } catch (error: unknown) {
        throwToErrorBoundary(error);
        return;
      }

      const filteredBlueprint = filterBlueprintForPhoneSalesAgent({
        blueprint,
      });

      const filteredBlueprintForProductLine = filterBlueprintForProductLine({
        blueprint: filteredBlueprint,
      });

      const blueprintWithComponents = compileBlueprintComponentMap({
        blueprint: filteredBlueprintForProductLine,
        getComponent,
      });

      const blueprintWithStaticData = transformBlueprintStaticData(
        blueprintWithComponents
      );

      const { blueprint: loadedBlueprint } = loadBlueprint(
        blueprintWithStaticData
      );

      setCompiledBlueprint(loadedBlueprint);
    }

    /* 
      NOTE: important context check to validate a blueprint fetch request has 
      been made to avoid useEffect loop triggered by throwToErrorBoundary.
    */
    if (!fetchAttempted) {
      setFetchAttempted(true);
      void getAndLoadBlueprint();
    }
  }, [
    blueprintVersion,
    getBlueprint,
    throwToErrorBoundary,
    fetchAttempted,
    setFetchAttempted,
  ]);

  useEffect(() => {
    if (compiledBlueprint) {
      const blueprintWithExperiments = filterBlueprintForExperimentation({
        blueprint: compiledBlueprint,
        anonymousExperiments,
      });

      setCompiledBlueprint(blueprintWithExperiments);
    }
  }, [compiledBlueprint, anonymousExperiments]);

  useEffect(() => {
    if (compiledBlueprint) {
      setForm(computeFormSteps(compiledBlueprint));
    }
  }, [compiledBlueprint]);

  return {
    schema: form.blueprint,
    registry: form.registry,
    formSteps: form.steps,
    setFormSteps,
  };
}
