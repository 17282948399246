import PersonalizedPlan from './assets/personalized-plan.svg';
import DeliverOnASchedule from './assets/deliver-on-a-schedule.svg';
import PeopleStanding from './assets/people-standing.svg';
import type { FunctionComponent, SVGProps } from 'react';

import UnderlineDrawing from './assets/underline.svg';

import styles from './BetterForThem.module.css';

// TODO: this icons will be replace with Lottie animations in the future, for now we are using static images

interface BetterForThemItem {
  id: number;
  asset: FunctionComponent<SVGProps<SVGSVGElement>>;
  title: React.ReactNode;
}

export const betterForThemItems: BetterForThemItem[] = [
  {
    id: 1,
    asset: PersonalizedPlan,
    title: (
      <>
        We’ll build a personalized <br /> plan for your dog
      </>
    ),
  },
  {
    id: 2,
    asset: DeliverOnASchedule,
    title: (
      <>
        Deliver it{' '}
        <span className={styles.underlineContainer}>
          for free
          <UnderlineDrawing className={styles.underline} />
        </span>{' '}
        on a schedule <br /> that works for you
      </>
    ),
  },
  {
    id: 3,
    asset: PeopleStanding,
    title: (
      <>
        With real people standing <br /> by to chat 24/7
      </>
    ),
  },
];
