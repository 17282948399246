import TurkeyBowlPng from './turkey-bowl.png';
import TurkeyBowlWebp from './turkey-bowl.webp';
import TurkeyPackPng from './turkey-pack.png';
import TurkeyPackWebp from './turkey-pack.webp';

import ArrowTopSvg from './arrow-top.svg';
import ArrowBottomSvg from './arrow-bottom.svg';

export const turkeyBowlSources = {
  webP: TurkeyBowlWebp,
  png: TurkeyBowlPng,
};

export const turkeyPackSources = {
  webP: TurkeyPackWebp,
  png: TurkeyPackPng,
};

export { ArrowTopSvg, ArrowBottomSvg };
