import { BasicInput } from '../base';

import styles from './ZipInput.module.css';

import type { TOSAComponentInput, TOSALeafNode } from '../../../types';

export function ZipInput(props: TOSAComponentInput<TOSALeafNode>) {
  return (
    <div className={styles.container}>
      <BasicInput {...props} />
    </div>
  );
}
