import type React from 'react';
import { Text, Grid, GridItem } from '@farmersdog/corgi-x';

import DoubleQuote from '../../assets/double-quote.svg';

import styles from './Quote.module.css';

interface QuoteProps {
  doubleQuotesOnTop?: boolean;
  text: string | React.ReactNode;
  author?: string;
  className?: string;
}

export const Quote = ({
  doubleQuotesOnTop = false,
  text,
  author,
  className,
}: QuoteProps) => {
  return (
    <Grid gap="sm" className={className}>
      {doubleQuotesOnTop && (
        <GridItem>
          <DoubleQuote />
        </GridItem>
      )}
      <GridItem>
        <Text variant="article-20" className={styles.quote} color="charcoal-3">
          {text}
        </Text>
      </GridItem>
      {!doubleQuotesOnTop && (
        <GridItem>
          <DoubleQuote />
        </GridItem>
      )}
      {author && (
        <GridItem>
          <Text
            variant="heading-16"
            color="charcoal-3"
            className={styles.author}
          >
            {author}
          </Text>
        </GridItem>
      )}
    </Grid>
  );
};
