import {
  PATH_SIGNUP_CHECKOUT,
  PATH_SIGNUP_ME,
  PATH_SIGNUP_PETS,
  PATH_SIGNUP_RECIPES,
} from '@farmersdog/constants/paths';

const ME_LABEL = 'Me';
const ME_PATH = `${PATH_SIGNUP_ME}?card=Me1`;
export const PETS_LABEL = 'My Dogs';
const PLAN_LABEL = 'Your Plan';
const CHECKOUT_LABEL = 'Checkout';

export const staticStepData = [
  {
    active: false,
    completed: true,
    path: ME_PATH,
    label: ME_LABEL,
    disabled: false,
  },
  {
    active: false,
    completed: true,
    path: PATH_SIGNUP_PETS,
    label: PETS_LABEL,
    disabled: false,
  },
  {
    active: true,
    completed: false,
    path: PATH_SIGNUP_RECIPES,
    label: PLAN_LABEL,
    disabled: false,
  },
  {
    active: false,
    completed: false,
    path: PATH_SIGNUP_CHECKOUT,
    label: CHECKOUT_LABEL,
    disabled: true,
  },
];
