import { NodeNames } from '@farmersdog/constants';

import { blueprintTraverseApply } from '../blueprint/utils';

import type {
  Blueprint,
  Fragment,
  Node,
  CompiledBlueprint,
} from '../blueprint/types';

interface StaticDataInput extends Record<string, unknown> {
  name?: string;
  description?: string;
}

export interface StaticDataOutput extends Record<string, unknown> {
  label?: string;
  name?: string;
  description?: string;
}

interface OptionsInputNode extends Node {
  input: {
    options: StaticDataInput[];
  };
}

type Transform = (
  input: StaticDataInput,
  fields?: string[]
) => StaticDataOutput;

const standardTransformer: Transform = (input, fields = []) => {
  const { name } = input;
  const forwardObj = fields.reduce(
    (obj: Record<string, unknown>, key: string) => {
      if (input && input[key]) {
        obj[key] = input[key];
      }
      return obj;
    },
    {}
  );

  return {
    name,
    label: name,
    value: name?.toLowerCase(),
    ...forwardObj,
  };
};

const unCapitalizeTransformer: Transform = (input, fields = []) => {
  if (!fields) {
    return input;
  }

  const result = { ...input };
  for (const field of fields) {
    const value = input[field];

    if (typeof value === 'string') {
      result[field] = value
        .split(' ')
        .map(word => word[0].toLowerCase() + word.substring(1))
        .join(' ');
    }
  }

  return result;
};

const transformMap: Record<string, Transform | Transform[] | null> = {
  [NodeNames.ActivityLevel]: input =>
    standardTransformer(input, ['description']),
  [NodeNames.BodyCondition]: input =>
    standardTransformer(input, ['description']),
  [NodeNames.Breeds]: [standardTransformer],
  [NodeNames.EatingStyle]: standardTransformer,
  [NodeNames.FoodType]: standardTransformer,
  [NodeNames.TreatsQuantity]: standardTransformer,
  [NodeNames.Issues]: input => standardTransformer(input, ['type']),
  [NodeNames.PrescriptionDiets]: input => standardTransformer(input, ['type']),
  [NodeNames.Nature]: [
    standardTransformer,
    input => unCapitalizeTransformer(input, ['name']),
  ],
};

function isOptionsInputNode(node: Node | Fragment): node is OptionsInputNode {
  return !!node.input?.options;
}

const apply = (node: Node | Fragment) => {
  const transform = transformMap[node.name];

  if (transform && node.input && isOptionsInputNode(node)) {
    if (Array.isArray(transform)) {
      transform.forEach(t => {
        node.input.options = node.input.options.map(option => t(option));
      });
    } else {
      node.input.options = node.input.options.map(option => transform(option));
    }
  }
};

export function transformBlueprintStaticData<
  B extends Blueprint | CompiledBlueprint,
>(blueprint: B): B {
  blueprintTraverseApply(blueprint.root, apply);

  blueprint.fragments.forEach(fragment => {
    blueprintTraverseApply(fragment, apply);
  });

  return blueprint;
}
