import { useMemo } from 'react';

import { PATH_SIGNUP_PETS } from '@farmersdog/constants/paths';

import { useGetLead } from '../../../../graphql/queries';

import { PETS_LABEL, staticStepData } from './staticStepData';

const PET_AGE = 'PetAge';

export function useStaticStepData() {
  const lead = useGetLead().data?.lead;

  const stepData = useMemo(() => {
    if (!lead) {
      return staticStepData;
    }

    const firstPetName = lead.pets[0].name;
    const petsPath = `${PATH_SIGNUP_PETS}?pet=${firstPetName}&card=${PET_AGE}`;

    const staticStepDataWithUpdatedPetPath = staticStepData.map(step => {
      if (step.label === PETS_LABEL) {
        return {
          ...step,
          path: petsPath,
        };
      }

      return step;
    });

    return staticStepDataWithUpdatedPetPath;
  }, [lead]);

  return stepData;
}
