import {
  mountAmazonTagLead,
  ga4Mounter,
  mountISpotMeConversion,
  mountArtsAiLead,
  mountIHeartRadioMeConversion,
  mountPostieEvent,
  PostieEvents,
  PostieSchemas,
  mountKatzLead,
} from '@farmersdog/pixels';

import { mountXLead, trackFluentLeadConversion } from '../vendors';

import { useRequestAttributesContext } from './useRequestAttributes';

import type {
  OnMeStepCompleted,
  OnMeStepCompletedData,
} from './useHandleFormCompleted';

export function useHandleMeCompleted(
  websiteHandleMeCompleted?: OnMeStepCompleted
) {
  const requestAttributes = useRequestAttributesContext();

  const handleMeCompleted = (step: string, data: OnMeStepCompletedData) => {
    if (websiteHandleMeCompleted) {
      websiteHandleMeCompleted(step, data);
    }

    mountIHeartRadioMeConversion();
    mountXLead();
    mountAmazonTagLead();
    mountISpotMeConversion({
      utmSource: requestAttributes?.utm_source,
    });
    mountArtsAiLead();
    mountKatzLead();
    ga4Mounter.meSuccess({
      data: { productLine: data?.lead?.productLine?.current ?? '' },
    });
    ga4Mounter.sendUserId({ userId: data?.lead?.corePostgresUserId });
    mountPostieEvent({
      eventName: PostieEvents.TrackUnstructEvent,
      properties: {
        schema: PostieSchemas.Email,
        data: {
          email: data?.lead?.email,
        },
      },
    });
    trackFluentLeadConversion({ email: data?.lead?.email });
  };

  return handleMeCompleted;
}
