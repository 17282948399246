import { Lightbox } from '@farmersdog/corgi';
import { SubscriptionType } from '@farmersdog/lead-browser-storage';

import { useGlobalLightbox, LightboxId } from '../../../hooks';

import { AssetPreloader } from './AssetPreloader';
import { Comparison as ComparisonComponent } from './Comparison';
import { DiggingDog as DiggingDogComponent } from './DiggingDog';

export interface CalculatingScreenContentProps {
  onCompleted: () => void;
}

interface CalculatingScreenProps {
  productLine: SubscriptionType;
}

export function CalculatingScreen({ productLine }: CalculatingScreenProps) {
  const { rootProps, close, setSuppressed } = useGlobalLightbox({
    id: LightboxId.CalculatingScreen,
    onOpen: () => {
      setSuppressed(true);
    },
  });

  const CalculatingScreenComponent =
    productLine === SubscriptionType.Diy
      ? DiggingDogComponent
      : ComparisonComponent;

  return (
    <>
      <AssetPreloader />
      <Lightbox withAnimation={false} {...rootProps}>
        <CalculatingScreenComponent onCompleted={close} />
      </Lightbox>
    </>
  );
}
