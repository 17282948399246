import { Accordion, AccordionPanel, Text } from '@farmersdog/corgi-x';
import { faqItems } from './data';

import styles from './FAQ.module.css';

export const FAQ = () => {
  return (
    <section className={styles.section}>
      <Text
        bold
        as="h3"
        color="kale-3"
        topSpacing="none"
        bottomSpacing="md"
        variant="heading-28"
      >
        Still have questions?
      </Text>
      <Accordion className={styles.accordion}>
        {faqItems.map(({ question, answer }) => (
          <AccordionPanel key={question} id={question} heading={question}>
            <Text
              as="p"
              align="left"
              vSpacing="none"
              leftSpacing="md"
              color="charcoal-3"
              variant="article-16"
            >
              {answer}
            </Text>
          </AccordionPanel>
        ))}
      </Accordion>
    </section>
  );
};
