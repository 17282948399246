import { PATH_WEBSITE_HOMEPAGE } from '@farmersdog/constants/paths';

import * as Types from '@/account/graphql/core/types';

interface GetHomePagePathArgs {
  isSubscriptionActive: boolean;
  subscriptionType?: Types.SubscriptionType | null;
}

export function getHomepagePath({
  subscriptionType,
  isSubscriptionActive,
}: GetHomePagePathArgs): string | undefined {
  if (
    subscriptionType === Types.SubscriptionType.Fresh &&
    isSubscriptionActive
  ) {
    return PATH_WEBSITE_HOMEPAGE;
  }
  return undefined;
}
