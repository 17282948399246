import classNames from 'classnames';

import {
  Mask,
  PageWidth,
  Text,
  useViewport,
  Carousel,
  Section,
} from '@farmersdog/corgi-x';

import Picture from 'src/components/Picture';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

import styles from './BenefitCarousel.module.scss';

interface Quote {
  name: string;
  quote: React.ReactNode;
  sources: string[];
}

interface BenefitCarouselProps {
  quotes: Quote[];
}

function BenefitCarousel({ quotes }: BenefitCarouselProps) {
  const { lg } = useViewport();
  const { accessibilityThemeStyles } = useAccessibilityThemeContext();

  return (
    <PageWidth enabled={lg}>
      <Carousel className={styles.carousel} centerFirstAndLast={!lg}>
        {quotes.map(quoteData => (
          <Section
            key={quoteData.name}
            aria-labelledby={quoteData.name}
            className={classNames(styles.card, accessibilityThemeStyles.card)}
          >
            <Mask shape="circle" size="md" className={styles.mask}>
              <Picture sources={quoteData.sources} alt={quoteData.name} />
            </Mask>
            <Text
              as="blockquote"
              variant="article-20"
              color="charcoal-3"
              className={styles.quote}
            >
              “{quoteData.quote}”
            </Text>
            <Text as="figcaption" variant="heading-16" color="charcoal-3">
              — {quoteData.name}
            </Text>
          </Section>
        ))}
      </Carousel>
    </PageWidth>
  );
}

export default BenefitCarousel;
