import classNames from 'classnames';

import { Grid, Text, Wave, Picture, useViewport } from '@farmersdog/corgi-x';

import heroJpg from '../../assets/hero.jpg';
import heroWebp from '../../assets/hero.webp';
import heroMobileJpg from '../../assets/heroMobile.jpg';
import heroMobileWebp from '../../assets/heroMobile.webp';
import { Container } from '../Container';
import { Treats } from '../Treats';

import styles from './Hero.module.css';
import { Title } from './Title';

import type { LeadSelectionTreat, Treat } from '../../../../graphql/types';

const HERO_IMAGES = {
  desktop: [heroJpg, heroWebp],
  mobile: [heroMobileJpg, heroMobileWebp],
};

type HeroProps = {
  availableTreats: Treat[];
  selectedTreat: Treat | null;
  onSelectTreat: (treat: Treat) => void;
  ctaElement: React.ReactNode;
  alreadyAddedTreats: LeadSelectionTreat[];
  petNames: string[];
};

export const Hero = ({
  availableTreats,
  selectedTreat,
  onSelectTreat,
  ctaElement,
  alreadyAddedTreats,
  petNames,
}: HeroProps) => {
  const viewport = useViewport();

  return (
    <Wave
      className={styles.wave}
      fill="Chickpea0"
      waveHeight={34}
      padding={viewport.md ? 'margin-lg' : undefined}
      withBottom
    >
      <Container>
        <div className={styles.hero}>
          {viewport.md && <Title petNames={petNames} />}
          <Grid
            alignItems="flex-start"
            flexDirection={{ lg: 'row', md: 'column' }}
            topSpacing={viewport.md ? 'lg' : undefined}
            justifyContent="center"
            gap={viewport.md ? '3xl' : 'sm'}
          >
            <div
              className={classNames(styles.section, styles.heroImageContainer)}
            >
              <div className={styles.limitedOffer}>
                <Text
                  as={viewport.md ? 'span' : 'div'}
                  variant="heading-16"
                  color="white"
                >
                  Limited time offer:{' '}
                </Text>
                <Text variant="heading-16" color="white" bold>
                  Free first bag of treats!
                </Text>
                <Text variant="heading-16" color="white">
                  {' '}
                  ($11.99 value)
                </Text>
              </div>
              <Picture
                sources={viewport.md ? HERO_IMAGES.desktop : HERO_IMAGES.mobile}
                alt="Dog taking treat"
                className={styles.image}
              />
            </div>
            {!viewport.md && <Title petNames={petNames} />}
            <div className={styles.section}>
              <Treats
                treats={availableTreats}
                currentSelection={selectedTreat}
                onSelect={onSelectTreat}
                alreadyAddedTreats={alreadyAddedTreats}
              />
              {viewport.md && <Grid justifyContent="center">{ctaElement}</Grid>}
              <Text
                as="p"
                align="center"
                topSpacing="md"
                bottomSpacing="none"
                variant={viewport.md ? 'heading-16' : 'heading-12'}
              >
                <strong>First bag free</strong> then $11.99 per bag in each
                future order.
              </Text>
              <Text
                as="p"
                align="center"
                topSpacing="none"
                variant={viewport.md ? 'heading-16' : 'heading-12'}
              >
                Make changes, skip orders, cancel anytime.
              </Text>
            </div>
          </Grid>
        </div>
      </Container>
    </Wave>
  );
};
