import {
  mobileLovedByHumans,
  desktopLovedByHumans,
} from '../../../../../AssetPreloader/assets/loved-by-humans';

import {
  desktopDogWalking,
  mobileDogWalking,
} from '../../../../../AssetPreloader/assets/q5-dog-walking';

import {
  desktopDogNature,
  mobileDogNature,
} from '../../../../../AssetPreloader/assets/q5-dog-nature';

import Q5DesktopUnderline from '../../../../assets/q5-hero-desktop-underline.svg';
import Q5MobileUnderline from '../../../../assets/q5-hero-mobile-underline.svg';

import type { MobileAndDesktopSources } from '../../../../../AssetPreloader/assets/shared';
import { Nowrap } from '@farmersdog/corgi-x';

import styles from './HeroContent.module.css';

interface HeroConfigReturn {
  title: JSX.Element;
  subtitle: JSX.Element;
  assets: MobileAndDesktopSources;
  alt: string;
  lowerBackground: boolean;
  cta: string;
  disclaimer?: string;
}

const lovedByHumansTitle = (
  <>
    Supported by vets.
    <br />
    Loved by dog people.
  </>
);

const lovedByHumansSubtitle = (
  <>
    Fresh food formulated by
    <br />
    board-certified nutritionists.
  </>
);

const lovedByHumans = {
  title: lovedByHumansTitle,
  subtitle: lovedByHumansSubtitle,
};

export const LovedByHumansConfig: HeroConfigReturn = {
  title: lovedByHumans.title,
  subtitle: lovedByHumans.subtitle,
  assets: {
    mobileSources: mobileLovedByHumans,
    desktopSources: desktopLovedByHumans,
  },
  alt: 'Dog looking at a bowl of food',
  lowerBackground: true,
  cta: 'Shop Fresh',
};

// ########################################################

export const CtaOnly50OffConfig: HeroConfigReturn = {
  ...LovedByHumansConfig,
  cta: 'Get 50% Off',
};

export const CtaOnlySeePlansAndPricingConfig: HeroConfigReturn = {
  ...LovedByHumansConfig,
  cta: 'See Plans & Pricing',
};

// ########################################################

const Q5DogTitle = (
  <span className={styles.underlineContainer}>
    <Nowrap> Fresh year,</Nowrap>
    <br /> <Nowrap> fresh food.</Nowrap>
    <Q5DesktopUnderline className={styles.q5DesktopUnderline} />
    <Q5MobileUnderline className={styles.q5MobileUnderline} />
  </span>
);

const Q5DogSubtitle = (
  <>
    Here&apos;s a New Year’s resolution you&apos;ll
    <br className={styles.mobileBreakline} /> actually want to keep: getting
    your dog
    <br className={styles.mobileBreakline} /> healthy with personally portioned
    food
    <br className={styles.mobileBreakline} /> based on their unique needs.
  </>
);

export const Q5DogNatureHeroOnlyConfig: HeroConfigReturn = {
  title: Q5DogTitle,
  subtitle: Q5DogSubtitle,
  assets: {
    mobileSources: mobileDogNature,
    desktopSources: desktopDogNature,
  },
  alt: 'Brown dog with a red collar in a forest.',
  cta: 'Shop Fresh',
  lowerBackground: false,
};

// ########################################################

export const Q5DogWalkingHeroConfig: HeroConfigReturn = {
  title: Q5DogTitle,
  subtitle: Q5DogSubtitle,
  assets: {
    mobileSources: mobileDogWalking,
    desktopSources: desktopDogWalking,
  },
  alt: 'Spotted Great Dane walking on a street.',
  cta: 'Shop Fresh',
  lowerBackground: false,
};
