import { Text } from '@farmersdog/corgi-x';

import styles from './Summary.module.css';

/**
 * Summary of our products impact
 */
function Summary() {
  return (
    <div className={styles.container}>
      <Text
        as="p"
        variant="article-20"
        color="charcoal-3"
        className={styles.text}
      >
        A simple change in what we put in dogs’ bowls can have a transformative
        impact on their health — improving everything from digestion and weight
        issues, to energy levels and life span. But these humans tell it best.
      </Text>
    </div>
  );
}

export default Summary;
