import type { DiyRecipe } from '../types';
import { PrimaryProtein } from '../types';
import beefAndBroccoliWebp from '../assets/beef-and-broccoli.webp';

export const beefAndBroccoli: DiyRecipe = {
  name: 'Beef & Broccoli',
  primaryProtein: PrimaryProtein.Beef,
  ingredients: [
    {
      name: 'Ground Beef (10% Fat)',
      quantity: 2.509,
      unit: 'lbs',
    },
    {
      name: 'Sweet Potato (Diced, Fresh or frozen)',
      quantity: 249,
      unit: 'grams',
    },
    {
      name: 'Broccoli (Fresh or frozen), cut into 1/4 inch dice',
      quantity: 249,
      unit: 'grams',
    },
    {
      name: 'Spinach (Chopped, Fresh or Frozen)',
      quantity: 174,
      unit: 'grams',
    },
    {
      name: 'Beef Liver (Fresh)',
      quantity: 172,
      unit: 'grams',
    },
    {
      name: 'DIY Nutrient Mix',
      quantity: 22.5,
      unit: 'grams',
    },
    {
      name: 'Parmesan Cheese (Shredded)',
      quantity: 10,
      unit: 'grams',
    },
    {
      name: 'Sunflower Oil',
      quantity: 9,
      unit: 'grams',
    },
    {
      name: 'Salmon Oil',
      quantity: 1,
      unit: 'gram',
    },
  ],
  id: 'beef-and-broccoli',
  cookTime: '40 mins',
  calories: 2430,
  utensils: [],
  image: beefAndBroccoliWebp,
  instructions: [],
};
