import { Logger } from '@farmersdog/logger';
import {
  getHasSSRAnonymousExperiment,
  useSSRAnonymousExperiments,
} from 'src/server/context/experimentsContext';
import type { AnonymousExperimentFlag } from 'src/server/middlewares/experimentsMiddleware';

interface UseSsrFeatureResult<TreatmentType> {
  treatment?: TreatmentType;
}

export const useSsrFeature = <TreatmentType = string>(
  featureKey: AnonymousExperimentFlag
): UseSsrFeatureResult<TreatmentType> => {
  const experiments = useSSRAnonymousExperiments();
  const hasFeature = getHasSSRAnonymousExperiment(experiments, featureKey);
  const serverTreatment = hasFeature && experiments[featureKey]?.treatment;
  const log = new Logger(`website:useSsrFeature:${featureKey}`);

  if (!hasFeature) {
    log.error(`No SSR Anonymous Experiments found for ${featureKey}`, {
      experiments,
    });
    return {};
  }

  if (!serverTreatment) {
    log.error(`No treatment found for ${featureKey}`, {
      experiment: experiments[featureKey],
    });
    return {};
  }

  return { treatment: serverTreatment as TreatmentType };
};
