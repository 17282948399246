import { Logger } from '@farmersdog/logger';

import {
  desktopLovedByHumans,
  mobileLovedByHumans,
} from '../../pages/Home/components/AssetPreloader/assets/loved-by-humans';
import {
  useSSRAnonymousExperiments,
  getHasSSRAnonymousExperiment,
} from '../../server/context/experimentsContext';
import type { MobileAndDesktopSources } from 'src/pages/Home/components/AssetPreloader/assets/shared';
import {
  desktopDogNature,
  mobileDogNature,
} from 'src/pages/Home/components/AssetPreloader/assets/q5-dog-nature';
import {
  desktopDogWalking,
  mobileDogWalking,
} from 'src/pages/Home/components/AssetPreloader/assets/q5-dog-walking';

import { HomepageHeroImageTreatments } from 'src/abTesting';
import { CVR_HOMEPAGE_HERO_IMAGE } from 'src/abTesting/features';

const CURRENT_TREATMENTS = [
  HomepageHeroImageTreatments.LovedByHumans,
  HomepageHeroImageTreatments.CtaOnly50Off,
  HomepageHeroImageTreatments.CtaOnlySeePlansAndPricing,
  HomepageHeroImageTreatments.Q5DogNatureHeroOnly,
  HomepageHeroImageTreatments.Q5DogWalkingHeroOnly,
] as const;

type CurrentTreatment = (typeof CURRENT_TREATMENTS)[number];

const assets: Record<CurrentTreatment, MobileAndDesktopSources> = {
  [HomepageHeroImageTreatments.LovedByHumans]: {
    mobileSources: mobileLovedByHumans,
    desktopSources: desktopLovedByHumans,
  },
  [HomepageHeroImageTreatments.CtaOnly50Off]: {
    mobileSources: mobileLovedByHumans,
    desktopSources: desktopLovedByHumans,
  },
  [HomepageHeroImageTreatments.CtaOnlySeePlansAndPricing]: {
    mobileSources: mobileLovedByHumans,
    desktopSources: desktopLovedByHumans,
  },
  [HomepageHeroImageTreatments.Q5DogNatureHeroOnly]: {
    mobileSources: mobileDogNature,
    desktopSources: desktopDogNature,
  },
  [HomepageHeroImageTreatments.Q5DogWalkingHeroOnly]: {
    mobileSources: mobileDogWalking,
    desktopSources: desktopDogWalking,
  },
};

type UseHomepageHeroExperimentReturn = MobileAndDesktopSources & {
  treatment: HomepageHeroImageTreatments;
};

const DEFAULT_TREATMENT = HomepageHeroImageTreatments.LovedByHumans;

export function useHomepageHeroExperiment(): UseHomepageHeroExperimentReturn {
  const experiments = useSSRAnonymousExperiments();

  const hasHomepageHeroExperiment = getHasSSRAnonymousExperiment(
    experiments,
    CVR_HOMEPAGE_HERO_IMAGE
  );

  if (!hasHomepageHeroExperiment) {
    const log = new Logger('website:useHomepageHeroExperiment');
    log.error(
      'No SSR Anonymous Experiments found in useHomepageHeroExperiment',
      {
        experiments,
      }
    );

    return {
      ...assets[DEFAULT_TREATMENT],
      treatment: HomepageHeroImageTreatments.Control,
    };
  }

  const homepageHeroExperiment = experiments.cvr_homepage_hero_image;
  if (!homepageHeroExperiment?.treatment) {
    const log = new Logger('website:useHomepageHeroExperiment');
    log.error('No treatment found for homepage hero experiment', {
      experiment: homepageHeroExperiment,
    });

    return {
      ...assets[DEFAULT_TREATMENT],
      treatment: HomepageHeroImageTreatments.Control,
    };
  }

  const treatment = homepageHeroExperiment.treatment;

  if (CURRENT_TREATMENTS.includes(treatment as CurrentTreatment)) {
    return {
      ...assets[treatment as CurrentTreatment],
      treatment,
    };
  }

  // Return the default assets if we don't actively recognize the treatment
  return {
    ...assets[DEFAULT_TREATMENT],
    treatment,
  };
}
