import { ContentReveal } from '@farmersdog/corgi-x';

import { usePriorFoodTypeProgression } from '../../../hooks';
import { DropdownInput } from '../base/DropdownInput';

import type { TOSAComponentInput, TOSALeafNode } from '../../../types';

export function TreatsQuantityInput(props: TOSAComponentInput<TOSALeafNode>) {
  const { node, useFeature, progress } = props;

  const { showSingleQuestionProgression, isReady } =
    usePriorFoodTypeProgression({
      useFeature,
    });

  const shouldRevealInput = progress.getPreviousLeafComplete(node.__self__);

  const overrideLabel = node.input?.label;

  if (!isReady) return null;

  return showSingleQuestionProgression ? (
    <ContentReveal in={shouldRevealInput} inAnimation="fade">
      <div>
        <DropdownInput {...props} overrideLabel={overrideLabel} />
      </div>
    </ContentReveal>
  ) : (
    <DropdownInput {...props} overrideLabel={overrideLabel} />
  );
}
