import { NodeNames } from '@farmersdog/constants';

import { ProductLineType } from '../../../../graphql/types';
import { filterObjectByFields } from '../../../../utils/filterObjectByFields';
import { isNotNullUndefinedOrEmptyString } from '../../../../utils/isNotNullUndefinedOrEmptyString';
import { denormalizePhone } from '../../../../utils/normalizers';

import { getAnonymousIdInput } from './getAnonymousIdInput';
import { getDiscountInput } from './getDiscountInput';
import { getProductLineInput } from './getProductLineInput';

import type { Position } from '../../../../blueprint/types';
import type { LeadInput, PetInput } from '../../../../graphql/types';
import type { FormFieldsType, FormValidationSchema } from '../../../../types';

const LEAD_KEYS = [
  NodeNames.FirstName,
  NodeNames.Zip,
  NodeNames.Phone,
  NodeNames.FreshFoodConfidence,
  NodeNames.PhoneConsent,
  NodeNames.Treats,
];

interface ConvertFormStateToLeadInputArgs {
  formValidationSchema: FormValidationSchema;
  formData: FormFieldsType;
}

export type ConvertFormStateToLeadInputType = (
  args: ConvertFormStateToLeadInputArgs
) => LeadInput;

export function convertFormStateToLeadInput({
  formValidationSchema,
  formData,
}: ConvertFormStateToLeadInputArgs): LeadInput {
  const leadData = formValidationSchema.cast(formData, {
    assert: 'ignore-optionality',
  }) as FormFieldsType;

  const numPets = Number(leadData.numPets);
  const pets = [];

  for (let petIndex = 0; petIndex < numPets; petIndex += 1) {
    const indexKey = `${petIndex + 1}` as Position;
    pets.push(convertToPetInput(leadData, indexKey));
  }

  const leadInput: Partial<LeadInput> = filterLeadInputs(leadData);

  const lead = {
    blueprintVersion: '1.0.0',
    ...leadInput,
    pets,
  };

  const anonymousId = getAnonymousIdInput();
  const productLine = getProductLineInput();
  const discount = getDiscountInput();

  if (anonymousId) {
    lead.anonymousId = anonymousId;
  }

  if (productLine) {
    lead.productLine = productLine;
  }

  if (discount) {
    lead.discount = discount;
  }

  return lead;
}

const petOptionalKeys: (keyof PetInput)[] = [
  'breeds',
  'weight',
  'targetWeight',
  'neutered',
  'activityLevel',
  'bodyCondition',
  'nature',
  'eatingStyle',
  'gender',
  'treatsQuantity',
  'foodType',
  'foodBrand',
  'issues',
  'prescriptionDiets',
];

export function convertToPetInput(
  data: FormFieldsType,
  indexKey: Position
): PetInput {
  const indexedPetNameKey = `name-${indexKey}` as const;

  const petInput: Partial<PetInput> = Object.fromEntries(
    petOptionalKeys
      .filter(petKey => {
        const indexedPetKey = `${petKey}-${indexKey}` as keyof FormFieldsType;
        return isNotNullUndefinedOrEmptyString(data[indexedPetKey]);
      })
      .map(petKey => {
        const indexedPetKey = `${petKey}-${indexKey}` as keyof FormFieldsType;

        return [petKey, data[indexedPetKey]];
      })
  );

  const birthdayInput = convertToBirthdayInput(data, indexKey);

  if (birthdayInput) {
    petInput.birthdayInput = birthdayInput;
  }

  const selectionInput = convertToSelectionInput(data, indexKey);

  if (selectionInput) {
    petInput.selection = selectionInput;
  }

  return {
    // @ts-expect-error TODO: Get types for forms validated by yup
    name: data[indexedPetNameKey],
    ...petInput,
  };
}

function convertToSelectionInput(
  data: FormFieldsType,
  indexKey: Position
): PetInput['selection'] | null {
  const selection: PetInput['selection'] = {
    fresh: null,
    diy: null, // TODO - handle diy selection when diy recipes page is moved to tosa
  };

  const selectedRecipes = data[`${NodeNames.FreshSelection}-${indexKey}`];

  if (!selectedRecipes) {
    return null;
  }

  selection.fresh = {
    productLine: ProductLineType.Fresh,
    options: { recipes: selectedRecipes },
  };

  return selection;
}

function convertToBirthdayInput(
  data: FormFieldsType,
  indexKey: Position
): PetInput['birthdayInput'] | null {
  const indexedBirthdayAmountKey = `birthdayAmount-${indexKey}` as const;
  const indexedBirthdayUnitKey = `birthdayUnit-${indexKey}` as const;

  const amount = data[indexedBirthdayAmountKey];
  const unit = data[indexedBirthdayUnitKey];

  if (unit && amount) {
    return {
      unit,
      amount,
    };
  }

  return null;
}

function filterLeadInputs(data: FormFieldsType) {
  const leadInput = filterObjectByFields({ object: data, fields: LEAD_KEYS });

  // extra confirmation to ensure phone is denormalized before submission
  if (leadInput[NodeNames.Phone]) {
    leadInput[NodeNames.Phone] = denormalizePhone(
      leadInput[NodeNames.Phone] as string
    );
  }
  return leadInput;
}
