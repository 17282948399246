import { NodeNames, tosaErrorCodes as errorCodes } from '@farmersdog/constants';

import {
  useDoesPhoneNumberExist,
  useValidateZipcode,
} from '../../../../graphql/queries';
import { getErrorMessage } from '../../../../validation';

import type { ValidateZipcodeQueryVariables } from '../../../../graphql/types';
import type { Experiments } from '../../../../types';

interface DoesPhoneNumberAlreadyExistArgs {
  email: string;
  phone: string | null;
}

interface ValidateContactDataArgs {
  email: string;
  zip: string;
  phone: string | null;
  experiments: Experiments;
}

export function useContactValidation() {
  const { refetch: refetchValidateZipcode, loading: validateZipcodeLoading } =
    useValidateZipcode({ errorPolicy: 'all', skip: true });
  const {
    refetch: refetchDoesPhoneNumberExist,
    loading: doesPhoneNumberExistLoading,
  } = useDoesPhoneNumberExist({ errorPolicy: 'all', skip: true });

  async function validateZipcodeInput({
    zipcode,
  }: ValidateZipcodeQueryVariables) {
    const zipcodeValidationResponse = await refetchValidateZipcode({
      zipcode,
    });

    if (!zipcodeValidationResponse?.data?.validateZipcode?.zipcode) {
      throw new Error(errorCodes.ZIP_NOT_SERVICEABLE);
    }
  }

  async function doesPhoneNumberAlreadyExist({
    email,
    phone,
  }: DoesPhoneNumberAlreadyExistArgs) {
    if (phone) {
      const phoneNumberAlreadyExistsResponse =
        await refetchDoesPhoneNumberExist({
          email,
          phone,
        });

      if (phoneNumberAlreadyExistsResponse?.data?.doesPhoneNumberExist) {
        throw new Error(errorCodes.PHONE_DUPLICATE);
      }

      if (
        phoneNumberAlreadyExistsResponse?.errors &&
        phoneNumberAlreadyExistsResponse.errors[0] &&
        phoneNumberAlreadyExistsResponse.errors[0].extensions?.code ===
          errorCodes.PHONE_INVALID
      ) {
        throw new Error(errorCodes.PHONE_INVALID);
      }
    }
  }

  async function validateContactData(data: ValidateContactDataArgs) {
    await Promise.all([
      validateZipcodeInput({ zipcode: data.zip }),
      doesPhoneNumberAlreadyExist({
        email: data.email,
        phone: data.phone,
      }),
    ]);
  }

  function handleContactValidationError(error: unknown) {
    const errorMessage = getErrorMessage(error);

    switch (errorMessage) {
      case errorCodes.ZIP_INVALID:
      case errorCodes.ZIP_NOT_SERVICEABLE: {
        return {
          errorMessage,
          field: NodeNames.Zip,
        };
      }
      case errorCodes.PHONE_INVALID:
      case errorCodes.PHONE_DUPLICATE: {
        return {
          errorMessage,
          field: NodeNames.Phone,
        };
      }
      default: {
        return null;
      }
    }
  }

  return {
    handleContactValidationError,
    validateContactData,
    validateZipcodeLoading,
    doesPhoneNumberExistLoading,
  };
}
