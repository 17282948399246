import { Link } from '@farmersdog/corgi-x';
import { AdditionalTerms } from 'src/components/AdditionalTerms';

const EMAIL = 'care@thefarmersdog.com';
const MAIL_TO = `mailto:${EMAIL}`;

export function BonusDiscountOfferTerms() {
  return (
    <AdditionalTerms title="Referral Terms">
      <p>
        Offer valid from January 14th, 2025 9:00am ET while supplies last online
        at thefarmersdog.com in the contiguous 48 United States only. Offer
        applicable to new customers only with an active automatic recurring
        subscription service. Offer applies to The Farmer’s Dog fresh food
        products only, not to applicable taxes, shipping & handling charges.
        Offer is non-transferable and not valid for cash or cash equivalent. No
        adjustments on previous purchases. The Farmer’s Dog reserves the right
        to modify, terminate or cancel the offer at any time. For inquiries,
        contact <Link to={MAIL_TO}>{EMAIL}</Link>.
      </p>
    </AdditionalTerms>
  );
}
