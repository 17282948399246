import classNames from 'classnames';

import type { PictureProps } from '@farmersdog/corgi-x';
import { Picture, Text } from '@farmersdog/corgi-x';

import styles from './PressBarItem.module.css';
import {
  CnetLogoPng,
  CnetLogoWebp,
  GoodHousekeepingLogoPng,
  GoodHousekeepingLogoWebp,
  GoogleLogoPng,
  GoogleLogoWebp,
  NewYorkMagazineLogoPng,
  NewYorkMagazineLogoWebp,
  PopularScienceLogoPng,
  PopularScienceLogoWebp,
} from 'src/components/Logos/assets';

export enum PressBarItems {
  PopularScience = 'PopularScience',
  Cnet = 'Cnet',
  Google = 'Google',
  GoodHousekeeping = 'GoodHousekeeping',
  NewYorkMagazine = 'NewYorkMagazine',
}

type PressBarItemProps = {
  name: PressBarItems;
} & Partial<PictureProps>;

const defaultConfig = {
  className: '',
  noQuotes: false,
};

const pressBarItemsConfig = {
  [PressBarItems.PopularScience]: {
    sources: [PopularScienceLogoWebp, PopularScienceLogoPng],
    alt: 'Popular Science Logo',
    height: 45,
    label:
      'The Farmer’s Dog is working to combat poor health with fresh, human-grade food',
  },
  [PressBarItems.Cnet]: {
    sources: [CnetLogoWebp, CnetLogoPng],
    alt: 'CNET Logo',
    height: 33,
    label:
      'A personalized profile and diet for your dog based on breed, age, activity level, ideal weight.',
    className: styles.cnetItem,
  },
  [PressBarItems.Google]: {
    sources: [GoogleLogoWebp, GoogleLogoPng],
    alt: 'Google customer reviews five stars',
    height: 78,
    label: 'Customer reviews',
    noQuotes: true,
  },
  [PressBarItems.GoodHousekeeping]: {
    sources: [GoodHousekeepingLogoWebp, GoodHousekeepingLogoPng],
    alt: 'Good Housekeeping Logo',
    height: 45,
    label: 'Helps you zero in on everything you need to consider for your dog',
  },
  [PressBarItems.NewYorkMagazine]: {
    sources: [NewYorkMagazineLogoWebp, NewYorkMagazineLogoPng],
    alt: 'New York Magazine Logo',
    height: 45,
    label:
      'Ongoing long-term feeding trials show that the nutritional value in its food exceeds AAFCO recommendations.',
  },
};

export const PressBarItem = ({ name, ...pictureProps }: PressBarItemProps) => {
  const { sources, alt, height, label, noQuotes, className } = {
    ...defaultConfig,
    ...pressBarItemsConfig[name],
  };
  return (
    <div className={classNames(styles.container, className)}>
      <Picture
        {...pictureProps}
        sources={sources}
        alt={alt}
        style={{ height }}
      />
      {label && (
        <Text
          as={noQuotes ? 'p' : 'blockquote'}
          variant="article-16"
          color="charcoal-3"
          className={styles.quote}
        >
          {noQuotes ? `${label}` : `“${label}”`}
        </Text>
      )}
    </div>
  );
};
