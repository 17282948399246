import type {
  BirthdayView,
  PetView,
  LeadView,
  PetProductFreshSelection,
  ProductLineType,
} from '../graphql/types';
import type { ValidationSchemaFields } from '../validation';
import type { ChangeEvent, SyntheticEvent } from 'react';

export type NodeId = string;

export enum NodeType {
  branch = 'branch',
  leaf = 'leaf',
}

export enum LayoutType {
  inline = 'inline',
  block = 'block',
  none = 'none',
}

export type NodeInput = Record<string, unknown>;
export type MaybeNodeInput<T extends NodeInput = NodeInput> =
  | T
  | null
  | undefined;

export interface GenericNode {
  name: string;
  type: NodeType;
  layout?: LayoutType;
  route?: string;
  validation?: unknown;
  component?: unknown;
  productLines?: ProductLineType[];
  input?: MaybeNodeInput;
  children?: GenericNode[];
  __self__?: NodeId;
}

// TODO This needs work and may not reflect actual required type
export interface ComponentInput<T extends GenericNode> {
  node: T;
  schema: CompiledBlueprint;
  registry: NodeRegistry;
  onComplete?: (e: SyntheticEvent) => Promise<void>;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

// TODO This needs work and may not reflect actual required type
export type NodeComponent<T extends GenericNode> = (
  input: ComponentInput<T>
) => JSX.Element;

export interface Fragment extends GenericNode {
  name: string;
  type: NodeType;
  route?: string;
  validation?: ValidationSchemaFields;
  component?: ComponentName | NodeComponent<Fragment>;
  input?: MaybeNodeInput;
  children?: Fragment[];
  __self__?: NodeId;
}

export interface Node extends GenericNode {
  validation?: ValidationSchemaFields;
  component?: NodeComponent<Node>;
  children?: Node[];
  __self__: NodeId;
}

interface BranchBase<T extends GenericNode> {
  children: T[];
  component?: NodeComponent<Branch<T>>;
}

interface LeafBase<T extends GenericNode> {
  component?: NodeComponent<Leaf<T>>;
}

export type Branch<T extends GenericNode> = T & BranchBase<T>;

export type Leaf<T extends GenericNode> = Omit<T, 'children'> & LeafBase<T>;

export type LeafFragment = Leaf<Fragment>;
export type BranchFragment = Branch<Fragment>;
export type LeafNode = Leaf<Node>;
export type BranchNode = Branch<Node>;
export interface Blueprint {
  version: string;
  root: BranchFragment;
  fragments: Fragment[];
}

export interface CompiledBlueprint {
  version: string;
  root: BranchNode;
  fragments: Fragment[];
}

export type NodeRegistry = Record<NodeId, Node>;

type PetBirthdayFields = Omit<BirthdayView, '__typename'>;
export type IndexedPetBirthdayFields = {
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  [K in keyof PetBirthdayFields &
    string as `birthday${Capitalize<K>}-${Position}`]?: PetBirthdayFields[K];
};

export type IndexedPetFreshSelectionFields = {
  [key in `freshSelection-${Position}`]?: PetProductFreshSelection['options']['recipes'];
};

export type IndexedPetFreshRecommendationFields = {
  [key in `freshRecommendation-${Position}`]?: PetProductFreshSelection['options']['recipes'];
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Positions = ['1', '2', '3', '4', '5', '6', '7', '8'] as const;
export type Position = (typeof Positions)[number];

export type LeadFields = Omit<
  LeadView,
  | '__typename'
  | 'anonymousId'
  | 'blueprintVersion'
  | 'productLine'
  | 'discount'
  | 'pets'
> & { phone: string | null };

export type PetFields = Omit<
  PetView,
  '__typename' | 'birthdayInput' | 'selection'
>;

export type IndexedPetFields = {
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  [K in keyof PetFields & string as `${K}-${Position}`]?: PetView[K];
};

export enum ComponentName {
  BasicInput = 'BasicInput',
  DropdownInput = 'DropdownInput',
  CheckboxInput = 'CheckboxInput',
  InlineError = 'InlineError',
  GetStarted = 'GetStarted',
  GetStartedCopyExperiment = 'GetStartedCopyExperiment',
  FormWrapper = 'FormWrapper',
  GetStartedWrapper = 'GetStartedWrapper',
  FormWithContinueButton = 'FormWithContinueButton',
  PetFormWrapper = 'PetFormWrapper',
  PetWeightForm = 'PetWeightForm',
  ContactInfoForm = 'ContactInfoForm',
  NumPetInput = 'NumPetInput',
  BodyConditionInput = 'BodyConditionInput',
  PetNameInput = 'PetNameInput',
  BirthdayUnitInput = 'BirthdayUnitInput',
  ActivityLevelInput = 'ActivityLevelInput',
  EatingStyleInput = 'EatingStyleInput',
  EmailInput = 'EmailInput',
  BreedsInput = 'BreedsInput',
  PetHealthCheckboxes = 'PetHealthCheckboxes',
  PetHealthInput = 'PetHealthInput',
  PetWeightInput = 'PetWeightInput',
  MeWhitespaceNode = 'MeWhitespaceNode',
  PetWhitespaceNode = 'PetWhitespaceNode',
  PetDetailsImage = 'PetDetailsImage',
  NumberOfPetsImage = 'NumberOfPetsImage',
  HealthForm = 'HealthForm',
  PetCurrentFoodForm = 'PetCurrentFoodForm',
  PetAgeSkipButton = 'PetAgeSkipButton',
  FoodTypeInput = 'FoodTypeInput',
  Form = 'Form',
  TreatsQuantityInput = 'TreatsQuantityInput',
  RecipesFormWrapper = 'RecipesFormWrapper',
  RecipesPage = 'RecipesPage',
  RecipesForm = 'RecipesForm',
  ZipInput = 'ZipInput',
  FoodBrandInput = 'FoodBrandInput',
}
