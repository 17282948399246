import { Text } from '@farmersdog/corgi-x';

import styles from './Steps.module.css';

export function Steps({ numSteps }: { numSteps: number }) {
  return (
    <div className={styles.container}>
      <Text variant="heading-16" color="carrot-2">
        Your Plan (3/{numSteps})
      </Text>
    </div>
  );
}
