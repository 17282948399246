import { CHECKOUT_SUCCESS_GID, katz, KatzEventName, PIXEL_ID } from './katz';

/**
 * Track a Katz checkout conversion
 */
export function trackKatzCheckoutConversion(): void {
  katz.track({
    ppt: PIXEL_ID,
    g: KatzEventName.CHECKOUT_SUCCESS,
    gid: CHECKOUT_SUCCESS_GID,
  });
}
