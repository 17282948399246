import { GridItem, Text } from '@farmersdog/corgi-x';

import styles from './HeadingText.module.css';

interface HeadingTextProps {
  header: string;
  subheader: string;
}
export function HeadingText({ header, subheader }: HeadingTextProps) {
  return (
    <>
      {/* mobile layout */}
      <div>
        <GridItem
          className={styles.mobile}
          xs={12}
          justifyContent="center"
          alignItems="center"
        >
          <Text as="h2" variant="heading-28" color="kale-3" bold>
            {header}
          </Text>
        </GridItem>
      </div>
      <div>
        <GridItem className={styles.mobile} justifyContent="center">
          <Text
            className={styles.subheader}
            as="h3"
            variant="heading-16"
            color="charcoal-3"
          >
            {subheader}
          </Text>
        </GridItem>
      </div>

      {/* desktop layout */}
      <div>
        <GridItem className={styles.desktop} xs={12}>
          <Text as="h2" variant="heading-40" color="kale-3" bold>
            {header}
          </Text>
        </GridItem>
      </div>
      <div>
        <GridItem className={styles.desktop}>
          <Text
            className={styles.subheader}
            as="h3"
            variant="heading-22"
            color="charcoal-3"
          >
            {subheader}
          </Text>
        </GridItem>
      </div>
    </>
  );
}
