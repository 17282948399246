import type { OnStepCompleted } from './useHandleFormCompleted';
import type { FetchLeadQuery } from '../graphql/types';

export function useHandleStepCompleted(
  websiteHandleStepCompleted?: OnStepCompleted
) {
  const handleStepCompleted = (
    step: string,
    lead: FetchLeadQuery['lead'] | null
  ) => {
    if (websiteHandleStepCompleted) {
      websiteHandleStepCompleted(step, lead);
    }
  };

  return handleStepCompleted;
}
