import config from '../config/index';
import logo from './assets/logo.png';
import ogImageJpg from './assets/og-image.jpg';
import schema from '../pages/Home/schema.json';

export const { description } = schema;
export const image = `${config('app.publicUrl')}${logo}`;
export const ogImage = `${config('app.publicUrl')}${ogImageJpg}`;
export const siteName = 'The Farmer’s Dog';
export const defaultTitle =
  'Human-Grade Fresh Dog Food Delivery | The Farmer’s Dog';
export const titleTemplate = '%s | The Farmer’s Dog';
