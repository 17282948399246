import { paths } from '@farmersdog/constants';
import { cookie } from '@farmersdog/lead-browser-storage';

import type { GraphQLError } from 'graphql';
import type { History } from 'history';

export interface HandleRequestTimeoutArgs {
  error: GraphQLError;
  history: History;
}

export const REQUEST_TIMEOUT_MESSAGE = 'Request Timed Out';
export const COOLDOWN_PERIOD_IN_MINUTES = 1;

/* 
  Util to determine if a graphql error is determined to be a request timeout, we will apply a
  cooldown period cookie with the cooldown duration determined by split config. The fallback
  default cooldown period is 1 minute.
*/
export function handleRequestTimeout({
  error,
  history,
}: HandleRequestTimeoutArgs) {
  const errorMessage = error?.message;
  if (errorMessage === REQUEST_TIMEOUT_MESSAGE) {
    cookie.setSignupShutoffCooldownCookie(COOLDOWN_PERIOD_IN_MINUTES);
    history.replace(paths.PATH_SIGNUP_SITE_UNAVAILABLE);
  }
}
