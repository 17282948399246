import get from 'lodash/get';

import type { Path, PathValue } from 'convict';
import type { convictConfig } from './convictConfig';
import type { SetOptional } from 'type-fest';

type ConvictConfig = typeof convictConfig;
type ConfigProperties = ReturnType<ConvictConfig['getProperties']>;

export type ConfigPath = Path<ConfigProperties>;
export type ConfigValue<K extends ConfigPath> = PathValue<ConfigProperties, K>;

declare global {
  interface Window {
    __APP_CONFIG__: ConfigProperties;
  }
}

/**
 * Get a configuration by key.
 * Server-side, use the config found in config/config.js
 * Client-side, use the config in the global window variable.
 */
export default function config(): ConfigProperties;
export default function config<K extends ConfigPath>(key: K): ConfigValue<K>;
export default function config<K extends ConfigPath>(
  key?: K
): ConfigValue<K> | ConfigProperties {
  if (!process.env.BROWSER) {
    const loadedConfig =
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-require-imports
      require('./convictConfig').convictConfig as ConvictConfig;

    return key
      ? (loadedConfig.get(key) as ConfigValue<K>)
      : loadedConfig.getProperties();
  }

  return key
    ? (get(window.__APP_CONFIG__, key) as ConfigValue<K>)
    : window.__APP_CONFIG__;
}

/**
 * Return the configuration for the client side. Print the content returned
 * by this function in the server-side rendered HTML document.
 */
export function getHtmlConfig() {
  if (process.env.BROWSER) {
    throw new Error('This function doesn’t work in this enviroment.');
  } else {
    const allConfig = config() as SetOptional<
      ConfigProperties,
      'basicAuth' | 'server'
    >;

    delete allConfig.basicAuth;
    delete allConfig.server;

    return `window.__APP_CONFIG__=${JSON.stringify(allConfig)}`;
  }
}
