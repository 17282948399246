import { useState, useCallback, useEffect, useMemo } from 'react';
import { Redirect, useHistory } from 'react-router';

import {
  PATH_SIGNUP,
  PATH_SIGNUP_CHECKOUT,
  PATH_SIGNUP_RECIPES,
  PATH_SIGNUP_TREATS,
} from '@farmersdog/constants/paths';
import { ContentReveal, useViewport } from '@farmersdog/corgi-x';

import {
  trackSignupFreeTreatAddTreatToCart,
  trackSignupFreeTreatsRemovedFromCart,
  trackSignupFreeTreatsSkipped,
  trackSignupFreeTreatsSuccess,
} from '../../analytics';
import { useFetchAvailableProducts, useGetLead } from '../../graphql/queries';
import {
  useLeadState,
  useTreatsInCheckoutUi,
  useThrowToErrorBoundary,
} from '../../hooks';
import TosaRootStyles from '../../TOSA.css';
import { TosaLayout } from '../layout';
import { DynamicPageTitle } from '../shared';
import { SignUpHeaderNonTosaPage } from '../shared/SignUpHeaderNonTosaPage';

import { Hero, Cta, HumanGradeTreats, FAQ } from './components';
import styles from './SignupTreatsPage.module.css';

import type { Treat, LeadSelectionInput } from '../../graphql/types';
import type { UseFeatureHook } from '../../types';

export interface SignupTreatsPageArgs {
  useFeature: UseFeatureHook;
}

export function SignupTreatsPage({ useFeature }: SignupTreatsPageArgs) {
  const viewport = useViewport();
  const history = useHistory();

  const { data: leadData } = useGetLead();

  const { writeLead } = useLeadState();

  const throwToErrorBoundary = useThrowToErrorBoundary();
  const { shouldRemoveTreatsPage } = useTreatsInCheckoutUi({ useFeature });
  const {
    data: availableProductsData,
    loading: isFetchAvailableProductsLoading,
  } = useFetchAvailableProducts();
  const availableTreats = useMemo(
    () => availableProductsData?.availableProducts?.treats ?? [],
    [availableProductsData?.availableProducts?.treats]
  );
  const [selectedTreat, setSelectedTreat] = useState<Treat | null>(null);
  const [currentlyLoadingButton, setCurrentlyLoadingButton] = useState<
    'primary' | 'secondary'
  >();

  // Initialize the treats a single time upon landing on the page;
  // if treats are initially selected we use a different UI structure for that visit to the treats page
  const initialAddedTreats = useMemo(
    () => leadData?.lead?.selection?.treats || [],
    [leadData?.lead?.selection?.treats]
  );

  useEffect(() => {
    if (!selectedTreat && availableTreats.length === 1) {
      setSelectedTreat(availableTreats[0]);
    }
  }, [availableTreats, selectedTreat]);

  const updateLeadSelection = useCallback(
    async (selection: LeadSelectionInput) => {
      try {
        await writeLead({
          lead: {
            blueprintVersion: '1.0.0',
            selection,
          },
        });
      } catch (error) {
        return throwToErrorBoundary(error);
      }
    },
    [writeLead, throwToErrorBoundary]
  );

  const handleAddTreat = useCallback(async () => {
    const currentSelection = selectedTreat || initialAddedTreats?.[0];
    if (isFetchAvailableProductsLoading || !currentSelection) {
      return;
    }

    setCurrentlyLoadingButton('primary');

    const selection: LeadSelectionInput = {
      treats: [
        {
          name: currentSelection.name,
          size: currentSelection.size,
          quantity: 1, // hardcoded to only allow one treat bag at checkout
        },
      ],
    };

    await updateLeadSelection(selection);

    trackSignupFreeTreatAddTreatToCart(currentSelection);
    trackSignupFreeTreatsSuccess();
    history.push(PATH_SIGNUP_CHECKOUT);
  }, [
    isFetchAvailableProductsLoading,
    selectedTreat,
    history,
    updateLeadSelection,
    initialAddedTreats,
  ]);

  const onSkip = useCallback(async () => {
    if (isFetchAvailableProductsLoading) {
      return;
    }

    setCurrentlyLoadingButton('secondary');
    const selection: LeadSelectionInput = {
      treats: [],
    };

    await updateLeadSelection(selection);

    const currentTreat = initialAddedTreats?.[0];
    if (currentTreat) {
      trackSignupFreeTreatsRemovedFromCart(currentTreat);
    } else {
      trackSignupFreeTreatsSkipped();
    }

    trackSignupFreeTreatsSuccess();
    history.push(PATH_SIGNUP_CHECKOUT);
  }, [
    isFetchAvailableProductsLoading,
    initialAddedTreats,
    history,
    updateLeadSelection,
  ]);

  const ctaElement = useMemo(
    () => (
      <Cta
        areAnyAddedTreats={!!initialAddedTreats?.length}
        onAddToPlan={handleAddTreat}
        onSkip={onSkip}
        currentlyLoadingButton={currentlyLoadingButton}
      />
    ),
    [currentlyLoadingButton, handleAddTreat, onSkip, initialAddedTreats]
  );

  // prevent flash of content while lead is loading
  if (!leadData) {
    return null;
  }

  const { lead } = leadData;

  const shouldRedirectToSignup = !lead;

  if (shouldRedirectToSignup) {
    return <Redirect to={PATH_SIGNUP} />;
  }

  const allPetsHaveRecipeSelections =
    lead.pets.length > 0 &&
    lead.pets.every(
      pet => (pet.selection?.fresh?.options.recipes || []).length > 0
    );

  // TODO: Break this logic for whether or not to be here/where to go out into our new navigation when we build our routing module
  const shouldRedirectToRecipes = !allPetsHaveRecipeSelections;

  if (shouldRedirectToRecipes) {
    return <Redirect to={PATH_SIGNUP_RECIPES} />;
  }

  if (shouldRemoveTreatsPage) {
    return <Redirect to={PATH_SIGNUP_CHECKOUT} />;
  }

  return (
    <ContentReveal inAnimation="fade" in>
      <div className={TosaRootStyles.root}>
        <DynamicPageTitle />
        <SignUpHeaderNonTosaPage currentPath={PATH_SIGNUP_TREATS} />

        <TosaLayout>
          <div className={styles.container}>
            <Hero
              availableTreats={availableTreats}
              selectedTreat={selectedTreat}
              onSelectTreat={setSelectedTreat}
              ctaElement={ctaElement}
              alreadyAddedTreats={initialAddedTreats ?? []}
              petNames={lead.pets.map(pet => pet.name)}
            />
            <HumanGradeTreats />
            <FAQ />
            {!viewport.md && ctaElement}
          </div>
        </TosaLayout>
      </div>
    </ContentReveal>
  );
}
