import { Button, Text } from '@farmersdog/corgi-x';

import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';

import styles from './CallToAction.module.scss';
import type { ReactNode } from 'react';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';
import { CtaTracker, TrackingEvent } from '@farmersdog/utils';
import { trackGoToSignup } from 'src/analytics';

interface Props {
  children: ReactNode;
  location: string;
  ctaCopy: string;
}

function CallToAction({ children, location, ctaCopy }: Props) {
  const { isAccessibilityThemeOn, accessibilityThemeStyles } =
    useAccessibilityThemeContext();

  return (
    <div
      className={
        isAccessibilityThemeOn
          ? styles.accessibilityThemeContainer
          : styles.container
      }
    >
      <CtaTracker
        trackingFunction={trackGoToSignup}
        event={TrackingEvent.goToSignup}
        trackingData={{
          page: 'Reviews',
          location,
          ctaCopy,
        }}
      >
        <Button
          type="link"
          to={PATH_SIGNUP_ME}
          className={accessibilityThemeStyles.responsiveCta}
        >
          <Text bold variant="heading-16">
            {children}
          </Text>
        </Button>
      </CtaTracker>
    </div>
  );
}

export default CallToAction;
