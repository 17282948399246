import type {
  HomepageHeroImageTreatments,
  ToggleTreatment,
} from 'src/abTesting';
import type {
  ACQ_HOMEPAGE_PRESS_BAR_LOWER,
  ACQ_HOMEPAGE_REDESIGN,
  CVR_HOMEPAGE_HERO_IMAGE,
} from 'src/abTesting/features';

export enum HomepageVetOptimizationTreatments {
  Control = 'control',
  Off = 'off',
  PillAndCard = 'pill_and_card',
  PillOnly = 'pill_only',
}

export const HEALTH_VET_OPTIMIZATION_HOMEPAGE =
  'HEALTH_VET_OPTIMIZATION_HOMEPAGE';

export interface AnonymousExperimentFlags {
  [CVR_HOMEPAGE_HERO_IMAGE]: {
    config: void;
    treatment: HomepageHeroImageTreatments;
  };
  [HEALTH_VET_OPTIMIZATION_HOMEPAGE]: {
    config: void;
    treatment: HomepageVetOptimizationTreatments;
  };
  [ACQ_HOMEPAGE_PRESS_BAR_LOWER]: {
    config: void;
    treatment: ToggleTreatment;
  };
  [ACQ_HOMEPAGE_REDESIGN]: {
    config: void;
    treatment: ToggleTreatment;
  };
}

export type AnonymousExperimentFlag = keyof AnonymousExperimentFlags;
