import { useMemo } from 'react';

import { cookie } from '@farmersdog/lead-browser-storage';

import { FeatureName } from '../../utils/features';
import { useGlobalDiscountOverride } from '../useGlobalDiscountOverride';
import { useTosaDiscountBannerCopy } from '../useTosaDiscountBannerCopy';

import type { Experiments, UseFeatureHook } from '../../types';

export function useAnonymousExperiments(
  useFeature: UseFeatureHook
): Experiments {
  const isDefaultDiscountEnabled = useFeature(FeatureName.CVR_DEFAULT_DISCOUNT);

  const { globalDiscountOverride } = useGlobalDiscountOverride(useFeature);

  const utmSource = cookie.getUtmSource();
  const skipGetStarted = useFeature(FeatureName.CVR_SKIP_GET_STARTED, {
    attributes: { utmSource },
  });
  const isNonBlueprintRecipesPage = useFeature(
    FeatureName.CVR_RECIPES_PAGE_NON_BLUEPRINT
  );

  const tosaDiscountBannerCopy = useTosaDiscountBannerCopy(useFeature);

  const experiments = useMemo(
    () => ({
      [FeatureName.CVR_DEFAULT_DISCOUNT]: isDefaultDiscountEnabled,
      [FeatureName.CVR_GLOBAL_DISCOUNT_OVERRIDE]: globalDiscountOverride,
      [FeatureName.CVR_SKIP_GET_STARTED]: skipGetStarted,
      [FeatureName.CVR_RECIPES_PAGE_NON_BLUEPRINT]: isNonBlueprintRecipesPage,
      [FeatureName.CVR_TOSA_DISCOUNT_BANNER_COPY]: tosaDiscountBannerCopy,
    }),
    [
      isDefaultDiscountEnabled,
      globalDiscountOverride,
      skipGetStarted,
      isNonBlueprintRecipesPage,
      tosaDiscountBannerCopy,
    ]
  );

  return experiments;
}
