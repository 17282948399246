import { katz, KatzEventName, PAGE_VIEW_GID, PIXEL_ID } from './katz';

/**
 * Track an Katz page view event
 */
export function trackKatzPageView(): void {
  katz.track({
    ppt: PIXEL_ID,
    g: KatzEventName.PAGE_VIEW,
    gid: PAGE_VIEW_GID,
  });
}
