import { MetaHeroGrid } from '../MetaHeroGrid';
import { FullScreenImageBackground } from '../FullScreenImageBackground';
import {
  LovedByHumansConfig,
  CtaOnly50OffConfig,
  CtaOnlySeePlansAndPricingConfig,
  Q5DogNatureHeroOnlyConfig,
  Q5DogWalkingHeroConfig,
} from './HeroContent.config';
import styles from './HeroContent.module.css';
import { HomepageHeroImageTreatments } from 'src/abTesting';
import { Q5Hero } from '../Q5Hero';

interface HeroContentProps {
  variant: HomepageHeroImageTreatments;
  isHeadlineLink: boolean;
  linkToFreshSignup: string;
  isAccessibilityThemeOn: boolean;
}

const DEFAULT_CONFIG = LovedByHumansConfig;

const HERO_CONFIG = {
  [HomepageHeroImageTreatments.Control]: DEFAULT_CONFIG,
  [HomepageHeroImageTreatments.Off]: DEFAULT_CONFIG,
  [HomepageHeroImageTreatments.LovedByHumans]: LovedByHumansConfig,
  [HomepageHeroImageTreatments.CtaOnly50Off]: CtaOnly50OffConfig,
  [HomepageHeroImageTreatments.CtaOnlySeePlansAndPricing]:
    CtaOnlySeePlansAndPricingConfig,
  [HomepageHeroImageTreatments.Q5DogNatureHeroOnly]: Q5DogNatureHeroOnlyConfig,
  [HomepageHeroImageTreatments.Q5DogWalkingHeroOnly]: Q5DogWalkingHeroConfig,
};

export const HeroContent = ({
  isHeadlineLink,
  linkToFreshSignup,
  isAccessibilityThemeOn,
  variant,
}: HeroContentProps) => {
  const heroConfig = HERO_CONFIG[variant];

  const isQ5Variant =
    variant === HomepageHeroImageTreatments.Q5DogNatureHeroOnly ||
    variant === HomepageHeroImageTreatments.Q5DogWalkingHeroOnly;

  if (isQ5Variant) {
    return (
      <Q5Hero
        variant={variant}
        cta={heroConfig.cta}
        title={heroConfig.title}
        subtitle={heroConfig.subtitle}
        linkToFreshSignup={linkToFreshSignup}
      >
        <FullScreenImageBackground
          isQ5Variant={isQ5Variant}
          moveBackgroundLower={heroConfig.lowerBackground}
          sources={heroConfig.assets}
          alt={heroConfig.alt}
        />
      </Q5Hero>
    );
  }

  return (
    <MetaHeroGrid
      isTextOnBottom
      withRightOverlay
      isHeadlineLink={isHeadlineLink}
      linkToFreshSignup={linkToFreshSignup}
      isAccessibilityThemeOn={isAccessibilityThemeOn}
      headlineText={heroConfig.title}
      subtitle={heroConfig.subtitle}
      ctaString={heroConfig.cta}
      disclaimer={heroConfig.disclaimer}
      desktopColumnWidth={8}
      gridItemClassname={styles.cta}
      titleClassname={styles.title}
      subtitleClassname={styles.subtitle}
      buttonClassname={styles.button}
      containerClassname={styles.container}
    >
      <FullScreenImageBackground
        moveBackgroundLower={heroConfig.lowerBackground}
        sources={heroConfig.assets}
        alt={heroConfig.alt}
      />
    </MetaHeroGrid>
  );
};
