import { formatCommaSeparatedList } from '../../../../utils/formatCommaSeparatedList';
import { pluralizeString } from '../../../../utils/pluralizeString';

const NON_BREAKING_SPACE = '\xa0';

export interface GetRecommendedRecipeCopyArgs {
  petName: string;
  lpfRecipeDisplayNames?: string[];
  isOnlyLpfRecipeRecommended: boolean;
}

export function getRecommendedRecipeCopy({
  petName,
  lpfRecipeDisplayNames = [],
  isOnlyLpfRecipeRecommended,
}: GetRecommendedRecipeCopyArgs): string {
  return isOnlyLpfRecipeRecommended
    ? `Based on your responses, we recommend our ${getRecipesString(lpfRecipeDisplayNames)}. Made with highly digestible human grade meat, grains, and fresh vegetables, this recipe is a great option for dogs like ${petName}.`
    : `Based on your responses, we recommend these fresh, balanced recipes for ${petName}.${NON_BREAKING_SPACE}They’re all made with highly digestible human grade meat and fresh vegetables and are ideal for ${petName}’s nutritional${NON_BREAKING_SPACE}needs.`;
}

function getRecipesString(recipes: string[]) {
  return `${formatCommaSeparatedList(
    recipes
  )} ${pluralizeString(recipes.length, 'recipe', 'recipes')}`;
}
