import { Grid, GridItem, Text, Nowrap } from '@farmersdog/corgi-x';
import {
  CheckmarkIconSvg,
  HeartIconSvg,
  StethoscopeIconSvg,
} from '../../../../assets';

import styles from './HeroIconItems.module.css';

const heroIconItems = [
  {
    id: 'veterinarian-approved',
    icon: StethoscopeIconSvg,
    text: 'Veterinarian approved',
  },
  {
    id: 'human-grade-safety',
    icon: CheckmarkIconSvg,
    text: (
      <>
        <Nowrap>Human-grade</Nowrap> safety
      </>
    ),
  },
  {
    id: 'loved-by-millions',
    icon: HeartIconSvg,
    text: (
      <>
        <Nowrap>Loved by</Nowrap> millions
      </>
    ),
  },
];

export const HeroIconItems = () => (
  <Grid
    flexDirection="row"
    justifyContent="space-between"
    className={styles.iconItemsContainer}
  >
    {heroIconItems.map(item => (
      <GridItem
        key={item.id}
        xs={3}
        flexDirection="row"
        alignItems="center"
        className={styles.iconItem}
      >
        <item.icon className={styles.icon} />
        <Text variant="heading-12" className={styles.iconText}>
          {item.text}
        </Text>
      </GridItem>
    ))}
  </Grid>
);
