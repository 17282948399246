import classNames from 'classnames';

import { RecipePack } from '../RecipePack';

import styles from './RecipesStack.css';
import { sortRecipes } from './utils';

import type { RecommendedRecipe } from '../types';

export interface RecipesStackProps {
  petName: string;
  foodPlanRecipes: RecommendedRecipe[];
  className?: string;
}

export function RecipesStack({
  petName,
  foodPlanRecipes,
  className,
}: RecipesStackProps) {
  const packs =
    foodPlanRecipes.length === 0
      ? [
          <span key={0}>
            <RecipePack petName={petName} displayName={''} />
          </span>,
        ]
      : createPacks({
          foodPlanRecipes: sortRecipes({
            recipes: foodPlanRecipes,
          }),
          petName,
        });

  return (
    <div
      className={classNames([styles.RecipesStack, className])}
      data-packs={packs.length}
    >
      {packs}
    </div>
  );
}

function createPacks({
  petName,
  foodPlanRecipes,
  className,
}: Omit<RecipesStackProps, 'useFeature'>) {
  while (foodPlanRecipes.length < 3) {
    // Packs rendering position: first (left), second (right), third (middle)
    // We make sure the first two (left & right) packs are always the same recipes
    foodPlanRecipes.unshift(foodPlanRecipes[0]);
  }

  return foodPlanRecipes.map((recipe, i) => {
    return (
      <span key={`${recipe.name}-${i}`}>
        <RecipePack
          recipeName={recipe.name}
          displayName={recipe.displayName}
          petName={petName}
          className={className}
        />
      </span>
    );
  });
}
