import track from '../track';
import events from '../eventNames';
import type { RecommendedTreatsPackage as LegacyRecommendedTreatsPackage } from 'src/pages/SnapSticksDetails/hooks';
import type { RecommendedTreatsPackage } from '@/account/treats/TreatsDetails/hooks';

export function trackTreatsRecommendation(
  recommendedTreatsPackages: LegacyRecommendedTreatsPackage[]
): void {
  track(events.treats_recommendation, {
    recommendedTreatsPackages,
  });
}

export function trackTreatsProductRecommendation(
  recommendedTreatsPackages: RecommendedTreatsPackage[]
): void {
  track(events.treats_recommendation, {
    recommendedTreatsPackages: recommendedTreatsPackages.map(treatPackage => ({
      packageSize: treatPackage.packageSize,
      quantity: treatPackage.quantity,
      recipeName: treatPackage.productName,
      approximateDays: treatPackage.approximateDays,
    })),
  });
}
