import { Logger } from '@farmersdog/logger';

import { getTokenFromCookie } from '@/account/utils/auth/getTokenFromCookie';
import { getUserIdFromToken } from '@/account/utils/auth/getUserIdFromToken';

import type { LogCustomerFacingInfoDataType } from './logCustomerFacingInfoDataType';

type LogCustomerFacingInfoInput = {
  namespace: string;
  eventName: string;
  userId?: number | null;
  data: LogCustomerFacingInfoDataType;
};

const FRONTEND_LOGGING_NAMESPACE_PREFIX = 'web:frontend:';

export function logCustomerFacingInfo({
  namespace,
  eventName,
  userId,
  data,
}: LogCustomerFacingInfoInput): void {
  let userIdFromToken: number | undefined;
  try {
    userIdFromToken = getUserIdFromToken(getTokenFromCookie());
  } catch {
    // Fail silently because we don't want to crash on logs
  }
  const logger = new Logger(
    `${FRONTEND_LOGGING_NAMESPACE_PREFIX}${namespace}`,
    {
      userId: userId ?? userIdFromToken,
      eventName,
    }
  );

  logger.info(`Customer facing info for: ${eventName}`, data);
}
