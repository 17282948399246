import { useCallback, useRef } from 'react';

import { useViewport } from '@farmersdog/corgi-x';

import { getCompletedData, scrollOncePerSession } from '../../../utils';
import { BranchNode } from '../../schema/BranchNode';
import { SubmitButton } from '../../shared';

import type { BranchNode as BranchNodeType } from '../../../blueprint/types';
import type { TOSAComponentInput } from '../../../types';

export function PetCurrentFoodForm(props: TOSAComponentInput<BranchNodeType>) {
  const viewport = useViewport();

  const formRef = useRef<HTMLFormElement | null>(null);

  const { formMethods, formSubmitRequest, progress, node } = props;

  const handleChange = useCallback(() => {
    if (!viewport.lg) {
      scrollOncePerSession({ ref: formRef, id: node.name });
    }
  }, [viewport.lg, node.name]);

  const onSubmit = formMethods.handleSubmit(async (formData, e) => {
    const data = getCompletedData({
      data: formData,
      completedLeafNodes: progress.getCompletedLeafNodes(),
    });

    if (props.onSubmit) {
      await props.onSubmit(data, e);
    }
  });

  return (
    <>
      <form id={props.node.name} onSubmit={onSubmit} ref={formRef}>
        <BranchNode {...props} onChange={handleChange} />
        <SubmitButton
          smallWhiteSpace={!viewport.lg}
          loading={formSubmitRequest.loading}
          {...props}
        />
      </form>
    </>
  );
}
