import type { PetInputForQuote } from '../../../graphql/types';
import type { PetNamesAndRecommendedCalories } from '../hooks/usePetNamesAndRecommendedCalories';

export const addRequestedCaloriesToPets = (
  pets: Omit<PetInputForQuote, 'requestedCalories'>[],
  petNamesAndRecommendedCalories: PetNamesAndRecommendedCalories[]
): PetInputForQuote[] => {
  return pets.map(pet => {
    const { name } = pet;
    const requestedCaloriesObject = petNamesAndRecommendedCalories.find(
      ({ petName }) => petName === name
    );

    if (!requestedCaloriesObject) {
      throw new Error(`No requestedCalories found for pet ${name}`);
    }

    return {
      ...pet,
      requestedCalories: requestedCaloriesObject.requestedCalories,
    };
  });
};
