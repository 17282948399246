import { PATH_HOME, PATH_SIGNUP_RECIPES } from '@farmersdog/constants/paths';
import { Link } from '@farmersdog/corgi';
import { Progress, Logo } from '@farmersdog/corgi-x';

import { PreviousLinkNavigationLabel } from '../../../../hooks/usePreviousLinkNavigation';
import { BackButton } from '../../../shared/SignupHeader/BackButton';
import { useStaticStepData } from '../hooks/useStaticStepData';

import styles from './SmallNavigation.module.css';
import { Steps } from './Steps';

interface SmallNavigationArgs {
  handleNavigatePrevious: () => void;
}

export function SmallNavigation({
  handleNavigatePrevious,
}: SmallNavigationArgs) {
  const stepData = useStaticStepData();

  return (
    <nav className={styles.container} data-testid="header-nav-mobile">
      <div className={styles.navigation}>
        <BackButton
          previousSignupRoute={PATH_SIGNUP_RECIPES}
          handleNavigatePrevious={handleNavigatePrevious}
          navigatePreviousLinkLabel={
            PreviousLinkNavigationLabel.previousSignupPage
          }
        />
        <Link
          to={PATH_HOME}
          className={styles.homeLink}
          aria-label={PreviousLinkNavigationLabel.homePage}
          data-testid="header-logo-mobile"
        >
          <Logo variant="product" size={50} />
        </Link>
        <Steps numSteps={stepData.length} />
      </div>
      <Progress
        role="status"
        className={styles.progress}
        title="Sign up progress"
        aria-label={`Step 3 of ${stepData.length}`}
        steps={stepData.length}
        activeStep={3}
        stepProgress={0}
      />
    </nav>
  );
}
