import classNames from 'classnames';

import { Link } from '@farmersdog/corgi';
import { Progress, Text } from '@farmersdog/corgi-x';

import { useStaticStepData } from '../hooks';

import styles from './StepsLarge.module.css';

export function StepsLarge() {
  const stepData = useStaticStepData();

  return (
    <div className={styles.container}>
      <Text variant="heading-22" className={styles.links} role="list">
        {stepData.map(({ active, completed, path, label, disabled }) => {
          return (
            <span role="listitem" key={path}>
              <Link
                aria-label={label}
                to={path}
                isActive={() => active}
                activeClassName={styles.active}
                disabled={disabled}
                tabIndex={disabled ? -1 : undefined}
                className={classNames({
                  [styles.disabled]: disabled,
                  [styles.completed]: completed,
                })}
              >
                {label}
              </Link>
            </span>
          );
        })}
      </Text>
      <div className={styles.progressAlignment}>
        <Progress
          role="status"
          className={styles.progress}
          title="Sign up progress"
          aria-label={`Step 3 of ${stepData.length}`}
          steps={stepData.length}
          activeStep={3}
          stepProgress={0}
        />
      </div>
    </div>
  );
}
