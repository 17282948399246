export { default as CnetLogoPng } from './cnet-2022.png';
export { default as CnetLogoWebp } from './cnet-2022.webp';
export { default as GoodHousekeepingLogoPng } from './good-housekeeping.png';
export { default as GoodHousekeepingLogoWebp } from './good-housekeeping.webp';
export { default as GoogleLogoPng } from './google.png';
export { default as GoogleLogoWebp } from './google.webp';
export { default as NewYorkMagazineLogoPng } from './new-york-magazine.png';
export { default as NewYorkMagazineLogoWebp } from './new-york-magazine.webp';
export { default as PopularScienceLogoPng } from './popular-science.png';
export { default as PopularScienceLogoWebp } from './popular-science.webp';
