import { Button, Grid, GridItem, Nowrap, PageWidth } from '@farmersdog/corgi-x';

import {
  events,
  track,
  trackHomepageSuccess,
  trackScrollPastBowlSection,
} from 'src/analytics';
import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';
import config from 'src/config';
import { Viewport } from 'src/screen';

import { HomepageTitle1 } from '../HomepageTitle';
import HomepageText from '../HomepageText';
import AutoNowrap from '../AutoNowrap';

import RevealingBowl from './RevealingBowl';
import IconHeadline from './IconHeadline';

import realFoodIcon from 'src/assets/images/real-food.png';
import madeFreshIcon from 'src/assets/images/made-fresh.png';
import usdaKitchensIcon from 'src/assets/images/usda-kitchens.png';
import vetApprovedIcon from 'src/assets/images/vet-approved.png';

import { getLinkToFreshSignup } from 'src/pages/Signup/DIY/utils/getLinkToSignupFlow';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';
import { useOnEnterView } from 'src/hooks/useOnEnterView';
import { useRef } from 'react';

import classNames from 'classnames';
import styles from './SectionBowl.module.scss';
import { CtaTracker, TrackingEvent } from '@farmersdog/utils';
import { useSelector } from 'react-redux';
import { selectRequestAttributes } from 'src/reducers/referrer';

const content = [
  <IconHeadline
    headline="Real Food"
    icon={<img src={realFoodIcon} width={44} alt="real food" />}
    key="icon-headline-bowl"
  >
    Human-grade meat and veggies in simple recipes, made{' '}
    <Nowrap>for dogs</Nowrap>
  </IconHeadline>,
  <IconHeadline
    headline={
      <>
        <Nowrap>Human Grade</Nowrap>
        <br className={styles.breakline} /> Safety
      </>
    }
    icon={<img src={usdaKitchensIcon} width={44} alt="usda kitchens" />}
    key="icon-headline-chef"
  >
    Safety and quality never before available <Nowrap>to pets</Nowrap>
  </IconHeadline>,
  <IconHeadline
    headline="Made Fresh"
    icon={<img src={madeFreshIcon} width={44} alt="steaming food plate" />}
    key="icon-headline-plate"
  >
    Maintain whole food and nutritional integrity
  </IconHeadline>,
  <IconHeadline
    headline="Vet Developed"
    icon={<img src={vetApprovedIcon} width={44} alt="vet developed" />}
    key="icon-headline-vet"
  >
    Nutrition that exceeds industry standards <Nowrap>(AAFCO)</Nowrap>
  </IconHeadline>,
];

function XSView() {
  const style = { width: '100%', maxWidth: 600 };

  return (
    <Grid alignItems="center" flexDirection="column">
      <GridItem xs={12} growContent style={{ ...style, padding: '1rem' }}>
        <RevealingBowl />
      </GridItem>
      <Grid flexDirection="column">
        <GridItem xs={6} flexDirection="row" style={style}>
          {content.slice(0, 2)}
        </GridItem>
        <GridItem xs={6} flexDirection="row" style={style}>
          {content.slice(2)}
        </GridItem>
      </Grid>
    </Grid>
  );
}

function LGView() {
  return (
    <Grid
      alignItems="center"
      justifyContent="center"
      topSpacing="md"
      bottomSpacing="md"
    >
      <GridItem md={4} lg={3} flexDirection="column" alignItems="center">
        {content.slice(0, 2)}
      </GridItem>
      <GridItem md={4} lg={6} growContent style={{ maxWidth: 600 }}>
        <RevealingBowl />
      </GridItem>
      <GridItem md={4} lg={3} flexDirection="column" alignItems="center">
        {content.slice(2)}
      </GridItem>
    </Grid>
  );
}

const DEFAULT_HEADER_TEXT = 'You shouldn’t be the only one eating healthy.';
const DEFAULT_BODY_TEXT = (
  <span>
    Like you, we love our pets and care about their health. That’s why we
    created The Farmer’s Dog — a service that delivers balanced, freshly made
    pet food with simple recipes, guided by science, and{' '}
    <Nowrap>driven by love.</Nowrap>
  </span>
);
const LONG_LIVE_DOGS_HEADER_TEXT = 'Dog Food Should Be Real Food';
const LONG_LIVE_DOGS_BODY_TEXT = `
There’s a big lie on every bag of kibble or can of… whatever’s in those.
That they call it “food”. Legally, it’s not even close. When our founders
learned this truth, they knew it was time to make a truly healthy, fresh
diet with real benefits for dogs, and their humans.
`;

const CTA_COPY = 'Build Personalized Plan';

function SectionBowl() {
  const requestAttributes = useSelector(selectRequestAttributes);
  const shouldUseLongLiveDogs = Boolean(config('features.longLiveDogs'));
  const headerText = shouldUseLongLiveDogs
    ? LONG_LIVE_DOGS_HEADER_TEXT
    : DEFAULT_HEADER_TEXT;
  const bodyText = shouldUseLongLiveDogs
    ? LONG_LIVE_DOGS_BODY_TEXT
    : DEFAULT_BODY_TEXT;

  const handleClickBowlCta = () => {
    track(events.homepage_click_bowl_cta);
  };

  const { isAccessibilityThemeOn, accessibilityThemeStyles } =
    useAccessibilityThemeContext();

  const bowlSectionBottomTrackerRef = useRef(null);
  useOnEnterView({
    onEnter: trackScrollPastBowlSection,
    ref: bowlSectionBottomTrackerRef,
  });

  return (
    <section
      style={{ marginTop: '4rem', marginBottom: '2rem' }}
      data-name="section-bowl"
    >
      <PageWidth>
        <Grid flexDirection="column" style={{ margin: '0 auto' }}>
          <HomepageTitle1 center style={{ marginBottom: 0 }}>
            <AutoNowrap>{headerText}</AutoNowrap>
          </HomepageTitle1>
          <HomepageText
            center
            style={{
              width: '100%',
              maxWidth: 600,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            {bodyText}
          </HomepageText>
        </Grid>

        <Viewport lg={<LGView />} xs={<XSView />} />
        <div ref={bowlSectionBottomTrackerRef} />

        <Grid justifyContent="center">
          <CtaTracker
            trackingFunction={trackHomepageSuccess}
            event={TrackingEvent.homepageSuccess}
            requestAttributes={requestAttributes}
            trackingData={{ location: 'Bowl', ctaCopy: CTA_COPY }}
          >
            <Button
              type="link"
              onClick={handleClickBowlCta}
              to={getLinkToFreshSignup(PATH_SIGNUP_ME)}
              className={classNames(accessibilityThemeStyles.responsiveCta, {
                [styles.accessibilityThemeCta]: isAccessibilityThemeOn,
              })}
            >
              {CTA_COPY}
            </Button>
          </CtaTracker>
        </Grid>
      </PageWidth>
    </section>
  );
}

export default SectionBowl;
