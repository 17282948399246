import { Navigation } from 'src/components/Navigation';
import {
  Page,
  CreditCardInput,
  StripeProvider,
  StripeElementsProvider,
  Form,
  Button,
  Input,
  StatusMessage,
} from '@farmersdog/corgi-x';
import config from 'src/config';

import AddressAutocomplete from 'src/components/AddressAutocomplete';
import StatesSelect from 'src/components/StatesSelect';
import { InputGrid } from '../Profile/components/InputGrid';
import styles from './UpdatePaymentMethod.module.css';
import { useUpdatePaymentMethod } from './useUpdatePaymentMethod';

export function UpdatePaymentMethod() {
  const stripeKey = config('stripe.publicKey');

  return (
    <StripeProvider publishableKey={stripeKey}>
      <StripeElementsProvider>
        <Content />
      </StripeElementsProvider>
    </StripeProvider>
  );
}

function Content() {
  const {
    formValues,
    updateFormValue,
    canSubmit,
    submit,
    isLoading,
    success,
    error,
  } = useUpdatePaymentMethod();

  return (
    <>
      <Navigation />

      {success && (
        <Page
          layout="base"
          heading="Success!"
          subHeading="You have successfully updated your payment method. You may now close this page."
        />
      )}

      {!success && (
        <Page layout="base" heading="Update Payment Method">
          <Form onSubmit={submit} className={styles.form}>
            <InputGrid>
              <Input
                label="Name on card"
                name="cardHolder"
                value={formValues.cardHolder}
                onChange={e => updateFormValue('cardHolder', e.target.value)}
              />

              <CreditCardInput
                type="cardNumber"
                label="Credit card number"
                onChange={e =>
                  updateFormValue('cardNumberComplete', e.complete)
                }
              />

              <CreditCardInput
                type="cardExpiry"
                label="Expiration date"
                onChange={e => updateFormValue('expiryComplete', e.complete)}
              />

              <CreditCardInput
                type="cardCvc"
                label="CVC"
                onChange={e => updateFormValue('cvcComplete', e.complete)}
              />
            </InputGrid>

            <InputGrid>
              <AddressAutocomplete
                label="Billing Address"
                name="addressLine1"
                autoComplete="billing address-line1"
                value={formValues.addressLine1}
                onChange={e => updateFormValue('addressLine1', e.target.value)}
                onSuggestionSelect={suggestion => {
                  if (suggestion.addressLine1) {
                    updateFormValue('addressLine1', suggestion.addressLine1);
                  }
                  if (suggestion.city) {
                    updateFormValue('city', suggestion.city);
                  }
                  if (suggestion.state) {
                    updateFormValue('state', suggestion.state);
                  }
                  if (suggestion.zip) {
                    updateFormValue('zip', suggestion.zip);
                  }
                }}
              />

              <Input
                label="Apartment Number"
                name="addressLine2"
                autoComplete="billing address-line2"
                value={formValues.addressLine2}
                onChange={e => updateFormValue('addressLine2', e.target.value)}
              />

              <Input
                label="City"
                name="city"
                autoComplete="billing city"
                value={formValues.city}
                onChange={e => updateFormValue('city', e.target.value)}
              />

              <InputGrid>
                <StatesSelect
                  label="State"
                  name="state"
                  autoComplete="billing state"
                  value={formValues.state}
                  onChange={e => {
                    if (e) {
                      updateFormValue('state', e);
                    }
                  }}
                />

                <Input
                  label="Zip"
                  name="zip"
                  autoComplete="billing postal-code"
                  value={formValues.zip}
                  onChange={e => updateFormValue('zip', e.target.value)}
                />
              </InputGrid>
            </InputGrid>

            <Button
              type="submit"
              aria-disabled={!canSubmit(formValues)}
              loading={isLoading}
              className={styles.button}
            >
              Submit
            </Button>

            <StatusMessage
              invalid={Boolean(error)}
              visuallyHidden={!error}
              politeness="polite"
            >
              {error?.graphQLErrors.map(e => e.message)}
            </StatusMessage>
          </Form>
        </Page>
      )}
    </>
  );
}
