import { Grid, type MatchingViewports, useViewport } from '@farmersdog/corgi-x';
import { useFeature } from 'src/abTesting';

import { CondensedMobilePressBar } from './CondensedMobilePressBar';

import styles from './SectionLogosExperiment.module.css';
import { PressBarItem, PressBarItems } from './PressBarItems';

const defaultPressBarItems = [
  PressBarItems.PopularScience,
  PressBarItems.Cnet,
  PressBarItems.Google,
  PressBarItems.GoodHousekeeping,
  PressBarItems.NewYorkMagazine,
];

const getItemsGridLayout = (viewport: MatchingViewports) => {
  if (viewport.xl) return [defaultPressBarItems];
  if (viewport.lg)
    return [
      [PressBarItems.PopularScience, PressBarItems.Google, PressBarItems.Cnet],
      [PressBarItems.GoodHousekeeping, PressBarItems.NewYorkMagazine],
    ];
  if (viewport.md)
    return [
      [PressBarItems.Google],
      [PressBarItems.PopularScience, PressBarItems.Cnet],
      [PressBarItems.GoodHousekeeping, PressBarItems.NewYorkMagazine],
    ];
  return null;
};

const PressBarItemsGrid = ({ layout }: { layout: PressBarItems[][] }) =>
  layout.map(row => (
    <Grid
      key={row.join('-')}
      justifyContent="center"
      alignItems="flex-start"
      gap="md"
      role="group"
    >
      {row.map(item => (
        <PressBarItem name={item} key={item} />
      ))}
    </Grid>
  ));

const MobilePressBar = () => {
  const isCondensedMobilePressBarEnabled =
    useFeature('cvr_condensed_press_bar').treatment === 'on';
  return isCondensedMobilePressBarEnabled ? (
    <CondensedMobilePressBar items={defaultPressBarItems} />
  ) : (
    <Grid
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="md"
      data-testid="default-logo-grid"
    >
      {defaultPressBarItems.map(item => (
        <PressBarItem name={item} key={item} />
      ))}
    </Grid>
  );
};

export const SectionLogosExperiment = () => {
  const viewport = useViewport();
  const layout = getItemsGridLayout(viewport);

  return (
    <section className={styles.sectionLogos}>
      {layout ? <PressBarItemsGrid layout={layout} /> : <MobilePressBar />}
    </section>
  );
};
