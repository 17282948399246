import { useEffect, useState } from 'react';

import { useLazyQuote } from '../../../graphql/queries/useLazyQuote';
import { getDiscountInput } from '../../../hooks/useLeadState/utils/convertFormStateToLeadInput/getDiscountInput';
import { useThrowToErrorBoundary } from '../../../hooks/useThrowToErrorBoundary';
import { getQuoteDiscountType } from '../../../utils/getQuoteDiscountType/getQuoteDiscountType';
import { addRequestedCaloriesToPets } from '../utils/addRequestedCaloriesToPets';
import {
  FRESH_PRODUCT_LINES,
  overwriteWithCheapestSelection,
} from '../utils/overwriteWithCheapestSelection';

import { usePetNamesAndRecommendedCalories } from './usePetNamesAndRecommendedCalories';

import type {
  FetchQuoteQuery,
  PetInputForQuote,
  QuoteResponse,
} from '../../../graphql/types';
import type { PetRecipes } from '../../../types';

export interface UseCheapestQuotesProps {
  petRecipes: PetRecipes;
  currentPetName: string;
  petsInput: Omit<PetInputForQuote, 'requestedCalories'>[] | null;
}

export interface QuoteResponseWithProductLine extends QuoteResponse {
  productLine: string;
}

export const useCheapestQuotes = ({
  petRecipes,
  currentPetName,
  petsInput,
}: UseCheapestQuotesProps) => {
  const discount = getDiscountInput();
  const { petNamesAndRecommendedCalories } =
    usePetNamesAndRecommendedCalories();

  const petsInputWithPersistedCheapestSelection =
    overwriteWithCheapestSelection({
      petRecipes,
      petsInput,
      currentPetName,
    });

  const [cheapestCoreQuote, setCheapestCoreQuote] = useState<
    FetchQuoteQuery['fetchQuote'] | null
  >(null);

  const [cheapestLpfQuote, setCheapestLpfQuote] = useState<
    FetchQuoteQuery['fetchQuote'] | null
  >(null);

  const [quoteQuery, quoteQueryState] = useLazyQuote({
    fetchPolicy: 'network-only', // Ensure new quotes are always fetched
  });

  const throwToErrorBoundary = useThrowToErrorBoundary();

  useEffect(() => {
    const fetchCheapestRecipes = () => {
      if (
        petsInputWithPersistedCheapestSelection &&
        petNamesAndRecommendedCalories
      ) {
        petsInputWithPersistedCheapestSelection.forEach(
          ({ pets, productLine }) => {
            if (!pets || quoteQueryState.called) return;

            const petsWithRecommendedCalories = addRequestedCaloriesToPets(
              pets,
              petNamesAndRecommendedCalories
            );
            const discountType =
              (discount?.type && getQuoteDiscountType(discount.type)) || null;

            // TODO: See if we can use useFetchQuote here instead of quoteQuery
            quoteQuery({
              variables: {
                pets: petsWithRecommendedCalories,
                shippingZip: null,
                discountCode: discount?.code || null,
                discountType,
              },
            })
              .then(response => {
                if (response.error) return throwToErrorBoundary(response.error);

                const data = response.data?.fetchQuote;
                if (data) {
                  switch (productLine) {
                    case FRESH_PRODUCT_LINES.core:
                      setCheapestCoreQuote(data);
                      break;
                    case FRESH_PRODUCT_LINES.lpf:
                      setCheapestLpfQuote(data);
                      break;
                  }
                }
              })
              .catch(error => {
                return throwToErrorBoundary(error);
              });
          }
        );
      }
    };

    void fetchCheapestRecipes();
  }, [
    discount?.code,
    discount?.type,
    petsInputWithPersistedCheapestSelection,
    quoteQuery,
    quoteQueryState.called,
    throwToErrorBoundary,
    petNamesAndRecommendedCalories,
  ]);

  return { cheapestCoreQuote, cheapestLpfQuote };
};
