import { getDate, getMonth, isSameDay, parseISO } from 'date-fns';

import { formatDateToMonthAndDay } from './formatDateToMonthAndDay';

interface Options {
  /** default true, will truncate month to 3 letters. */
  abbreviatedMonth?: boolean;
}

/**
 *
 * @param startDateString - the start of the date range
 * @param endDateString - the end of the date range
 * @param options - optional arguments
 * @returns If start and end date are the same, start date formatted to
 * abbreviated month and day ie: Jan 1. If start and end date are in the same
 * month, a date range including month, ie: Jan 1—5. If start and end date are
 * in different months, a date range including both months, ie: Jan 1—Feb 5.
 * @throws if parsed start or end date strings produce an "Invalid Date"
 *
 * @example start and end dates are the same
 * ```ts
 * formatDateRange('2000-01-01', '2000-01-01') // Jan 1
 * ```
 *
 * @example start and end dates are in the same month
 * ```ts
 * formatDateRange('2000-01-01', '2000-01-05') // Jan 1—5
 * ```
 *
 * @example start and end dates are in different months
 * ```ts
 * formatDateRange('2000-01-01', '2000-02-05') // Jan 1—Feb 5
 * ```
 */
export function formatDateRange(
  startDateString: string,
  endDateString: string,
  options: Options = { abbreviatedMonth: true }
): string {
  const startDate = parseISO(startDateString);
  if (startDate.toString() === 'Invalid Date') {
    throw new Error('start date is invalid');
  }

  const endDate = parseISO(endDateString);
  if (endDate.toString() === 'Invalid Date') {
    throw new Error('end date is invalid');
  }

  const startMonthAndDay = formatDateToMonthAndDay(startDateString, {
    abbreviatedMonth: options.abbreviatedMonth,
  });
  const endMonthAndDay = formatDateToMonthAndDay(endDateString, {
    abbreviatedMonth: options.abbreviatedMonth,
  });

  if (isSameDay(startDate, endDate)) {
    return startMonthAndDay;
  }

  if (getMonth(startDate) === getMonth(endDate)) {
    return `${startMonthAndDay}—${getDate(endDate)}`;
  }

  return `${startMonthAndDay}—${endMonthAndDay}`;
}
