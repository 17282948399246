export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: string; output: string };
  DateTime: { input: string; output: string };
  Email: { input: any; output: any };
  JSON: { input: any; output: any };
  JSONObject: { input: any; output: any };
  Phone: { input: any; output: any };
  StripeSource: { input: any; output: any };
};

/** Used to describe the amount of activity for a pet. */
export type ActivityLevel = {
  __typename?: 'ActivityLevel';
  /** Further description of the activity level */
  description: Scalars['String']['output'];
  /** The activity level ID */
  id: Scalars['Int']['output'];
  /** The activity level name */
  name: Scalars['String']['output'];
};

/** A subscription add-on */
export type AddOn = {
  __typename?: 'AddOn';
  /** The add-on's ID */
  id: Scalars['Int']['output'];
  /** Is this item a one-off (AKA not recurring via the subscription)? */
  isOneOff: Scalars['Boolean']['output'];
  /** The product */
  product: Product;
  /** ID of the product */
  productId: Scalars['Int']['output'];
  /** The quantity of the product */
  quantity: Scalars['Int']['output'];
  /** ID of the subscription */
  subscriptionId: Scalars['Int']['output'];
  /** SKU of the product */
  variantSku: Scalars['String']['output'];
};

export type AddOnInput = {
  /** Is this a one-time purchase (as opposed to a recurring subscription)? */
  isOneOff?: InputMaybe<Scalars['Boolean']['input']>;
  /** Product ID */
  productId: Scalars['Int']['input'];
  /** Product quantity */
  quantity: Scalars['Int']['input'];
};

export type AddOptOutUserInput = {
  /** Segment ID of users opting out of tracking */
  anonymousId: Scalars['String']['input'];
  /** Email address of user opting out of tracking */
  email: Scalars['Email']['input'];
  /** Full name of user opting out of tracking */
  name: Scalars['String']['input'];
  /** Phone number of user opting out of tracking */
  phone: Scalars['Phone']['input'];
};

/** The result of adding a user to the opt out list */
export type AddOptOutUserResultType = {
  __typename?: 'AddOptOutUserResultType';
  /** Whether or not the request to add the user to the opt out list was successful */
  success: Scalars['Boolean']['output'];
};

export type AddressInput = {
  /** The street address */
  addressLine1: Scalars['String']['input'];
  /** Apartment number, unit number, etc of the address */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** The city of the address */
  city: Scalars['String']['input'];
  /** The ID of the country */
  countryId: Scalars['Int']['input'];
  /** Delivery instructions for the address (FedEx and LaserShip only) */
  deliveryInstructions?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the address */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the state */
  stateId: Scalars['Int']['input'];
  /** Is the address validated */
  validated?: InputMaybe<Scalars['Boolean']['input']>;
  /** The zipcode of the address */
  zip: Scalars['String']['input'];
};

export enum AddressType {
  /** Address is a billing address */
  Billing = 'billing',
  /** Address is a shipping address */
  Shipping = 'shipping',
}

/** A body condition for a pet. */
export type BodyCondition = {
  __typename?: 'BodyCondition';
  /** The body condition's description */
  description: Scalars['String']['output'];
  /** The body condition's ID */
  id: Scalars['Int']['output'];
  /** The body condition's name */
  name: Scalars['String']['output'];
};

/** A breed for a pet. */
export type Breed = {
  __typename?: 'Breed';
  /** The breed ID */
  id: Scalars['Int']['output'];
  /** The breed's name */
  name: Scalars['String']['output'];
  /** The size definition of a particular breed */
  size: BreedSize;
  /** A URL to information about this breed (often to a kennel club website) */
  url: Maybe<Scalars['String']['output']>;
  /** The maximum weight for this breed in the unit specified by 'weightUnit' */
  weightMax: Maybe<Scalars['Int']['output']>;
  /** The minimum weight for this breed in the unit specified by 'weightUnit' */
  weightMin: Maybe<Scalars['Int']['output']>;
  /** The unit for the breed's weight */
  weightUnit: Maybe<Scalars['String']['output']>;
};

export enum BreedSize {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
  XLarge = 'x_large',
  XSmall = 'x_small',
}

export type BufferOption = {
  __typename?: 'BufferOption';
  /** Number of days of food this buffer would provide */
  days: Scalars['Int']['output'];
  /** Next rescheduled order date if customer accepted this buffer */
  rescheduleDate: Scalars['Date']['output'];
  /** Whether this reschedule date will result in two billings in one week */
  willDoubleBill: Scalars['Boolean']['output'];
};

/** Range of buffer days for this subscription */
export type BufferRange = {
  __typename?: 'BufferRange';
  /** Default days of buffer for this subscription */
  defaultDays: Scalars['Int']['output'];
  /** Maximum days of buffer for this subscription */
  maxDays: Scalars['Int']['output'];
  /** Minimum days of buffer for this subscription */
  minDays: Scalars['Int']['output'];
  /** Recommended days of buffer for this subscription */
  recommendedDays: Scalars['Int']['output'];
};

/** The user's current and new passwords. */
export type ChangePasswordInput = {
  /** The user's current password */
  currentPassword: Scalars['String']['input'];
  /** The user's new password */
  newPassword: Scalars['String']['input'];
};

export type CheckoutInput = {
  /** Acquisition channel related metadata needed at checkout */
  attributionMetadata?: InputMaybe<Scalars['JSONObject']['input']>;
  /** The billing address of the User */
  billingAddress: AddressInput;
  /** The communication preferences of the User */
  communicationPreferences?: InputMaybe<CommunicationPreferencesInput>;
  /** The referrer in the coupon cookie at checkout */
  cookieReferrer?: InputMaybe<CookieReferrer>;
  /** The email address of the User */
  email: Scalars['Email']['input'];
  /** The first name of the User */
  firstName: Scalars['String']['input'];
  /** The full name of the User */
  fullName: Scalars['String']['input'];
  /** The last name of the User */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** The UTC time at which lead was created */
  leadCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The password of the User */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The phone number of the User */
  phone: Scalars['Phone']['input'];
  /** The quote the user viewed before checking out */
  proposedQuote?: InputMaybe<ProposedQuote>;
  /** The shipping address of the User */
  shippingAddress: AddressInput;
  /** User Stripe token or full payload */
  stripeToken: Scalars['StripeSource']['input'];
  /** Subscription details about the User */
  subscription: PendingSubscriptionInputType;
};

/** A customer's preferences about whether and how to receive communications from TFD (e.g. email or SMS) on different subjects (order reminders, order updates, etc.) */
export type CommunicationPreferenceType = {
  __typename?: 'CommunicationPreferenceType';
  /** Should customer receive marketing by SMS? */
  marketingSms: Scalars['Boolean']['output'];
  /** Should customer receive order reminders by email? */
  orderRemindersEmail: Scalars['Boolean']['output'];
  /** Should customer receive order reminders by SMS? */
  orderRemindersSms: Scalars['Boolean']['output'];
  /** Should customer receive order updates by email? */
  orderUpdatesEmail: Scalars['Boolean']['output'];
  /** Should customer receive order updates by SMS? */
  orderUpdatesSms: Scalars['Boolean']['output'];
};

export type CommunicationPreferencesInput = {
  /** Should customer receive marketing by SMS? */
  marketingSms?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should customer receive order reminders by SMS? */
  orderRemindersSms?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should customer receive order updates by SMS? */
  orderUpdatesSms?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Application configuration information for client use */
export type Configuration = {
  __typename?: 'Configuration';
  /** Maximum number of days the next order can be delayed */
  maxDelayDurationInDays: Scalars['Int']['output'];
};

export type CookieReferrer = {
  /** The time the referrer associated with the coupon cookie */
  referrerAssociatedAt: Scalars['String']['input'];
  /** The referrer code in the coupon cookie at checkout */
  referrerCode: Scalars['String']['input'];
  /** The referrer type in the coupon cookie at checkout */
  referrerType: ReferrerType;
};

/** A country in the world */
export type Country = {
  __typename?: 'Country';
  /** The country's The Farmer's Dog ID */
  id: Scalars['Int']['output'];
  /** The country's ISO-3166 alpha-2 code */
  iso: Maybe<Scalars['String']['output']>;
  /** The country's ISO-3166 alpha-3 code */
  iso3: Maybe<Scalars['String']['output']>;
  /** The country's ISO-3166 name */
  isoName: Maybe<Scalars['String']['output']>;
  /** The country's display name */
  name: Scalars['String']['output'];
  /** The country's ISO-3166 numeric code */
  numcode: Maybe<Scalars['Int']['output']>;
  /** The list of states within this country in alphabetical order */
  states: Maybe<Array<State>>;
  /** Does this country have states? */
  statesRequired: Maybe<Scalars['Boolean']['output']>;
};

export type CreateMyPetInput = {
  /** The activity level of the pet */
  activity?: InputMaybe<Scalars['Int']['input']>;
  /** The pet's birthday */
  birthday?: InputMaybe<Scalars['Date']['input']>;
  /**
   * Describes how accurate the birthday is down to
   *         years, months, weeks or if it's the actual date
   */
  birthdayAccuracy?: InputMaybe<PetBirthdayAccuracy>;
  /** A list of breed IDs to set on the pet */
  breedIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** The body condition of the pet */
  condition?: InputMaybe<Scalars['Int']['input']>;
  /** The pet's current food */
  currentFood?: InputMaybe<CurrentFoodInput>;
  /** The pet's eating style */
  eatingStyle?: InputMaybe<Scalars['Int']['input']>;
  /** The pet's gender */
  gender?: InputMaybe<PetGender>;
  /** Names of the issues to associate with the pet */
  issueNames?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The pet's name */
  name: Scalars['String']['input'];
  /** The nature of the pet */
  nature?: InputMaybe<Scalars['Int']['input']>;
  /** Is the pet neutered/spayed or not? */
  neutered?: InputMaybe<Scalars['Boolean']['input']>;
  /** Pet has a prescription diet that is different from what we provide */
  otherPrescriptionDiet?: InputMaybe<Scalars['String']['input']>;
  /** IDs of the prescription diets to set on the pet */
  prescriptionDietIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** The required amount of calories that the pet should be eating */
  requiredCalories?: InputMaybe<Scalars['Int']['input']>;
  /** A list of restrictions related to the pet */
  restrictionNames?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The pet's target weight if there is a target weight */
  targetWeight?: InputMaybe<Scalars['Int']['input']>;
  /** The quantity of treats/scraps the pet eats */
  treatsQuantity?: InputMaybe<Scalars['Int']['input']>;
  /** The pet's current weight */
  weight?: InputMaybe<Scalars['Int']['input']>;
};

export type CreatePetInput = {
  /** The activity level of the pet */
  activity?: InputMaybe<Scalars['Int']['input']>;
  /** The pet's birthday */
  birthday?: InputMaybe<Scalars['Date']['input']>;
  /**
   * Describes how accurate the birthday is down to
   *         years, months, weeks or if it's the actual date
   */
  birthdayAccuracy?: InputMaybe<PetBirthdayAccuracy>;
  /** A list of breed IDs to set on the pet */
  breedIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** The body condition of the pet */
  condition?: InputMaybe<Scalars['Int']['input']>;
  /** The pet's current food */
  currentFood?: InputMaybe<CurrentFoodInput>;
  /** The pet's eating style */
  eatingStyle?: InputMaybe<Scalars['Int']['input']>;
  /** The pet's gender */
  gender?: InputMaybe<PetGender>;
  /** Names of the issues to associate with the pet */
  issueNames?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The pet's name */
  name: Scalars['String']['input'];
  /** The nature of the pet */
  nature?: InputMaybe<Scalars['Int']['input']>;
  /** Is the pet neutered/spayed or not? */
  neutered?: InputMaybe<Scalars['Boolean']['input']>;
  /** When true remove pet from customer account and all communications.  Typically this is done when the pet has passed. */
  noContact?: InputMaybe<Scalars['Boolean']['input']>;
  /** Pet has a prescription diet that is different from what we provide */
  otherPrescriptionDiet?: InputMaybe<Scalars['String']['input']>;
  /** IDs of the prescription diets to set on the pet */
  prescriptionDietIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** The required amount of calories that the pet should be eating */
  requiredCalories?: InputMaybe<Scalars['Int']['input']>;
  /** A list of restrictions related to the pet */
  restrictionNames?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Does the pet require a special diet */
  specialDiet?: InputMaybe<Scalars['Boolean']['input']>;
  /** The pet's target weight if there is a target weight */
  targetWeight?: InputMaybe<Scalars['Int']['input']>;
  /** The quantity of treats/scraps the pet eats */
  treatsQuantity?: InputMaybe<Scalars['Int']['input']>;
  /** The pet's current weight */
  weight?: InputMaybe<Scalars['Int']['input']>;
};

export type CreditCard = {
  __typename?: 'CreditCard';
  /** The credit card's expiration month */
  expirationMonth: Scalars['Int']['output'];
  /** The credit card's expiration month */
  expirationYear: Scalars['Int']['output'];
  /** The ID of the credit card */
  id: Scalars['String']['output'];
  /** The last four digits of the credit card */
  last4Digits: Scalars['String']['output'];
  /** The name associated with the credit card */
  nameOnCard: Maybe<Scalars['String']['output']>;
};

/** A view of a customer's available credit. */
export type CreditView = {
  __typename?: 'CreditView';
  /** The amount of available credit in cents */
  amount: Scalars['Int']['output'];
  /** The expiration of the available credit */
  expirationDate: Maybe<Scalars['Date']['output']>;
  /** Whether the customer has ever had any credits */
  hasCreditHistory: Scalars['Boolean']['output'];
};

/** The pet's current food */
export type CurrentFood = {
  __typename?: 'CurrentFood';
  /** Amount of the current food being consumed on a daily basis */
  amount: Maybe<Scalars['String']['output']>;
  /** The brand of the current food */
  brand: Maybe<Scalars['String']['output']>;
  /** The id of the pet's current food */
  id: Scalars['Int']['output'];
  /** The specific product for the current food */
  product: Maybe<Scalars['String']['output']>;
  /** The type of current food */
  type: Maybe<CurrentFoodType>;
  /** The measurement units of the amount of current food */
  unit: Maybe<CurrentFoodUnit>;
};

/** The pet's current food */
export type CurrentFoodInput = {
  /** Amount of the current food being consumed on a daily basis */
  amount?: InputMaybe<Scalars['String']['input']>;
  /** The brand of the current food */
  brand?: InputMaybe<Scalars['String']['input']>;
  /** The id of the pet's current food */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** The specific product for the current food */
  product?: InputMaybe<Scalars['String']['input']>;
  /** The type of current food */
  type?: InputMaybe<CurrentFoodType>;
  /** The measurement units of the amount of current food */
  unit?: InputMaybe<CurrentFoodUnit>;
};

export enum CurrentFoodType {
  /** Dehydrated food */
  Dehydrated = 'dehydrated',
  /** Dry food */
  Dry = 'dry',
  /** Fresh food */
  Fresh = 'fresh',
  /** Homemade food */
  Homemade = 'homemade',
  /** Raw food */
  Raw = 'raw',
  /** Wet food */
  Wet = 'wet',
}

export enum CurrentFoodUnit {
  /** Food amount measurement in cups */
  Cup = 'cup',
  /** Food amount measurement in grams */
  Gram = 'gram',
}

export type DiyRecommendationInput = {
  _?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A discount amount with a unit */
export type DiscountAmountWithUnit = {
  __typename?: 'DiscountAmountWithUnit';
  /** The amount of the discount */
  amount: Scalars['Float']['output'];
  /** The unit of the discount */
  type: Scalars['String']['output'];
};

/** Address district */
export type District = {
  __typename?: 'District';
  /** City of the District */
  city: Scalars['String']['output'];
  /** Country of the District */
  country: Country;
  /** Id of the Country */
  countryId: Scalars['Int']['output'];
  /** Postal code of the District */
  postalCode: Scalars['String']['output'];
  /** State of the District */
  state: State;
  /** Id of the State */
  stateId: Scalars['Int']['output'];
};

export type DiyQuotePet = {
  __typename?: 'DiyQuotePet';
  id: Scalars['Int']['output'];
  suggestedCalories: Maybe<Scalars['Int']['output']>;
};

export type DiyQuotePrice = {
  __typename?: 'DiyQuotePrice';
  regular: DiyQuotePriceInfo;
  trial: Maybe<DiyQuotePriceInfo>;
};

export type DiyQuotePriceInfo = {
  __typename?: 'DiyQuotePriceInfo';
  baseAmount: Scalars['Int']['output'];
  discountAmount: Scalars['Int']['output'];
  itemsTotalAmount: Scalars['Int']['output'];
  netAmount: Scalars['Int']['output'];
  shippingAmount: Scalars['Int']['output'];
  taxAmount: Scalars['Int']['output'];
  totalAmount: Scalars['Int']['output'];
  weeklyAmount: Scalars['Int']['output'];
};

export type DiyQuoteProductQuantity = {
  __typename?: 'DiyQuoteProductQuantity';
  productId: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  /** Variant sku, this should replace Product id (ask PLX team) */
  variantSku: Scalars['String']['output'];
};

export type DiyQuoteRecommendation = {
  __typename?: 'DiyQuoteRecommendation';
  cadence: Scalars['Int']['output'];
  dailyCalories: Scalars['Int']['output'];
  products: Array<Maybe<DiyQuoteProductQuantity>>;
};

export type DiyQuoteShipment = {
  __typename?: 'DiyQuoteShipment';
  regular: DiyQuoteShipmentInfo;
  /** @deprecated This will always be `null` in practice, but we are keeping this as a queryable field for compatibility with fresh quotes. */
  trial: Maybe<DiyQuoteShipmentInfo>;
};

export type DiyQuoteShipmentInfo = {
  __typename?: 'DiyQuoteShipmentInfo';
  defaultFrequency: Scalars['Int']['output'];
  frequency: Scalars['Int']['output'];
  pricePerWeek: Scalars['Int']['output'];
  shipmentFrequencyInDays: Maybe<Array<Scalars['Int']['output']>>;
};

export type DiyQuoteSubscription = {
  __typename?: 'DiyQuoteSubscription';
  accepted: Maybe<DiyQuoteRecommendation>;
  recommended: Maybe<DiyQuoteRecommendation>;
};

/** DIY subscription quote data */
export type DiySubscriptionQuote = {
  __typename?: 'DiySubscriptionQuote';
  pets: Array<Maybe<DiyQuotePet>>;
  price: DiyQuotePrice;
  shipment: DiyQuoteShipment;
  subscription: DiyQuoteSubscription;
};

/** Describes a pet's eating style */
export type EatingStyle = {
  __typename?: 'EatingStyle';
  /** The eating style ID */
  id: Scalars['Int']['output'];
  /** The eating style name */
  name: Scalars['String']['output'];
};

/** A pet food allergy. */
export type FoodAllergy = {
  __typename?: 'FoodAllergy';
  /** The food allergy's ID */
  id: Scalars['Int']['output'];
  /** A food allergy's name */
  name: Scalars['String']['output'];
};

/** A pet food brand. */
export type FoodBrand = {
  __typename?: 'FoodBrand';
  /** The brand's ID */
  id: Scalars['Int']['output'];
  /** The brand's name */
  name: Scalars['String']['output'];
};

/** Describes a pet food type */
export type FoodType = {
  __typename?: 'FoodType';
  /** The food type ID */
  id: Scalars['String']['output'];
  /** The food type name */
  name: Scalars['String']['output'];
};

export enum FreshFoodConfidenceEnum {
  AlreadyBelieve = 'ALREADY_BELIEVE',
  AmNotSureYet = 'AM_NOT_SURE_YET',
  DontBelieve = 'DONT_BELIEVE',
}

export type FreshRecommendationInput = {
  _?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A pet health issue. */
export type HealthIssue = {
  __typename?: 'HealthIssue';
  /** Is the issue enabled? */
  enabled: Scalars['Boolean']['output'];
  /** The health issue's ID */
  id: Scalars['Int']['output'];
  /** A health issue's name */
  name: Scalars['String']['output'];
  /** The type of health issue */
  type: HealthIssueType;
};

/** The type of health issue */
export enum HealthIssueType {
  /** System default health issue */
  Default = 'default',
  /** User created health issue */
  User = 'user',
}

/** A line item on an order */
export type LineItem = {
  __typename?: 'LineItem';
  /** Metadata for this line item */
  metadata: LineItemMetadata;
  /** ID of the order this line item belongs to */
  orderId: Scalars['Int']['output'];
  /** ID of the order shipping box this line item is in */
  orderShippingBoxId: Scalars['Int']['output'];
  /** The product for this line item */
  product: Product;
  /** ID of the product */
  productId: Scalars['Int']['output'];
  /** Quantity of this product included in the order */
  quantity: Scalars['Int']['output'];
  /** ID of the subscription the order belongs to */
  subscriptionId: Scalars['Int']['output'];
  /** UUID for the line item */
  uuid: Scalars['String']['output'];
};

/** Pet breed line item metadata */
export type LineItemBreedMetadata = {
  __typename?: 'LineItemBreedMetadata';
  /** The breed ID */
  id: Scalars['Int']['output'];
  /** The breed's name */
  name: Scalars['String']['output'];
  /** A URL to information about this breed (often to a kennel club website) */
  url: Maybe<Scalars['String']['output']>;
  /** The maximum weight for this breed in the unit specified by 'weightUnit' */
  weightMax: Maybe<Scalars['Int']['output']>;
  /** The minimum weight for this breed in the unit specified by 'weightUnit' */
  weightMin: Maybe<Scalars['Int']['output']>;
  /** The unit for the breed's weight */
  weightUnit: Maybe<Scalars['String']['output']>;
};

/** Pet food allergy line item metadata */
export type LineItemFoodAllergyMetadata = {
  __typename?: 'LineItemFoodAllergyMetadata';
  /** The food allergy's ID */
  id: Scalars['Int']['output'];
  /** A food allergy's name */
  name: Scalars['String']['output'];
};

/** Food plan metadata for a line item */
export type LineItemFoodPlanMetadata = {
  __typename?: 'LineItemFoodPlanMetadata';
  /** Is this plan active? */
  active: Scalars['Boolean']['output'];
  /** The plan's calories */
  calories: Maybe<Scalars['Float']['output']>;
  /** The number of calories a pet gets from The Farmer's Dog food each day */
  dailyCalories: Maybe<Scalars['Int']['output']>;
  /** The plan's ID */
  id: Scalars['Int']['output'];
  /** How many days a full pack lasts for this plan */
  lastingDays: Scalars['Float']['output'];
  /** ID of the pet the plan is tied to */
  petId: Maybe<Scalars['Int']['output']>;
  /** The plan's products */
  planProducts: Maybe<Array<LineItemPlanProductMetadata>>;
  /** How much of a full pack should the pet be feed each day? */
  portion: Maybe<Scalars['Float']['output']>;
  /** The mixing plan ratio (how much of a pet's diet is The Farmer's Dog food) */
  ratio: Maybe<Scalars['Float']['output']>;
  /** How many packs of food a customer has at their home for this plan */
  stock: Scalars['Float']['output'];
  /** ID of the subscription this plan belongs to */
  subscriptionId: Scalars['Int']['output'];
  /** Is this a transition plan? */
  transition: Scalars['Boolean']['output'];
  /** The plan's type */
  type: PlanType;
};

/** Pet health issue line item metadata */
export type LineItemHealthIssueMetadata = {
  __typename?: 'LineItemHealthIssueMetadata';
  /** The health issue's ID */
  id: Scalars['Int']['output'];
  /** A health issue's name */
  name: Maybe<Scalars['String']['output']>;
};

/** Metadata related to the order */
export type LineItemMetadata = {
  __typename?: 'LineItemMetadata';
  /** The add-on metadata for this line item if it's a one-off add-on product line item */
  oneOffAddOn: Maybe<LineItemOneOffAddOnMetadata>;
  /** The pet metadata for this line item if it's a food line item for fresh subscriptions */
  pet: Maybe<LineItemPetMetadata>;
  /** The food plan metadata for this line item if it's a food line item */
  plan: Maybe<LineItemFoodPlanMetadata>;
  /** The product metadata for this line item */
  product: LineItemProductMetadata;
  /** Food plan statistics describing the plan across the entire order */
  stats: Maybe<LineItemStatsMetadata>;
  /** The add-on metadata for this line item if it's a subscription add-on product line item */
  subscriptionAddOn: Maybe<LineItemSubscriptionAddOnMetadata>;
  /** A list of recommended treat portions for each treat and pet */
  treatPortions: Maybe<Array<RecommendedTreatsPortion>>;
};

/** One-off add-on metadata for a line item */
export type LineItemOneOffAddOnMetadata = {
  __typename?: 'LineItemOneOffAddOnMetadata';
  /** ID of the product */
  productId: Scalars['Int']['output'];
  /** The quantity of the product */
  quantity: Scalars['Int']['output'];
};

/** Pet metadata for a line item */
export type LineItemPetMetadata = {
  __typename?: 'LineItemPetMetadata';
  activity: Maybe<Scalars['Int']['output']>;
  /** The pet's estimated birthdate */
  birthday: Maybe<Scalars['Date']['output']>;
  /** How accurate is the pet's birthday we have recorded? */
  birthdayAccuracy: Maybe<PetBirthdayAccuracy>;
  /** Pet breed metadata */
  breeds: Maybe<Array<LineItemBreedMetadata>>;
  condition: Maybe<Scalars['Int']['output']>;
  /** Pet creation date */
  createdAt: Scalars['DateTime']['output'];
  eatingStyle: Maybe<Scalars['Int']['output']>;
  /** The pet's gender */
  gender: Maybe<PetGender>;
  /** The pet's ID */
  id: Scalars['Int']['output'];
  /** Pet health issue metadata */
  issues: Maybe<Array<LineItemHealthIssueMetadata>>;
  /** The pet's name */
  name: Scalars['String']['output'];
  nature: Maybe<Scalars['Int']['output']>;
  /** Is the pet neutered? */
  neutered: Maybe<Scalars['Boolean']['output']>;
  /** Can we contact the customer about this pet? */
  noContact: Scalars['Boolean']['output'];
  /** Other prescription diet */
  otherPrescriptionDiet: Maybe<LineItemPetPrescriptionDietMetadata>;
  /** The pet's required calores as prescribed by a vet */
  requiredCalories: Maybe<Scalars['Float']['output']>;
  /** Pet food allergy metadata */
  restrictions: Maybe<Array<LineItemFoodAllergyMetadata>>;
  /** Is the pet on a special diet? */
  specialDiet: Maybe<Scalars['Boolean']['output']>;
  /** The pet's suggested daily calories based on their weight and other factors */
  suggestedCalories: Maybe<Scalars['Float']['output']>;
  /** The pet's target weight in grams */
  targetWeight: Maybe<Scalars['Int']['output']>;
  treatsQuantity: Maybe<Scalars['Int']['output']>;
  /** The user's ID */
  userId: Scalars['Int']['output'];
  /** The pet's weight in grams */
  weight: Maybe<Scalars['Int']['output']>;
};

/** Prescription diet metadata */
export type LineItemPetPrescriptionDietMetadata = {
  __typename?: 'LineItemPetPrescriptionDietMetadata';
  /** The prescription diet's ID */
  id: Scalars['Int']['output'];
  /** The prescription diet's name */
  name: Scalars['String']['output'];
};

/** Food plan product metadata for a line item */
export type LineItemPlanProductMetadata = {
  __typename?: 'LineItemPlanProductMetadata';
  /** The plan product's ID */
  id: Scalars['Int']['output'];
  /** ID of the product */
  productId: Scalars['Int']['output'];
  /** Name of the product */
  productName: Scalars['String']['output'];
  /** Product SKU */
  productSKU: Scalars['String']['output'];
  /** What portion of the plan's products is this product? */
  ratio: Scalars['Float']['output'];
  /** ID of the recipe */
  recipeId: Scalars['Int']['output'];
  /** Name of the recipe */
  recipeName: Scalars['String']['output'];
};

/** Product metadata for a line item */
export type LineItemProductMetadata = {
  __typename?: 'LineItemProductMetadata';
  /** If this product is 'food', the number of calories it includes */
  calories: Maybe<Scalars['Float']['output']>;
  /** The height of the product in centimeters */
  height: Scalars['Float']['output'];
  /** The product's ID */
  id: Scalars['Int']['output'];
  /** The length of the product in centimeters */
  length: Scalars['Float']['output'];
  /** The product's name */
  name: Scalars['String']['output'];
  /** Products pack code */
  packCode: Maybe<Scalars['String']['output']>;
  /** The cost of the product in cents */
  price: Scalars['Int']['output'];
  /** If this product is 'food', the recipe for it */
  recipe: Maybe<LineItemRecipeMetadata>;
  /** If this product is 'food', the recipe ID for it */
  recipeId: Maybe<Scalars['Int']['output']>;
  /** The product's unique SKU */
  sku: Scalars['String']['output'];
  /** Is this product enabled? */
  status: ProductStatus;
  /** What type of product this is */
  type: ProductType;
  /** The volume of the product in cubic centimeters */
  volume: Scalars['Float']['output'];
  /** The weight of the product in grams */
  weight: Scalars['Float']['output'];
  /** The width of the product in centimeters */
  width: Scalars['Float']['output'];
};

/** Recipe metadata for a line item */
export type LineItemRecipeMetadata = {
  __typename?: 'LineItemRecipeMetadata';
  /** The number of calories per kg and lb in the recipe */
  calorieContent: Maybe<Scalars['String']['output']>;
  /** The display name for the recipe */
  displayName: Maybe<Scalars['String']['output']>;
  /** A summary of the recipe's full ingredients */
  fullIngredients: Maybe<Scalars['String']['output']>;
  /** The protein/fat/fiber breakdown in the recipe */
  guaranteedAnalysis: Maybe<Scalars['String']['output']>;
  /** The recipe's ID */
  id: Scalars['Int']['output'];
  /**
   * A summary of the recipe's ingredients
   * @deprecated Replaced by fullIngredients
   */
  ingredients: Maybe<Scalars['String']['output']>;
  /** The number of calories per gram in the recipe */
  kcalPerGram: Scalars['Float']['output'];
  /** A summary of the recipe's main ingredients */
  mainIngredients: Maybe<Scalars['String']['output']>;
  /** The recipe's name */
  name: Scalars['String']['output'];
  /** A summary of the recipe's supplements */
  supplements: Maybe<Scalars['String']['output']>;
  /** The protein/fat/fiber breakdown in the recipe */
  typicalAnalysis: Maybe<Scalars['String']['output']>;
};

/** Food plan statistics describing the plan across the entire order */
export type LineItemStatsMetadata = {
  __typename?: 'LineItemStatsMetadata';
  /** Total calories (sum of all the pack calories) included for the plan in the order */
  calories: Scalars['Int']['output'];
  /** Total carryover days from this plan and order into the next order */
  carryover: Scalars['Int']['output'];
  /** Days of food included for the plan in the order */
  days: Scalars['Int']['output'];
  /** Number of food packs included for the plan in the order */
  quantity: Scalars['Int']['output'];
  /** Plan recipe metadata */
  recipes: Array<LineItemStatsRecipeMetadata>;
  /** Total volume (cm^3) of all the food packs included for the plan in the order */
  volume: Scalars['Int']['output'];
  /** Total weight (g) of all the food packs included for the plan in the order */
  weight: Scalars['Int']['output'];
};

/** Food plan recipe statistics */
export type LineItemStatsRecipeMetadata = {
  __typename?: 'LineItemStatsRecipeMetadata';
  /** Total calories included for this specific recipe in the order */
  calories: Scalars['Int']['output'];
  /** Days of food included for this specific recipe in the order */
  days: Scalars['Int']['output'];
  /** ID of the recipe */
  id: Scalars['Int']['output'];
  /** Number of food packs included for this specific recipe in the order */
  quantity: Scalars['Int']['output'];
  /** Total volume (cm^3) of food packs included for this specific recipe in the order */
  volume: Scalars['Int']['output'];
  /** Total weight (g) of food packs included for this specific recipe in the order */
  weight: Scalars['Int']['output'];
};

/** Subscription add-on metadata for a line item */
export type LineItemSubscriptionAddOnMetadata = {
  __typename?: 'LineItemSubscriptionAddOnMetadata';
  /** The add-on's ID */
  id: Scalars['Int']['output'];
  /** ID of the product */
  productId: Scalars['Int']['output'];
  /** The quantity of the product */
  quantity: Scalars['Int']['output'];
  /** ID of the subscription */
  subscriptionId: Scalars['Int']['output'];
};

/** The user's email address and password. */
export type LoginInput = {
  /** The user's email address */
  email: Scalars['Email']['input'];
  /** The user's password */
  password: Scalars['String']['input'];
};

/** A customer's view of their order. */
export type MyOrderView = {
  __typename?: 'MyOrderView';
  /** Total cost in cents for add ons on this order */
  addOnsTotal: Maybe<Scalars['Int']['output']>;
  /** Credits that can be used for this order payment */
  applicableCredits: Scalars['Int']['output'];
  /** The date the order was delivered */
  arrivalDate: Maybe<Scalars['DateTime']['output']>;
  /** The order's base cost in cents */
  baseFee: Scalars['Int']['output'];
  /** The name of the fulfillment batch */
  batch: Maybe<Scalars['String']['output']>;
  /** The order's billing address */
  billingAddress: UserAddressView;
  /** The order's billing address ID */
  billingAddressId: Scalars['Int']['output'];
  /** The order total minus applicable credits */
  cashTotal: Scalars['Int']['output'];
  /** The date and time that the order was created */
  createdAt: Scalars['DateTime']['output'];
  /** A custom status for the order */
  customStatus: Maybe<Scalars['String']['output']>;
  /** Days since previous shipment */
  daysSinceShipment: Scalars['Int']['output'];
  /** The desired date the order will be delivered */
  desiredArrivalDate: Maybe<Scalars['Date']['output']>;
  /** The desired date the order will be shipped */
  desiredShipDate: Maybe<Scalars['Date']['output']>;
  /** The order's discount amount in cents */
  discountTotal: Scalars['Int']['output'];
  /** Discounts that are associated with this order */
  discounts: Maybe<Array<OrderDiscount>>;
  /** Earliest date we expect the order to arrive based on the desired arrival date, as communicated to the customer. */
  earliestDesiredArrivalDate: Maybe<Scalars['Date']['output']>;
  /**
   * The estimated delivery date for the order
   * @deprecated Deprecated, should not be used as the Split flag that powers it was removed
   */
  estimatedDeliveryDate: Maybe<Scalars['Date']['output']>;
  /**
   * Earliest date we expect the order to arrive, as communicated to the customer. If calculated date is before today, then today will be returned instead.
   * @deprecated Deprecated, should use 'earliestDesiredArrivalDate' field instead
   */
  estimatedEarliestArrivalDate: Scalars['Date']['output'];
  /**
   * Latest date we expect the order to arrive, as communicated to the customer. If calculated date is before today, then today will be returned instead.
   * @deprecated Deprecated, should use 'latestDesiredArrivalDate' field instead
   */
  estimatedLatestArrivalDate: Scalars['Date']['output'];
  /** The time the order was last exported */
  exportedAt: Maybe<Scalars['DateTime']['output']>;
  /** Were the shipping labels purchased outside the standard process? */
  external: Scalars['Boolean']['output'];
  /** Does the order have a shipping label purchased? */
  hasShippingLabel: Scalars['Boolean']['output'];
  /** Whether this order has surprise free treats */
  hasSurpriseFreeTreats: Maybe<Scalars['Boolean']['output']>;
  /** The order's ID */
  id: Scalars['Int']['output'];
  /** Is the order currently being fulfilled? */
  inFulfillment: Scalars['Boolean']['output'];
  /** Has the order been cancelled? */
  isCancelled: Scalars['Boolean']['output'];
  /** Has the order been delivered? */
  isCompleted: Scalars['Boolean']['output'];
  /** Can the order be modified (or is it locked in)? */
  isEditable: Scalars['Boolean']['output'];
  /** Is the order locked */
  isLocked: Scalars['Boolean']['output'];
  /** Can the order be rescheduled */
  isReschedulable: Scalars['Boolean']['output'];
  /** Whether this order is a step up (transition) order */
  isStepUp: Maybe<Scalars['Boolean']['output']>;
  /** The cost of the order's line items in cents */
  itemTotal: Scalars['Int']['output'];
  /** A unique key used for querying a specific order, used for both persisted and projected orders */
  key: Scalars['String']['output'];
  /** Latest date we expect the order to arrive based on the desired arrival date, as communicated to the customer. */
  latestDesiredArrivalDate: Maybe<Scalars['Date']['output']>;
  /** Order line items */
  lineItems: Maybe<Array<LineItem>>;
  /** The order's meals cost in cents */
  mealsTotal: Scalars['Int']['output'];
  /** Metadata about the order including subscription and plan snapshots */
  metadata: OrderMetadata;
  /** The order number */
  number: Maybe<Scalars['String']['output']>;
  /** The order's payment details */
  payment: Maybe<OrderPayment>;
  /** Number of times we've attempted to bill the order */
  paymentRetryCount: Scalars['Int']['output'];
  /** The order's payment status */
  paymentStatus: OrderPaymentStatus;
  /** The planned date the order will be delivered */
  plannedArrivalDate: Maybe<Scalars['Date']['output']>;
  /** The daily meal price before taxes in cents */
  preTaxDailyMealPrice: Maybe<Scalars['Int']['output']>;
  /** The number of packs in this order */
  quantity: Maybe<Scalars['Int']['output']>;
  /** The relative position of the order in the queue of all of the orders for that subscription */
  queuePosition: OrderQueuePosition;
  /** The communicated cut off date and time for a user to reschedule the order. Time should be displayed as provided, ignore local timezone */
  rescheduleCutOffDate: Scalars['DateTime']['output'];
  /** The estimated date the order will be shipped */
  shipDate: Scalars['Date']['output'];
  /** How many days we expect the order to be in transit */
  shipmentDays: Maybe<Scalars['Int']['output']>;
  /** The order's shipment status */
  shipmentStatus: OrderShipmentStatus;
  /** The order's shipping address */
  shippingAddress: UserAddressView;
  /** The order's shipping address ID */
  shippingAddressId: Scalars['Int']['output'];
  /** The name of the carrier delivering the order */
  shippingCarrier: Maybe<Scalars['String']['output']>;
  /** The order's shipping cost in cents */
  shippingTotal: Scalars['Int']['output'];
  /** States this order is in */
  states: Maybe<Array<OrderState>>;
  /** The order's tax amount in cents */
  taxTotal: Scalars['Int']['output'];
  /** The order's total cost in cents */
  total: Scalars['Int']['output'];
  /** The tracking number from the carrier */
  trackingNumber: Maybe<Scalars['String']['output']>;
  /** Link to the tracking information from the carrier */
  trackingURL: Maybe<Scalars['String']['output']>;
  /** Returns a list of links to all the tracking information for each box from the carrier, if the boxes are tracked separately; returns a single item in the array if there is a main tracking link */
  trackingURLs: Array<OrderTrackingUrlType>;
  /** The order type */
  type: OrderType;
};

/** A GraphQL connection for MyOrderView. Used to paginate through long lists. See https://graphql.org/learn/pagination/#complete-connection-model for details. */
export type MyOrderViewConnection = {
  __typename?: 'MyOrderViewConnection';
  /** The actual list of items returned in this page */
  edges: Maybe<Array<MyOrderViewEdge>>;
  /** ID of the next order to be processed (the first future order) */
  nextOrderToBeProcessedId: Maybe<Scalars['Int']['output']>;
  /** Information about the returned page of data */
  pageInfo: PageInfo;
  /** Total number of items */
  totalCount: Scalars['Int']['output'];
};

/** A GraphQL connection for MyOrderView. Used to paginate through long lists. See https://graphql.org/learn/pagination/#complete-connection-model for details. */
export type MyOrderViewConnectionEdgesArgs = {
  ids: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  sort: InputMaybe<OrderSortBy>;
  states: InputMaybe<Array<InputMaybe<OrderState>>>;
};

/** A GraphQL connection edge for MyOrderView. See https://graphql.org/learn/pagination/#complete-connection-model for details. */
export type MyOrderViewEdge = {
  __typename?: 'MyOrderViewEdge';
  /** Unique edge identifier within the connection */
  cursor: Scalars['String']['output'];
  /** A MyOrderView */
  node: MyOrderView;
};

/** A customer's view of their pet. */
export type MyPetView = {
  __typename?: 'MyPetView';
  activity: Maybe<Scalars['Int']['output']>;
  /** The pet's estimated birthdate */
  birthday: Maybe<Scalars['Date']['output']>;
  /** How accurate is the pet's birthday we have recorded? */
  birthdayAccuracy: Maybe<PetBirthdayAccuracy>;
  /** The breed(s) of the pet */
  breeds: Maybe<Array<Breed>>;
  condition: Maybe<Scalars['Int']['output']>;
  /** Pet creation date */
  createdAt: Scalars['DateTime']['output'];
  /** The pet's current food */
  currentFood: Maybe<CurrentFood>;
  eatingStyle: Maybe<Scalars['Int']['output']>;
  /** The pet's gender */
  gender: Maybe<PetGender>;
  /** The pet's ID */
  id: Scalars['Int']['output'];
  /** A puppy starts it's life gaining weight and growing at an accelerated rate. During this time it's crucial to ensure the calorie intake meets the current weight of the dog. When true, the pet is in this growth phase. */
  isRapidlyGrowing: Scalars['Boolean']['output'];
  /** The pet's health issues */
  issues: Maybe<Array<HealthIssue>>;
  /** The pet's name */
  name: Scalars['String']['output'];
  nature: Maybe<Scalars['Int']['output']>;
  /** Is the pet neutered? */
  neutered: Maybe<Scalars['Boolean']['output']>;
  /** Can we contact the customer about this pet? */
  noContact: Scalars['Boolean']['output'];
  /** The pet's prescription diet if it is different from what we provide */
  otherPrescriptionDiet: Maybe<Scalars['String']['output']>;
  /**
   * The pet's plan
   * @deprecated Instead use subscription.foodPlans.
   */
  plan: MyPlan;
  /** The pet's prescription diets */
  prescriptionDiets: Maybe<Array<PrescriptionDiet>>;
  /** The recommended calories for the pet based on their current body profile. */
  recommendedCalories: Maybe<RecommendedCaloriesResponseType>;
  /** The pet's required calores as prescribed by a vet */
  requiredCalories: Maybe<Scalars['Float']['output']>;
  /** The pet's food restrictions */
  restrictions: Maybe<Array<Restriction>>;
  showRecommendedCalories: Maybe<Scalars['Boolean']['output']>;
  /** Is the pet on a special diet? */
  specialDiet: Maybe<Scalars['Boolean']['output']>;
  /** The pet's suggested daily calories based on their weight and other factors */
  suggestedCalories: Maybe<Scalars['Float']['output']>;
  /** A pet's suggested calories adjusted to the nearest available DIY calorie bucket */
  suggestedDiyCalories: Maybe<Scalars['Float']['output']>;
  /** The pet's target weight in grams */
  targetWeight: Maybe<Scalars['Int']['output']>;
  treatsQuantity: Maybe<Scalars['Int']['output']>;
  /** The pet's weight in grams */
  weight: Maybe<Scalars['Int']['output']>;
};

/** A customer's view of their plan. */
export type MyPlan = {
  __typename?: 'MyPlan';
  /** Is this plan active? */
  active: Scalars['Boolean']['output'];
  /** Available food products based on serving combination for this plan */
  availableFoodProducts: Maybe<Array<Product>>;
  /** The mixing plan ratios for currently selected serving combination */
  availableMixingRatios: Maybe<Array<Scalars['Float']['output']>>;
  /** The plan's calories. Will be null for incomplete pets during a multi-pet signup. */
  calories: Maybe<Scalars['Float']['output']>;
  /** The number of calories a pet gets from The Farmer's Dog food each day */
  dailyCalories: Scalars['Int']['output'];
  /** The plan's ID */
  id: Scalars['Int']['output'];
  /** How many days a full pack lasts for this plan */
  lastingDays: Scalars['Float']['output'];
  /** ID of the pet the plan is tied to */
  petId: Scalars['Int']['output'];
  /** Name of the pet the plan is tied to */
  petName: Scalars['String']['output'];
  /** The plan's products */
  planProducts: Maybe<Array<PlanProduct>>;
  /** The stock records for the plan */
  planStock: Maybe<Array<PlanStock>>;
  /** How much of a full pack should the pet be feed each day? Will be null for incomplete pets during a multi-pet signup. */
  portion: Maybe<Scalars['Float']['output']>;
  /**
   * The plan's products
   * @deprecated Deprecated, should use 'planProducts' field instead
   */
  products: Maybe<Array<Product>>;
  /** The predicted future stock at next delivery date */
  projectedStockAtNextDelivery: Scalars['Float']['output'];
  /** The mixing plan ratio (how much of a pet's diet is The Farmer's Dog food) */
  ratio: Scalars['Float']['output'];
  /** The plan's recipes */
  recipes: Maybe<Array<PlanRecipe>>;
  /**
   * How many packs of food a customer has at their home for this plan
   * @deprecated Deprecated, should use 'stockAsDays'/'stockAsPacks' fields instead
   */
  stock: Scalars['Float']['output'];
  /** Remaining stock for a pet counted in days left */
  stockAsDays: Maybe<Scalars['Float']['output']>;
  /** Remaining stock for a pet counted in remaining packs */
  stockAsPacks: Maybe<Scalars['Float']['output']>;
  /** ID of the subscription this plan belongs to */
  subscriptionId: Scalars['Int']['output'];
  /** Is this plan a transition plan? */
  transition: Scalars['Boolean']['output'];
  /** The plan's type */
  type: PlanType;
};

/** A customer's view of their own subscription. */
export type MySubscriptionView = {
  __typename?: 'MySubscriptionView';
  /** The add-ons attached to this subscription */
  addOns: Maybe<Array<AddOn>>;
  /**
   * Dates that can be selected for the next order's shipDate (used for rush or delay)
   * @deprecated Instead use user.availableNextDates
   */
  availableNextDates: Array<Scalars['Date']['output']>;
  /** Desired buffer stock for this subscription (as days of food) */
  bufferDays: Maybe<Scalars['Int']['output']>;
  /** An array of buffer options with associated number of days and reschedule date */
  bufferOptions: Maybe<Array<BufferOption>>;
  /** Range of buffer days for a fresh subscription. Not applicable for DIY */
  bufferRange: Maybe<BufferRange>;
  /** Whether the next order can be rescheduled */
  canRescheduleNextOrder: Maybe<Scalars['Boolean']['output']>;
  /** The quote generated when the customer checked out */
  checkoutQuote: Maybe<Scalars['JSON']['output']>;
  /** The current quote */
  currentQuote: Maybe<SubscriptionQuote>;
  discountPercentage: Maybe<Scalars['Float']['output']>;
  /** The feeding guides for each pet with a food plan */
  feedingGuides: Maybe<Array<SubscriptionFeedingGuide>>;
  fillBox: Maybe<Scalars['Boolean']['output']>;
  /** In this subscription's queue of orders, the soonest future order that can be edited or rescheduled */
  firstUnlockedOrder: Maybe<MyOrderView>;
  /** The food plans attached to this subscription */
  foodPlans: Maybe<Array<MyPlan>>;
  frequency: Maybe<Scalars['Int']['output']>;
  /** Is there space in the box for add-ons after adding the food? */
  hasSpaceForAddOns: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  includeTraysAndLids: Maybe<Scalars['Boolean']['output']>;
  /** Whether the subscription has just in time enabled */
  justInTimeEnabled: Maybe<Scalars['Boolean']['output']>;
  /** Maximum number of addOns allowed for this subscription. (0 can also mean AddOns are disabled) */
  maxNumberOfAddOns: Scalars['Int']['output'];
  /** The most recently delivered order */
  mostRecentlyDeliveredOrder: Maybe<MyOrderView>;
  multiBox: Maybe<Scalars['Boolean']['output']>;
  /** The planned arrival date of the next unpaid, unscheduled order. */
  nextArrivalDate: Maybe<Scalars['Date']['output']>;
  nextDate: Maybe<Scalars['Date']['output']>;
  nextDesiredArrivalDate: Maybe<Scalars['Date']['output']>;
  /** In this subscription's queue of orders, the next order expected to arrive at the customer's door */
  nextOrderToBeDelivered: Maybe<MyOrderView>;
  numberOfBoxes: Maybe<Scalars['Int']['output']>;
  /**
   * The orders for this subscription ascending chronologically
   * @deprecated Instead use ordersConnection
   */
  orders: Maybe<Array<MyOrderView>>;
  /** A connection of orders */
  ordersConnection: MyOrderViewConnection;
  price: Maybe<Scalars['Int']['output']>;
  priceVersionId: Maybe<Scalars['Int']['output']>;
  /** Recommended next date based on current stock */
  recommendedNextDate: Maybe<Scalars['Date']['output']>;
  /** How many treats do we recommend the customer add to their order? */
  recommendedTreatQuantity: Scalars['Int']['output'];
  /** Recommended list of treats package quantities the customer should add to their order. */
  recommendedTreatsPackageQuantities: Array<RecommendedTreatsPackageQuantity>;
  /** Daily portion of each treat per pet that we recommend to the customer. */
  recommendedTreatsPortions: Array<RecommendedTreatsPortion>;
  /** The communicated cut off date and time for a user to reschedule the order. Time should be displayed as provided, ignore local timezone */
  rescheduleCutoffDateForNextOrder: Maybe<Scalars['DateTime']['output']>;
  startDate: Maybe<Scalars['Date']['output']>;
  status: SubscriptionStatus;
  /** Calculates a nextDate based solely on your reported plan stock info */
  stockAdjustedNextDate: Scalars['Date']['output'];
  /** Stock status immediately before the next delivery, aggregated for all plans on this subscription */
  stockStatusAtNextDelivery: Maybe<StockStatus>;
  /** products and quantities in a non-fresh food subscription */
  subscriptionProducts: Maybe<Array<SubscriptionProduct>>;
  /** Frequencies available for a users subscription */
  suggestedFrequencies: SuggestedFrequenciesType;
  trialEnabled: Maybe<Scalars['Boolean']['output']>;
  trialPeriodDiscountPercentage: Maybe<Scalars['Float']['output']>;
  type: Maybe<SubscriptionType>;
  userId: Scalars['Int']['output'];
};

/** A customer's view of their own subscription. */
export type MySubscriptionViewAddOnsArgs = {
  productIds: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  types: InputMaybe<Array<InputMaybe<ProductType>>>;
};

/** A customer's view of their own subscription. */
export type MySubscriptionViewOrdersConnectionArgs = {
  first: InputMaybe<Scalars['Int']['input']>;
  ids: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  queuePositions: InputMaybe<Array<InputMaybe<OrderQueuePosition>>>;
  shipmentStatuses: InputMaybe<Array<InputMaybe<OrderShipmentStatus>>>;
  sort: InputMaybe<OrderSortBy>;
};

/** A customer's view of their own subscription. */
export type MySubscriptionViewStockAdjustedNextDateArgs = {
  planStockStates: Array<PlanStockStateInput>;
};

/** A customer's view of their own user account. */
export type MyUserView = {
  __typename?: 'MyUserView';
  /** User activation date post signup */
  activatedAt: Maybe<Scalars['DateTime']['output']>;
  /** Dates that can be selected for the next order's shipDate (used for rush or delay) */
  availableNextDates: Array<Scalars['Date']['output']>;
  /** The customer's billing address */
  billingAddress: Maybe<UserAddressView>;
  /** User's preferences on receiving various TFD communications (updates, reminders) via email, SMS, etc. */
  communicationPreferences: CommunicationPreferenceType;
  /** User creation date */
  createdAt: Scalars['DateTime']['output'];
  /** Credit available for the user to spend on products */
  credit: CreditView;
  /** List of credit card details associated with the user */
  creditCards: Maybe<Array<CreditCard>>;
  /** The Users current subscription type during signup */
  currentSignupSubscriptionType: Maybe<SubscriptionType>;
  /** A stringified JSON array of subscription types that the user is eligible to sign up for */
  eligibleSignupSubscriptionTypes: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName: Maybe<Scalars['String']['output']>;
  /** Manual confirmation that a user has not committed fraud */
  fraudOverrideEnabled: Scalars['Boolean']['output'];
  /** User's default subscription frequency at the time of checkout */
  frequenciesAtCheckoutFrequency: Maybe<Scalars['Int']['output']>;
  /** Confidence level in the value of fresh food */
  freshFoodConfidence: Maybe<Scalars['Int']['output']>;
  /** First name and last name combined */
  fullName: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  lastName: Maybe<Scalars['String']['output']>;
  /** A flag to determine if this user can be contacted */
  noContact: Maybe<Scalars['Boolean']['output']>;
  /** List of the customer's pets */
  pets: Maybe<Array<MyPetView>>;
  phone: Maybe<Scalars['Phone']['output']>;
  /** The customer's recipe product versions */
  recipeProductVersions: Maybe<Array<RecipeProductVersion>>;
  /** Code customers can use to get credit for referring other customers */
  referralCode: Scalars['String']['output'];
  /** The customer's data used to make referrals */
  referralStats: Maybe<ReferralStatsType>;
  /** If a bill failed event is current on account, and retry payment should be enabled */
  retryPaymentIsUnlocked: Maybe<Scalars['Boolean']['output']>;
  role: UserRole;
  /** The customer's shipping address */
  shippingAddress: Maybe<UserAddressView>;
  status: UserStatus;
  /** Card ID for Stripe */
  stripeCard: Maybe<Scalars['ID']['output']>;
  /** The customer's subscription which specify the details of what the customer will receive and how often they should receive it */
  subscription: MySubscriptionView;
  /** The list of the customer's subscriptions differentiated by type */
  subscriptions: Array<MySubscriptionView>;
  /** The trial start weeks options available to a user */
  trialStartWeeks: Maybe<TrialStartWeeks>;
};

/** A customer's view of their own user account. */
export type MyUserViewPetsArgs = {
  petsInput: InputMaybe<PetsArgumentsInput>;
};

/** A customer's view of their own user account. */
export type MyUserViewSubscriptionsArgs = {
  subscriptionsInput: InputMaybe<SubscriptionsArgumentsInput>;
};

/** A customer's view of their own user account. */
export type MyUserViewTrialStartWeeksArgs = {
  trialStartWeeksInput: InputMaybe<TrialStartWeeksInput>;
};

export enum NoRecommendationReasonsType {
  AdultDogWithExtremeIncreasedWeight = 'ADULT_DOG_WITH_EXTREME_INCREASED_WEIGHT',
  PuppyWithAbnormalWeightChange = 'PUPPY_WITH_ABNORMAL_WEIGHT_CHANGE',
  UnexpectedError = 'UNEXPECTED_ERROR',
}

/** A discount that was applied to an item */
export type OrderDiscount = {
  __typename?: 'OrderDiscount';
  /** The amount of the discount */
  discountAmountWithUnit: DiscountAmountWithUnit;
  /** The reason why a discount was applied */
  reason: OrderDiscountReason;
  /** The SKU of the item, if applicable */
  sku: Maybe<Scalars['String']['output']>;
  /** The type of discount applied to the order */
  type: OrderDiscountType;
};

export enum OrderDiscountReason {
  SecondBoxDiscount = 'SecondBoxDiscount',
  SubscriberFreeFirstTreats = 'SubscriberFreeFirstTreats',
  SubscriptionDiscount = 'SubscriptionDiscount',
  SurpriseFreeTreats = 'SurpriseFreeTreats',
  TreatsInCheckout = 'TreatsInCheckout',
  TrialDiscount = 'TrialDiscount',
}

export enum OrderDiscountType {
  /** A discount applied to the entire order */
  Order = 'order',
  /** A discount applied to a specific SKU */
  Sku = 'sku',
}

/** Metadata related to the order */
export type OrderMetadata = {
  __typename?: 'OrderMetadata';
  /** Pets that are associated to the order */
  pets: Maybe<Array<MyPetView>>;
  stats: OrderMetadataStats;
};

/** A pet's plan details at the time this order was shipped */
export type OrderMetadataPlanStats = {
  __typename?: 'OrderMetadataPlanStats';
  /** The total calories for the plan in this order (sum of pack calories) */
  calories: Scalars['Int']['output'];
  /** The plan's daily calories at the time this order was shipped */
  dailyCalories: Scalars['Int']['output'];
  /** The number of days worth of food in this order for the plan */
  days: Scalars['Float']['output'];
  /** The plan ID */
  id: Scalars['Int']['output'];
  /** The number of packs of in this order for the plan */
  quantity: Scalars['Int']['output'];
};

/** A snapshot of how the subscription looked when this order was shipped */
export type OrderMetadataStats = {
  __typename?: 'OrderMetadataStats';
  /** The amount of food in this order, expressed in days */
  avgDays: Scalars['Float']['output'];
  /** The frequency (fresh)/cadence (diy) that is associated with this order */
  frequency: Scalars['Int']['output'];
  /** Snapshots of plan data */
  plans: Maybe<Array<OrderMetadataPlanStats>>;
  /** Are treats skipped for this order */
  skipAddOns: Maybe<Scalars['Boolean']['output']>;
  /** The amount of food in this order, expressed in weeks (rounded down to the nearest week) */
  weeksOfFoodInShipment: Scalars['Int']['output'];
};

/** The order payment details */
export type OrderPayment = {
  __typename?: 'OrderPayment';
  /** The amount of cash used for payment */
  cash: Scalars['Int']['output'];
  /** The amount of credits used for payment */
  credits: Scalars['Int']['output'];
};

export enum OrderPaymentStatus {
  Cancelled = 'cancelled',
  Failed = 'failed',
  Free = 'free',
  Paid = 'paid',
  PartiallyPaid = 'partially_paid',
  Processing = 'processing',
  Queued = 'queued',
  Refunded = 'refunded',
  Transferred = 'transferred',
  Unpaid = 'unpaid',
}

export enum OrderQueuePosition {
  Future = 'future',
  NextToBeDelivered = 'next_to_be_delivered',
  Past = 'past',
}

export enum OrderShipmentStatus {
  AcquisitionFailed = 'acquisition_failed',
  Cancelled = 'cancelled',
  Delayed = 'delayed',
  Delivered = 'delivered',
  DeliveryFailed = 'delivery_failed',
  Failed = 'failed',
  Processing = 'processing',
  Queued = 'queued',
  Scheduled = 'scheduled',
  Shipped = 'shipped',
  Transit = 'transit',
  Unscheduled = 'unscheduled',
}

export type OrderSortBy = {
  /** Sorting direction */
  dir: SortDirectionEnum;
  /** The field on which to sort by */
  field: OrderSortFieldEnum;
};

export enum OrderSortFieldEnum {
  ArrivalDate = 'arrivalDate',
  ShipDate = 'shipDate',
}

export enum OrderState {
  Cancelled = 'cancelled',
  Current = 'current',
  Delivered = 'delivered',
  Future = 'future',
  Invalid = 'invalid',
  Next = 'next',
}

export type OrderTrackingUrlType = {
  __typename?: 'OrderTrackingUrlType';
  trackingNumber: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

export enum OrderType {
  Regular = 'regular',
  Trial = 'trial',
}

/** Standard page info for the GraphQL connection model. See https://graphql.org/learn/pagination/#complete-connection-model for details. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Used to fetch the next page of results */
  endCursor: Maybe<Scalars['String']['output']>;
  /** Is there another page of data available after this one? */
  hasNextPage: Maybe<Scalars['Boolean']['output']>;
  /** Is there another page of data available before this one? */
  hasPreviousPage: Maybe<Scalars['Boolean']['output']>;
  /** Used to fetch the previous page of results */
  startCursor: Maybe<Scalars['String']['output']>;
};

export type PendingSubscriptionAddOns = {
  /** The treats that the user has selected to add to their subscription */
  treats?: InputMaybe<Array<PendingSubscriptionAddOnsTreat>>;
};

export type PendingSubscriptionAddOnsTreat = {
  /** The name of the treats to be added to the subscription */
  name: Scalars['String']['input'];
  /** The number of packages of treats to add to the subscription */
  quantity: Scalars['Int']['input'];
  /** The size of the treats to add to the subscription */
  size: TreatSize;
};

export type PendingSubscriptionInputType = {
  /** The addons that the user has selected to add to their subscription */
  addOns?: InputMaybe<PendingSubscriptionAddOns>;
  /** The frequency in which orders of the subscription will be sent out */
  frequency?: InputMaybe<Scalars['Int']['input']>;
  /** Whether to include trays and lids in the order */
  includeTraysAndLids?: InputMaybe<Scalars['Boolean']['input']>;
  /** The start date for a subscription */
  startDate?: InputMaybe<Scalars['Date']['input']>;
  /** The type of the subscription. */
  type: SubscriptionType;
};

/** Describes a pet's personality */
export type PersonalityType = {
  __typename?: 'PersonalityType';
  /** The personality type ID */
  id: Scalars['Int']['output'];
  /** The personality type display name */
  name: Scalars['String']['output'];
};

/** Paired with birthday, provides info on how accurate that info is */
export enum PetBirthdayAccuracy {
  /** Birthday is the exact date */
  Date = 'date',
  /** Birthday is accurate to the month */
  Months = 'months',
  /** Birthday is accurate to the week */
  Weeks = 'weeks',
  /** Birthday is accurate to the year */
  Years = 'years',
}

export enum PetGender {
  Female = 'female',
  Male = 'male',
}

export type PetMutationsInput = {
  /** List of pets to create */
  create?: InputMaybe<Array<CreatePetInput>>;
  /** List of pet IDs to delete */
  delete?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of pets to update */
  update?: InputMaybe<Array<UpdatePetInput>>;
};

export type PetsArgumentsInput = {
  /** Flag to grab pets based on whether they have an active of inactive food plan */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** List of pet ids */
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

/** An association between a plan and product. */
export type PlanProduct = {
  __typename?: 'PlanProduct';
  /** The plan product's ID */
  id: Scalars['Int']['output'];
  /** ID of the plan */
  planId: Scalars['Int']['output'];
  /** The product */
  product: Product;
  /** ID of the product */
  productId: Scalars['Int']['output'];
  /** What portion of the plan's products is this product? */
  ratio: Scalars['Float']['output'];
};

/** A The Farmer's Dog recipe for a plan. */
export type PlanRecipe = {
  __typename?: 'PlanRecipe';
  /** The user friendly name of the recipe */
  displayName: Scalars['String']['output'];
  /** The recipe's ID */
  id: Scalars['Int']['output'];
  /** The recipe's name */
  name: Scalars['String']['output'];
  /** What portion of this plan's food is this recipe? */
  ratio: Scalars['Float']['output'];
};

/** A record of a change to planStock or 're-base' of planStock for a plan. */
export type PlanStock = {
  __typename?: 'PlanStock';
  /** The moment in time when this record is effective. May differ from created_at because of delays in downstream dependencies (deliveries). */
  addedAt: Scalars['DateTime']['output'];
  /** The ID of the base stock record that begins this set of stock changes. Is null for records of type "base". */
  baseStockId: Maybe<Scalars['Int']['output']>;
  /** All of the associated records of type "change" that share this same base stock ID */
  changeStocks: Maybe<Array<PlanStock>>;
  /** Auto-generated timestamp of when this record was created */
  createdAt: Scalars['DateTime']['output'];
  /** The unique planStock record ID (auto-incremented) */
  id: Scalars['Int']['output'];
  /** The order ID associated with this change record. Refers to Orders table. May be null. */
  orderId: Scalars['Int']['output'];
  /** The ID for the plan associated with this record. Refers to Plans table. */
  planId: Scalars['Int']['output'];
  /** The burn down rate of packs by day. Matches the daily pack quantity recommended to customers. */
  portion: Scalars['Float']['output'];
  /** The subscription ID associated with this change record. Refers to Subscriptions table. */
  subscriptionId: Scalars['Int']['output'];
  /** The type of record ("base" or "change") */
  type: PlanStockType;
  /** Auto-generated timestamp of when this record was updated. Note: planStock records are currently never updated, so this field is redundant. */
  updatedAt: Scalars['DateTime']['output'];
  /** The starting amount of packs for a base record, or the delta of packs for a change record. */
  value: Scalars['Float']['output'];
};

export type PlanStockStateInput = {
  /** Number of days a single food pack from the most recently delivered order is lasting */
  lastingDays?: InputMaybe<Scalars['Float']['input']>;
  /** A pet's plan ID */
  planId: Scalars['Int']['input'];
  /** How many total food packs the pet has at home */
  value: Scalars['Float']['input'];
};

/** The type of plan stock record */
export enum PlanStockType {
  /** The record resets the stock to 0 to begin recording changes again */
  Base = 'base',
  /** The record is recording a change to the running stock data */
  Change = 'change',
}

/** What type of plan a plan is */
export enum PlanType {
  /** A food plan */
  Food = 'food',
}

/** A prescription diet for a pet. */
export type PrescriptionDiet = {
  __typename?: 'PrescriptionDiet';
  /** Can we serve pets that require this diet? */
  enabled: Scalars['Boolean']['output'];
  /** The prescription diet's ID */
  id: Scalars['Int']['output'];
  /** The prescription diet's name */
  name: Scalars['String']['output'];
};

/** A The Farmer's Dog Product. */
export type Product = {
  __typename?: 'Product';
  /** If this product is 'food', the number of calories it includes */
  calories: Maybe<Scalars['Float']['output']>;
  /** The product's ID */
  id: Scalars['Int']['output'];
  /** The product's name */
  name: Scalars['String']['output'];
  /** The cost of the product in cents */
  price: Scalars['Int']['output'];
  /** Name corresponding to the name of the recipe (now Product). */
  productName: Maybe<Scalars['String']['output']>;
  /** If this product is 'food', the recipe for it */
  recipe: Maybe<Recipe>;
  /** If this product is 'food', the recipe ID for it */
  recipeId: Maybe<Scalars['Int']['output']>;
  /** The product's unique SKU */
  sku: Scalars['String']['output'];
  /** Is this product enabled? */
  status: ProductStatus;
  treatSize: Maybe<TreatSize>;
  /** What type of product this is */
  type: ProductType;
  /** The volume of the product in cubic centimeters */
  volume: Scalars['Float']['output'];
  /** The weight of the product in grams */
  weight: Scalars['Float']['output'];
};

/** Serving combination for product */
export type ProductCombination = {
  __typename?: 'ProductCombination';
  /** Calories in package */
  calories: Scalars['Int']['output'];
  /** Previous combination size increment in % */
  decrement: Maybe<Scalars['Float']['output']>;
  /** Next combination size increment in % */
  increment: Maybe<Scalars['Float']['output']>;
  /** Serving portion */
  portion: Scalars['Float']['output'];
  /** Combination size identifier (package calories * portion) */
  size: Scalars['Int']['output'];
};

/** A quantity of a specific product */
export type ProductQuantity = {
  __typename?: 'ProductQuantity';
  /** The productId */
  productId: Scalars['Int']['output'];
  /** The quantity */
  quantity: Scalars['Int']['output'];
};

/** Whether a product is enabled or not */
export enum ProductStatus {
  /** This product is disabled */
  Disabled = 'disabled',
  /** This product is enabled */
  Enabled = 'enabled',
}

/** What type of product a product is */
export enum ProductType {
  /** DIY product (nutrition powder) */
  Diy = 'diy',
  /** Food product (a The Farmer's Dog recipe) */
  Food = 'food',
  /** Containers of Food */
  FoodContainer = 'food_container',
  /** Printed inserts like feeding guides */
  Leaflet = 'leaflet',
  /** Packaging material */
  Packaging = 'packaging',
  /** Supplement product like vitamins or oils */
  Supplement = 'supplement',
  /** Treat product like snacks or bones */
  Treat = 'treat',
  /** Ice for keeping the box cool during shipping */
  Undefined = 'undefined',
}

/** As view of a customer's order. */
export type ProjectedOrderView = {
  __typename?: 'ProjectedOrderView';
  /** Credits that can be used for this order payment */
  applicableCredits: Scalars['Int']['output'];
  /** The order's base cost in cents */
  baseFee: Scalars['Int']['output'];
  /** The order's billing address */
  billingAddress: UserAddressView;
  /** The order's billing address ID */
  billingAddressId: Scalars['Int']['output'];
  /** The order's discount amount in cents */
  discountTotal: Scalars['Int']['output'];
  /**
   * Earliest date we expect the order to arrive, as communicated to the customer. If calculated date is before today, then today will be returned instead.
   * @deprecated Deprecated, should use 'earliestDesiredArrivalDate' field instead
   */
  estimatedEarliestArrivalDate: Scalars['Date']['output'];
  /** The order's ID */
  id: Scalars['Int']['output'];
  /** The cost of the order's line items in cents */
  itemTotal: Scalars['Int']['output'];
  /** Metadata about the order including subscription and plan snapshots */
  metadata: OrderMetadata;
  /** The order number */
  number: Maybe<Scalars['String']['output']>;
  /** The order's payment status */
  paymentStatus: OrderPaymentStatus;
  /** The estimated date the order will be shipped */
  shipDate: Scalars['Date']['output'];
  /** The order's shipment status */
  shipmentStatus: OrderShipmentStatus;
  /** The order's shipping address */
  shippingAddress: UserAddressView;
  /** The order's shipping address ID */
  shippingAddressId: Scalars['Int']['output'];
  /** The order's shipping cost in cents */
  shippingTotal: Scalars['Int']['output'];
  /** States this order is in */
  states: Maybe<Array<OrderState>>;
  /** The order's tax amount in cents */
  taxTotal: Scalars['Int']['output'];
  /** The order's total cost in cents */
  total: Scalars['Int']['output'];
  /** The order type */
  type: OrderType;
};

export type ProjectedOrdersInput = {
  /** The number of orders to project */
  first?: InputMaybe<Scalars['Int']['input']>;
  /** The id of the subscription to project orders for */
  subscriptionId: Scalars['Int']['input'];
  /** Subscription overrides to use for projecting orders */
  subscriptionOverrides?: InputMaybe<PendingSubscriptionInputType>;
};

export type ProposedQuote = {
  /** The total on the add ons of the bill */
  addOnsTotalAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Whether the user submitted an updated address via the address modal */
  didUpdateAddress?: InputMaybe<Scalars['Boolean']['input']>;
  /** The discount amount of the bill */
  discountAmount?: InputMaybe<Scalars['Float']['input']>;
  /** The discount percentage of the bill */
  discountPercentage: Scalars['Float']['input'];
  /** The grand total of the bill */
  grandTotal: Scalars['Float']['input'];
  /** Whether the user checked out with express pay */
  isExpressPay?: InputMaybe<Scalars['Boolean']['input']>;
  /** The referrer of the user */
  referrer?: InputMaybe<Referrer>;
  /** The tax amount of the bill */
  taxAmount: Scalars['Float']['input'];
};

export type RecalculatePlansFromFeedingInfoInput = {
  /** The pets to calculate plan data for */
  pets: Array<RecalculatePlansFromFeedingInfoPetInput>;
};

export type RecalculatePlansFromFeedingInfoPetInput = {
  /** A pet ID */
  id: Scalars['Int']['input'];
  /** Is our food being mixed with other food */
  isMixingWithOtherFood: Scalars['Boolean']['input'];
  /** How many days a pack of food is lasting */
  packLastingDays: Scalars['Float']['input'];
};

export type RecalculatePlansInput = {
  /** Active state of the new plan */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** Calories in new plan */
  calories: Scalars['Int']['input'];
  /** Plan Id */
  id: Scalars['Int']['input'];
  /** Mixing ratio of new plan */
  ratio: Scalars['Float']['input'];
};

/** A The Farmer's Dog recipe. */
export type Recipe = {
  __typename?: 'Recipe';
  /** The calorie content of the recipe */
  calorieContent: Maybe<Scalars['String']['output']>;
  /** A UI displayable name for the recipe */
  displayName: Scalars['String']['output'];
  /** A summary of the recipe's full ingredients */
  fullIngredients: Scalars['String']['output'];
  /** The guaranteed analysis of the recipe */
  guaranteedAnalysis: Maybe<Scalars['String']['output']>;
  /** The recipe's ID */
  id: Scalars['Int']['output'];
  /** Whether or not a recipe is unavailable */
  isUnavailable: Maybe<Scalars['Boolean']['output']>;
  /** The number of calories per gram in the recipe */
  kcalPerGram: Scalars['Float']['output'];
  /** A summary of the recipe's main ingredients */
  mainIngredients: Scalars['String']['output'];
  /** A human-readable unique identifer for the recipe (like a slug) */
  name: Scalars['String']['output'];
  /** The product line associated with the recipe */
  productLine: Maybe<RecipeProductLine>;
  /** Recipe to replace unavailable recipe */
  replacedBy: Maybe<Scalars['String']['output']>;
  /** A summary of the recipe's supplements */
  supplements: Scalars['String']['output'];
  /** Which category of products is this a recipe for? */
  type: RecipeType;
  /** The dry matter typical analysis of the recipe */
  typicalAnalysis: Maybe<Scalars['String']['output']>;
};

/** The product line of the recipe */
export enum RecipeProductLine {
  Core = 'core',
  Lpf = 'lpf',
}

export type RecipeProductVersion = {
  __typename?: 'RecipeProductVersion';
  /** The desired product version number */
  productVersion: Scalars['Int']['output'];
  /** The recipe Id that is being versioned */
  recipeId: Scalars['Int']['output'];
};

/** The kind of recipe */
export enum RecipeType {
  /** For our main food recipes */
  Food = 'food',
  /** For treat recipes */
  Treat = 'treat',
}

export type RecommendFoodPlanInput = {
  /** The pet's activity level between 1 (low activity) and 4 (high activity) */
  activityLevel: Scalars['Int']['input'];
  /** The pet's birthdate */
  birthday: Scalars['Date']['input'];
  /** The pet's body condition between 1 (underweight) and 4 (overweight) */
  bodyCondition: Scalars['Int']['input'];
  /** List of the pet's breed IDs */
  breedIds: Array<Scalars['Int']['input']>;
  /** The percentage of this pet's calories that will come from The Farmer's Dog food */
  mixingPlanRatio: Scalars['Float']['input'];
  /** Is the pet neutered? */
  neutered: Scalars['Boolean']['input'];
  /** Ideal weight of the pet in grams */
  targetWeight?: InputMaybe<Scalars['Int']['input']>;
  /** Weight of the pet in grams */
  weight: Scalars['Int']['input'];
};

/** A recommendation for a subscription */
export type Recommendation = {
  __typename?: 'Recommendation';
  /** A cadence (in days) */
  cadence: Scalars['Int']['output'];
  /** A list of products and associated quantities */
  products: Array<ProductQuantity>;
};

/** Parameters for a recommendation request */
export type RecommendationsInput = {
  diy?: InputMaybe<DiyRecommendationInput>;
  fresh?: InputMaybe<FreshRecommendationInput>;
  treats?: InputMaybe<TreatsRecommendationInput>;
};

/** A collection of requested recommendations */
export type RecommendationsResult = {
  __typename?: 'RecommendationsResult';
  /** A DIY recommendation */
  diy: Maybe<Recommendation>;
  /** A fresh food recommendation */
  fresh: Maybe<Recommendation>;
  /** A treats recommendation */
  treats: Maybe<Recommendation>;
};

export type RecommendedCaloriesResponseType = {
  __typename?: 'RecommendedCaloriesResponseType';
  combination: Maybe<ProductCombination>;
  noRecommendationReason: Maybe<NoRecommendationReasonsType>;
};

/** A recommendation of what a food plan should look like for a pet that can be used to update the plan for that pet. */
export type RecommendedFoodPlan = {
  __typename?: 'RecommendedFoodPlan';
  /** The plan's calories */
  calories: Scalars['Float']['output'];
  /** How much of a full pack should the pet be feed each day? */
  portion: Scalars['Float']['output'];
  /** The mixing plan ratio (how much of a pet's diet is The Farmer's Dog food) */
  ratio: Scalars['Float']['output'];
  /** The plan's recipes */
  recipes: Maybe<Array<PlanRecipe>>;
};

/** A recommended quantity of a treats package for the customer’s subscription. */
export type RecommendedTreatsPackageQuantity = {
  __typename?: 'RecommendedTreatsPackageQuantity';
  /** The treat’s approximate duration days */
  approximateDays: Scalars['Int']['output'];
  /** The recommended quantity to add to the subscription. */
  quantity: Scalars['Int']['output'];
  /** The treat’s recipe id */
  recipeId: Scalars['Int']['output'];
  /** The treat’s recipe name */
  recipeName: Scalars['String']['output'];
  /** The treat’s sku */
  sku: Scalars['String']['output'];
  treatSize: TreatSize;
};

/** Daily portion of each treat per pet that we recommend to the customer. */
export type RecommendedTreatsPortion = {
  __typename?: 'RecommendedTreatsPortion';
  /** ID of the pet who this recommendation is for */
  petId: Scalars['Int']['output'];
  /** The name of the pet who this recommendation is for */
  petName: Scalars['String']['output'];
  /** Daily recommended portion */
  portion: Scalars['Float']['output'];
  /** The treat’s recipe id */
  recipeId: Scalars['Int']['output'];
  /** The treat’s recipe name */
  recipeName: Scalars['String']['output'];
};

export type ReferralStatsType = {
  __typename?: 'ReferralStatsType';
  /** The discount percentage that the user can currently grant */
  discountPercentage: Scalars['Int']['output'];
  /** Whether the CRM has set that the user is unable to give free referrals */
  freeReferralEnabled: Scalars['Boolean']['output'];
  /** The user's referral code */
  referralCode: Scalars['String']['output'];
  /** The user's referral link */
  referralLink: Scalars['String']['output'];
  /** The user's state which determines their discountPercentage */
  referralState: ReferralState;
  /** Referrals made by the user */
  referralsMade: Maybe<Array<Maybe<ReferralMade>>>;
};

export type Referrer = {
  /** The time the referrer was associated */
  referrerAssociatedAt: Scalars['String']['input'];
  /** The id of the referrer */
  referrerId: Scalars['Int']['input'];
};

export enum ReferrerType {
  Partner = 'partner',
  User = 'user',
}

export type RescheduleNextOrderInput = {
  /** More detailed reason info */
  reasonDescription: Scalars['String']['input'];
  /** Reason for reschedule */
  reasonId: RescheduleNextOrderReason;
  /** Date to schedule the next order for */
  shipDate: Scalars['Date']['input'];
  /** The subscription type to be updated */
  subscriptionType?: InputMaybe<SubscriptionType>;
  /** Whether the reschedule is a rush or a delay */
  type: RescheduleNextOrderTypes;
};

export enum RescheduleNextOrderReason {
  BillingDelay = 'billing_delay',
  BufferDelay = 'buffer_delay',
  BufferRush = 'buffer_rush',
  CalibrateDelay = 'calibrate_delay',
  CalibrateRush = 'calibrate_rush',
  DamagedPackRush = 'damaged_pack_rush',
  DiyAnotherDogRush = 'diy_another_dog_rush',
  DiyBillingDelay = 'diy_billing_delay',
  DiyDamagedPouchRush = 'diy_damaged_pouch_rush',
  DiyExtraMixRush = 'diy_extra_mix_rush',
  DiyFeedingLessDelay = 'diy_feeding_less_delay',
  DiyFeedingMoreRush = 'diy_feeding_more_rush',
  DiyLostPouchRush = 'diy_lost_pouch_rush',
  DiyMixingDelay = 'diy_mixing_delay',
  DiyMoreTimeDelay = 'diy_more_time_delay',
  DiyNotEnoughMixRush = 'diy_not_enough_mix_rush',
  DiyOtherDelay = 'diy_other_delay',
  DiyOtherRush = 'diy_other_rush',
  DiyTooMuchMixDelay = 'diy_too_much_mix_delay',
  DiyTransitioningDelay = 'diy_transitioning_delay',
  DiyTravelingDelay = 'diy_traveling_delay',
  DiyTravelingRush = 'diy_traveling_rush',
  ExtraPacksRush = 'extra_packs_rush',
  FeedingAnotherRush = 'feeding_another_rush',
  FeedingLessDelay = 'feeding_less_delay',
  FeedingMoreRush = 'feeding_more_rush',
  GracePeriod = 'grace_period',
  InternalDelay = 'internal_delay',
  InternalRush = 'internal_rush',
  LostPackRush = 'lost_pack_rush',
  MixingDelay = 'mixing_delay',
  OtherDelay = 'other_delay',
  OtherRush = 'other_rush',
  RunningLowRush = 'running_low_rush',
  TemporaryPause = 'temporary_pause',
  TooMuchFoodDelay = 'too_much_food_delay',
  TransitioningDelay = 'transitioning_delay',
  TravelingDelay = 'traveling_delay',
  TravelingRush = 'traveling_rush',
  TrialDelay = 'trial_delay',
}

export enum RescheduleNextOrderTypes {
  Delay = 'delay',
  Rush = 'rush',
}

/** A pet food restriction/allergy */
export type Restriction = {
  __typename?: 'Restriction';
  /** Restriction ID */
  id: Scalars['Int']['output'];
  /** Restriction name */
  name: Maybe<Scalars['String']['output']>;
  /** Restriction type */
  type: RestrictionType;
};

/** The type of restriction */
export enum RestrictionType {
  /** System default restriction */
  Default = 'default',
  /** User created restriction */
  User = 'user',
}

export type RootMutation = {
  __typename?: 'RootMutation';
  addOptOutUser: AddOptOutUserResultType;
  /** Change the user's password by providing their current password. */
  changePassword: MyUserView;
  /** A singular endpoint allowing a User to checkout. */
  checkout: MyUserView;
  /** Create a new pet with a default fresh plan */
  createMyPet: MyPetView;
  /** Use the user's email address and password to login */
  loginNew: Session;
  rescheduleNextOrder: MyUserView;
  setAddOnsMutation: MyUserView;
  setFirstUnlockedOrderAsSticky: Maybe<MyOrderView>;
  setMyAddOns: MyUserView;
  suspendMySubscription: MyUserView;
  /**
   *
   *     This mutation can be used to update logged in user fields and user-associated objects.
   *     It allows for partial updates: included fields will be updated, while omitted
   *     fields will be ignored.
   *
   */
  updateMe: MyUserView;
  updateMyBuffer: Maybe<MyUserView>;
  updateMyCommunicationPreferences: MyUserView;
  /** @deprecated use updateMe instead */
  updateMyPet: MyUserView;
  updateMySubscription: MyUserView;
  /** Update a customer's default payment method. */
  updatePaymentMethod: Scalars['Boolean']['output'];
  updateStock: MyUserView;
  verifyMyAddress: VerifyAddressResult;
};

export type RootMutationAddOptOutUserArgs = {
  input: AddOptOutUserInput;
};

export type RootMutationChangePasswordArgs = {
  input: ChangePasswordInput;
};

export type RootMutationCheckoutArgs = {
  input: CheckoutInput;
};

export type RootMutationCreateMyPetArgs = {
  input: CreateMyPetInput;
};

export type RootMutationLoginNewArgs = {
  input: LoginInput;
};

export type RootMutationRescheduleNextOrderArgs = {
  input: RescheduleNextOrderInput;
};

export type RootMutationSetAddOnsMutationArgs = {
  input: SetAddOnsInput;
};

export type RootMutationSetMyAddOnsArgs = {
  input: SetMyAddOnsInput;
};

export type RootMutationSuspendMySubscriptionArgs = {
  input: SuspendMySubscriptionInput;
};

export type RootMutationUpdateMeArgs = {
  input: UpdateMeInput;
};

export type RootMutationUpdateMyBufferArgs = {
  input: UpdateMyBufferInput;
};

export type RootMutationUpdateMyCommunicationPreferencesArgs = {
  input: CommunicationPreferencesInput;
};

export type RootMutationUpdateMyPetArgs = {
  input: UpdatePetInput;
  petId: Scalars['Int']['input'];
};

export type RootMutationUpdateMySubscriptionArgs = {
  input: UpdateMySubscriptionInput;
};

export type RootMutationUpdatePaymentMethodArgs = {
  input: UpdatePaymentMethodInput;
};

export type RootMutationUpdateStockArgs = {
  input: UpdateStockInput;
};

export type RootMutationVerifyMyAddressArgs = {
  input: VerifyAddressInput;
};

export type RootQuery = {
  __typename?: 'RootQuery';
  /** A list of the active feature IDs/keys */
  activeFeatures: Array<Scalars['ID']['output']>;
  /** A list of the different activity levels that can describe a pet's amount of activity */
  activityLevels: Array<ActivityLevel>;
  /** A list of enabled add-ons products */
  addOnsProducts: Array<Product>;
  /** Possible calories options for DIY */
  availableDiyCalories: Array<Scalars['Int']['output']>;
  /** A list of the pet body conditions we support */
  bodyConditions: Array<BodyCondition>;
  /** A list of the pet breeds we have data for sorted alphabetically */
  breeds: Array<Breed>;
  /** Application configuration information for client use */
  configuration: Configuration;
  /** A list of the countries we currently support */
  countries: Array<Country>;
  districtByPostalCode: District;
  /** Returns a DIY subscription quote. */
  diySubscriptionQuote: Maybe<DiySubscriptionQuote>;
  /** A list of the different eating styles that can describe how a pet eats */
  eatingStyles: Array<EatingStyle>;
  /** A list of the pet food allergies we have data for */
  foodAllergies: Array<FoodAllergy>;
  /** A list of the pet food brands we have data for sorted alphabetically */
  foodBrands: Array<FoodBrand>;
  /** A list of the different types of food that a pet can eat */
  foodTypes: Array<FoodType>;
  /** Returns referral stats for the specified user */
  getReferralStats: Maybe<ReferralStatsType>;
  /** A list of the pet health issues we have data for sorted alphabetically */
  healthIssues: Array<HealthIssue>;
  /** Your user account */
  me: MyUserView;
  /** The cutoff date for scheduling a user's first shipment. This is dynamic and changes weekly once the current date hits. */
  orderChangeCutoffDate: Scalars['DateTime']['output'];
  /** A list of different pet personality types */
  personalityTypes: Array<PersonalityType>;
  /** A list of prescription diets we have data for sorted alphabetically */
  prescriptionDiets: Array<PrescriptionDiet>;
  /** Possible product and package combinations */
  productCombinations: Array<ProductCombination>;
  /** A list of projected orders */
  projectedOrders: Maybe<Array<Maybe<ProjectedOrderView>>>;
  /** Returns recalculated plans based on the input but does not store the data in the database. */
  recalculatePlans: Maybe<MyUserView>;
  /** Predict new plan information based on current feeding data */
  recalculatePlansFromFeedingInfo: MyUserView;
  /** A list of our food recipes */
  recipes: Array<Recipe>;
  /** Recommend a food plan given pet data */
  recommendFoodPlan: RecommendedFoodPlan;
  /** A requested set of recommendations */
  recommendations: RecommendationsResult;
  /** A list of different treat usage buckets */
  treatUsageBuckets: Array<TreatUsageBucket>;
  /** An endpoint for validating whether US shipping addresses are deliverable */
  validateShippingAddress: ValidateShippingAddressView;
  /** The API version number/identifier */
  version: Scalars['ID']['output'];
};

export type RootQueryAddOnsProductsArgs = {
  ids: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  types: InputMaybe<Array<InputMaybe<ProductType>>>;
};

export type RootQueryDistrictByPostalCodeArgs = {
  postalCode: Scalars['String']['input'];
};

export type RootQueryGetReferralStatsArgs = {
  id: Scalars['Int']['input'];
};

export type RootQueryProjectedOrdersArgs = {
  input: ProjectedOrdersInput;
};

export type RootQueryRecalculatePlansArgs = {
  input: Array<InputMaybe<RecalculatePlansInput>>;
};

export type RootQueryRecalculatePlansFromFeedingInfoArgs = {
  input: RecalculatePlansFromFeedingInfoInput;
};

export type RootQueryRecommendFoodPlanArgs = {
  input: RecommendFoodPlanInput;
};

export type RootQueryRecommendationsArgs = {
  input: RecommendationsInput;
};

export type RootQueryValidateShippingAddressArgs = {
  input: ValidateShippingAddressInput;
};

/** Successful login response. */
export type Session = {
  __typename?: 'Session';
  token: Scalars['String']['output'];
};

export type SetAddOnInput = {
  /** Is this a one-time purchase (as opposed to a recurring subscription)? */
  isOneOff?: InputMaybe<Scalars['Boolean']['input']>;
  /** Product Name of the new AddOn */
  productName: Scalars['String']['input'];
  /** Size of the new AddOn */
  productSize: Scalars['String']['input'];
  /** AddOn quantity */
  quantity: Scalars['Int']['input'];
};

export type SetAddOnsInput = {
  /** Replaces the add-ons for your subscription */
  addOns: Array<SetAddOnInput>;
};

export type SetMyAddOnsInput = {
  /** Replaces the add-ons for your subscription */
  addOns: Array<AddOnInput>;
};

export enum SortDirectionEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

/** A state (like for countries) */
export type State = {
  __typename?: 'State';
  /** The state's abbreviation */
  abbr: Maybe<Scalars['String']['output']>;
  /** The state's country ID */
  countryId: Scalars['Int']['output'];
  /** The state's The Farmer's Dog ID */
  id: Scalars['Int']['output'];
  /** The state's display name */
  name: Scalars['String']['output'];
};

export enum StockStatus {
  /** Just the right amount of food in stock */
  Ok = 'ok',
  /** Too much food in stock */
  Overage = 'overage',
  /** Too little food in stock */
  Shortage = 'shortage',
  /** Will run out of food within next delivery window */
  Warning = 'warning',
}

/** An overview of a pets current calories and portion */
export type SubscriptionFeedingGuide = {
  __typename?: 'SubscriptionFeedingGuide';
  /**
   * The pets' daily amount of calories being fed
   * @deprecated Use "dailyCalories" instead
   */
  calories: Scalars['Float']['output'];
  /** The pets' daily amount of calories being fed */
  dailyCalories: Scalars['Float']['output'];
  /** Whether the pet is on a mixing plan */
  isMixing: Scalars['Boolean']['output'];
  /** ID of the pet the feeding guide is tied to */
  petId: Scalars['Int']['output'];
  /** ID of the food plan */
  planId: Scalars['Int']['output'];
  /** The pet's current portion being fed */
  portion: Scalars['Float']['output'];
  /** Current number of packs in stock for this plan */
  stockAsPacks: Maybe<Scalars['Float']['output']>;
};

export type SubscriptionProduct = {
  __typename?: 'SubscriptionProduct';
  /** SubscriptionProduct id */
  id: Scalars['Int']['output'];
  /** DEPRECATED: Use Variant SKU instead. */
  productId: Scalars['Int']['output'];
  /** Quantity of the product in this subscription */
  quantity: Scalars['Int']['output'];
  /** Replacement for deprecated Product ID as Product Identifier */
  variantSku: Scalars['String']['output'];
};

export type SubscriptionProductInput = {
  /** SubscriptionProduct id */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Product id */
  productId: Scalars['Int']['input'];
  /** Quantity of the product in this subscription */
  quantity: Scalars['Int']['input'];
  /** Variant sku, this should replace Product id (ask PLX team) */
  variantSku: Scalars['String']['input'];
};

export type SubscriptionQuote = {
  __typename?: 'SubscriptionQuote';
  pets: Maybe<Array<SubscriptionQuotePet>>;
  price: Maybe<SubscriptionQuotePricing>;
  shipment: Maybe<SubscriptionQuoteShipmentDetails>;
  /** Information about the user's subscription */
  subscription: Maybe<SubscriptionQuoteSubscription>;
};

export type SubscriptionQuotePet = {
  __typename?: 'SubscriptionQuotePet';
  id: Scalars['Int']['output'];
  price: Maybe<SubscriptionQuotePetPrice>;
  shipment: Maybe<SubscriptionQuotePetShipment>;
};

export type SubscriptionQuotePetPrice = {
  __typename?: 'SubscriptionQuotePetPrice';
  dailyBaseAmount: Maybe<Scalars['Int']['output']>;
  dailyDiscountAmount: Maybe<Scalars['Int']['output']>;
  dailyItemsTotalAmount: Maybe<Scalars['Int']['output']>;
  dailyNetAmount: Maybe<Scalars['Int']['output']>;
  dailyShippingAmount: Maybe<Scalars['Int']['output']>;
  dailyTaxAmount: Maybe<Scalars['Int']['output']>;
  dailyTotalAmount: Maybe<Scalars['Int']['output']>;
  totalBaseAmount: Maybe<Scalars['Int']['output']>;
  totalDiscountAmount: Maybe<Scalars['Int']['output']>;
  totalItemsTotalAmount: Maybe<Scalars['Int']['output']>;
  totalNetAmount: Maybe<Scalars['Int']['output']>;
  totalShippingAmount: Maybe<Scalars['Int']['output']>;
  totalTaxAmount: Maybe<Scalars['Int']['output']>;
  totalTotalAmount: Maybe<Scalars['Int']['output']>;
  weeklyBaseAmount: Maybe<Scalars['Int']['output']>;
  weeklyDiscountAmount: Maybe<Scalars['Int']['output']>;
  weeklyItemsTotalAmount: Maybe<Scalars['Int']['output']>;
  weeklyNetAmount: Maybe<Scalars['Int']['output']>;
  weeklyShippingAmount: Maybe<Scalars['Int']['output']>;
  weeklyTaxAmount: Maybe<Scalars['Int']['output']>;
  weeklyTotalAmount: Maybe<Scalars['Int']['output']>;
};

export type SubscriptionQuotePetShipment = {
  __typename?: 'SubscriptionQuotePetShipment';
  dailyCalories: Maybe<Scalars['Int']['output']>;
  dailyVolume: Maybe<Scalars['Int']['output']>;
  dailyWeight: Maybe<Scalars['Int']['output']>;
  totalCalories: Maybe<Scalars['Int']['output']>;
  totalVolume: Maybe<Scalars['Int']['output']>;
  totalWeight: Maybe<Scalars['Int']['output']>;
  weeklyCalories: Maybe<Scalars['Int']['output']>;
  weeklyVolume: Maybe<Scalars['Int']['output']>;
  weeklyWeight: Maybe<Scalars['Int']['output']>;
};

export type SubscriptionQuotePrice = {
  __typename?: 'SubscriptionQuotePrice';
  addOnsTotalAmount: Maybe<Scalars['Int']['output']>;
  baseAmount: Maybe<Scalars['Int']['output']>;
  discountAmount: Maybe<Scalars['Int']['output']>;
  discountPercentage: Maybe<Scalars['Int']['output']>;
  itemsTotalAmount: Maybe<Scalars['Int']['output']>;
  /** totalAmount - taxAmount - addOnsTotalAmount + discountAmount */
  mealsTotalAmount: Maybe<Scalars['Int']['output']>;
  netAmount: Maybe<Scalars['Int']['output']>;
  shippingAmount: Maybe<Scalars['Int']['output']>;
  taxAmount: Maybe<Scalars['Int']['output']>;
  totalAmount: Maybe<Scalars['Int']['output']>;
};

export type SubscriptionQuotePricing = {
  __typename?: 'SubscriptionQuotePricing';
  regular: Maybe<SubscriptionQuotePrice>;
  trial: Maybe<SubscriptionQuotePrice>;
};

export type SubscriptionQuoteProductType = {
  __typename?: 'SubscriptionQuoteProductType';
  /**
   * DEPRECATED: Use variantSku as the product identifier.
   * @deprecated Use variantSku as the product identifier.
   */
  productId: Scalars['Int']['output'];
  /** The product's quantity */
  quantity: Scalars['Int']['output'];
  /** Replacement for deprecated productId (ask PLX) */
  variantSku: Scalars['String']['output'];
};

export type SubscriptionQuoteShipment = {
  __typename?: 'SubscriptionQuoteShipment';
  avgDays: Maybe<Scalars['Int']['output']>;
  boxes: Maybe<Array<SubscriptionQuoteShipmentBox>>;
  defaultFrequency: Maybe<Scalars['Int']['output']>;
  fillBox: Maybe<Scalars['Boolean']['output']>;
  freeVolume: Maybe<Scalars['Int']['output']>;
  frequencies: Maybe<Array<Scalars['Int']['output']>>;
  frequency: Maybe<Scalars['Int']['output']>;
  multiBox: Maybe<Scalars['Boolean']['output']>;
  packages: Maybe<Array<SubscriptionQuoteShipmentPackage>>;
  packagingWeight: Maybe<Scalars['Int']['output']>;
  plans: Maybe<Scalars['JSON']['output']>;
  pricePerDay: Maybe<Scalars['Int']['output']>;
  pricePerWeek: Maybe<Scalars['Int']['output']>;
  shipmentFrequencyInDays: Maybe<Array<Scalars['Int']['output']>>;
  shipmentFrequencyInWeeks: Maybe<Array<Scalars['Int']['output']>>;
  totalDays: Maybe<Scalars['Int']['output']>;
  totalVolume: Maybe<Scalars['Int']['output']>;
  totalWeight: Maybe<Scalars['Int']['output']>;
};

export type SubscriptionQuoteShipmentBox = {
  __typename?: 'SubscriptionQuoteShipmentBox';
  days: Maybe<Scalars['Int']['output']>;
  packagingWeight: Maybe<Scalars['Int']['output']>;
  ref: Maybe<Scalars['String']['output']>;
  totalVolume: Maybe<Scalars['Int']['output']>;
  totalWeight: Maybe<Scalars['Int']['output']>;
};

export type SubscriptionQuoteShipmentDetails = {
  __typename?: 'SubscriptionQuoteShipmentDetails';
  regular: Maybe<SubscriptionQuoteShipment>;
  trial: Maybe<SubscriptionQuoteShipment>;
};

export type SubscriptionQuoteShipmentPackage = {
  __typename?: 'SubscriptionQuoteShipmentPackage';
  days: Maybe<Scalars['Int']['output']>;
  planId: Maybe<Scalars['Int']['output']>;
  productId: Maybe<Scalars['Int']['output']>;
  totalVolume: Maybe<Scalars['Int']['output']>;
  totalWeight: Maybe<Scalars['Int']['output']>;
  variantSku: Maybe<Scalars['Int']['output']>;
};

export type SubscriptionQuoteSubscription = {
  __typename?: 'SubscriptionQuoteSubscription';
  /** The quote the user has accepted */
  accepted: Maybe<SubscriptionQuoteSubscriptionDetails>;
  /** The quote from the recommendation engine */
  recommended: Maybe<SubscriptionQuoteSubscriptionDetails>;
};

export type SubscriptionQuoteSubscriptionDetails = {
  __typename?: 'SubscriptionQuoteSubscriptionDetails';
  /** The number of days between deliveries */
  cadence: Maybe<Scalars['Int']['output']>;
  /** A pet's daily calorie intake */
  dailyCalories: Maybe<Scalars['Int']['output']>;
  /** The subscription's products */
  products: Maybe<Array<Maybe<SubscriptionQuoteProductType>>>;
};

export enum SubscriptionStatus {
  Active = 'active',
  Pending = 'pending',
  Suspended = 'suspended',
}

export enum SubscriptionSuspensionReason {
  /** Cooking home cooked meals is too inconvenient for the customer */
  CookingInconvenient = 'cooking_inconvenient',
  /** The customer had an issue with my box or delivery */
  DeliveryIssues = 'delivery_issues',
  /** The customer's pet is not eating */
  DogNotEating = 'dog_not_eating',
  /** The customer's pet(s) are not eating the home cooked food */
  DogNotEatingHomeCooked = 'dog_not_eating_home_cooked',
  /** The customer doesn't like recurring shipments */
  DontLikeRecurring = 'dont_like_recurring',
  /** Internal/Influencer */
  Internal = 'internal',
  /** The customer needs more time on the trial food */
  NeedMoreTimeOnTrial = 'need_more_time_on_trial',
  /** The customer is no longer using our nutrient mix */
  NutrientMixNoLongerUsed = 'nutrient_mix_no_longer_used',
  /** Other suspension reason */
  Other = 'other',
  /** The food packs are inconvenient or messy */
  PacksInconvenientMessy = 'packs_inconvenient_messy',
  /** The customer's pet passed away */
  PetPassed = 'pet_passed',
  /** The customer's pet(s) require a prescription diet */
  PrescriptionDietRequired = 'prescription_diet_required',
  /** The recipe options are non-sufficient */
  RecipeOptions = 'recipe_options',
  /** The customer's pet experienced stomach or digestive issues */
  StomachDigestiveIssues = 'stomach_digestive_issues',
  /** Switching to a Fresh subscription */
  SwitchingToFresh = 'switching_to_fresh',
  /** A temporary pause with plans to continue at a later date */
  TemporaryPause = 'temporary_pause',
  /** The subscription is too expensive */
  TooExpensive = 'too_expensive',
  /** Customer has too much food */
  TooMuchFood = 'too_much_food',
  /** Customer has too much nutrient mix */
  TooMuchNutrientMix = 'too_much_nutrient_mix',
}

export enum SubscriptionType {
  Diy = 'diy',
  Fresh = 'fresh',
}

export type SubscriptionsArgumentsInput = {
  /** List of statuses of which to determine which subscriptions to retrieve */
  statuses?: InputMaybe<Array<InputMaybe<SubscriptionStatus>>>;
  /** List of types of subscriptions to retrieve */
  types?: InputMaybe<Array<InputMaybe<SubscriptionType>>>;
};

/** Information pertaining to a specific suggested frequency */
export type SuggestedFrequenciesInfoType = {
  __typename?: 'SuggestedFrequenciesInfoType';
  /** The suggested frequencies' frequency amount */
  frequency: Scalars['Int']['output'];
  /** The suggested frequencies' quote */
  quote: SubscriptionQuote;
};

export type SuggestedFrequenciesType = {
  __typename?: 'SuggestedFrequenciesType';
  /** The maximum possible suggested frequency for the customer */
  max: SuggestedFrequenciesInfoType;
  /** The minimum possible suggested frequency for the customer */
  min: Maybe<SuggestedFrequenciesInfoType>;
};

export type SuspendMySubscriptionInput = {
  /** If reason is "other", the custom suspension reason */
  otherReason?: InputMaybe<Scalars['String']['input']>;
  /** Freeform contextual suspension information */
  questionnaire?: InputMaybe<Scalars['JSON']['input']>;
  /** Reason for suspension */
  reasonType: SubscriptionSuspensionReason;
  /** The subscription type to be suspended */
  subscriptionType?: InputMaybe<SubscriptionType>;
};

/** The size of the treat, if this product is a treat */
export enum TreatSize {
  Large = 'large',
  Regular = 'regular',
}

/** Loosely describes the amount of treats a pet gets */
export type TreatUsageBucket = {
  __typename?: 'TreatUsageBucket';
  /** The treat usage bucket ID */
  id: Scalars['Int']['output'];
  /** The treat usage bucket display name */
  name: Scalars['String']['output'];
};

export type TreatsRecommendationInput = {
  productId?: InputMaybe<Scalars['Int']['input']>;
};

/** The trial start week options available to a user */
export type TrialStartWeeks = {
  __typename?: 'TrialStartWeeks';
  /** The available trial start week options */
  options: Array<Maybe<Scalars['Date']['output']>>;
  /** The time at which these options are no longer valid */
  validUntil: Scalars['DateTime']['output'];
};

export type TrialStartWeeksInput = {
  /** The type of the subscription */
  subscriptionType?: InputMaybe<SubscriptionType>;
};

export type UpdateMeInput = {
  /** The user's billing address */
  billingAddress?: InputMaybe<AddressInput>;
  /** The user's communication preferences */
  communicationPreferences?: InputMaybe<CommunicationPreferencesInput>;
  /** The Users current subscription type during signup */
  currentSignupSubscriptionType?: InputMaybe<SubscriptionType>;
  /** A stringified JSON array of subscription types that the user is eligible to sign up for */
  eligibleSignupSubscriptionTypes?: InputMaybe<Scalars['String']['input']>;
  /** The user's email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** The user's first name */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Set to true if the account is believed to be fraudulent */
  fraud?: InputMaybe<Scalars['Boolean']['input']>;
  /** User confidence in the benefits of fresh food */
  freshFoodConfidence?: InputMaybe<FreshFoodConfidenceEnum>;
  /** The user's full name */
  fullName?: InputMaybe<Scalars['String']['input']>;
  /** The user's last name */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** A flag to determine if this user can be contacted */
  noContact?: InputMaybe<Scalars['Boolean']['input']>;
  /** User pet mutation instructions */
  petMutations?: InputMaybe<PetMutationsInput>;
  /** The user's phone */
  phone?: InputMaybe<Scalars['Phone']['input']>;
  /** The user's shipping address */
  shippingAddress?: InputMaybe<AddressInput>;
  /** The user's subscription */
  subscription?: InputMaybe<UpdateMySubscriptionInput>;
};

export type UpdateMyBufferInput = {
  /** Buffer for this subscription (as number of days of food) */
  bufferDays: Scalars['Int']['input'];
};

export type UpdateMyFoodPlanInput = {
  /** Is this plan active when the subscription is active? */
  active: Scalars['Boolean']['input'];
  /** The plan's calories */
  calories: Scalars['Int']['input'];
  /** ID of the plan to update */
  id: Scalars['Int']['input'];
  /** How much of a full pack should the pet be feed each day? */
  portion: Scalars['Float']['input'];
  /** The mixing plan ratio (how much of a pet's diet is The Farmer's Dog food) */
  ratio: Scalars['Float']['input'];
  /** The recipes to update */
  recipes: Array<UpdateMyFoodPlanRecipeInput>;
};

export type UpdateMyFoodPlanRecipeInput = {
  /** ID of the recipe */
  id: Scalars['Int']['input'];
  /** What portion of this plan's food is this recipe? */
  ratio: Scalars['Float']['input'];
};

export type UpdateMySubscriptionInput = {
  /** A list of food plans to be set or update on the subscription */
  foodPlans?: InputMaybe<Array<UpdateMyFoodPlanInput>>;
  /** The frequency in which orders of the subscription will be sent out */
  frequency?: InputMaybe<Scalars['Int']['input']>;
  /** The date of when the next order will go out */
  nextDate?: InputMaybe<Scalars['Date']['input']>;
  /** A flag that represents if the next order should ignore treats */
  skipTreatsFromNextOrder?: InputMaybe<Scalars['Boolean']['input']>;
  /** New status for the subscription */
  status?: InputMaybe<SubscriptionStatus>;
  /** The products and quantities specified in the subscription */
  subscriptionProducts?: InputMaybe<Array<SubscriptionProductInput>>;
  /** If present, this field will be used to determine which subscription to update */
  type?: InputMaybe<SubscriptionType>;
};

/** The input for the updatePaymentMethod mutation. */
export type UpdatePaymentMethodInput = {
  /** The new Stripe payment method token. */
  stripeToken: Scalars['String']['input'];
  /** The authentication token to authorize the request. */
  token: Scalars['String']['input'];
};

export type UpdatePetInput = {
  /** The activity level of the pet */
  activity?: InputMaybe<Scalars['Int']['input']>;
  /** The pet's birthday */
  birthday?: InputMaybe<Scalars['Date']['input']>;
  /**
   * Describes how accurate the birthday is down to
   *         years, months, weeks or if it's the actual date
   */
  birthdayAccuracy?: InputMaybe<PetBirthdayAccuracy>;
  /** A list of breed IDs to set on the pet */
  breedIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** The body condition of the pet */
  condition?: InputMaybe<Scalars['Int']['input']>;
  /** The pet's current food */
  currentFood?: InputMaybe<CurrentFoodInput>;
  /** The pet's eating style */
  eatingStyle?: InputMaybe<Scalars['Int']['input']>;
  /** The pet's gender */
  gender?: InputMaybe<PetGender>;
  /** The ID the pet */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Names of the issues to associate with the pet */
  issueNames?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The pet's name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The nature of the pet */
  nature?: InputMaybe<Scalars['Int']['input']>;
  /** Is the pet neutered/spayed or not? */
  neutered?: InputMaybe<Scalars['Boolean']['input']>;
  /** Determines whether or not we should contact the owner of the pet */
  noContact?: InputMaybe<Scalars['Boolean']['input']>;
  /** Pet has a prescription diet that is different from what we provide */
  otherPrescriptionDiet?: InputMaybe<Scalars['String']['input']>;
  /** IDs of the prescription diets to set on the pet */
  prescriptionDietIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** The required amount of calories that the pet should be eating */
  requiredCalories?: InputMaybe<Scalars['Int']['input']>;
  /** A list of restrictions related to the pet */
  restrictionNames?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Does the pet require a special diet */
  specialDiet?: InputMaybe<Scalars['Boolean']['input']>;
  /** The pet's target weight if there is a target weight */
  targetWeight?: InputMaybe<Scalars['Int']['input']>;
  /** The quantity of treats/scraps the pet eats */
  treatsQuantity?: InputMaybe<Scalars['Int']['input']>;
  /** The pet's current weight */
  weight?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateStockInput = {
  /** Current stock for plans */
  planStocks: Array<UpdateStockPlanInput>;
};

export type UpdateStockPlanInput = {
  /** A pet's plan ID */
  planId: Scalars['Int']['input'];
  /** How much of stock is being used daily */
  portion?: InputMaybe<Scalars['Float']['input']>;
  /** How much total stock the user has at home */
  stock: Scalars['Float']['input'];
};

export type UserAddressView = {
  __typename?: 'UserAddressView';
  /** The street address */
  addressLine1: Maybe<Scalars['String']['output']>;
  /** Apartment number, unit number, etc of the address */
  addressLine2: Maybe<Scalars['String']['output']>;
  /** The city of the address */
  city: Maybe<Scalars['String']['output']>;
  /** The country of the address */
  country: Maybe<Country>;
  /** Delivery instructions for the address */
  deliveryInstructions: Maybe<Scalars['String']['output']>;
  /** The ID of the address */
  id: Scalars['Int']['output'];
  /** Is the address a residential address */
  isResidential: Maybe<Scalars['Boolean']['output']>;
  /** The state of the address */
  state: Maybe<State>;
  /** The tax rate associated to the address */
  taxRate: Maybe<Scalars['Float']['output']>;
  /** The type of the address */
  type: AddressType;
  /** The zip of the address */
  zip: Maybe<Scalars['String']['output']>;
};

export enum UserRole {
  Admin = 'admin',
  CrmAdmin = 'crm_admin',
  CrmAgent = 'crm_agent',
  CrmPending = 'crm_pending',
  CrmViewOnly = 'crm_view_only',
  Disabled = 'disabled',
  User = 'user',
}

export enum UserStatus {
  /** Checked out */
  Active = 'active',
  Disabled = 'disabled',
  /** Awaiting checkout */
  Pending = 'pending',
}

export type ValidateShippingAddressInput = {
  /** The first line of the address, commonly used for street number */
  addressLine1: Scalars['String']['input'];
  /** The second line of the address, commonly used for building division */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** The city of the address */
  city: Scalars['String']['input'];
  /** The 2-letter state abbreviation of the address */
  stateAbbreviation: Scalars['String']['input'];
  /** The 5 or 9 digit zip code of the address */
  zip: Scalars['String']['input'];
};

export type ValidateShippingAddressView = {
  __typename?: 'ValidateShippingAddressView';
  /** The first line of the address, commonly used for street number */
  addressLine1: Scalars['String']['output'];
  /** The second line of the address, commonly used for building division */
  addressLine2: Maybe<Scalars['String']['output']>;
  /** The city of the address */
  city: Scalars['String']['output'];
  /** The 2-letter state abbreviation of the address */
  stateAbbreviation: Scalars['String']['output'];
  /** The 5 or 9 digit zip code of the address */
  zip: Scalars['String']['output'];
};

export type VerifyAddressInput = {
  /** The street address */
  addressLine1: Scalars['String']['input'];
  /** Apartment number, unit number, etc of the address */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** The city of the address */
  city: Scalars['String']['input'];
  /** The state abbreviation of the address */
  stateAbbreviation: Scalars['String']['input'];
  /** The type of address */
  type: AddressType;
  /** The zip of the address */
  zip: Scalars['String']['input'];
};

export enum VerifyAddressInvalidReason {
  /** When address has no shipping rates */
  CannotShipToAddress = 'CANNOT_SHIP_TO_ADDRESS',
  /** When address belongs to state we do not ship to */
  CannotShipToState = 'CANNOT_SHIP_TO_STATE',
  /** When address is already used by another user (looks like fraud) */
  DuplicateAddress = 'DUPLICATE_ADDRESS',
  /** When address does not pass Shippo's USPS address validator */
  ShippoUspsInvalid = 'SHIPPO_USPS_INVALID',
}

export type VerifyAddressResult = {
  __typename?: 'VerifyAddressResult';
  /** The street address */
  addressLine1: Scalars['String']['output'];
  /** Apartment number, unit number, etc of the address */
  addressLine2: Maybe<Scalars['String']['output']>;
  /** The city of the address */
  city: Scalars['String']['output'];
  /** Message explanation of the invalidity of the address */
  invalidMessage: Maybe<Scalars['String']['output']>;
  /** Reason why an address is invalid */
  invalidReason: Maybe<VerifyAddressInvalidReason>;
  /** The state of the address */
  state: State;
  /** The type of the address, shipping or billing */
  type: AddressType;
  /** Is the address a verfified address */
  verified: Scalars['Boolean']['output'];
  /** The zip of the address */
  zip: Scalars['String']['output'];
};

/** Information on a referral that was given */
export type ReferralMade = {
  __typename?: 'referralMade';
  /** The discount given */
  discountGiven: Scalars['Int']['output'];
  /** The user's first name */
  firstName: Scalars['String']['output'];
  /** The user's ID */
  userId: Scalars['Int']['output'];
};

export enum ReferralState {
  DefaultDiscount = 'default_discount',
  DiscountAfterLimit = 'discount_after_limit',
  Free = 'free',
  FreeUnlimited = 'free_unlimited',
}
