/* CH#49502: Used by RET and CVR */
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { browserOnly } from '@farmersdog/utils';

import config from 'src/config';
import { reporter } from 'src/services/reporter';

declare global {
  interface Window {
    onScriptError?: (src: string) => void;
  }
}

browserOnly(window => {
  window.onScriptError = (src: string) =>
    reporter.error(`Script failed to load: src=${src}`);
});

type ScriptProps = JSX.IntrinsicElements['script'];
export function Script({ children, ...props }: ScriptProps) {
  const deferScripts = config('features.deferThirdPartyScripts');
  const [loaded, setLoaded] = useState(!deferScripts);

  useEffect(() => {
    if (loaded) {
      return;
    }

    browserOnly((window, document) => {
      if (document.readyState === 'complete') {
        setLoaded(true);
        return;
      }

      const handleLoad = () => {
        window.removeEventListener('load', handleLoad);
        setLoaded(true);
      };

      window.addEventListener('load', handleLoad);
      return () => window.removeEventListener('load', handleLoad);
    });
  }, [loaded]);

  if (!loaded) {
    return null;
  }

  return (
    <Helmet>
      <script
        {...props}
        // @ts-expect-error react helmet converts these to strings
        onError={`window.onScriptError('${props.src}')`}
      >
        {children}
      </script>
    </Helmet>
  );
}
