import { useEffect } from 'react';

import { trackRecipesPlanDetails } from '../analytics';
import { usePetNamesAndRecommendedCalories } from '../components/RecipesPage/hooks';
import { addRequestedCaloriesToPets } from '../components/RecipesPage/utils/addRequestedCaloriesToPets';
import { useQuote } from '../graphql/queries/useQuote';
import { convertToPetInputsForQuoteNonBlueprint } from '../utils/convertToPetInputForQuote/convertToPetInputForQuoteNonBlueprint';
import { getQuoteDiscountType } from '../utils/getQuoteDiscountType/getQuoteDiscountType';

import { getDiscountInput } from './useLeadState/utils/convertFormStateToLeadInput/getDiscountInput';
import { useThrowToErrorBoundary } from './useThrowToErrorBoundary';

import type { FetchLeadQuery } from '../graphql/types';
import type { PetRecipes } from '../types';

interface UseFetchQuoteArgs {
  currentPetName: string;
  recommendedPetRecipesMap: PetRecipes;
  pets: FetchLeadQuery['lead']['pets'];
  // We default to current pet's recommended plan if currentPetRecipeSelection
  // is omitted
  currentPetRecipeSelection?: string[];
  shouldTrack?: boolean;
}

export const useFetchQuote = ({
  currentPetName,
  recommendedPetRecipesMap,
  pets,
  currentPetRecipeSelection,
  shouldTrack = true,
}: UseFetchQuoteArgs) => {
  const discount = getDiscountInput();
  const { petNamesAndRecommendedCalories } =
    usePetNamesAndRecommendedCalories();

  // for recommended plan, we only need to check if the recommendation is valid
  // for custom plan, we need to check if the selection is valid as well as the recommendation
  const recipeSelectionsAreValid = currentPetRecipeSelection
    ? currentPetRecipeSelection.length > 0
    : true;

  const petsInput = recipeSelectionsAreValid
    ? convertToPetInputsForQuoteNonBlueprint({
        pets,
        currentPetRecipeSelection: currentPetRecipeSelection,
        currentPetName,
        recommendedPetRecipesMap,
      })
    : undefined;

  const petsWithRecommendedCalories =
    petsInput && petNamesAndRecommendedCalories
      ? addRequestedCaloriesToPets(petsInput, petNamesAndRecommendedCalories)
      : undefined;

  const discountType =
    (discount?.type && getQuoteDiscountType(discount.type)) || null;

  const { data, error, loading } = useQuote({
    variables: {
      pets: petsWithRecommendedCalories!,
      shippingZip: null,
      discountCode: discount?.code || null,
      discountType,
    },
    skip: !petsWithRecommendedCalories,
  });

  const throwToErrorBoundary = useThrowToErrorBoundary();

  if (error) {
    throwToErrorBoundary(error);
  }

  useEffect(() => {
    if (data && petsInput && shouldTrack) {
      trackRecipesPlanDetails({
        currentPetName,
        petsInput,
        quote: data.fetchQuote,
        isRecommendedPlan: !currentPetRecipeSelection,
      });
    }
    // We only want to track when the response changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { quote: data?.fetchQuote || null, quoteQueryState: { loading } };
};
