import { Pixel } from '../Pixel';

export const PIXEL_ID = 24687;
export const PAGE_VIEW_GID = 61568;
export const CHECKOUT_SUCCESS_GID = 61570;

export enum KatzEventName {
  PAGE_VIEW = 'homepage_visits',
  CHECKOUT_SUCCESS = 'sign_ups',
}

interface BaseAttributes extends Record<string, unknown> {
  ppt: typeof PIXEL_ID;
}

interface PageViewAttributes extends BaseAttributes {
  g: KatzEventName.PAGE_VIEW;
  gid: typeof PAGE_VIEW_GID;
}

interface CheckoutSuccessAttributes extends BaseAttributes {
  g: KatzEventName.CHECKOUT_SUCCESS;
  gid: typeof CHECKOUT_SUCCESS_GID;
}

export type KatzAttributes = PageViewAttributes | CheckoutSuccessAttributes;

export const katz = new Pixel<KatzAttributes>({
  vendorName: 'Katz',
  baseUrl: 'https://trkn.us/pixel/conv/',
  stringifyOptions: {
    delimiter: '&',
    addQueryPrefix: false,
  },
});
