import { urls } from '@/account/utils/constants';

const { FEEDING_TIPS_WEBSITE } = urls;

const portionUrlMap: Record<number, string> = {
  0.25: '1-4_pack',
  0.5: '1-2_pack',
  1: '1_pack',
  2: '2_packs',
  3: '3_packs',
};

export function formatFeedingTipsURL(portion: number): string | undefined {
  if (portionUrlMap[portion]) {
    return `${FEEDING_TIPS_WEBSITE}${portionUrlMap[portion]}`;
  }

  return undefined;
}
