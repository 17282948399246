interface Question {
  question: string;
  answer: string;
}

//TODO: These questions and answers are placeholders. Replace them with the actual FAQ items.

export const faqItems: Question[] = [
  {
    question: 'Why feed fresh?',
    answer:
      'Fresh is not dry, it’s not wet, or raw. Fresh is gently cooked just enough to get rid of dangerous pathogens while maintaining whole food integrity. We believe there’s a big difference between surviving and thriving.',
  },
  {
    question: 'Do vets approve?',
    answer:
      'Yes, vets approve and often recommend fresh food for its nutritional benefits and high-quality ingredients.',
  },
  {
    question: 'What does it cost?',
    answer:
      "The cost varies based on your dog's size, age, and nutritional needs. You can customize a plan that fits your budget.",
  },
  {
    question: 'How does my plan work?',
    answer:
      "Your plan is tailored to your dog's unique needs. Fresh food is delivered to your door on a schedule that works for you.",
  },
  {
    question: 'What if my dog doesn’t like it?',
    answer:
      "We offer a satisfaction guarantee and will work with you to find the best solution for your dog's preferences.",
  },
];
