import classNames from 'classnames';

import config from 'src/config';
import { Mask, Text, useViewport, Grid } from '@farmersdog/corgi-x';
import { WHITE } from '@farmersdog/corgi/constants';

import { useReferrer } from 'src/hooks/useReferrer';
import { getDiscountAmountCopy } from 'src/utils/referral';
import { DiscountType } from 'src/graphql/enums';
import Present from 'src/assets/icons/present.svg';

import styles from './DiscountBadge.module.css';

interface ViewProps {
  discountAmount: number;
  discountType: DiscountType;
}

function XSView({ discountAmount, discountType }: ViewProps) {
  const shouldReduceFontSize =
    discountAmount === 100 && discountType === DiscountType.percentage;

  return (
    <Grid
      className={styles.DiscountBadge}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Mask className={styles.DiscountBadgeMask} shape="circle" size="xs">
        <div className={styles.DiscountBadgeSvgContainer}>
          {/* @ts-expect-error Alt works just fine here */}
          <Present fill={WHITE} width={24} height={24} alt="Present Icon" />
        </div>
      </Mask>
      <Text
        variant="heading-12"
        color="kale-3"
        bold
        className={classNames(styles.DiscountBadgeText, {
          [styles.mobileFreeTrialText]: shouldReduceFontSize,
        })}
        id="checkout-discount-badge-discount-copy"
      >
        {getDiscountAmountCopy({ discountAmount, discountType })}
      </Text>
    </Grid>
  );
}

function LGView({ discountAmount, discountType }: ViewProps) {
  return (
    <Grid className={styles.DiscountBadge} alignItems="center">
      <Mask className={styles.DiscountBadgeMask} shape="circle" size="sm">
        <div className={styles.DiscountBadgeSvgContainer}>
          <Present
            role="img"
            fill={WHITE}
            width={32}
            height={32}
            // @ts-expect-error Alt works just fine here
            alt="Present Icon"
          />
        </div>
      </Mask>
      <span className={styles.DiscountBadgeTextContainer}>
        <Text
          className={styles.DiscountBadgeText}
          variant="heading-16"
          color="kale-3"
          bold
          as="h2"
        >
          {discountAmount === 100 && discountType === DiscountType.percentage
            ? 'Free trial'
            : 'Special offer'}
        </Text>
        <Text
          className={styles.DiscountBadgeText}
          variant="heading-16"
          color="kale-3"
          bold
          as="p"
          id="checkout-discount-badge-discount-copy"
        >
          {getDiscountAmountCopy({ discountAmount, discountType })}
        </Text>
      </span>
    </Grid>
  );
}

function DiscountBadge() {
  const { hasCalledFetch, discountAmount, discountType } = useReferrer();
  const { lg } = useViewport();

  if (!(hasCalledFetch && discountAmount > 0 && config('features.trial'))) {
    return null;
  }

  return lg ? (
    <LGView discountAmount={discountAmount} discountType={discountType} />
  ) : (
    <XSView discountAmount={discountAmount} discountType={discountType} />
  );
}

export default DiscountBadge;
