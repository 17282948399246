import eventNames from 'src/analytics/eventNames';
import track from 'src/analytics/track';

/**
 * Tracks the "Go To Signup" event.
 *
 * @param page - The page where the event is triggered.
 * @param location - The location on the page where the event is triggered.
 * @param ctaCopy - The copy text of the call-to-action that was clicked.
 */

export interface TrackGoToSignupArgs {
  page: string;
  location: string;
  ctaCopy: string;
}

export function trackGoToSignup({
  page,
  location,
  ctaCopy,
}: TrackGoToSignupArgs) {
  track(eventNames.signup_get_started, {
    page,
    ctaLocation: location,
    ctaText: ctaCopy,
  });
}
