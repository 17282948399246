import { useCallback, useEffect, useMemo, useState } from 'react';

import type { RecipeNames } from '@farmersdog/constants';
import { NodeNames } from '@farmersdog/constants';
import { ContentLoader } from '@farmersdog/corgi-x';
import { petMixingPlanRatiosState } from '@farmersdog/lead-browser-storage';
import { Logger } from '@farmersdog/logger';

import {
  trackRecipesClickBackToRecommendedPlan,
  trackRecipesClickContinueWithRecommendation,
  trackRecipesClickSelectYourOwnPlan,
} from '../../analytics';
import { getNodeNameAndPosition } from '../../blueprint/utils';
import config from '../../config';
import { useGetLead } from '../../graphql/queries';
import { useSurpriseHigherDiscountExperiment } from '../../hooks';
import { useHandleRecipesCompleted } from '../../hooks/useHandleRecipesCompleted';
import { useSetLpfProductEligibility } from '../../hooks/useSetLpfProductEligibility';
import { scroller } from '../../utils';
import { MultiRecipeModal } from '../RecipeModal/MultiRecipeModal';

import { FRESH_RECOMMENDATION_STATE_NAME } from './constants';
import {
  useClearCustomizedSelection,
  useControlFreshSelection,
  useFetchPetsRecommendedRecipes,
  usePetNamesAndRecommendedCalories,
  useRemoveUnavailableRecipes,
  useShouldLandOnCustomization,
} from './hooks';
import { useSurpriseHigherDiscountRecipesPage } from './hooks/useSurpriseHigherDiscountRecipesPage';
import styles from './RecipesPage.module.css';
import { RecipesSelection } from './RecipesSelection/RecipesSelection';
import { RecommendedPlan } from './RecommendedPlan';
import { RecommendedPlanWrapper } from './RecommendedPlanWrapper';
import { checkLpfRecipeStatus } from './utils/checkLpfRecipeStatus';
import { getPetInfo } from './utils/getPetInfo';
import { getRecommendedRecipeCopy } from './utils/getRecommendedRecipeCopy';
import { getSelectedAvailableRecipes } from './utils/getSelectedAvailableRecipes';

import type { Position } from '../../blueprint/types';
import type { FetchQuoteQuery } from '../../graphql/types';
import type {
  FormFieldsType,
  PetRecipes,
  TOSAComponentInput,
  TOSALeafNode,
} from '../../types';

export function RecipesPage(props: TOSAComponentInput<TOSALeafNode>) {
  const {
    node,
    formMethods,
    petRecipes,
    setPetRecipes,
    formSubmitRequest,
    formValidationSchema,
    useFeature,
  } = props;

  const { refetch } = usePetNamesAndRecommendedCalories();
  useEffect(() => {
    // We want to refetch recommended calories when the page is loaded so that
    // if a customer goes back into the signup flow and changes their pets'
    // details, we don't get now-stale data from the cache.
    //
    // The ideal solution here is making the pet data into inputs to the
    // `FetchPetsWithRecommendedCalories` query itself so that Apollo knows
    // when to refetch (as we do with the fetchQuote query).
    refetch();
  }, [refetch]);

  const { isRecipesPageEnabled, code } = useSurpriseHigherDiscountExperiment({
    useFeature,
  });

  useSurpriseHigherDiscountRecipesPage({
    shouldReplaceDiscount: isRecipesPageEnabled,
    code,
  });

  const { position } = getNodeNameAndPosition(node.name);
  const formValues = formMethods.getValues();
  const currentSelection =
    formValues[`${NodeNames.FreshSelection}-${position}`];

  const petName = getPetInfo({ formValues, petPosition: position }).name;

  const [isCustomizingRecipes, setIsCustomizingRecipes] = useState<
    null | boolean
  >(null);

  const {
    petRecipesQueryState,
    currentPetRecipes,
    recommendedRecipes,
    recommendedRecipesNames,
  } = useFetchPetsRecommendedRecipes({
    currentPetName: petName,
    petRecipes,
    setPetRecipes,
  });

  const formattedRecommendedRecipes = useMemo(
    () =>
      recommendedRecipes.map(recipe => ({
        name: recipe.name as RecipeNames,
        displayName: recipe.content.displayName,
        recommended: recipe.recommended,
      })),
    [recommendedRecipes]
  );

  const { isLpfRecipeEligible, isOnlyLpfRecipeRecommended } =
    checkLpfRecipeStatus(currentPetRecipes || []);

  const availableSelectedRecipesNames = getSelectedAvailableRecipes({
    currentPetRecipes,
    currentSelection,
  });

  const { defaultToCustomization } = useShouldLandOnCustomization({
    availableSelectedRecipesNames,
    recommendedRecipesNames,
    setIsCustomizingRecipes,
    isCustomizingRecipes,
  });

  const clearCustomizedSelection = useCallback(() => {
    formMethods.setValue(`${NodeNames.FreshSelection}-${position}`, []);
  }, [formMethods, position]);

  const setCustomizedSelection = useCallback(
    (recipes: string[]) => {
      formMethods.setValue(`${NodeNames.FreshSelection}-${position}`, recipes);
    },
    [formMethods, position]
  );

  useRemoveUnavailableRecipes({
    setCustomizedSelection,
    currentSelectedRecipeNames: currentSelection,
    availableSelectedRecipesNames,
  });

  const { setHasToggledSelection } = useClearCustomizedSelection({
    defaultToCustomization,
    clearCustomizedSelection,
  });

  const { freshSelectionControlledField, handleSelectionChange } =
    useControlFreshSelection({
      control: formMethods.control,
      petPosition: position,
      currentSelection: currentSelection ?? [],
      toggleSelection: () => setHasToggledSelection(true),
    });

  useEffect(() => {
    // Ensure page scrolls to top when transitioning between recipes
    scroller.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }, [isCustomizingRecipes]);

  const onRecipesCompleted = useHandleRecipesCompleted();
  const isLastPet = formValues.numPets === Number(position);

  const lead = useGetLead().data?.lead ?? null;

  const userId = lead?.corePostgresUserId
    ? String(lead.corePostgresUserId)
    : undefined;

  let lpfRecipeNames = null;
  if (currentPetRecipes) {
    lpfRecipeNames = currentPetRecipes
      .filter(recipe => recipe.content.productLine === 'lpf')
      .map(recipe => recipe.name);
  }

  useSetLpfProductEligibility({
    shouldPersistLpfEligibility: isLpfRecipeEligible,
    email: lead?.email ?? null,
    lpfRecipeNames,
  });

  const recommendedRecipeCopy = getRecommendedRecipeCopy({
    petName,
    lpfRecipeDisplayNames: currentPetRecipes
      ?.filter(({ content: { productLine } }) => productLine === 'lpf')
      .map(({ content: { displayName } }) => displayName),
    isOnlyLpfRecipeRecommended,
  });

  const handleRecommendedPlanSubmit = (
    quote: FetchQuoteQuery['fetchQuote'] | null
  ) => {
    formMethods.setValue(`${NodeNames.FreshSelection}-${position}`, []);
    petMixingPlanRatiosState.resetAllPetRatios();
    trackRecipesClickContinueWithRecommendation({
      recommendedRecipes,
    });
    onRecipesCompleted({
      lead,
      isLastPet,
      quote,
    });
  };

  const handleCustomizeRecipesSubmit = (
    quote: FetchQuoteQuery['fetchQuote'] | null
  ) => {
    formMethods.setValue(`${FRESH_RECOMMENDATION_STATE_NAME}-${position}`, []);
    petMixingPlanRatiosState.resetAllPetRatios();
    onRecipesCompleted({
      lead,
      isLastPet,
      quote,
    });
  };

  const handleReturnToRecommendedPlan = () => {
    setIsCustomizingRecipes(false);
    trackRecipesClickBackToRecommendedPlan();
  };

  const handleCustomizePlan = () => {
    setIsCustomizingRecipes(true);
    trackRecipesClickSelectYourOwnPlan();
  };

  const loading =
    isCustomizingRecipes === null ||
    petRecipesQueryState.loading ||
    !currentPetRecipes;

  const logger = useMemo(() => {
    return new Logger('tosa:frontend:RecipesPage');
  }, []);

  if (config.get('features.tosaLogging')) {
    if (!loading) {
      logger.info('recipes page loading completed');
    } else {
      logger.info('recipes page is loading', {
        isCustomizingRecipes,
        'petRecipesQueryState.loading': petRecipesQueryState.loading,
        currentPetRecipes,
      });
    }
  }

  return (
    <ContentLoader loading={loading} height="100vh">
      <MultiRecipeModal recipes={recommendedRecipes} petName={petName} />
      {isCustomizingRecipes ? (
        <RecipesSelection
          recipes={currentPetRecipes ?? []}
          returnToRecommendedPlan={handleReturnToRecommendedPlan}
          currentSelection={currentSelection ?? []}
          onSubmit={handleCustomizeRecipesSubmit}
          formSubmitting={formSubmitRequest.loading}
          freshSelectionControlledField={freshSelectionControlledField}
          handleSelectionChange={handleSelectionChange}
          petName={petName}
          formValidationSchema={formValidationSchema}
          petRecipes={petRecipes}
          formValues={formValues}
          selectionIsValid={
            hasValidSelection({ formValues, petPosition: position }) &&
            hasValidRecommendation({
              formValues,
              petRecipes,
            })
          }
          recommendationIsValid={hasValidRecommendation({
            formValues,
            petRecipes,
          })}
          useFeature={useFeature}
          userId={userId}
        />
      ) : (
        <RecommendedPlanWrapper petName={petName}>
          <RecommendedPlan
            className={styles.recommendedPlan}
            recommendedRecipes={formattedRecommendedRecipes}
            customizePlan={handleCustomizePlan}
            registerProps={formMethods.register(
              `${FRESH_RECOMMENDATION_STATE_NAME}-${position}`
            )}
            onSubmit={handleRecommendedPlanSubmit}
            formSubmitting={formSubmitRequest.loading}
            petName={petName}
            formValidationSchema={formValidationSchema}
            petRecipes={petRecipes}
            formValues={formValues}
            recommendationIsValid={hasValidRecommendation({
              formValues,
              petRecipes,
            })}
            recommendedRecipeCopy={recommendedRecipeCopy}
          />
        </RecommendedPlanWrapper>
      )}
    </ContentLoader>
  );
}

interface HasValidRecommendationArgs {
  formValues: FormFieldsType;
  petRecipes: PetRecipes;
}
export function hasValidRecommendation({
  formValues,
  petRecipes,
}: HasValidRecommendationArgs) {
  const petsNames = getPetsNames(formValues);

  for (let i = 0; i < petsNames.length; i++) {
    const recipes = petRecipes[petsNames[i]];
    const hasRecommendation = recipes && recipes.length > 0;
    if (!hasRecommendation) {
      return false;
    }
  }

  return true;
}

interface HasValidSelectionArgs {
  formValues: FormFieldsType;
  petPosition: Position;
}
export function hasValidSelection({
  formValues,
  petPosition,
}: HasValidSelectionArgs) {
  const selection = formValues[`${NodeNames.FreshSelection}-${petPosition}`];
  return Boolean(selection && selection.length > 0);
}

function getPetsNames(formValues: FormFieldsType) {
  return Object.entries(formValues)
    .filter(([key]) => key.startsWith(`${NodeNames.Name}`))
    .map(([, val]) => val) as string[];
}
