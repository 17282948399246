import { PageWidth, Picture } from '@farmersdog/corgi-x';
import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';
import { getLinkToFreshSignup } from '@farmersdog/tosa/src/utils/getLinkToSignupFlow';
import { imageCardSources } from './assets';

import styles from './ImageCard.module.css';
import { ExtendedButton } from '../ExtendedButton';

export const ImageCard = () => {
  const linkToFreshSignup = getLinkToFreshSignup(PATH_SIGNUP_ME);

  return (
    <PageWidth className={styles.wrapper}>
      <div className={styles.container}>
        <Picture
          sources={[imageCardSources.webp, imageCardSources.jpg]}
          className={styles.backgroundImage}
          alt="Boy sitting in The Farmers Dog box"
        />
        <ExtendedButton
          className={styles.cta}
          variant="bordered-white"
          type="link"
          to={linkToFreshSignup}
        >
          Get Started
        </ExtendedButton>
      </div>
    </PageWidth>
  );
};
