import config from 'src/config';

/**
 * FOR DEV USE ONLY
 * https://developers.cloudflare.com/turnstile/troubleshooting/testing/#dummy-sitekeys-and-secret-keys
 */
export enum TestKeys {
  VISIBLE_ALWAYS_PASS = '1x00000000000000000000AA',
  VISIBLE_ALWAYS_FAIL = '2x00000000000000000000AB',
  INVISIBLE_ALWAYS_PASS = '1x00000000000000000000BB',
  INVISIBLE_ALWAYS_FAIL = '2x00000000000000000000BB',
  FORCE_CHALLENGE = '3x00000000000000000000FF',
}

export enum ApplicationEnvironment {
  Dev = 'development',
  Prod = 'production',
  Staging = 'staging',
}

/**
 * Return Turnstile site key depending on environment
 */
export function getTurnstileSiteKey(): string {
  const environment = config('app.env');
  switch (environment) {
    case ApplicationEnvironment.Prod:
      return config('cloudflare.turnstile.siteKey');
    default:
      return TestKeys.INVISIBLE_ALWAYS_PASS;
  }
}
