import { Section, Text } from '@farmersdog/corgi-x';

import { betterForThemItems } from './data';

import { paths } from '@farmersdog/constants';

import styles from './BetterForThem.module.css';
import { ExtendedButton } from '../ExtendedButton';

export const BetterForThem = () => {
  return (
    <Section
      variant="card"
      className={styles.section}
      aria-label="Better for them and easier for you"
    >
      <Text
        bold
        as="h2"
        color="kale-3"
        align="center"
        bottomSpacing="lg"
        variant="heading-28"
      >
        Better for them
        <br />
        and easier for you
      </Text>
      {betterForThemItems.map(({ id, asset: Icon, title }) => (
        <div key={id} className={styles.item}>
          <Icon className={styles.icon} />
          <Text
            topSpacing="sm"
            align="center"
            color="kale-3"
            variant="heading-16"
            bold
            as="h3"
          >
            {title}
          </Text>
        </div>
      ))}

      <ExtendedButton
        type="link"
        to={paths.PATH_SIGNUP}
        variant="bordered-kale"
        className={styles.button}
      >
        Make the Switch
      </ExtendedButton>
    </Section>
  );
};
