import { createElement, forwardRef, useId } from 'react';
import classNames from 'classnames';
import {
  Text,
  Mask,
  PageWidth,
  VideoSection,
  useViewport,
} from '@farmersdog/corgi-x';
import { Vegetable } from '@farmersdog/corgi-x/icons';

import { BENEFITS_PICKY } from 'src/constants/wistiaVideoIds';

import Picture from 'src/components/Picture';

import Benefit from '../Benefit';
import BenefitCarousel from '../BenefitCarousel';
import CallToAction from '../CallToAction';

import QUOTES from './quotes';
import mainAssets from './assets/main';
import styles from './BenefitPicky.module.scss';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

const WISTIA_OPTIONS = {
  popover: true,
};

type BenefitPickyProps = {
  innerRef: React.ForwardedRef<HTMLDivElement>;
};

function BenefitPicky({ innerRef }: BenefitPickyProps) {
  const { isAccessibilityThemeOn } = useAccessibilityThemeContext();
  const { lg } = useViewport();

  const overlayId = useId();

  return (
    <div
      id="benefit-picky"
      ref={innerRef}
      className={classNames(styles.container, {
        [styles.oysterBackground]: isAccessibilityThemeOn,
      })}
    >
      <div className={styles.content}>
        <Benefit
          icon={<Vegetable height={24} baseColor="Kale3" />}
          title="Pleasing Picky Eaters"
          quote={
            <>
              This is the first time in a LONG time that Finnegan has been
              excited about dinner. We’ve gone through countless brands of
              canned food. I decided to switch… and am so glad I did. For the
              same amount of money, I have fresh food delivered that I know he
              is going to eat. Thank you for such a wonderful product!
            </>
          }
          author={
            <Text variant="heading-16" color="charcoal-3">
              — Finnegan’s mom
            </Text>
          }
          picture={
            <Mask shape="circle" size="xl">
              <Picture
                sources={mainAssets}
                alt="boosting health and wellness dog"
              />
            </Mask>
          }
        />
      </div>
      <BenefitCarousel quotes={QUOTES} />
      <PageWidth enabled={lg}>
        <VideoSection
          wistiaId={BENEFITS_PICKY}
          wistiaOptions={WISTIA_OPTIONS}
          className={styles.video}
          aria-labelledby={overlayId}
          overlayContent={
            <figure className={styles.videoQuote}>
              <Text
                as="blockquote"
                variant="heading-28"
                color="white"
                className={styles.quote}
                id={overlayId}
              >
                “He never really liked his food, he wouldn’t eat until 10
                o’clock at night.”
              </Text>
              <Text variant="heading-16" color="white" bold>
                – Malo’s mom
              </Text>
            </figure>
          }
        />
      </PageWidth>
      <div
        className={classNames({
          [styles.ctaPadding]: isAccessibilityThemeOn,
        })}
      >
        <CallToAction location="Pleasing Picky Eaters" ctaCopy="Let’s Go">
          Let’s Go
        </CallToAction>
      </div>
    </div>
  );
}

export default forwardRef((props, innerRef) =>
  createElement(BenefitPicky, {
    ...props,
    innerRef: innerRef as React.ForwardedRef<HTMLDivElement>,
  })
);
