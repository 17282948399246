import type { FreshCheckoutQuoteReturn } from './types';
import { useStatelessFreshQuote } from '../useStatelessFreshQuote';
import { useMemo } from 'react';
import { getHasSalesTax } from '../../utils/quote';
import { SubscriptionType } from '@farmersdog/lead-browser-storage';
import sumBy from 'lodash/sumBy';
import { RecommendationStrategy } from 'src/graphql/tosa.types';

function getCentsFromDollars(dollars: number): number {
  return Math.round(dollars * 100);
}

export function useFreshCheckoutQuote(): FreshCheckoutQuoteReturn {
  const { quote } = useStatelessFreshQuote();

  return useMemo(() => {
    if (!quote) {
      return {
        hasQuote: false,
        hasSalesTaxApplied: false,
        totalAmount: 0,
        hasDiscount: false,
        subtotalAmount: 0,
        taxAmount: 0,
        trialDiscountPercentage: 0,
        regularDiscountPercentage: 0,
        regularPricePerWeek: 0,
        regularDailyPrice: 0,
        totalPrice: 0,
        pets: [],
        trialOrderDaysOfFood: 0,
        regularOrderDaysOfFood: 0,
        trialOrderTreatLineItems: [],
        trialOrderTreatsTotal: 0,
        type: SubscriptionType.Fresh,
        recommendationStrategy: RecommendationStrategy.Recommend,
        householdCalories: 0,
      };
    }

    const totalPrice =
      getCentsFromDollars(quote.regularOrder.breakdown.daily.amount) *
      quote.trialOrder.planDetails.daysOfFood;
    const trialDiscountPercentage =
      quote.trialOrder.discount.discountPercentage;

    // Pet-specific pricing is based on regular orders
    const quotePets = quote.regularOrder.breakdown.daily.pets;
    const isMultiPet = quotePets.length > 1;

    const petsPrices = quotePets.map(pet => {
      const dailyPrice = getCentsFromDollars(pet.amount);

      return {
        name: pet.name,
        subtotal: isMultiPet
          ? dailyPrice * quote.trialOrder.planDetails.daysOfFood
          : totalPrice,
        dailyPrice: dailyPrice,
      };
    });

    const trialOrderTreatsTotal = sumBy(
      quote.trialOrder.breakdown.lineItems.treats,
      'discountedAmount'
    );

    const householdCalories = quote.temporaryPetPlans.reduce(
      (total, plan) => total + plan.dailyKCal,
      0
    );

    return {
      // Quote Info
      hasQuote: !!quote,
      // TODO: We need to align on communicating sales tax calculated as 0 just for the trial vs
      // not having sales tax applied at all vs sales tax not being calculated yet.
      // https://app.shortcut.com/farmersdog/story/126061
      hasSalesTaxApplied: getHasSalesTax(quote.trialOrder.tax),
      // Pricing
      totalAmount: getCentsFromDollars(quote.trialOrder.grandTotal),
      hasDiscount: trialDiscountPercentage > 0,
      subtotalAmount: getCentsFromDollars(
        quote.trialOrder.preTaxDiscountedSubtotal
      ),
      taxAmount: getCentsFromDollars(quote.trialOrder.tax ?? 0),
      trialDiscountPercentage,
      regularDiscountPercentage: quote.regularOrder.discount.discountPercentage,
      regularPricePerWeek: getCentsFromDollars(
        quote.regularOrder.breakdown.weekly.amount
      ),
      regularDailyPrice: getCentsFromDollars(
        quote.regularOrder.breakdown.daily.amount
      ),
      totalPrice,

      // Pet-specific pricing
      pets: petsPrices,
      // Shipment
      trialOrderDaysOfFood: quote.trialOrder.planDetails.daysOfFood,
      regularOrderDaysOfFood: quote.regularOrder.planDetails.daysOfFood,
      // treats
      trialOrderTreatLineItems: quote.trialOrder.breakdown.lineItems.treats,
      trialOrderTreatsTotal: getCentsFromDollars(trialOrderTreatsTotal),
      type: SubscriptionType.Fresh,
      recommendationStrategy:
        quote.recommendationStrategy ?? RecommendationStrategy.Recommend,

      // Used for surprise higher discount
      householdCalories,
    };
  }, [quote]);
}
