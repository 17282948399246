import { useCallback } from 'react';
import { useHistory } from 'react-router';

import { handleRequestTimeout } from './utils';

import type { HandleRequestTimeoutArgs } from './utils';
export function useHandleRequestTimeout() {
  const history = useHistory();

  const safelyHandleRequestTimeout = useCallback(
    (error: HandleRequestTimeoutArgs['error']) => {
      return handleRequestTimeout({
        error,
        history,
      });
    },
    [history]
  );

  return safelyHandleRequestTimeout;
}
