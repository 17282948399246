import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cookie from 'js-cookie';

import { track, events } from 'src/analytics';
import { fetchReferrer } from 'src/actions/referrer';
import { REFERRAL_COOKIE } from 'src/constants/cookie';

import { REFERRER_TYPE_PARTNER } from 'src/constants/referrer';

const Partner = props => {
  const { match, actions, location, history } = props;

  useEffect(() => {
    const fetchAndTrack = async () => {
      let response;

      const doRedirect = () => history.replace(`/${location.search}`);

      try {
        response = await actions.fetchReferrer(REFERRER_TYPE_PARTNER, {
          code: match.params.referrerCode,
        });
      } catch (err) {
        cookie.remove(REFERRAL_COOKIE);
        doRedirect();
        return;
      }

      const event = {
        category: 'All',
        label: match.params.referrerCode,
        value: response?.data.discountPercentage,
      };
      track(events.partner_inbound, event);
      doRedirect();
    };

    fetchAndTrack();
  }, [match, actions, location, history]);

  return null;
};

Partner.propTypes = {
  actions: PropTypes.shape({
    fetchReferrer: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      referrerCode: PropTypes.string,
    }).isRequired,
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  history: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchReferrer }, dispatch),
});

export default withRouter(connect(null, mapDispatchToProps)(Partner));
