import { Pixel } from '../../Pixel';

export const PIXEL_ID = 24687;

export const gid = 61569;

export enum KatzEventName {
  LEAD = 'leads_pets_page',
}

export interface KatzAttributes extends Record<string, unknown> {
  ppt: number;
  g: KatzEventName;
  gid: number;
}

export const katz = new Pixel<KatzAttributes>({
  vendorName: 'Katz',
  baseUrl: 'https://trkn.us/pixel/conv/',
  stringifyOptions: {
    delimiter: ';',
    addQueryPrefix: false,
  },
});
