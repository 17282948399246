import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { paths } from '@farmersdog/constants';
import {
  PATH_SIGNUP,
  PATH_SIGNUP_ME,
  PATH_SIGNUP_PETS,
} from '@farmersdog/constants/paths';
import { Text } from '@farmersdog/corgi-x';
import { useSetCouponCookie } from '@farmersdog/coupons';
import { signupState } from '@farmersdog/lead-browser-storage';

import { TosaLayout } from './components/layout';
import { RecipesPageNonBlueprint } from './components/RecipesPage/RecipesPageNonBlueprint';
import { Node } from './components/schema/Node';
import { SignupHeader, DynamicPageTitle } from './components/shared';
import { CalculatingScreen } from './components/shared/CalculatingScreen/CalculatingScreen';
import { ErrorModal } from './components/shared/ErrorModal';
import { SignupTreatsPage } from './components/SignupTreatsPage';
import { TosaDevTools } from './components/TosaDevTools';
import config from './config';
import { useGetLead } from './graphql/queries';
import {
  LightboxProvider,
  useRecoverSignupByUrl,
  RequestAttributesProvider,
  StaticDataFetchContextProvider,
  useReloadApp,
} from './hooks';
import {
  useFeaturePlaceholder,
  useAnonymousExperiments,
} from './hooks/experiments';
import { useDefaultDiscountExperiment } from './hooks/experiments/useDefaultDiscountExperiment';
import { useConfigureGa4 } from './hooks/useConfigureGa4';
import { useDebugTosa } from './hooks/useDebugTosa';
import { useFormNavigation } from './hooks/useFormNavigation';
import { useFormQueryUrl } from './hooks/useFormQueryUrl';
import { useFormSubmit } from './hooks/useFormSubmit';
import { useFormValidation } from './hooks/useFormValidation';
import { useHandleFormCompleted } from './hooks/useHandleFormCompleted';
import { useHandleMeCompleted } from './hooks/useHandleMeCompleted';
import { useHandlePageView } from './hooks/useHandlePageView';
import { useHandlePetsCompleted } from './hooks/useHandlePetsCompleted';
import { useHandleStepCompleted } from './hooks/useHandleStepCompleted';
import { useIdentifyLead } from './hooks/useIdentifyLead';
import { useOverrideRoute } from './hooks/useOverrideRoute';
import { useProgress } from './hooks/useProgress';
import { useRestoreLeadProgress } from './hooks/useRestoreLeadProgress';
import { useRoute } from './hooks/useRoute';
import { useSchema } from './hooks/useSchema';
import { useScrollToTop } from './hooks/useScrollToTop';
import styles from './TOSA.css';
import { FeatureName } from './utils';
import { getBlueprintVersion } from './utils/getBlueprintVersion';
import { redirectIfNodeRemoved } from './utils/redirectIfNodeRemoved';

import type { ReloadApp } from './hooks';
import type {
  OnTosaCompleted,
  OnStepCompleted,
  OnMeStepCompleted,
} from './hooks/useHandleFormCompleted';
import type { GetBlueprint } from './hooks/useSchema';
import type { FormFieldsType, PetRecipes, UseFeatureHook } from './types';

export interface TOSAProps {
  getBlueprint: GetBlueprint;
  onTosaCompleted?: OnTosaCompleted;
  onMeCompleted?: OnMeStepCompleted;
  onStepCompleted?: OnStepCompleted;
  useFeature?: UseFeatureHook;
  clearSignupUser: () => void;
}

export interface TOSAComponentProps extends TOSAProps {
  reloadApp: ReloadApp;
}

const CALCULATING_SCREEN_PATHS: string[] = [
  PATH_SIGNUP,
  PATH_SIGNUP_ME,
  PATH_SIGNUP_PETS,
];

export function TOSAComponent({
  getBlueprint,
  onMeCompleted: websiteOnMeCompleted,
  onTosaCompleted,
  onStepCompleted: websiteOnStepCompleted,
  reloadApp,
  useFeature: websiteUseFeature,
  clearSignupUser,
}: TOSAComponentProps) {
  const enableDevTools = config.get('features.tosaDevTools');
  const useFeature = websiteUseFeature ?? useFeaturePlaceholder;
  const [restoreComplete, setRestoreComplete] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState<null | string>(
    null
  );

  useConfigureGa4();
  const location = useLocation();

  const shouldRenderCalculatingScreen = !CALCULATING_SCREEN_PATHS.includes(
    location.pathname
  );

  const anonymousExperiments = useAnonymousExperiments(useFeature);
  const blueprintVersion = getBlueprintVersion();

  const { schema, registry, formSteps, setFormSteps } = useSchema({
    getBlueprint,
    blueprintVersion,
    anonymousExperiments,
  });

  const [availablePetRecipesCache, setAvailablePetRecipesCache] =
    useState<PetRecipes>({});
  const clearAvailablePetRecipesCache = () => setAvailablePetRecipesCache({});

  const onMeCompleted = useHandleMeCompleted(websiteOnMeCompleted);
  const onPetsCompleted = useHandlePetsCompleted();
  const onStepCompleted = useHandleStepCompleted(websiteOnStepCompleted);

  const urlEmail = useRecoverSignupByUrl();

  useSetCouponCookie();

  const formNavigation = useFormNavigation();
  const { current } = formNavigation;

  const { branchValidationSchema, formValidationSchema } = useFormValidation({
    current,
    root: schema?.root,
    registry,
  });

  const formMethods = useForm<FormFieldsType>({
    resolver: yupResolver(branchValidationSchema),
    defaultValues: {},
    mode: 'onChange',
  });

  useFormQueryUrl({
    formMethods,
    loaded: Boolean(schema && current && restoreComplete),
    schema,
    registry,
    setFormSteps,
    experiments: anonymousExperiments,
  });

  useRoute({
    registry,
    current,
    blueprint: schema,
    setFormSteps,
    formNavigation,
    formMethods,
    formSteps,
    experiments: anonymousExperiments,
  });

  const { getValues, reset } = formMethods;

  const { subscriptionTypes } = signupState.get();
  const productLine = subscriptionTypes.current;

  const progress = useProgress({
    schema,
    formSteps,
    formMethods,
    formNavigation,
    useFeature,
  });

  const lead = useGetLead().data?.lead ?? null;

  redirectIfNodeRemoved({
    current,
    formSteps,
    formNavigation,
    getNextIncompleteBranch: progress.getNextIncompleteBranch,
    anonymousExperiments,
    lead,
  });

  useScrollToTop({ formNavigation });
  useDebugTosa({ formMethods });

  useRestoreLeadProgress({
    formNavigation,
    urlEmail,
    formSteps,
    setFormSteps,
    blueprint: schema,
    reset,
    getNextIncompleteBranch: progress.getNextIncompleteBranch,
    getLastCompletedBranch: progress.getLastCompletedBranch,
    getFirstBlockedBranch: progress.getFirstBlockedBranch,
    handleRestoreComplete: () => setRestoreComplete(true),
    experiments: anonymousExperiments,
  });

  useOverrideRoute({
    registry,
    getValues,
    formSteps,
    getBranchComplete: progress.getBranchComplete,
    formNavigation,
  });

  const handleFormCompleted = useHandleFormCompleted({
    formSteps,
    onMeCompleted,
    onPetsCompleted,
    onTosaCompleted,
    onStepCompleted,
    experiments: anonymousExperiments,
  });

  useHandlePageView({
    current,
    formSteps,
    email: lead?.email,
  });

  const [handleSubmit, formSubmitRequest] = useFormSubmit({
    formNavigation,
    formValidationSchema,
    formSteps,
    progress,
    onFormCompleted: handleFormCompleted,
    setErrorModalMessage,
    clearAvailablePetRecipesCache,
  });

  useDefaultDiscountExperiment({ experiments: anonymousExperiments });

  if (!schema || !registry || !current) {
    return null;
  }

  return (
    <div className={styles.root}>
      <DynamicPageTitle />

      <SignupHeader
        formNavigation={formNavigation}
        formSteps={formSteps}
        registry={registry}
        getValues={getValues}
        blueprint={schema}
        setFormSteps={setFormSteps}
        reset={reset}
        useFeature={useFeature}
        experiments={anonymousExperiments}
      />

      <ErrorModal
        errorMessage={errorModalMessage}
        setErrorModalMessage={setErrorModalMessage}
        reloadApp={reloadApp}
      />

      {shouldRenderCalculatingScreen && (
        <CalculatingScreen productLine={productLine} />
      )}

      {enableDevTools && (
        <TosaDevTools
          formMethods={formMethods}
          schema={schema}
          registry={registry}
          formSteps={formSteps}
          setFormSteps={setFormSteps}
          experiments={anonymousExperiments}
          goToStep={formNavigation.goToStep}
        />
      )}

      <TosaLayout>
        <Text variant="heading-28" color="charcoal-3" as="div">
          <Node
            onSubmit={handleSubmit}
            onFormCompleted={handleFormCompleted}
            formMethods={formMethods}
            formSubmitRequest={formSubmitRequest}
            schema={schema}
            registry={registry}
            setFormSteps={setFormSteps}
            formNavigation={formNavigation}
            node={schema.root}
            formSteps={formSteps}
            progress={progress}
            formValidationSchema={formValidationSchema}
            reloadApp={reloadApp}
            useFeature={useFeature}
            experiments={anonymousExperiments}
            petRecipes={availablePetRecipesCache} //TODO: POST SC-132233 This can be removed when the Recipes Page is out of the blueprint
            setPetRecipes={setAvailablePetRecipesCache} //TODO: POST SC-132233 This can be removed when the Recipes Page is out of the blueprint
            clearAvailablePetRecipesCache={clearAvailablePetRecipesCache} //TODO: POST SC-132233 This can be removed when the Recipes Page is out of the blueprint
            setErrorModalMessage={setErrorModalMessage}
            clearSignupUser={clearSignupUser}
          />
        </Text>
      </TosaLayout>
    </div>
  );
}

export function TOSA(props: TOSAProps) {
  const [appKey, reloadApp] = useReloadApp();

  const anonymousExperiments = useAnonymousExperiments(
    props.useFeature as UseFeatureHook
  );
  const isNonBlueprintRecipesPage =
    anonymousExperiments[FeatureName.CVR_RECIPES_PAGE_NON_BLUEPRINT]
      ?.treatment === 'on';

  useIdentifyLead();

  return (
    <RequestAttributesProvider>
      <LightboxProvider>
        <Switch>
          {/* // TODO - this should look at the tosa cookie value and fetch a full lead if possible
          Then render the pet routes for the lead conditionally based on lead.pet data
          This route should only be rendered once we have established lead data is present */}
          {isNonBlueprintRecipesPage && (
            <Route
              path={`${paths.PATH_SIGNUP_RECIPES_V2}/:email?`}
              key={'Ruby'} // TODO - replace with lead pet names
            >
              <RecipesPageNonBlueprint
                useFeature={props.useFeature as UseFeatureHook}
                petName={'Ruby'} // TODO - replace with lead pet names
                isLastPet={false} // TODO - when programmatically creating routes, set this to true for the last pet
              />
            </Route>
          )}
          <Route path={`${paths.PATH_SIGNUP_TREATS}/:email?`}>
            <SignupTreatsPage useFeature={props.useFeature as UseFeatureHook} />
          </Route>
          <Route path={`${paths.PATH_SIGNUP}/:pageOrEmail?/:email?`}>
            <StaticDataFetchContextProvider key={appKey}>
              <TOSAComponent reloadApp={reloadApp} {...props} />
            </StaticDataFetchContextProvider>
          </Route>
          <Route>
            <Redirect to={paths.PATH_SIGNUP} />
          </Route>
        </Switch>
      </LightboxProvider>
    </RequestAttributesProvider>
  );
}
