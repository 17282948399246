import type { ReactElement } from 'react';
import { useState, useRef, useEffect, useCallback } from 'react';
import styles from './Marquee.module.css';

type AnimationDuration = `${number}s` | `${number}ms`;

interface MarqueeProps {
  children: ReactElement;
  duration?: AnimationDuration;
}

export const Marquee: React.FC<MarqueeProps> = ({
  children,
  duration = '10s',
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const measureRef = useRef<HTMLSpanElement>(null);

  const [copies, setCopies] = useState<number>(2);

  const recalcCopies = useCallback(() => {
    if (!containerRef.current || !measureRef.current) return;
    const containerWidth = containerRef.current.getBoundingClientRect().width;
    const textWidth = measureRef.current.getBoundingClientRect().width;
    let neededCopies = 1;
    if (textWidth > 0) {
      while (neededCopies * textWidth < containerWidth * 2) {
        neededCopies++;
      }
    }
    setCopies(Math.max(2, neededCopies));
  }, []);

  useEffect(() => {
    recalcCopies();

    const resizeObserver = new ResizeObserver(() => {
      recalcCopies();
    });
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [recalcCopies]);

  useEffect(() => {
    recalcCopies();
  }, [children, recalcCopies]);

  return (
    <div
      ref={containerRef}
      className={styles.wrapper}
      style={
        {
          '--marquee-duration': duration,
          '--copies': copies,
        } as React.CSSProperties
      }
      data-testid="marquee-wrapper"
    >
      <div className={styles.marquee}>
        {Array.from({ length: copies }).map((_, index) => (
          <span
            key={index}
            className={styles.text}
            ref={index === 0 ? measureRef : undefined}
            aria-hidden={index !== 0}
          >
            {children}
          </span>
        ))}
      </div>
    </div>
  );
};
