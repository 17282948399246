import { Text, useViewport } from '@farmersdog/corgi-x';

import FiveStars from '../FiveStars';
import CallToAction from '../CallToAction';

import VetsSlider from './VetsSlider';
import VetsWide from './VetsWide';
import styles from './Veterinarians.module.scss';
import VETS_DATA from './vetsData';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

function Veterinarians() {
  const { isAccessibilityThemeOn, KaleBackgroundWrapper } =
    useAccessibilityThemeContext();

  const viewport = useViewport();

  const VetsComponent = viewport.md ? (
    <VetsWide vetsData={VETS_DATA} />
  ) : (
    <VetsSlider vetsData={VETS_DATA} />
  );

  const color = isAccessibilityThemeOn ? 'Kale3' : 'White';
  const sectionContent = (
    <>
      <FiveStars color={color} />
      <Text
        as="h2"
        variant="heading-40"
        color={color}
        className={styles.heading}
        bold
      >
        Veterinarian Testimonials
      </Text>
      {VetsComponent}
      <CallToAction location="Veterinarian Testimonials" ctaCopy="Get Started">
        Get Started
      </CallToAction>
    </>
  );

  return isAccessibilityThemeOn ? (
    <KaleBackgroundWrapper addBackgroundPadding={false}>
      <div className={styles.accessibilityThemeContainer}>{sectionContent}</div>
    </KaleBackgroundWrapper>
  ) : (
    <div className={styles.container}>{sectionContent}</div>
  );
}

export default Veterinarians;
