import classNames from 'classnames';
import {
  PageWidth,
  Text,
  useViewport,
  ContentWidth,
} from '@farmersdog/corgi-x';

import styles from './Instagram.module.scss';
import DATA from './data';
import InstagramCard from 'src/components/InstagramCard';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

function Instagram() {
  const { lg } = useViewport();
  const headingVariant = lg ? 'heading-52' : 'heading-40';

  const { isAccessibilityThemeOn } = useAccessibilityThemeContext();

  return (
    <div
      className={classNames(styles.container, {
        [styles.accessibilityThemeContainer]: isAccessibilityThemeOn,
      })}
    >
      <PageWidth>
        <Text
          className={styles.heading}
          variant={headingVariant}
          color="kale-3"
          as="h2"
          bold
        >
          Farmer’s Dogs are social animals
        </Text>
      </PageWidth>
      <div className={styles.instagram}>
        {DATA.map(props => (
          <ContentWidth key={props.url} withGutter={lg}>
            <InstagramCard {...props} />
          </ContentWidth>
        ))}
      </div>
    </div>
  );
}

export default Instagram;
