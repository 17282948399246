import { Divider as CorgiDivider, PageWidth } from '@farmersdog/corgi-x';
import styles from './Divider.module.scss';

function Divider() {
  return (
    <div className={styles.container}>
      <PageWidth>
        <CorgiDivider style="dotted" orientation="horizontal" spacing={0} />
      </PageWidth>
    </div>
  );
}

export default Divider;
