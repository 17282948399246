import Cookie from 'js-cookie';

export const FACEBOOK_PIXEL_COOKIE = '_fbp';
export const FACEBOOK_CLICK_ID_COOKIE = '_fbc';
export const PHONE_SALES_AGENT_COOKIE = 'phone-sales-agent';
export const REFERRAL_COOKIE = 'referral';
export const SIGNUP_SHUTOFF_COOLDOWN_COOKIE = 'signup-shutoff-cooldown';
export const CORE_POSTGRES_USER_ID = 'core-postgres-user-id';
export const TOSA_DEV_TOOLS_SKIP_CALCULATING_SCREEN =
  'tosa-dev-tools-skip-calculating-screen';
export const CHANNEL_GROUPING_ATTRIBUTES = 'channel-grouping-attributes';
export const UTM_SOURCE = 'utm-source';
export const FLUENT_ID_COOKIE = 'tfd_fluent_id';

/**
 * Returns `true` if user is a phone sales agent
 */
function isPhoneSalesAgent(): boolean {
  return Cookie.get(PHONE_SALES_AGENT_COOKIE) === 'true';
}

/**
 * Returns `true` if should skip the caluclating screen with tosa dev tools
 */
function devToolsSkipCalculatingScreen(): boolean {
  return Cookie.get(TOSA_DEV_TOOLS_SKIP_CALCULATING_SCREEN) === 'true';
}

/**
 * Returns the Facebook Pixel cookie. Used for the Facebook Conversions API (via Segment)
 */
function getFacebookPixelCookie() {
  return Cookie.get(FACEBOOK_PIXEL_COOKIE);
}

/**
 * Returns the Facebook click ID cookie. Used for the Facebook Conversions API (via Segment)
 */
function getFacebookClickIdCookie() {
  return Cookie.get(FACEBOOK_CLICK_ID_COOKIE);
}

export function setSignupShutoffCooldownCookie(minutes: number) {
  const expires = new Date(new Date().getTime() + minutes * 60 * 1000);
  Cookie.set(SIGNUP_SHUTOFF_COOLDOWN_COOKIE, 'true', { expires });
}

export function isSignupShutoffCooldownComplete() {
  const signupShutoffCooldownCookie = Cookie.get(
    SIGNUP_SHUTOFF_COOLDOWN_COOKIE
  );

  return !signupShutoffCooldownCookie;
}

export function getCorePostGresUserId(): string | undefined {
  return Cookie.get(CORE_POSTGRES_USER_ID);
}

export function setCorePostGresUserId(id: string): void {
  Cookie.set(CORE_POSTGRES_USER_ID, id, { sameSite: 'Strict' });
}

export function setDevToolsSkipCalculatingScreen(value: boolean) {
  Cookie.set(TOSA_DEV_TOOLS_SKIP_CALCULATING_SCREEN, value.toString());
}

export interface ReferrerCookie {
  referrerCode?: string;
  referrerType?: string;
  referrerAssociatedAt?: Date;
  [k: string | symbol]: unknown;
}

/**
 * Returns the referrer user data coming from the referral cookie.
 */
export function getReferrerCookie(): ReferrerCookie | void {
  const cookieValue = Cookie.get(REFERRAL_COOKIE);
  if (!cookieValue) {
    return;
  }
  const parsed = JSON.parse(cookieValue) as ReferrerCookie;
  if (
    !parsed.referrerAssociatedAt ||
    !parsed.referrerType ||
    !parsed.referrerCode
  ) {
    throw new Error('Invalid cookie format');
  }
  return parsed;
}

type ChannelGroupingAttributes = {
  [key: string]: string | boolean;
};

export function setChannelGroupingAttributes(
  attribute: ChannelGroupingAttributes
) {
  const existingAttributes = getChannelGroupingAttributes();

  Cookie.set(
    CHANNEL_GROUPING_ATTRIBUTES,
    JSON.stringify({ ...existingAttributes, ...attribute }),
    { expires: 365 }
  );
}

export function getChannelGroupingAttributes(): ChannelGroupingAttributes {
  const value = Cookie.get(CHANNEL_GROUPING_ATTRIBUTES);

  if (!value) {
    return {};
  }

  return JSON.parse(value) as ChannelGroupingAttributes;
}

export function setUtmSource(attribute: string) {
  Cookie.set(UTM_SOURCE, attribute, {
    expires: 365,
  });
}

export function getUtmSource(): string | undefined {
  const value = Cookie.get(UTM_SOURCE);

  if (!value) {
    return undefined;
  }

  return value;
}

export function getFluentId() {
  return Cookie.get(FLUENT_ID_COOKIE);
}

export default {
  isPhoneSalesAgent,
  devToolsSkipCalculatingScreen,
  setDevToolsSkipCalculatingScreen,
  getFacebookPixelCookie,
  getFacebookClickIdCookie,
  getReferrerCookie,
  setSignupShutoffCooldownCookie,
  isSignupShutoffCooldownComplete,
  getCorePostGresUserId,
  setCorePostGresUserId,
  setChannelGroupingAttributes,
  getChannelGroupingAttributes,
  setUtmSource,
  getUtmSource,
  getFluentId,
};
