import { anonymousId } from '@farmersdog/lead-browser-storage';
import { Logger } from '@farmersdog/logger';
import { browserOnly } from '@farmersdog/utils';
import { useSelector } from 'react-redux';
import { ToggleTreatments, useFeature } from 'src/abTesting';
import { ACQ_HOMEPAGE_REDESIGN } from 'src/abTesting/features';
import { selectTokenId } from 'src/reducers/auth';

interface UseCheckForHomepageRedesignTreatmentMismatchProps {
  serverTreatment: ToggleTreatments | undefined;
}

export function useCheckForHomepageRedesignTreatmentMismatch({
  serverTreatment,
}: UseCheckForHomepageRedesignTreatmentMismatchProps) {
  const userId = useSelector(selectTokenId) as string | undefined;
  const { treatment: clientTreatment, isReady } = useFeature(
    ACQ_HOMEPAGE_REDESIGN
  );

  browserOnly(() => {
    const isControl = clientTreatment === ToggleTreatments.control;
    if (!isReady || isControl) return;

    if (clientTreatment !== serverTreatment) {
      const tfdAnonymousId = anonymousId.get();
      const log = new Logger(
        'website:checkForHomepageRedesignTreatmentMismatch'
      );
      log.warn('Homepage Redesign Experiment Treatment Mismatch: ', {
        clientTreatment,
        serverTreatment,
        userId,
        tfdAnonymousId,
      });
    }
  });
}
