import { FeatureName } from '../../../../../../utils';

import type { Experiments } from '../../../../../../types';

export type HigherDiscountBannerEligibilityProps = {
  discountAmount?: number;
  couponCode?: string;
  experiments: Experiments;
};

enum OnbFrequencyCapExperimentalDiscountCode {
  ONBFREQUENCYCAP60 = 'onbfrequencycap60',
  ONBFREQUENCYCAP70 = 'onbfrequencycap70',
}
const higherDiscountCodeMap: Record<
  OnbFrequencyCapExperimentalDiscountCode,
  number
> = {
  [OnbFrequencyCapExperimentalDiscountCode.ONBFREQUENCYCAP60]: 60,
  [OnbFrequencyCapExperimentalDiscountCode.ONBFREQUENCYCAP70]: 70,
};

export function isEligibleForHigherDiscountBanner({
  discountAmount,
  couponCode,
  experiments,
}: HigherDiscountBannerEligibilityProps): boolean {
  const isGlobalDiscountOn =
    experiments[FeatureName.CVR_GLOBAL_DISCOUNT_OVERRIDE]?.treatment === 'on';
  return (
    !isGlobalDiscountOn &&
    !!couponCode &&
    couponCode in higherDiscountCodeMap &&
    discountAmount ===
      higherDiscountCodeMap[
        couponCode as OnbFrequencyCapExperimentalDiscountCode
      ]
  );
}
