import { PageWidth, Text, Grid, Link, Picture } from '@farmersdog/corgi-x';
import config from 'src/config';
import classNames from 'classnames';

import { Quote } from '../Quote';

import vetsKnowBest from '../../assets/vets-know-best.png';
import TripleUnderline from '../../assets/triple-underline.svg';
import CheckMark from '../../assets/checkmark.svg';
import FreshFood from '../../assets/fresh-food.svg';
import Stethoscope from '../../assets/vets-know-best-stethoscope.svg';
import APOPLogo from '../../assets/apop-logo.png';
import WSAVALogo from '../../assets/wsava-logo.png';

import styles from './VetsKnowBest.module.css';

const QUOTE_TEXT = (
  <>
    No animal’s digestive system is designed to eat high-processed food every
    meal of their lives. The Farmer’s Dog makes it easy to feed something
    <span className={styles.underlinedSubject}>
      {' '}
      better.
      <TripleUnderline className={styles.underline} />
    </span>
  </>
);

interface Certification {
  icon: JSX.Element;
  text: string;
}

const CERTIFICATIONS: Certification[] = [
  {
    icon: <Stethoscope />,
    text: 'On-staff veterinarians and board-certified nutritionists',
  },
  {
    icon: <FreshFood />,
    text: 'AAFCO-certified complete and balanced nutrition',
  },
  {
    icon: <CheckMark />,
    text: 'Made and tested in FDA-compliant facilities',
  },
];

export const VetsKnowBest = () => {
  return (
    <PageWidth>
      <div>
        {/* TODO: replace this placeholder image with video once we have it. */}
        <img
          src={vetsKnowBest}
          className={styles.placeholderImage}
          alt="Vets Know Best"
        />
      </div>
      <div className={styles.testimonialContainer}>
        <Text variant="heading-28" color="kale-3" bold>
          Vets know best
        </Text>
        <Quote
          className={styles.quote}
          text={QUOTE_TEXT}
          author="Dr. Alex Schechter"
          doubleQuotesOnTop
        />
      </div>
      {CERTIFICATIONS.map(({ icon, text }, index) => (
        <Grid
          key={`${text} - ${index}`}
          className={classNames(styles.certification, {
            [styles.withBottomBorder]: index < CERTIFICATIONS.length - 1,
          })}
          flexDirection="row"
        >
          <div className={styles.iconContainer}>{icon}</div>
          <Text
            as="div"
            variant="heading-16"
            color="kale-3"
            className={styles.certificationLabel}
          >
            {text}
          </Text>
        </Grid>
      ))}
      <Grid
        className={styles.associationLogos}
        justifyContent="center"
        gap="sm"
      >
        <Picture
          sources={[WSAVALogo]}
          alt="World Small Animal Veterinary Association Logo"
        />
        <Picture
          sources={[APOPLogo]}
          alt="Association for Pet Obesity Prevention Logo"
        />
      </Grid>
      <div className={styles.vetProfessional}>
        <Text as="div" align="center">
          Are you a vet professional?
        </Text>
        <Text as="div" align="center">
          Visit our{' '}
          <Link
            to={`${config('app.vetPortalUrl')}`}
            className={styles.vetPortalLink}
          >
            Vet Team Portal
          </Link>
        </Text>
      </div>
    </PageWidth>
  );
};
