import { useState, useEffect } from 'react';

import { getDiscountTypeAndAmount, isReferrerValid } from './utils/discount';
import type { ReferrerResponse } from './utils/getCoupon';
import { getCoupon } from './utils/getCoupon';
import * as couponCookie from './utils/couponCookie';
import type {
  DiscountType,
  DiscountTypeAndAmount,
  ReferrerType,
} from './types';

export const promises = new Set<Promise<void>>();
export const responses = new Map<string, ReferrerResponse>();
export const errors = new Map<string, Error>();

interface UseCouponReturn {
  /* Whether the referrer is valid */
  isValid: boolean;
  /* The discount amount - Falls back to the provided default if no coupon is found */
  discountAmount: number;
  /* The discount type - Falls back to the provided default if no coupon is found */
  discountType: DiscountType;
  /* The coupon code */
  couponCode?: string;
  /* Whether the coupon is loading */
  loading: boolean;
  /* Any errors return from fetching a coupon */
  error?: Error;
  /* The referrer type */
  referrerType: ReferrerType | undefined;
  /* The coupon response */
  coupon?: ReferrerResponse;
}

interface UseCouponArgs {
  apiUrl: string;
  defaultDiscount: DiscountTypeAndAmount;
}

/**
 * Return the current referrer
 */
export function useCoupon({
  apiUrl,
  defaultDiscount,
}: UseCouponArgs): UseCouponReturn {
  const [coupon, setCoupon] = useState<ReferrerResponse>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error>();
  const cookie = couponCookie.get();
  const cookieReferrerCode = cookie.referrerCode;
  const cookieReferrerType = cookie.referrerType;

  useEffect(() => {
    if (!cookieReferrerCode || !cookieReferrerType) {
      setLoading(false);
      return;
    }

    const get = async () => {
      setLoading(true);
      await Promise.allSettled(Array.from(promises));

      if (responses.has(cookieReferrerCode)) {
        setCoupon(responses.get(cookieReferrerCode));
        setLoading(false);
        return;
      }

      if (errors.has(cookieReferrerCode)) {
        setError(errors.get(cookieReferrerCode));
        setLoading(false);
        return;
      }

      try {
        const nextCoupon = await getCoupon({
          apiUrl,
          coupon: {
            code: cookieReferrerCode,
            type: cookieReferrerType,
          },
        });

        responses.set(cookieReferrerCode, nextCoupon);

        if (isReferrerValid(nextCoupon)) {
          setCoupon(nextCoupon);
        } else {
          couponCookie.remove();
        }
      } catch (caughtError: unknown) {
        const nextError =
          caughtError instanceof Error
            ? caughtError
            : new Error('Error fetching coupon');

        errors.set(cookieReferrerCode, nextError);
        setError(nextError);
        couponCookie.remove();
      } finally {
        setLoading(false);
      }
    };

    const promise = get();
    promises.add(promise);
    // Warning: Cookie based values don't change without a rerender of the consuming component
  }, [apiUrl, cookieReferrerCode, cookieReferrerType]);

  const isValid = isReferrerValid(coupon);

  const { discountAmount, discountType } = getDiscountTypeAndAmount({
    coupon,
    defaultDiscount,
  });

  return {
    loading,
    error,
    isValid,
    discountType,
    discountAmount,
    couponCode: cookieReferrerCode,
    referrerType: cookieReferrerType,
    coupon,
  };
}
