import { useRouteMatch, useParams } from 'react-router-dom';

import { paths } from '@farmersdog/constants';

import { Enable } from './components/Enable';
import { useBlueprint } from './graphql/queries';
import {
  useAnonymousExperiments,
  AnonymousExperimentsContextProvider,
} from './hooks';
import { TOSA } from './TOSA';

import type {
  OnTosaCompleted,
  OnStepCompleted,
  OnMeStepCompleted,
} from './hooks';
import type { GetSignupParamsArgs } from './hooks/useSignupParams';
import type { UseFeatureHook } from './types';

export interface PartialTosaProps {
  onTosaCompleted?: OnTosaCompleted;
  onMeCompleted?: OnMeStepCompleted;
  onStepCompleted?: OnStepCompleted;
  useFeature: UseFeatureHook;
  clearSignupUser: () => void;
}

export function PartialTosa({
  onTosaCompleted,
  onMeCompleted,
  onStepCompleted,
  useFeature,
  clearSignupUser,
}: PartialTosaProps) {
  const getBlueprint = useBlueprint();

  const isEnablePage = useRouteMatch(paths.PATH_SIGNUP_PRODUCT_ENABLE);
  const params = useParams<GetSignupParamsArgs>();

  const experiments = useAnonymousExperiments(useFeature);

  if (isEnablePage && params.enablementId) {
    return <Enable id={params.enablementId} />;
  }

  return (
    <AnonymousExperimentsContextProvider experiments={experiments}>
      <TOSA
        getBlueprint={getBlueprint}
        onTosaCompleted={onTosaCompleted}
        onMeCompleted={onMeCompleted}
        onStepCompleted={onStepCompleted}
        useFeature={useFeature}
        clearSignupUser={clearSignupUser}
      />
    </AnonymousExperimentsContextProvider>
  );
}
