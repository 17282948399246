import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { paths } from '@farmersdog/constants';

import { useLeadState, useRecoverSignupByUrl } from '../../hooks';
import { useSetLpfProductEligibility } from '../../hooks/useSetLpfProductEligibility';
import { useThrowToErrorBoundary } from '../../hooks/useThrowToErrorBoundary';

export function EnableLpf() {
  const history = useHistory();
  const urlEmail = useRecoverSignupByUrl();

  const { createLead, createLeadState } = useLeadState();

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const throwToErrorBoundary = useThrowToErrorBoundary();

  useEffect(() => {
    const authorize = async () => {
      try {
        const result = await createLead({
          email: urlEmail as string,
        });

        setIsAuthenticated(result.isAuthenticated);
      } catch (error) {
        throwToErrorBoundary(error);
      }
    };
    if (urlEmail && !createLeadState.called) {
      void authorize();
    }
  }, [urlEmail, throwToErrorBoundary, createLead, createLeadState.called]);

  const setLpfProductEligibility = useSetLpfProductEligibility({
    // only update eligibility if an auth token is obtained
    shouldPersistLpfEligibility: isAuthenticated,
    email: urlEmail,
  });

  useEffect(() => {
    if (
      !urlEmail ||
      (setLpfProductEligibility.called && !setLpfProductEligibility.loading) // redirect to signup after the update request has completed, regardless of whether it fails or succeeds
    ) {
      history.push(paths.PATH_SIGNUP);
    }
  }, [
    setLpfProductEligibility.called,
    setLpfProductEligibility.loading,
    history,
    urlEmail,
  ]);

  return null;
}
