import { Button, Text } from '@farmersdog/corgi-x';
import styles from './Navigation.module.scss';
import config from 'src/config';

export const VetLinkCtaCard = () => {
  return (
    <div>
      <div className={styles.vetLinkCtaCard}>
        <Text variant="heading-16" bold bottomSpacing="sm" color="kale-3">
          Are you a veterninary professional?
        </Text>
        <Text variant="heading-12" bottomSpacing="md" color="charcoal-3">
          Register for our Vet Team Portal to access our nutrition guide and get
          special discounts.
        </Text>
        <Button
          variant="secondary-mini"
          type="anchor"
          href={config('app.vetPortalUrl')}
          target="_blank"
          rel="noopener"
          className={styles.vetLinkCtaCardButton}
        >
          Learn More
        </Button>
      </div>
    </div>
  );
};
