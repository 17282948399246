import { useEffect, useRef } from 'react';

import { NodeNames } from '@farmersdog/constants';

import { getNodeNameAndPosition } from '../../../blueprint/utils';
import { useThrowToErrorBoundary } from '../../../hooks/useThrowToErrorBoundary';
import { getCompletedData, scrollOncePerSession } from '../../../utils';
import { isNotNullUndefinedOrEmptyString } from '../../../utils/isNotNullUndefinedOrEmptyString';
import { BranchNode } from '../../schema/BranchNode';
import { SubmitButton } from '../../shared';

import { usePetWeightValidation } from './hooks/usePetWeightValidation';

import type { BranchNode as BranchNodeType } from '../../../blueprint/types';
import type { TOSAComponentInput } from '../../../types';

export function PetWeightForm(props: TOSAComponentInput<BranchNodeType>) {
  const formRef = useRef<HTMLFormElement | null>(null);
  const educationRef = useRef<HTMLDivElement>(null);

  const { formMethods, formSubmitRequest, progress, useFeature, node } = props;
  const { position } = getNodeNameAndPosition(props.node.name);
  const weightField = `${NodeNames.Weight}-${position}` as const;
  const targetWeightField = `${NodeNames.TargetWeight}-${position}` as const;
  const breedsField = `${NodeNames.Breeds}-${position}` as const;
  const throwToErrorBoundary = useThrowToErrorBoundary();

  const { validateWeight, handleWeightValidationError } =
    usePetWeightValidation({
      weightField,
      targetWeightField,
      setError: formMethods.setError,
      useFeature,
    });

  const onSubmit = formMethods.handleSubmit(async (formData, e) => {
    const data = getCompletedData({
      data: formData,
      completedLeafNodes: progress.getCompletedLeafNodes(),
    });
    const weight = data[weightField];
    const targetWeight = data[targetWeightField];
    const breeds = data[breedsField];
    if (!breeds || !isNotNullUndefinedOrEmptyString(weight)) {
      return throwToErrorBoundary(new Error('Breeds and weight are required'));
    }

    const { isValid, weightError, targetWeightError } = await validateWeight({
      breeds,
      weight,
      targetWeight,
    });

    if (!isValid) {
      return handleWeightValidationError({
        weightError,
        targetWeightError,
      });
    }

    if (props.onSubmit) {
      await props.onSubmit(data, e);
    }
  });

  const shouldScrollToEducationBox = false;

  useEffect(() => {
    if (shouldScrollToEducationBox) {
      scrollOncePerSession({ ref: educationRef, id: node.name });
    }
  }, [shouldScrollToEducationBox, node.name]);

  return (
    <form id={props.node.name} onSubmit={onSubmit} ref={formRef}>
      <BranchNode {...props} />
      <SubmitButton loading={formSubmitRequest.loading} {...props} />
    </form>
  );
}
