import type { FreshRecipeRecommendation } from '../../../graphql/types';

interface LpfRecipeStatus {
  isLpfRecipeEligible: boolean;
  isLpfRecipeRecommended: boolean;
  isOnlyLpfRecipeRecommended: boolean;
}

export function checkLpfRecipeStatus(
  recipes: FreshRecipeRecommendation[]
): LpfRecipeStatus {
  let isLpfRecipeEligible = false;
  let isLpfRecipeRecommended = false;
  let otherRecipesThanLpfRecommended = false;

  recipes.forEach(recipe => {
    if (recipe.content.productLine === 'lpf') {
      isLpfRecipeEligible = true;
      if (recipe.recommended) {
        isLpfRecipeRecommended = true;
      }
    } else if (recipe.recommended) {
      otherRecipesThanLpfRecommended = true;
    }
  });

  return {
    isLpfRecipeEligible,
    isLpfRecipeRecommended,
    isOnlyLpfRecipeRecommended:
      isLpfRecipeRecommended && !otherRecipesThanLpfRecommended,
  };
}
