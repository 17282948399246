import { Text, PageWidth } from '@farmersdog/corgi-x';

import styles from './Benefit.module.css';

interface BenefitProps {
  icon: React.ReactNode;
  title: string;
  quote: React.ReactNode;
  author?: React.ReactNode;
  picture?: React.ReactNode;
}

function Benefit({ icon, title, quote, author, picture }: BenefitProps) {
  return (
    <div className={styles.container}>
      <PageWidth>
        <figure className={styles.content}>
          <div className={styles.icon}>{icon}</div>
          <Text
            variant="heading-28"
            color="kale-3"
            bold
            as="figcaption"
            className={styles.title}
          >
            {title}
          </Text>
          <Text
            as="blockquote"
            color="charcoal-3"
            variant="article-20"
            className={styles.quote}
          >
            “{quote}”
          </Text>
          {author}
        </figure>
      </PageWidth>
      {picture && <div className={styles.picture}>{picture}</div>}
    </div>
  );
}

export default Benefit;
