import classNames from 'classnames';
import { ContentWidth } from '@farmersdog/corgi';
import { Button, PageWidth } from '@farmersdog/corgi-x';
import { TrackingEvent, CtaTracker } from '@farmersdog/utils';
import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';

import { useCorgiViewport } from 'src/screen';
import { trackGoToSignup } from 'src/analytics';
import Picture, { LoadingOption } from 'src/components/Picture';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

import styles from './DentalDiseaseSection.module.scss';

import { Content } from '../Content';

import { sections, images } from '../../data';

const TRACKING_DATA = {
  page: 'Why Fresh?',
  location: 'The Dog Years Are Over',
  ctaCopy: sections.dentalDisease.cta,
};

export interface DentalDiseaseProps {
  /** Optional class name */
  className?: string;
}

export function DentalDiseaseSection({ className }: DentalDiseaseProps) {
  const { accessibilityThemeStyles } = useAccessibilityThemeContext();

  return (
    <PageWidth
      className={classNames(
        className,
        styles.container,
        useCorgiViewport({
          xs: styles.xs,
          lg: styles.lg,
        })
      )}
    >
      <ContentWidth fr={2} alignSelf="start" withPadding>
        <Content className={styles.content}>
          {sections.dentalDisease.content}
        </Content>
        <CtaTracker
          event={TrackingEvent.goToSignup}
          trackingFunction={trackGoToSignup}
          trackingData={TRACKING_DATA}
        >
          <Button
            type="link"
            variant="primary"
            to={PATH_SIGNUP_ME}
            className={classNames([
              styles.cta,
              accessibilityThemeStyles.responsiveCta,
            ])}
          >
            {sections.dentalDisease.cta}
          </Button>
        </CtaTracker>
      </ContentWidth>
      <ContentWidth fr={1} alignSelf="end">
        <Picture
          className={styles.image}
          loading={LoadingOption.lazy}
          sources={images.dentalDiseaseGraph.sources}
          alt={images.dentalDiseaseGraph.alt}
        />
      </ContentWidth>
    </PageWidth>
  );
}
