import {
  PATH_HOME,
  PATH_SIGNUP_TREATS,
  PATH_WEBSITE_HOMEPAGE,
} from '@farmersdog/constants/paths';
import { PreviousLinkNavigationLabel } from '@farmersdog/tosa/src/hooks/usePreviousLinkNavigation';

import type { Step } from './steps';

interface GetHomeLinkArgs {
  isSuccessPage: boolean;
  shouldShowTreatsPage: boolean;
  lastCompletedStep: Step | undefined;
}
export function getHomeLink({
  isSuccessPage,
  shouldShowTreatsPage,
  lastCompletedStep,
}: GetHomeLinkArgs): string {
  if (isSuccessPage) {
    return PATH_WEBSITE_HOMEPAGE;
  }

  if (shouldShowTreatsPage) {
    return PATH_SIGNUP_TREATS;
  }

  return lastCompletedStep?.path || PATH_HOME;
}

interface GetHomeLinkAccessibleTextArgs {
  homeLink: string;
}
export function getHomeLinkAccessibleText({
  homeLink,
}: GetHomeLinkAccessibleTextArgs): string {
  return homeLink === PATH_HOME || homeLink === PATH_WEBSITE_HOMEPAGE
    ? PreviousLinkNavigationLabel.homePage
    : PreviousLinkNavigationLabel.previousSignupPage;
}
