import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { track } from '@farmersdog/analytics';
import {
  PATH_HOME,
  PATH_SIGNUP_ME,
  PATH_SIGNUP_RECIPES,
  PATH_SIGNUP_CHECKOUT,
} from '@farmersdog/constants/paths';
import { mountTikTokEvent, TIKTOK_PAGE_VIEW_EVENT } from '@farmersdog/pixels';

import { segmentTrackEvents } from '../../../analytics';

export const tikTokPageViewRoutes = [
  PATH_HOME,
  PATH_SIGNUP_ME,
  PATH_SIGNUP_RECIPES,
  PATH_SIGNUP_CHECKOUT,
];

export interface UseMountTikTokPageViewArgs {
  email?: string;
  isFirstPetsPage: boolean;
}

export function useMountTikTokPageView({
  email,
  isFirstPetsPage,
}: UseMountTikTokPageViewArgs) {
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (tikTokPageViewRoutes.includes(pathname) || isFirstPetsPage) {
      const eventId = uuidv4();

      void mountTikTokEvent(TIKTOK_PAGE_VIEW_EVENT, {
        email,
        event_id: eventId,
      });
      // call segement track with a special event to deduplicate tiktok events in S2S integration
      track(segmentTrackEvents.tiktok_page_view, {
        email,
        eventId,
      });
    }

    // We only want to track pathname/routing variable changes
    // TODO: The only other dependency is email, not sure why we wouldn't want
    // to track changes to that as well?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, isFirstPetsPage]);
}
