import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { PATH_SIGNUP_RECIPES_V2 } from '@farmersdog/constants/paths';
import { useToast } from '@farmersdog/corgi';
import { ContentLoader } from '@farmersdog/corgi-x';
import { petMixingPlanRatiosState } from '@farmersdog/lead-browser-storage';

import {
  trackRecipesClickBackToRecommendedPlan,
  trackRecipesClickContinueWithRecommendation,
  trackRecipesClickSelectYourOwnPlan,
} from '../../analytics';
import { useGetLead } from '../../graphql/queries';
import {
  useLeadState,
  useSurpriseHigherDiscountExperiment,
  useThrowToErrorBoundary,
} from '../../hooks';
import { useHandleRecipesCompleted } from '../../hooks/useHandleRecipesCompleted';
import { useSetLpfProductEligibility } from '../../hooks/useSetLpfProductEligibility';
import { scroller } from '../../utils';
import { TosaLayout } from '../layout';
import { MultiRecipeModal } from '../RecipeModal/MultiRecipeModal';
import { SignUpHeaderNonTosaPage } from '../shared/SignUpHeaderNonTosaPage';

import { useFetchPetsRecommendedRecipesV2 } from './hooks';
import { useSurpriseHigherDiscountRecipesPage } from './hooks/useSurpriseHigherDiscountRecipesPage';
import styles from './RecipesPage.module.css';
import { MAX_SELECTED_RECIPES } from './RecipesSelection/RecipesSelection';
import { RecipesSelectionV2 } from './RecipesSelection/RecipesSelectionV2';
import { RecommendedPlanV2 } from './RecommendedPlan/RecommendedPlanV2';
import { checkLpfRecipeStatus } from './utils/checkLpfRecipeStatus';
import { getSelectedAvailableRecipes } from './utils/getSelectedAvailableRecipes';

import type { FetchQuoteQuery } from '../../graphql/types';
import type { UseFeatureHook } from '../../types';
import type { ChangeEvent } from 'react';

export interface RecipesPageNonBlueprintArgs {
  useFeature: UseFeatureHook;
  petName: string;
  isLastPet: boolean;
}

// TODO - put a place holder pet name on here from TOSA props
export function RecipesPageNonBlueprint({
  useFeature,
  petName,
  isLastPet,
}: RecipesPageNonBlueprintArgs) {
  const { isRecipesPageEnabled, code } = useSurpriseHigherDiscountExperiment({
    useFeature,
  });

  useSurpriseHigherDiscountRecipesPage({
    shouldReplaceDiscount: isRecipesPageEnabled,
    code,
  });

  const [isCustomizingRecipes, setIsCustomizingRecipes] = useState<
    null | boolean
  >(null);
  const [currentPetRecipeSelection, setCurrentPetRecipeSelection] = useState<
    string[] | undefined
  >(undefined);

  const { getLead, getLeadState } = useLeadState();

  const lead = useGetLead().data?.lead ?? null;

  // const updateLeadRecipeSelection = useCallback(
  //   async (selection: LeadSelectionInput) => {
  //     try {
  //       await writeLead({
  //         lead: {
  //           blueprintVersion: '1.0.0',
  //           selection,
  //         },
  //       });
  //     } catch (error) {
  //       return throwToErrorBoundary(error);
  //     }
  //   },
  //   [writeLead, throwToErrorBoundary]
  // );

  const throwToErrorBoundary = useThrowToErrorBoundary();
  const onRecipesCompleted = useHandleRecipesCompleted();
  const dispatchToast = useToast();

  const { recommendedPetRecipesMap } = useFetchPetsRecommendedRecipesV2();
  const currentPetRecipes = recommendedPetRecipesMap?.[petName];
  const recommendedRecipes = useMemo(
    () => (currentPetRecipes ?? []).filter(recipe => recipe.recommended),
    [currentPetRecipes]
  );

  // ! TODO - remove this in SC-132424; lead should be passed to component from routing
  // so we will not need to explicitly fetch lead here; page will never render without lead.
  useEffect(() => {
    // should only be run for direct recovery to the treats page if cached lead is not available
    async function fetchLead() {
      try {
        const leadData = await getLead();
        if (!leadData) {
          return;
        }
      } catch (error) {
        throwToErrorBoundary(error);
      }
    }

    if (!lead && !getLeadState.loading && !getLeadState.called) {
      void fetchLead();
    }
  }, [
    lead,
    getLead,
    throwToErrorBoundary,
    getLeadState.called,
    getLeadState.loading,
  ]);

  const { isLpfRecipeEligible } = checkLpfRecipeStatus(currentPetRecipes || []);

  useEffect(() => {
    // TODO: Remove dependence on lead once we pass it in as a prop
    if (!lead || !recommendedRecipes) {
      return;
    }

    // We default this to an empty array because the first time we load the
    // page, the pet will have no selections
    const recipeSelectionFromLead =
      lead.pets.find(pet => pet.name === petName)?.selection?.fresh?.options
        .recipes ?? [];

    const availableSelectedRecipesNames = getSelectedAvailableRecipes({
      currentPetRecipes: recommendedRecipes,
      currentSelection: recipeSelectionFromLead,
    });

    setCurrentPetRecipeSelection(availableSelectedRecipesNames);
  }, [lead, recommendedRecipes, petName]);

  useEffect(() => {
    // We only want to run this for the first setting of isCustomizingRecipes;
    // the user can control it thereafter
    if (isCustomizingRecipes !== null) {
      return;
    }

    const currentPetRecipeSelectionMatchesRecommendation = isEqual(
      new Set(recommendedRecipes.map(recipe => recipe.name)),
      new Set(currentPetRecipeSelection)
    );

    setIsCustomizingRecipes(!currentPetRecipeSelectionMatchesRecommendation);
  }, [isCustomizingRecipes, recommendedRecipes, currentPetRecipeSelection]);

  useEffect(() => {
    // Ensure page scrolls to top when transitioning between recipes
    scroller.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }, [isCustomizingRecipes]);

  const lpfRecipeNames = currentPetRecipes
    ? currentPetRecipes
        .filter(recipe => recipe.content.productLine === 'lpf')
        .map(recipe => recipe.name)
    : null;

  useSetLpfProductEligibility({
    shouldPersistLpfEligibility: isLpfRecipeEligible,
    email: lead?.email ?? null,
    lpfRecipeNames,
  });

  const handleRecommendedPlanSubmit = useCallback(
    (quote: FetchQuoteQuery['fetchQuote'] | null) => {
      petMixingPlanRatiosState.resetAllPetRatios();
      trackRecipesClickContinueWithRecommendation({
        recommendedRecipes,
      });
      // ! TODO - Update Lead pet with recommendedRecipes
      // ! TODO - add route push to next pet or treats or checkout
      onRecipesCompleted({
        lead,
        isLastPet,
        quote,
      });
    },
    [recommendedRecipes, onRecipesCompleted, lead, isLastPet]
  );

  const handleCustomizeRecipesSubmit = useCallback(
    (quote: FetchQuoteQuery['fetchQuote'] | null) => {
      petMixingPlanRatiosState.resetAllPetRatios();
      // ! TODO - Update Lead pet with `currentPetRecipeSelection` state
      // ! TODO - add route push to next pet or treats or checkout
      onRecipesCompleted({
        lead,
        isLastPet,
        quote,
      });
    },
    [onRecipesCompleted, lead, isLastPet]
  );

  const handleSelectionChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newSelectedRecipes = [...(currentPetRecipeSelection ?? [])];
      const value = e.target.value;
      const isNewValue = !newSelectedRecipes.includes(value);
      if (isNewValue) {
        newSelectedRecipes.push(value);
      } else {
        newSelectedRecipes.splice(newSelectedRecipes.indexOf(value), 1);
      }

      if (newSelectedRecipes.length > MAX_SELECTED_RECIPES) {
        dispatchToast({
          variant: 'neutral',
          children: `You have ${MAX_SELECTED_RECIPES} recipes selected. Please deselect a recipe before choosing another.`,
        });
      } else {
        setCurrentPetRecipeSelection(newSelectedRecipes);
      }
    },
    [currentPetRecipeSelection, dispatchToast]
  );

  const handleReturnToRecommendedPlan = useCallback(() => {
    setIsCustomizingRecipes(false);
    trackRecipesClickBackToRecommendedPlan();
  }, []);

  const handleCustomizePlan = useCallback(() => {
    setIsCustomizingRecipes(true);
    trackRecipesClickSelectYourOwnPlan();
  }, []);

  const isLoading =
    !lead || isCustomizingRecipes === null || !recommendedPetRecipesMap;

  if (isLoading) {
    return (
      <ContentLoader loading={true} height="100vh">
        <></>
      </ContentLoader>
    );
  }

  return (
    <>
      <SignUpHeaderNonTosaPage currentPath={PATH_SIGNUP_RECIPES_V2} />
      <TosaLayout>
        <MultiRecipeModal recipes={recommendedRecipes} petName={petName} />
        {isCustomizingRecipes ? (
          <RecipesSelectionV2
            returnToRecommendedPlan={handleReturnToRecommendedPlan}
            currentPetRecipeSelection={currentPetRecipeSelection ?? []}
            onSubmit={handleCustomizeRecipesSubmit}
            isFormSubmitting={false} // ! TODO - apply an actual submitting state
            handleSelectionChange={handleSelectionChange}
            petName={petName}
            pets={lead.pets}
            recommendedPetRecipesMap={recommendedPetRecipesMap}
            useFeature={useFeature}
          />
        ) : (
          <RecommendedPlanV2
            className={styles.recommendedPlan}
            customizePlan={handleCustomizePlan}
            onSubmit={handleRecommendedPlanSubmit}
            isFormSubmitting={false} // ! TODO - apply an actual submitting state
            pets={lead.pets}
            petName={petName}
            recommendedPetRecipesMap={recommendedPetRecipesMap}
          />
        )}
      </TosaLayout>
    </>
  );
}
