export const PASSWORD_MATCH_VALIDATION_MESSAGE =
  'Make sure the two passwords are the same.';

export const PAGE_SUBHEADER = 'Just like your dog, we’ll never forget you.';

export const PAGE_HEADER_CREATE = 'Create password';
export const PAGE_HEADER_CHANGE = 'Change password';

export const PAGE_TITLE_SUCCESS = 'Password has been changed';
export const PAGE_TITLE_DEFAULT = 'Change password';

export const PASSWORD_INPUT_LABEL = 'Choose a password';
export const PASSWORD_CONFIRMATION_INPUT_LABEL = 'Confirm password';
export const SET_PASSWORD_BUTTON_LABEL = 'Set password';
export const TOKEN_EXPIRED_TITLE = 'Looks like that link expired!';
export const PASSWORD_HAS_BEEN_CHANGED_MESSAGE =
  'Your password has been changed.';
