import { useEffect } from 'react';

import { setIsOnDoNotSellShareList } from '@farmersdog/lead-browser-storage';
import { setCorePostGresUserId } from '@farmersdog/lead-browser-storage/src/cookie/cookie';
import { ga4Mounter } from '@farmersdog/pixels';

import { useGetLead } from '../../graphql/queries/useGetLead';
import { reconcileCouponCookieAndLeadDiscount } from '../../utils/reconcileCouponCookieAndLeadDiscount';

export function useIdentifyLead() {
  const { data } = useGetLead();
  const lead = data?.lead;

  useEffect(() => {
    if (typeof lead?.consent?.dnss === 'boolean') {
      setIsOnDoNotSellShareList(lead.consent.dnss);
    }
  }, [lead?.consent?.dnss]);

  useEffect(() => {
    reconcileCouponCookieAndLeadDiscount({
      leadDiscount: lead?.discount,
      temporaryGrainsDiscount: lead?.temporaryGrainsDiscount,
    });
  }, [lead?.discount, lead?.temporaryGrainsDiscount]);

  useEffect(() => {
    if (lead?.corePostgresUserId) {
      ga4Mounter.sendUserId({ userId: lead.corePostgresUserId });
      setCorePostGresUserId(String(lead.corePostgresUserId));
    }
  }, [lead?.corePostgresUserId]);
}
