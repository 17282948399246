import { useMemo } from 'react';
import { useRouteMatch } from 'react-router';

import { paths } from '@farmersdog/constants';

import { useTosaAuthToken } from '../../../SignupTosa/useTosaAuthToken';
import type { OverallProgress } from 'src/reducers/signup/utils/calculateProgress';

const {
  PATH_SIGNUP_ME,
  PATH_SIGNUP_PETS,
  PATH_SIGNUP_RECIPES,
  PATH_SIGNUP_PLANS,
  PATH_SIGNUP_CHECKOUT,
  PATH_SIGNUP_TOSA_ME_OVERRIDE,
  PATH_SIGNUP_TOSA_PETS_OVERRIDE,
} = paths;

/**
 * Check if a path contains any of the paths in the pathInputs array
 */
function pathContains(pathname: string, ...pathInputs: string[]) {
  return pathInputs.some(path => pathname.search(path) > -1);
}

/**
 * Calculate the percentage of a step that has been completed
 * @returns the total percentage completed from this step
 */
function getStepPercentage(innerSteps: boolean[]) {
  const completedInnerSteps = innerSteps.filter(Boolean);
  return 100 * (completedInnerSteps.length / innerSteps.length);
}

export interface Step {
  path: string;
  label: string;
  completed: boolean;
  active: boolean;
  percentage: number;
}

export interface Progress {
  overall: OverallProgress;
  me: Record<string, boolean>;
  pets: Record<string, boolean>[];
  plans: Record<string, boolean>[];
}

/**
 * Reusable step logic as a function of progress and pathname
 * @returns a memoized array of steps that will recalculate when progress and pathname change
 */
export function useSteps(progress: Progress, pathname: string): Step[] {
  const tosaAuthToken = useTosaAuthToken();
  const isCheckoutPage = useRouteMatch(PATH_SIGNUP_CHECKOUT);
  const isRecipesPage = useRouteMatch(PATH_SIGNUP_RECIPES);
  const isPlansPage = useRouteMatch(PATH_SIGNUP_PLANS);

  const linkShouldReturnToTosa =
    tosaAuthToken && (isCheckoutPage || isRecipesPage || isPlansPage);

  return useMemo(() => {
    const { overall, pets, plans, me } = progress;
    const safePets = Array.isArray(pets) ? pets : [{}];

    return [
      {
        path: linkShouldReturnToTosa
          ? PATH_SIGNUP_TOSA_ME_OVERRIDE
          : PATH_SIGNUP_ME,
        label: 'Me',
        completed: overall.me === 1 && !pathContains(pathname, PATH_SIGNUP_ME),
        active: pathContains(pathname, PATH_SIGNUP_ME),
        percentage: getStepPercentage(Object.values(me)),
      },
      {
        path: linkShouldReturnToTosa
          ? PATH_SIGNUP_TOSA_PETS_OVERRIDE
          : PATH_SIGNUP_PETS,
        label: 'My Dogs',
        completed:
          overall.pets === 1 &&
          !pathContains(pathname, PATH_SIGNUP_ME, PATH_SIGNUP_PETS),
        active: pathContains(pathname, PATH_SIGNUP_PETS),
        percentage: getStepPercentage(
          safePets.flatMap(Object.values) as boolean[]
        ),
      },
      {
        path: PATH_SIGNUP_RECIPES,
        label: 'Your Plan',
        completed:
          overall.plans === 1 &&
          !pathContains(
            pathname,
            PATH_SIGNUP_ME,
            PATH_SIGNUP_PETS,
            PATH_SIGNUP_RECIPES,
            PATH_SIGNUP_PLANS
          ),
        active: pathContains(pathname, PATH_SIGNUP_RECIPES, PATH_SIGNUP_PLANS),
        percentage: getStepPercentage(
          plans.flatMap(Object.values) as boolean[]
        ),
      },
      {
        path: PATH_SIGNUP_CHECKOUT,
        label: 'Checkout',
        completed:
          overall.plans === 1 &&
          !pathContains(
            pathname,
            PATH_SIGNUP_ME,
            PATH_SIGNUP_PETS,
            PATH_SIGNUP_RECIPES,
            PATH_SIGNUP_PLANS
          ),
        active: pathContains(pathname, PATH_SIGNUP_CHECKOUT),
        percentage: 0,
      },
    ];
  }, [progress, pathname, linkShouldReturnToTosa]);
}
