import { RecipeNames } from '@farmersdog/constants';

import { useFetchQuote } from '../../../hooks/useFetchQuote';

import type { FetchLeadQuery, QuoteResponse } from '../../../graphql/types';
import type { PetRecipes } from '../../../types';

const CHEAPEST_CORE_RECIPES = [RecipeNames.BEEF];
const CHEAPEST_LPF_RECIPES = [RecipeNames.CHICKEN_OATS];

export interface UseCheapestQuotesProps {
  currentPetName: string;
  recommendedPetRecipesMap: PetRecipes;
  pets: FetchLeadQuery['lead']['pets'];
}

export interface QuoteResponseWithProductLine extends QuoteResponse {
  productLine: string;
}

export const useCheapestQuotesV2 = ({
  currentPetName,
  recommendedPetRecipesMap,
  pets,
}: UseCheapestQuotesProps) => {
  const quoteArguments = {
    currentPetName,
    recommendedPetRecipesMap,
    pets,
    shouldTrack: false,
  };

  const { quote: cheapestCoreQuote } = useFetchQuote({
    ...quoteArguments,
    currentPetRecipeSelection: CHEAPEST_CORE_RECIPES,
  });

  const { quote: cheapestLpfQuote } = useFetchQuote({
    ...quoteArguments,
    currentPetRecipeSelection: CHEAPEST_LPF_RECIPES,
  });

  return { cheapestCoreQuote, cheapestLpfQuote };
};
