import { useSelector } from 'react-redux';
import { GridItem } from '@farmersdog/corgi-x';
import { CtaTracker, TrackingEvent } from '@farmersdog/utils';
import { selectRequestAttributes } from 'src/reducers/referrer';
import { trackHomepageSuccess } from 'src/analytics';

import { ExtendedButton } from '../../../ExtendedButton';

import styles from './HeroCta.module.css';

type HeroCtaProps = {
  linkToFreshSignup: string;
};

const ctaLabel = 'Get Started';

export const HeroCta = ({ linkToFreshSignup }: HeroCtaProps) => {
  const requestAttributes = useSelector(selectRequestAttributes);
  return (
    <GridItem justifyContent="center">
      <CtaTracker
        trackingFunction={trackHomepageSuccess}
        event={TrackingEvent.homepageSuccess}
        trackingData={{ location: 'Hero', ctaCopy: ctaLabel }}
        requestAttributes={requestAttributes}
      >
        <ExtendedButton
          variant="solid-kale"
          type="link"
          to={linkToFreshSignup}
          className={styles.heroCta}
        >
          {ctaLabel}
        </ExtendedButton>
      </CtaTracker>
    </GridItem>
  );
};
