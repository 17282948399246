import type { DiyRecipe } from '../types';
import { PrimaryProtein, RecipesCategory } from '../types';
import genericDiyRecipeImageWebp from '../assets/generic-diy-recipe-image.webp';

export const lowFatBeefAndSweetPotato: DiyRecipe = {
  name: 'Low-Fat Beef & Sweet Potato',
  id: 'low-fat-beef-&-sweet-potato',
  primaryProtein: PrimaryProtein.Beef,
  category: RecipesCategory.LowFat,
  ingredients: [
    {
      name: '95% lean ground beef',
      quantity: 900,
      unit: 'g',
      alternativeQuantity: 2,
      alternativeUnit: 'lb',
    },
    {
      name: 'sweet potato, shredded or finely diced',
      quantity: 550,
      unit: 'g',
      alternativeQuantity: 1.2,
      alternativeUnit: 'lb',
    },
    {
      name: 'cooked couscous',
      quantity: 500,
      unit: 'g',
      alternativeQuantity: 1.1,
      alternativeUnit: 'lb',
    },
    {
      name: 'egg whites',
      quantity: 243,
      unit: 'g',
      alternativeQuantity: 9,
      alternativeUnit: 'egg',
    },
    {
      name: 'carrots, shredded or cut into 1/4 inch dice',
      quantity: 200,
      unit: 'g',
      alternativeQuantity: 0.44,
      alternativeUnit: 'lb',
    },
    {
      name: 'The Farmer’s Dog DIY Nutrient Mix',
      quantity: 1,
      unit: 'packet',
    },
    {
      name: 'green beans',
      quantity: 175,
      unit: 'g',
      alternativeQuantity: 0.37,
      alternativeUnit: 'lb',
    },
    {
      name: 'sunflower oil',
      quantity: 14,
      unit: 'g',
      alternativeQuantity: 3,
      alternativeUnit: 'teaspoon',
    },
    {
      name: 'salmon oil',
      quantity: 0.9,
      unit: 'g',
      alternativeQuantity: 0.2,
      alternativeUnit: 'teaspoon',
    },
  ],
  ingredientsNote:
    'Fresh or frozen vegetables may be used in these recipes. For best results, weigh by grams and use food safe thermometer.',
  cookTime: '40 mins',
  ingredientSummary:
    'Beef, Sweet Potato, Couscous, Egg White, Carrots, Green Beans, DIY Nutrient Mix, Sunflower Oil, Salmon Oil',
  utensils: [
    '5+ quart pot/skillet/dutch oven',
    'knife and cutting board or food processor (or buy pre-cut frozen)',
    'spatula',
    'kitchen scale',
    'food safe thermometer',
  ],
  image: genericDiyRecipeImageWebp,
  instructions: [
    {
      name: 'Step 1',
      description:
        'Cook the couscous according to the instructions on the packaging and set aside.',
    },
    {
      name: 'Step 2',
      description:
        'Place pot/skillet/dutch oven over medium to medium-high heat on the stovetop.',
    },
    {
      name: 'Step 3',
      description:
        'Add the ground beef to the pot. Break apart and stir until the meat is no longer pink and is broken into small pieces.',
    },
    {
      name: 'Step 4',
      description:
        'Add the sweet potatoes, carrots, cooked couscous, and green beans. Cook until the vegetables are soft and easily break apart or are tender when pierced with a fork.',
      note: 'Be sure to check that all ingredients are soft and easily broken in order to avoid choking hazards. Large chunks may require additional cooking time. Do not drain any liquid or fat that’s a result of the cooking process.',
    },
    {
      name: 'Step 5',
      description:
        'Add the egg whites to the pot and stir to evenly distribute. Cook until the eggs are firm and no longer runny.',
    },
    {
      name: 'Step 6',
      description:
        'Using a food safe thermometer, check the temperature at several places throughout the pot to verify that a food safe temperature of 165°F is reached.',
      note: 'In order to maintain safe, ready-to-eat meals it is important that all food reaches the USDA guidelines for safety.',
    },
    {
      name: 'Step 7',
      description:
        'Remove the pot from the heat and add sunflower oil and salmon oil. Then, sprinkle {numberOfPackets} of The Farmer’s Dog DIY Nutrient Mix evenly across the surface. Stir to evenly distribute throughout the food until you can no longer see the nutrient mix.',
    },
  ],
  calories: 2430,
};
