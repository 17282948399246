import { useMemo } from 'react';

import { Logger } from '@farmersdog/logger';

import { usePetsWithRecommendedCalories } from '../../../graphql/queries/usePetsWithRecommendedCalories';

const logger = new Logger('tosa:frontend:useGetRecommendedCalories');

export interface PetNamesAndRecommendedCalories {
  petName: string;
  requestedCalories: number;
}

export function usePetNamesAndRecommendedCalories(): {
  petNamesAndRecommendedCalories: PetNamesAndRecommendedCalories[] | undefined;
  refetch: () => void;
} {
  const { data, error, refetch } = usePetsWithRecommendedCalories();

  if (error) {
    logger.error('Failed to fetch recommended calories', {
      error,
    });
  }

  const petNamesAndRecommendedCalories = useMemo(() => {
    if (!data) {
      return undefined;
    }

    const mappedPetData = data.lead.pets.map(pet => ({
      petName: pet.name,
      requestedCalories: pet.recommendedCalories,
    }));

    const everyPetHasRecommendedCalories = mappedPetData.every(
      (pet): pet is PetNamesAndRecommendedCalories =>
        typeof pet.requestedCalories === 'number'
    );

    if (!everyPetHasRecommendedCalories) {
      logger.error('Not all pets have recommended calories', {
        mappedPetData,
      });

      return undefined;
    }

    return mappedPetData;
  }, [data]);

  return { petNamesAndRecommendedCalories, refetch };
}
