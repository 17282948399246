import type { DiscountTypeAndAmount } from '../types';
import type {
  ReferrerResponse,
  CouponResponse,
  UserReferrerResponse,
} from './getCoupon';
import { REFERRAL_NOT_FOUND_RESPONSE_DATA } from './getCoupon';

interface GetDiscountTypeAndAmountArgs {
  coupon?: ReferrerResponse;
  defaultDiscount: DiscountTypeAndAmount;
}

export function getDiscountTypeAndAmount({
  coupon,
  defaultDiscount,
}: GetDiscountTypeAndAmountArgs): DiscountTypeAndAmount {
  if (!isReferrerValid(coupon)) {
    return defaultDiscount;
  }

  if (hasDiscountTypeAndAmount(coupon)) {
    return {
      discountAmount: coupon.discountAmount,
      discountType: coupon.discountType,
    };
  }

  return defaultDiscount;
}

export function isReferrerValid(referrer?: ReferrerResponse) {
  if (!referrer) {
    return false;
  }

  const referrerHasInvalidProp = Object.prototype.hasOwnProperty.call(
    referrer,
    'invalid'
  );

  if (referrer === REFERRAL_NOT_FOUND_RESPONSE_DATA) {
    return false;
  }

  if (!referrerHasInvalidProp) {
    // assume valid referrer if not supplied with invalid prop
    return true;
  }

  return !referrer.invalid;
}

function hasDiscountTypeAndAmount(
  referrer: unknown
): referrer is DiscountTypeAndAmount {
  return (
    !!referrer &&
    typeof referrer === 'object' &&
    'discountAmount' in referrer &&
    'discountType' in referrer &&
    typeof referrer.discountAmount === 'number' &&
    typeof referrer.discountType === 'string'
  );
}

export function isCouponResponse(
  referrer: ReferrerResponse
): referrer is CouponResponse {
  return Object.prototype.hasOwnProperty.call(referrer, 'type');
}

export function isUserReferrerResponse(
  referrer: ReferrerResponse
): referrer is UserReferrerResponse {
  return Object.prototype.hasOwnProperty.call(referrer, 'name');
}
