/* CH#49502: Used by RET and CVR */
import type { RefObject } from 'react';

import {
  AppNav,
  Button,
  Link,
  Banner as CorgiBanner,
  useViewport,
} from '@farmersdog/corgi-x';
import DiscountCopy from 'src/components/DiscountCopy';

import useReferral from 'src/hooks/useReferral';
import { useReferrer } from 'src/hooks/useReferrer';

import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';
import { CtaTracker, TrackingEvent } from '@farmersdog/utils';

import useNavigationItems from './hooks/useNavigationItems';
import { getLinkToFreshSignup } from 'src/pages/Signup/DIY/utils/getLinkToSignupFlow';
import {
  CXAlertBanner,
  useShouldShowCXAlertBanner,
} from 'src/components/CXAlertBanner';
import { useFeature } from 'src/abTesting';
import { CVR_MOVE_NAV_LOGO_DOWN } from 'src/abTesting/features';

import { DiscountCTAText, getCtaText } from '../DiscountCTAText';
import classNames from 'classnames';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';
import styles from './Navigation.module.scss';
import { useVetOptimizationLinks } from 'src/components/Navigation/hooks/useVetOptimizationLinks';
import { VetLinkCtaCard } from './VetLinkCtaCard';
import { selectRequestAttributes } from 'src/reducers/referrer';
import { useSelector } from 'react-redux';
import { trackHomepageSuccess } from 'src/analytics';

function DefaultBanner() {
  const requestAttributes = useSelector(selectRequestAttributes);
  const { fellBackToDefaultDiscount } = useReferral();
  const { hasCalledFetch, discountAmount, discountType, referrer } =
    useReferrer();
  const shouldShowCXAlertBanner = useShouldShowCXAlertBanner();

  const highlightedText = discountAmount === 0 ? 'START NOW' : 'REDEEM NOW';

  return (
    <>
      {shouldShowCXAlertBanner ? (
        <CXAlertBanner />
      ) : (
        <CorgiBanner className={styles.banner}>
          <CtaTracker
            event={TrackingEvent.homepageSuccess}
            trackingData={{ location: 'Banner', ctaCopy: highlightedText }}
            requestAttributes={requestAttributes}
            trackingFunction={trackHomepageSuccess}
          >
            <Link to={getLinkToFreshSignup(PATH_SIGNUP_ME)}>
              {hasCalledFetch && (
                <DiscountCopy
                  fellBackToDefaultDiscount={fellBackToDefaultDiscount}
                  discountAmount={discountAmount}
                  discountType={discountType}
                  referrer={referrer}
                  highlightedText={highlightedText}
                />
              )}
            </Link>
          </CtaTracker>
        </CorgiBanner>
      )}
    </>
  );
}

interface NavigationProps {
  overlay?: boolean;
  ctaTrigger?: RefObject<HTMLElement>;
  banner?: React.ReactElement;
  ctaDetails?: { text: string; to: string };
}
function Navigation({
  overlay,
  ctaTrigger,
  banner,
  ctaDetails,
}: NavigationProps) {
  const requestAttributes = useSelector(selectRequestAttributes);
  const ctaTo = ctaDetails?.to ?? getLinkToFreshSignup(PATH_SIGNUP_ME);
  const ctaText = ctaDetails?.text ?? <DiscountCTAText />;
  const vetOptimizationLinks = useVetOptimizationLinks();
  const { discountAmount, discountType } = useReferrer();

  const viewport = useViewport();

  const items = useNavigationItems({
    recommendVetPortal: true,
    vetOptimizationLinks,
  });

  const isTabletOrMobile = viewport.xs && !viewport.lg;
  const isLogoDown =
    useFeature(CVR_MOVE_NAV_LOGO_DOWN, { attributes: { isTabletOrMobile } })
      .treatment === 'on';

  const { isAccessibilityThemeOn, accessibilityThemeStyles } =
    useAccessibilityThemeContext();
  const ctaStylings = classNames({
    [accessibilityThemeStyles.navigationCta]: isAccessibilityThemeOn,
  });

  const afterMenuListEl = vetOptimizationLinks?.showPillAndCard ? (
    <VetLinkCtaCard />
  ) : undefined;

  return (
    <>
      {banner ?? <DefaultBanner />}
      <header className={styles.header}>
        <AppNav
          overlayMode={overlay ? 'dark' : undefined}
          shiftLogoUp={!isLogoDown}
          {...items}
          callToAction={
            ctaTrigger && {
              element: (
                <CtaTracker
                  event={TrackingEvent.homepageSuccess}
                  trackingFunction={trackHomepageSuccess}
                  requestAttributes={requestAttributes}
                  trackingData={{
                    location: 'Sticky Nav',
                    ctaCopy: getCtaText(discountAmount, discountType)
                      .trackableText,
                  }}
                >
                  <Button
                    className={ctaStylings}
                    variant="special"
                    type="link"
                    to={ctaTo}
                  >
                    {ctaText}
                  </Button>
                </CtaTracker>
              ),
              trigger: ctaTrigger,
            }
          }
          menuCallToAction={
            ctaTrigger && (
              <Button className={ctaStylings} type="link" to={ctaTo}>
                {ctaText}
              </Button>
            )
          }
          afterMenuListEl={afterMenuListEl}
        />
      </header>
    </>
  );
}

export default Navigation;
