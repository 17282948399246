import dogWalkingDesktopJpg from './q5-dog-walking-background-desktop.jpg';
import dogWalkingDesktopWebp from './q5-dog-walking-background-desktop.webp';

import dogWalkingMobileJpg from './q5-dog-walking-background-mobile.jpg';
import dogWalkingMobileWebp from './q5-dog-walking-background-mobile.webp';

export const mobileDogWalking = {
  webP: dogWalkingMobileWebp,
  jpg: dogWalkingMobileJpg,
};

export const desktopDogWalking = {
  webP: dogWalkingDesktopWebp,
  jpg: dogWalkingDesktopJpg,
};
