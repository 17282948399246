import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { PATH_HOME } from '@farmersdog/constants/paths';

import { trackKatzPageView } from './trackKatzPageView';

export const trackKatzPageViewRoutes = [PATH_HOME];

/**
 * Run an effect when the location pathname changes to track page views with the
 * data required for an Katz page view event.
 */
export function useTrackKatzPageView() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (trackKatzPageViewRoutes.includes(pathname)) {
      trackKatzPageView();
    }
  }, [pathname]);
}
