import { GridItem, Text, Nowrap } from '@farmersdog/corgi-x';
import { UnderlineSvg } from './assets';
import styles from './HeroTitle.module.css';

export const HeroTitle = () => (
  <GridItem>
    <Text
      as="h1"
      bold
      color="Kale3"
      variant="heading-28"
      align="center"
      bottomSpacing="sm"
      className={styles.heroTitle}
    >
      The recipe for
      <br /> a long healthy life
    </Text>
    <Text
      as="p"
      bold
      color="Kale3"
      variant="heading-16"
      align="center"
      vSpacing="none"
      className={styles.heroSubtitle}
    >
      <Nowrap>Made by Board-Certified </Nowrap>
      <Nowrap>Vet Nutritionists.</Nowrap>
      <br />
      <Nowrap>Plans tailored just </Nowrap>
      <Nowrap>
        <em className={styles.underlinedText}>
          for your dog.
          <UnderlineSvg className={styles.underline} />
        </em>
      </Nowrap>
    </Text>
  </GridItem>
);
