import type { DiyRecipe } from '../types';
import { PrimaryProtein } from '../types';
import genericDiyRecipeImageWebp from '../assets/generic-diy-recipe-image.webp';

export const porkSweetPotatoAndRice: DiyRecipe = {
  name: 'Pork, Sweet Potato & Rice',
  id: 'pork-sweet potato-and-rice',
  primaryProtein: PrimaryProtein.Pork,
  ingredients: [
    {
      name: 'pork tenderloin cut into 1⁄2 inch or smaller cubes',
      quantity: 1200,
      unit: 'g',
      alternativeQuantity: 2.6,
      alternativeUnit: 'lb',
    },
    {
      name: 'cooked white rice',
      quantity: 350,
      unit: 'g',
      alternativeQuantity: 2.25,
      alternativeUnit: 'cup',
    },
    {
      name: 'sweet potato, shredded or finely diced',
      quantity: 300,
      unit: 'g',
      alternativeQuantity: 2.25,
      alternativeUnit: 'cup',
    },
    {
      name: 'broccoli, riced or finely chopped',
      quantity: 200,
      unit: 'g',
      alternativeQuantity: 2.25,
      alternativeUnit: 'cup',
    },
    {
      name: 'sunflower oil',
      quantity: 50,
      unit: 'g',
      alternativeQuantity: 11,
      alternativeUnit: 'teaspoon',
    },
    {
      name: 'The Farmer’s Dog DIY Nutrient Mix',
      quantity: 1,
      unit: 'packet',
    },
    {
      name: 'salmon oil',
      quantity: 15,
      unit: 'g',
      alternativeQuantity: 3.25,
      alternativeUnit: 'teaspoon',
    },
  ],
  ingredientsNote:
    'Fresh or frozen vegetables may be used in these recipes. For best results, weigh by grams and use food safe thermometer.',
  cookTime: '40 mins',
  ingredientSummary:
    'Pork Tenderloin, White Rice, Sweet Potato, Broccoli, Sunflower Oil, DIY Nutrient Mix, Salmon Oil',
  utensils: [
    '5+ quart pot/skillet/dutch oven',
    'knife and cutting board or food processor (or buy pre-cut frozen)',
    'spatula',
    'kitchen scale',
    'food safe thermometer',
  ],
  image: genericDiyRecipeImageWebp,
  instructions: [
    {
      name: 'Step 1',
      description:
        'Cook rice according to the instructions on the packaging and set aside.',
    },
    {
      name: 'Step 2',
      description:
        'Place pot/skillet/dutch oven over medium to medium-high heat on the stovetop. Pour the sunflower oil in and spread it to evenly coat the bottom.',
    },
    {
      name: 'Step 3',
      description:
        'Add the pork to the pot. Stir until the meat is no longer translucent/pink.',
    },
    {
      name: 'Step 4',
      description:
        'Add the broccoli and sweet potato. Cook until the vegetables are soft and easily break apart or are tender when pierced with a fork.',
      note: 'Be sure to check that all ingredients are soft and easily broken in order to avoid choking hazards. Large chunks may require additional cooking time. Do not drain any fat or liquid that’s a result of the cooking process.',
    },
    {
      name: 'Step 5',
      description:
        'Add the cooked rice, sunflower oil, and salmon oil and stir to evenly distribute. Using a food safe thermometer, check the temperature at several places throughout the pot to verify that a food safe temperature of 165°F is reached.',
      note: 'In order to maintain safe, ready-to-eat meals it is important that all food reaches the USDA guidelines for safety.',
    },
    {
      name: 'Step 6',
      description:
        'Remove the pot from the heat and sprinkle the {numberOfPackets} of The Farmer’s Dog DIY Nutrient Mix evenly across the surface. Stir to evenly distribute throughout the food until you can no longer see the nutrient mix.',
    },
  ],
  calories: 2430,
};
